.account_info {
	background-color: rgb(246 246 246);
	padding: 20px;
	margin: 10px 0;
}

.account_info .css-14el2xx-placeholder {
	font-size: 14px;
}

.custom_css_select {
	width: 100%;
	min-width: 205px;
}

.account_info .comp_title {
	border-bottom: #e2e0e0 solid 1px;
	margin: 5px 0;
	font-weight: 700;
}

.account_info .comp_title h5 {
	font-weight: 700;
}

.account_info .account_prof {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0;
}

.account_info .account_prof .profile_img {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}

.account_info .account_prof .profile_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.account_info.autoVanish_box .comp_title {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
}

.account_info.autoVanish_box .comp_title h5 {
	margin-right: 15px;
}

.account_info.autoVanish_box .autoVanish span {
	font-size: 12px;
}

.inp_fields.autoVanish__field {
	flex-direction: row;
	flex-wrap: nowrap;
}

.inp_fields.autoVanish__field.inp_fields > input {
	flex-direction: row;
	flex-wrap: nowrap;
}

@media screen and (max-width: 1024px) {
	.account_info .acc_form .acc_btn {
		width: 45% !important;
		margin: 10px 0;
	}
}

@media screen and (max-width: 425px) {
	.account_info .inp_fields .emp_radio_inputs label {
		width: 100%;
	}

	.account_info .inp_fields {
		margin: 10px 0 !important;
	}

	.account_info .acc_form .acc_btn {
		justify-content: unset !important;
		width: 100% !important;
		margin: 10px 0;
	}

	.account_info .acc_form .acc_btn button {
		min-width: 100px !important;
		padding: 8px 15px !important;
	}

	.account_info .account_prof {
		flex-direction: column;
		gap: 15px;
	}

	.account_info .account_prof .change_prof_btn button {
		padding: 8px 15px !important;
		margin-top: 10px;
	}

	.account_info .acc_form .acc_btn .save_btn {
		margin-left: 10px;
	}
}

.account_info .account_prof .change_prof_btn {
	background-color: #fff;
	border-radius: 27px;
	border: #6d2ef1 solid 1px;
	padding: 8px 30px;
	font-size: 15px;
	color: #6d2ef1;
	font-weight: 700;
	text-align: center;
	position: relative;
	width: 230px;
}

.account_info .account_prof .change_prof_btn input {
	position: absolute;
	top: 0;
	left: 0;
	width: 230px;
	height: 40px;
	visibility: hidden;
}

.account_info .inp_fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	/* margin: 20px 0; */
	margin: 20px 0 0 0;
}

.account_info .inp_fields > span {
	font-size: 15px;
	font-weight: 600;
	/* margin: 5px 0; */
}

.account_info .inp_fields > input {
	width: 100%;
	height: 42px;
	outline: none;
	border: 1px solid #e2e0e0;
	padding: 0 10px;
	border-radius: 4px;
}

.account_info .inp_fields > input::placeholder {
	color: #afafaf;
}

.account_info .inp_fields > select {
	width: 100%;
	height: 42px;
	outline: none;
	border: 1px solid #e2e0e0;
	padding: 0 10px;
	border-radius: 4px;
}

.account_info .inp_fields > textarea {
	resize: none;
	width: 100%;
	height: 120px;
	outline: none;
	border: 1px solid #e2e0e0;
	padding: 0 10px;
	font-size: 14px;
	border-radius: 4px;
}

.account_info .acc_form .acc_btn {
	width: 31%;
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}

.account_info .acc_form .acc_btn button {
	background-color: #fff;
	border-radius: 27px;
	border: #6d2ef1 solid 1px;
	min-width: 130px;
	padding: 8px 30px;
	font-size: 15px;
	color: #6d2ef1;
	font-weight: 700;
	text-align: center;
}

.account_info .acc_form .acc_btn .save_btn {
	background-color: #6d2ef1;
	border-radius: 27px;
	-webkit-border-radius: 27px;
	-moz-border-radius: 27px;
	border: #6d2ef1 solid 1px;
	min-width: 130px;
	padding: 8px 30px;
	font-size: 15px;
	color: #fff;
	font-weight: 700;
	text-align: center;
}

.account_info .css-1okebmr-indicatorSeparator {
	display: none;
}

.account_info .custom_css_select div {
	border: none !important;
	border-radius: 2px !important;
}

.account_info .custom_css_select {
	border: #cbcaca solid 1px !important;
	border-radius: 2px !important;
}

.account_info .inp_fields .emp_radio_inputs {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.account_info .inp_fields .emp_radio_inputs label {
	display: flex;
	align-items: center;
}

.account_info .inp_fields .emp_radio_inputs span {
	font-size: 13px;
	margin: 0 0 0 5px;
}

.account_info .account_type_alert {
	font-size: 13px;
	margin: 15px 0;
}

.account_info .account_type_alert span {
	font-weight: bold;
}

.account_info .acc_btn .cls_acc_btn {
	background-color: #6d2ef1 !important;
	color: #fff !important;
}

.select-reason label > span {
	font-size: 11px;
	margin: 0 0 0 5px;
}

.select-reason .popup-radio label {
	display: flex;
	align-items: center;
	margin: 7px 0;
}

.get-startedQuestion {
	/* margin-top: 20px;
	margin-bottom: 40px; */
	width: 200px;
	text-align: center;
	background-color: #6d2ef1;
	color: #fff;
	padding: 10px 0;
	border-radius: 30px;
	text-decoration: none;
	cursor: pointer;
}

.questionOnebtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
	width: 75%;
}

.get-startedQuestion:hover {
	color: #fff;
}

@media (max-width: 768px) {
	.HourlyType_row {
		flex-direction: column;
	}

	.hourlyType_item {
		width: 80%;
		margin-bottom: 10px;
	}
}

@media (max-width: 1199px) {
	.TimeSheetPendingTimeSheetPending {
		overflow-x: scroll;
	}

	.timesheetPending {
		width: 1000px;
	}
}

@media (max-width: 767px) {
	.fixedPendingfixedPending {
		overflow-x: scroll;
	}

	.fixedPending {
		width: 500px;
	}

	.fixedDataname_row {
		width: 50% !important;
	}

	.fixedDataname_rowHeading {
		width: 50% !important;
	}
}

.bosoodosdot_inp {
	border-bottom: none !important;
}

@media (max-width: 767px) {
	.swiper-wrapper {
		width: 50% !important;
	}
}

@media (max-width: 1023px) {
	.swiper-wrapper {
		width: 50% !important;
	}
}

.learn_and_earn_section1 {
	width: 70%;
	margin: 0 auto;
	padding: 210px 0 60px 0;
}

.learn_and_earn_section1 h1 {
	color: #00065a;
	font-size: 60px;
	font-weight: 800;
	letter-spacing: -1px;
	margin: 0 auto;
	position: relative;
	z-index: 2;
	text-align: center;
	width: 785px;
}

.learn_and_earn_section1 h1 .home_number_one {
	font-weight: 700;
	font-size: 280px;
	position: absolute;
	top: -150px;
	left: 0;
	right: 0;
	opacity: 0.2;
	color: transparent;
	-webkit-text-stroke: 2px rgb(110, 106, 106);
	text-shadow: none;
}

.learn_and_earn_section1 .pink-line {
	content: " ";
	position: absolute;
	background-color: #ff88fe;
	height: 26px;
	width: 426px;
	z-index: -1;
	top: 48px;
	right: 55px;
}

.learn_and_earn_section1 p {
	text-align: center;
	font-size: 16px;
	line-height: 25px;
	margin-top: 50px;
	padding: 0 100px;
}

.home_second_middle {
	display: flex;
	padding-top: 80px;
	margin-right: -107px;
	position: relative;
}

.overlayback {
	background: rgb(255, 255, 255);
	background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0.2049194677871149) 70%
	);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	/* left: 500px; */
}

.home_second_middle2 .overlayback {
	background: rgb(255, 255, 255);
	background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0,
			rgba(255, 255, 255, 1) 48%
	);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.home_second_middle .home_left_div {
	width: 50%;
	margin-top: 80px;
	z-index: 200;
}

.home_second_middle .home_right_div {
	width: 50%;
}

.home_right_div .home_main_img {
	width: 100%;
	height: 100%;
}

.home_second_middle .home_text_h3 {
	color: #00065a;
	font-weight: 800;
	letter-spacing: -1px;
	font-size: 60px;
	width: 422px;
	position: relative;
}

.home_second_middle .home_number_three {
	-webkit-text-stroke: 2px #6e6a6a;
	color: transparent;
	font-size: 280px;
	font-weight: 700;
	opacity: 0.2;
	position: absolute;
	top: -180px;
	right: -50px;
	text-shadow: none;
}

.home_second_middle .pink-linethree {
	background-color: #ff88fe;
	content: "";
	height: 25px;
	left: 0;
	position: absolute;
	bottom: -5px;
	width: 400px;
	z-index: -1;
}

.home_second_middle .home_left_div_parathree {
	font-size: 16px;
	line-height: 25px;
	margin-top: 40px;
	width: 80%;
}

.home_second_middle2 {
	display: flex;
	padding-top: 110px;
	margin-left: -150px;
	position: relative;
}

.home_second_middle2 .home_left_div2 {
	width: 50%;
}

.home_second_middle2 .home_left_div2 .home_main_img {
	width: 100%;
	height: 100%;
}

.home_second_middle2 .home_right_div2 {
	width: 50%;
	margin-top: 80px;
	margin-left: -70px;
	z-index: 200;
}

.home_second_middle2 .home_text_h4 {
	color: #00065a;
	font-weight: 800;
	letter-spacing: -1px;
	font-size: 60px;
	position: relative;
	width: 580px;
}

.home_second_middle2 .home_left_div_para4 {
	font-size: 16px;
	line-height: 25px;
	margin-top: 40px;
	width: 80%;
}

.home_second_middle2 .pink-linefour {
	background-color: #ff88fe;
	content: "";
	height: 25px;
	position: absolute;
	z-index: -1;
	width: 346px;
	left: 106px;
	bottom: -5px;
}

.home_second_middle2 .home_number_four {
	-webkit-text-stroke: 2px #6e6a6a;
	color: transparent;
	font-size: 280px;
	font-weight: 700;
	opacity: 0.2;
	position: absolute;
	top: -200px;
	/* left: 115px; */
	text-shadow: none;
}

.home_second_middle5 {
	display: flex;
	padding-top: 100px;
	margin-right: -107px;
	position: relative;
}

.home_second_middle5 .home_left_div5 {
	width: 50%;
	margin-top: 100px;
	z-index: 200;
}

.home_second_middle5 .home_right_div5 {
	width: 50%;
}

.home_right_div5 .home_main_img {
	width: 100%;
	height: 100%;
}

.home_second_middle5 .home_text_h5 {
	color: #00065a;
	font-weight: 800;
	letter-spacing: -1px;
	font-size: 57px;
	position: relative;
	width: 600px;
}

.home_second_middle5 .pink-linefive {
	background-color: #ff88fe;
	content: "";
	height: 25px;
	position: absolute;
	z-index: -1;
	left: 0;
	top: 45px;
	width: 400px;
}

.home_second_middle5 .home_left_div_parafive {
	font-size: 16px;
	line-height: 25px;
	margin-top: 40px;
	width: 90%;
}

.home_second_middle5 .home_number_five {
	-webkit-text-stroke: 2px #6e6a6a;
	color: transparent;
	font-size: 300px;
	font-weight: 700;
	opacity: 0.2;
	position: absolute;
	text-shadow: none;
	left: 20px;
	top: -200px;
}

.learn_and_earn_section5_wrapper {
	background-color: #ebf4ff;
	padding: 80px 0 50px 0;
}

.learn_and_earn_section5companies {
	width: 710px;
	margin: 0 auto;
	padding: 0 !important;
	position: relative;
}

.learn_and_earn_section5freelancer {
	width: 800px;
	margin: 0 auto;
	padding: 0 !important;
	position: relative;
}

.learn_and_earn_section5 .btn_div {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.learn_and_earn_section5 .home_last_btn {
	background-color: #170048;
	border: 1px solid #170048;
	color: #fff;
	padding: 14px 0;
	font-size: 25px;
	font-weight: 500;
	width: 40%;
	border-radius: 50px;
}

.home_second_middle .home_text_h3freelancer {
	color: #00065a;
	font-weight: 800;
	letter-spacing: -1px;
	font-size: 60px;
	width: 440px;
	position: relative;
}

.home_second_middle .pink-line-freelancer-3 {
	background-color: #ff88fe;
	content: "";
	height: 30px;
	right: 0;
	position: absolute;
	bottom: 0;
	width: 280px;
	z-index: -1;
}

.learn_and_earn_section1 .pink-line-freelancer {
	content: " ";
	position: absolute;
	background-color: #ff88fe;
	height: 26px;
	width: 380px;
	z-index: -1;
	top: 43px;
	right: 80px;
}

.home_second_middle2 .pink-line-freelancer-4 {
	background-color: #ff88fe;
	content: "";
	height: 33px !important;
	position: absolute;
	z-index: -1;
	width: 322px !important;
	top: 40px;
	right: 0;
}

.home_second_middle2 .home_text_h4freelancer {
	color: #00065a;
	font-weight: 800;
	letter-spacing: -1px;
	font-size: 60px;
	position: relative;
	width: 590px;
}

.home_second_middle5 .pink-line-freelancer-5 {
	background-color: #ff88fe;
	content: "";
	height: 27px;
	position: absolute;
	z-index: -1;
	left: 0;
	bottom: 0;
	width: 400px;
}

.learn_and_earn_section1 .textPageDatatitle6 {
	text-align: center;
}

.section_three_wrapper .section_three {
	width: 100%;
	height: 990px;
	background-size: 670px;
	background-repeat: no-repeat;
	background-position: right;
	margin-left: 107px;
	position: relative;
}

.section_three_wrapper .section_three_info {
	position: relative;
	z-index: 22;
}

.section_three_wrapper .section_three_info .section_three_heading {
	color: #00065a;
	font-size: 70px;
	font-weight: 700;
	letter-spacing: -1px;
	line-height: 75px;
	width: 800px;
	padding-top: 160px;
	position: relative;
}

.section_three_wrapper .section_three_info .section_three_para {
	color: #393939;
	font-size: 22px;
	font-weight: 600;
	width: 700px;
	margin-bottom: 0;
	margin-top: 30px;
}

.section_three_wrapper .overlayback {
	background: rgb(255, 255, 255);
	background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 46%,
			rgba(255, 255, 255, 0.2049194677871149) 70%
	);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.section_three_wrapper .section_three_number {
	-webkit-text-stroke: 1px #c4c1c161;
	fill: none;
	color: transparent;
	font-size: 350px;
	font-weight: 800;
	position: absolute;
	left: 80px;
	top: 90px;
	text-shadow: none;
	z-index: -1;
}

.section_three_wrapper .section_three_list {
	position: relative;
	z-index: 22;
	background-color: #2b397b;
	display: flex;
	margin-right: 100px;
	margin-left: -100px;
	margin-top: 100px;
}

.section_three_wrapper .section_three_list .section_three_item {
	width: 33%;
	height: 100%;
	padding: 25px 30px;
	text-align: center;
}

.section_three_wrapper .section_three_item_heading {
	color: #fff;
	font-size: 28px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 34px;
	min-height: 80px;
	margin-bottom: 0;
}

.section_three_wrapper .section_three_item_para {
	color: #fff;
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 26px;
	opacity: 40%;
	padding: 0 30px;
	margin-bottom: 0;
}

.section_three_wrapper .box_area_border_section_three {
	background-color: #fff;
	width: 90%;
	height: 10px;
	position: absolute;
	right: 100px;
	z-index: 22;
}

.section_three_wrapper .box_area_border_section_three:before {
	background-color: #ff88fe;
	content: " ";
	height: 20px;
	left: 385px;
	position: absolute;
	width: 200px;
}

.section_three_wrapper .box_area_border_section_three:after {
	background-color: #6d2ef1;
	content: " ";
	height: 20px;
	position: absolute;
	right: 0;
	width: 50%;
}

.section_three_wrapper .section_three_numberPnik {
	content: " ";
	position: absolute;
	bottom: 0;
	right: 0;
	width: 570px;
	background-color: #ff88fe;
	height: 20px;
	z-index: -1;
}

.learn_and_earn_section5left {
	position: absolute;
	top: 0;
	transform: rotatez(0deg);
	left: -500px;
	width: 480px;
}

.learn_and_earn_section5right {
	position: absolute;
	top: -100px;
	transform: rotatez(35deg);
	right: -350px;
	width: 320px;
}

.companiew_pink {
	position: relative;
}

.freelancer_pink {
	position: relative;
}

.companiew_pink::before {
	content: " ";
	position: absolute;
	background-color: #ff88fe;
	height: 20px;
	width: 459px;
	z-index: -1;
	bottom: 75px;
	right: -101px;
}

.freelancer_pink::before {
	content: " ";
	position: absolute;
	background-color: #ff88fe;
	height: 27%;
	width: 340px;
	z-index: -1;
	bottom: 5px;
	right: 0;
}

.clientHeadeing {
	font-size: 57px;
	color: #170048;
	font-weight: 700;
	line-height: 70px;
}

@media (max-width: 1199px) {
	.clientHeadeing {
		font-size: 50px;
		line-height: 56px;
	}

	.companiew_pink::before {
		right: -89px;
		width: 400px;
		bottom: 60px;
	}
}

@media (max-width: 1023px) {
	.clientHeadeing {
		font-size: 37px;
		line-height: 45px;
	}

	.companiew_pink::before {
		right: -64px;
		width: 300px;
		bottom: 45px;
	}
}

@media (max-width: 767px) {
	.companiew_pink::before {
		right: -34px;
	}
}

@media (max-width: 481px) {
	.companiew_pink::before {
		right: -98px;
	}
}

.add_your_skills {
	border: 1px solid #d4d4d5;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 61px;
	width: 100%;
}

.add_your_skills_heading {
	margin-top: 30px !important;
}

.add_your_skillstheme_btns {
	min-width: 140px;
	color: #fff;
	background-color: #6610f2;
	border: #6d2ef1 solid 1px;
	border-radius: 20px !important;
	padding: 8px 30px;
	font-weight: 600;
}

.add_your_skillstheme_btns:disabled {
	background-color: #bdbdbd;
	border: none;
	cursor: not-allowed;
}

.add_your_skills .search_inp_box {
	width: 100%;
}

.showonresponsiveimage {
	display: none;
}

.service_heading_div {
	position: relative;
	font-size: 61px;
	text-align: center;
	width: 640px;
	letter-spacing: -1px;
	color: #00065a;
	font-weight: bold;
	margin: 100px auto;
}

.service_heading_div_02 {
	font-size: 400px;
	font-weight: 700;
	-webkit-text-stroke: 1px #c4c1c161;
	position: absolute;
	color: transparent;
	fill: none;
	z-index: -1;
	text-shadow: none;
	top: -200px;
	left: 0;
	right: 0;
}

.blue_ater_h_min0202 {
	position: absolute;
	top: 45px;
	right: 0;
	height: 25px !important;
	content: "";
	background-color: #ff88fe;
	width: 390px;
	z-index: -1;
}

.pink-line-freelancer-6 {
	content: " ";
	position: absolute;
	background-color: #ff88fe;
	height: 20px;
	width: 408px;
	z-index: -1;
	left: 42px;
	bottom: 70px;
}

.time_shee_btn {
	background-color: green !important;
}

.accept-invitation-wrapper {
	width: 350px;
	margin: 150px auto;
	text-align: center;
	padding: 70px 30px 30px 30px;
	box-shadow: 0 -3px 25px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	border-radius: 15px;
}

.accept-invitation-freelancer {
	position: absolute;
	top: -70px;
	left: 0;
	right: 0;
}

.accept-invitation-freelancer img {
	width: 130px;
	height: 130px;
	border-radius: 50%;
}

.accept-buttons {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.accept-invitation-wrapper p {
	font-size: 14px;
}

.accept-buttons .accept-buttons-first {
	background-color: #6610f2;
	padding: 10px 30px;
	border: none;
	border-radius: 30px;
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
}

.accept-buttons .accept-buttons-second {
	background-color: #a8aab8;
	padding: 10px 30px;
	border: none;
	border-radius: 30px;
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
}

.boostTable th {
	padding: 10px;
	font-size: 14px;
	font-weight: 600;
}

.boostTable td {
	border: 1px solid #d5e0d5;
	padding: 10px;
	font-size: 14px;
	border-radius: 20px;
}

.setBid-btn {
	width: 150px;
	padding: 10px 0;
	border: none;
	outline: none;
	font-size: 16px;
	background-color: #6d2ef1;
	color: #fff;
	margin-top: 30px;
	border-radius: 30px;
}

.setBid-btn .fa-plus {
	margin-right: 5px;
}

.boostamount-input {
	width: 30%;
	display: flex;
	align-items: center;
	margin-top: 30px;
	position: relative;
}

.boostamount-input input {
	width: 100%;
	height: 45px;
	border-radius: 6px;
	border: 2px solid #f1f1f1;
	outline: none;
	padding: 0 10px;
}

.boostamount-input input:focus {
	border: 1px solid #6d2ef1;
}

.boostamount-input button {
	position: absolute;
	top: 7px;
	right: 15px;
	width: 70px;
	padding: 5px 0;
	border: none;
	outline: none;
	font-size: 14px;
	background-color: #6d2ef1;
	color: #fff;
	border-radius: 15px;
}

.boostamount-input button:disabled {
	background-color: #c5c5c5;
	cursor: not-allowed;
}

/* .refreshTime span {
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
  color: #5e6d55;
} */

.refreshTime .fa-refresh {
	color: #6d2ef1;
}

.totl_mil {
	font-weight: 700;
	font-size: 17px;
	margin-bottom: 10px;
	padding: 0;
}

.milesone_se_he {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 600;
	width: 90%;
	margin: auto auto 5px;
}

.milestone_send_pro {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	margin: auto;
	font-weight: 400;
	font-size: 14px;
	padding-bottom: 5px;
}

@media (max-width: 767px) {
	.milesone_se_he {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.milestone_send_pro {
		width: 100%;
	}
}
