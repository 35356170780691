
.HWP_Popup {
	max-width: 500px;
	padding: 25px;
}

.HWP_Popup .content {
	margin: 30px 0;
}

.freelancer_popups > h5 {
	color: #6d2ef1;
	font-weight: 600;
}

.freelancer_popups > p {
	font-size: 12px;
	font-weight: 500;
	/* color: rgb(128 123 123); */
	margin: 20px 0;
}

.freelancer_popups > p > span {
	font-size: 12px;
	color: #6d2ef1;
}

.freelancer_popups .content h5 {
	font-size: 16px;
	font-weight: 600;
}

.freelancer_popups .content .popup-radio label {
	display: flex;
	margin: 10px 0;
}

.freelancer_popups .content .popup-radio input {
	accent-color: #6d2ef1;
}

.freelancer_popups .content .popup-radio span {
	accent-color: #6d2ef1;
	margin: 0 5px;
	font-size: 12px;
}

.freelancer_popup_btns {
	display: flex;
	flex-wrap: wrap;
}

.freelancer_popup_btns button {
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6d2ef1;
	background-color: #fff;
	font-weight: bold;
	border-radius: 20px !important;
	text-transform: uppercase;
	padding: 8px 45px;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.freelancer_popup_btns ._cancle_submit button:hover {
	color: #fff;
	background-color: #6d2ef1;
}

.freelancer_popup_btns .save-submit button {
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background-color: #6d2ef1;
	font-weight: bold;
	border-radius: 20px !important;
	text-transform: uppercase;
	padding: 8px 45px;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.cancleSubsPopupBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
}

.subsPopup {
	display: flex;
	flex-direction: column;
}

.subsPopup2 {
	display: flex;
	flex-direction: column;
}

.cancleSubHead {
	font-size: 35px;
	line-height: 106px;
	font-weight: bold;
	letter-spacing: 0;
	color: #6d2ef1;
	text-align: center;
}

.cancleSubHead2 {
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0;
	color: #545454;
}

.subsNotNowbtn {
	color: #6d2ef1;
	background-color: #fff;
	font-weight: bold;
	border-radius: 20px !important;
	text-transform: uppercase;
	font-size: 14px;
	padding: 10px 30px;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.subsCancelSubsbtn {
	color: #fff;
	background-color: #6d2ef1;
	font-weight: 500;
	border-radius: 20px !important;
	text-transform: uppercase;
	font-size: 13px;
	padding: 10px 30px !important;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.subsCancrightubsbtn {
	margin-right: 0;
}

.subsCancelSubsbtn:hover {
	color: #fff;
	background-color: #6d2ef1;
}

@media screen and (max-width: 768px) {
	.subsNotNowbtn {
		margin-top: 0;
		margin-left: 10px;
	}

	.subsCancelSubsbtn {
		margin-top: 0;
	}
}

@media screen and (max-width: 320px) {
	.cancleSubHead {
		margin-top: 0;
		font-size: 19px !important;
		padding-bottom: 5px;
	}

	.cancleSubHead2 {
		padding: 0 10px;
	}
}

@media screen and (max-width: 425px) {
	.freelancer_popup_btns {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.freelancer_popup_btns button {
		width: 220px;
		margin-top: 10px !important;
	}

	.subsNotNowbtn {
		color: #6d2ef1;
		background-color: #fff;
		font-weight: bold;
		border-radius: 20px !important;
		font-size: 14px;
		margin-top: 5px;
		margin-right: 15px;
		border: #6d2ef1 solid 1px;
		-webkit-border-radius: 60px !important;
	}

	.subsCancelSubsbtn {
		width: 266px !important;
		padding: 10px 0 !important;
		font-size: 14px;
	}
}

@media screen and (min-width: 700px) {
	.subsCancelSubs {
		flex-wrap: nowrap;
	}
}

.freelancer_popup_inps label {
	display: flex;
	flex-direction: column;
	margin: 15px 0;
}

.freelancer_popup_inps label > span {
	font-size: 15px;
	font-weight: 600;
}

.freelancer_popup_inps .custom_css_select {
	border: #e8e7e7 solid 1px !important;
}

.freelancer_popup_inps .custom_css_select .css-14el2xx-placeholder {
	font-size: 12px !important;
}

.freelancer_popup_inps .custom_css_select div {
	border: none !important;
	border-radius: 2px !important;
}

.freelancer_popups .css-1okebmr-indicatorSeparator {
	display: none;
}

@media screen and (min-width: 768px) {
	.AMT_Popup {
		min-width: 550px;
	}
}

.freelancer_popup_inps .freelancer_popup_textarea textarea {
	width: 100%;
	height: 120px;
	resize: none;
	font-size: 12px;
	border: #e8e7e7 solid 1px;
	outline: none;
}

.left_Characters {
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
	color: #9e9d9d;
}

.freelancer_popup_inps .freelancer_popup_textarea textarea::placeholder {
	font-size: 12px;
}

.freelancer_popups .Popup_note {
	font-size: 12px;
	font-weight: 500;
	margin: 20px 0;
	color: #838181;
}

.freelancer_popups .Popup_note > b {
	/* color: #6d2ef1; */
}

.freelancer_popups .Popup_note > span {
	color: #6d2ef1;
	cursor: pointer;
}

.drag_portfolio {
	display: flex !important;
	flex-direction: unset !important;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.drag_portfolio > input {
	visibility: hidden;
	width: 1px;
	height: 1px;
}

.portfolio_popup {
	width: 600px !important;
}

/* @media screen and (max-width: 426px) {
  .portfolio_popup {
    width: 600px !important;
  }
} */
@media screen and (max-width: 767px) {
	.portfolio_popup {
		width: 90% !important;
		margin: 0 auto !important;
	}
}

.addMobPopup_heading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* color: rgb(94, 94, 94); */
}

.addMobPopup_heading h5 {
	font-size: 16px;
}

.addMobPopup_heading span {
	/* color: rgb(151, 151, 151); */
	font-size: 14px;
}

.addMobPopup_heading span > span {
	font-weight: 500;
	font-size: 12px;
}

.message_header_img {
	width: 100px;
	height: auto;
	margin: 10px 0;
}

.message_header_img img {
	width: 100px;
	height: auto;
}

.authenticator_verification_popup {
	min-width: 500px;
	max-width: 500px;
}

@media screen and (max-width: 426px) {
	.authenticator_verification_popup {
		min-width: 280px;
	}
}

@media screen and (max-width: 321px) {
	.authenticator_verification_popup {
		min-width: 280px;
		max-width: 282px;
	}
}

.suggest_qr {
	font-size: 13px;
	margin-top: 15px;
}

.suggest_qr span > span {
	color: #6d2ef1;
	cursor: pointer;
}

.popup_padd {
	padding: 30px;
}

.portfolio_img_pre {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0;
}

.portfolio_img_pre img {
	width: 100px;
	height: auto;
	object-fit: cover;
}

.varifyDocs_pre_img {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0;
	width: 70%;
}

.varifyDocs_pre_img > div {
	margin-right: 15px;
	display: flex;
	flex-direction: column;
}

.varifyDocs_pre_img img {
	width: 110px;
	height: 82px;
	object-fit: cover;
}

/* ================ successs popup ======================== */

.success_popup {
	max-width: 400px !important;
	min-width: 300px;
	position: relative;
	top: 0;
}

.success_popup .success_heading {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: -15px;
	color: #6d2ef1;
	font-weight: 600;
	margin-top: 30px;
	flex-direction: column;
}

.success_popup .sucess_message {
	display: flex;
	justify-content: center;
	font-weight: 400;
	color: #000;
}

.success_popup .sucess_message > div {
	text-align: center;
}

.success_popup .success_popup_btn {
	display: flex;
	justify-content: center;
}

.success_popup .success_popup_btn button {
	background-color: #6d2ef1;
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	font-weight: bold;
	border-radius: 60px !important;
	/* text-transform: uppercase; */
	padding: 8px 34px;
	margin-top: 30px;
	min-width: 129px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.hourly_price {
	margin-bottom: 30px;
}

.hourly-price_rsph {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
}

.input_houry_price {
	border: #b9c9dc solid 1px;
	display: flex;
	font-size: 18px;
	line-height: 30px;
	font-weight: 600;
	color: #8f8f8f;
	padding: 3px 20px;
	align-items: center;
}

.input_houry_price input {
	border: none;
	outline: none;
}

.hourly_price label {
	font-weight: 600;
	margin-bottom: 6px;
}

/* Error Popup start */
.error_popup {
	position: fixed;
	z-index: 99999;
	top: 5px;
	max-width: 700px;
	right: 50%;
	padding: 15px 8px;
	transform: translate(50%, 0);
	min-width: 350px;
	background-color: #e74c3c;
	color: #fff;
	border-radius: 5px;
	animation-name: error_comes;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
}

.error_popup .error_container {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.error_popup .message {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 10px;
	word-wrap: break-word;
	white-space: pre-line;
}

.error_popup .close_pp_btn {
	float: right;
}

.error_popup .close_pp_btn svg {
	fill: #fff !important;
}

.error_popup .error_progress {
	width: 100%;
	height: 5px;
	background: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	animation-name: errorProgress;
	animation-duration: 5s;
	animation-timing-function: ease-in-out;
}

@media screen and (max-width: 360px) {
	.error_popup {
		padding: 16px 3px;
		transform: translate(50%, 0);
		min-width: 293px;
	}
}

.message_popup {
	position: fixed;
	z-index: 99999;
	top: 5px;
	max-width: 750px;
	right: 50%;
	padding: 15px 8px;
	transform: translate(50%, 0);
	min-width: 350px;
	background-color: #6f42c1;
	color: #fff;
	border-radius: 5px;
	animation-name: error_comes;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
}

.message_popup .message_container {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.message_popup .message {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 10px;
	word-break: break-word;
	white-space: pre-line;
}

.message_popup .close_pp_btn {
	float: right;
}

.message_popup .close_pp_btn svg {
	fill: #fff !important;
}

.message_popup .message_progress {
	width: 100%;
	height: 5px;
	background: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	animation-name: errorProgress;
	animation-duration: 5s;
	animation-timing-function: ease-in-out;
}

@keyframes errorProgress {
	0% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}

@keyframes error_comes {
	0% {
		transform: translate(50%, -100%);
	}
	100% {
		transform: translate(50%, 0);
	}
}

/* Error Popup end */

/* Message Popup start */

/* ================================ confirmation popup ================================== */
.confirmation_popup {
	max-width: 440px !important;
	position: relative;
	top: -15%;
	border-radius: 4px;
}

.confirmation_popup h2 {
	font-size: 28px;
}

.conf-heading {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6d2ef1;
	margin-top: -25px;
}

.conf-desc {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	color: #8d8d8d;
	padding: 5px;
}

.text-cent,
.text-cent button {
	background-color: #6d2ef1;
	color: #fff;
}

.confirmation_popup_btns {
	display: flex;
	margin: 35px 0 5px 0;
	justify-content: center;
}

.confirmation_popup_btns .confirm_no_btn {
	background-color: #fff;
	color: #6d2ef1;
	font-size: 18px;
	line-height: 30px;
	font-weight: bold;
	border-radius: 60px !important;
	text-transform: uppercase;
	padding: 8px 39px;
	margin-top: 5px;
	min-width: 133px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.confirmation_popup_btns .confirm_yes_btn {
	background-color: #6d2ef1;
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	font-weight: bold;
	border-radius: 60px !important;
	text-transform: uppercase;
	padding: 8px 39px;
	margin-top: 5px;
	min-width: 133px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}
