.chat-bg {
	background-color: #f2f8ff;
	padding: 0 0 10px;
	height: calc(100vh - 88px);
}

.uni-chat-header {
	background-color: #fff;
}

@media (max-width: 767px) {
	.uni-chat-header {
		margin: 0 -12px;
	}
}

.chat-header {
	padding: 10px;
	display: flex;
	justify-content: space-between;
}

.searchbox_hat {
	background-color: #fff;
	padding: 12px 20px;
	border: #f2f2f2 solid 1px;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.doller-icon {
	position: absolute;
	top: 12px;
	left: 10px;
	color: gray;
}

.select_box_hat {
	position: relative;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}

.select_box_hat .bxshat_svg {
	position: absolute;
	right: 9px;
	top: 25%;
	background-color: #6d2ef1;
	padding: 5px;
	z-index: 10;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.select_box_hat .css-1s2u09g-control {
	border: none !important;
}

.select_box_hat .custom_css_select {
	min-width: auto !important;
}

.searchbox_hat input {
	background-color: transparent;
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
}

.bxshat_svg {
	height: 25px;
	width: 25px;
	margin-right: 10px;
}

.bxshat_svg svg {
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 425px) {
	.bxshat_svg {
		display: none;
	}

	.searchbox_hat {
		padding: 2px 5px;
	}

	.searchbox_hat input {
		padding: 5px;
	}
}

.chat_header_buttons {
	display: flex;
}

.chat_header_buttons button {
	outline: none;
	border: none;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	background-color: transparent;
}

.chat_header_buttons .zoom_meet_start_btn {
	height: 40px;
	width: 48px;
}

.chat_header_buttons .activeBtn {
	outline: none;
	border: none;
	height: 40px;
	width: 40px;
	display: flex;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	padding: 10px;
	margin-right: 5px;
}

.chat_header_buttons .chatsBtn {
	background: #f3f3f3 0 0 no-repeat padding-box;
}

.round-chat-btn {
	border-radius: 50px;
	margin-left: 10px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.chb_fill_gray button svg g,
.chb_fill_gray button svg path,
.chb_fill_gray button svg rect {
	fill: #afafaf !important;
}

/* .chat_header_buttons button:hover {
  background-color: #6d2ef1;
}

.chat_header_buttons button:hover svg path,
.chat_header_buttons button:hover svg rect {
  fill: #fff !important;
} */
.chat_header_buttons .activeBtn {
	background-color: #6d2ef1;
}

/* .chat_header_buttons .svg_toggle svg path,
.chat_header_buttons .svg_toggle svg g rect {
  fill: #6d2ef1 !important;
} */

.chat_header_buttons button.chatsBtn svg rect {
	fill: #6d2ef1;
}

.chat_header_buttons button.activeBtn svg rect {
	fill: #fff;
}

.chat_header_buttons .activeBtn svg path,
.chat_header_buttons button svg rect {
	fill: #fff;
}

.chat_header_buttons .activeBtn svg {
	height: 100%;
	width: 100%;
	fill: #6d2ef1;
}

.chat_header_buttons button svg path,
.chat_header_buttons button svg rect {
	fill: #6d2ef1;
}

.chat_p_name {
	font-size: 15px;
	font-weight: bold;
	word-break: break-word;
	line-height: 30px;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
}

.chat_ucountry {
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	margin-right: 10px;
}

.chat_status_date {
	font-size: 13px;
	margin-left: 15px;
	font-weight: 300;
	line-height: 20px;
	color: #3b3b3b;
}

/* .css-13cymwt-control {
  border: none !important;
  background-color: inherit !important;
  border-style: none !important;
} */

.contact-box-chat {
	padding: 10px 20px;
	/* border-right: #fa61ff solid 5px; */
	width: 100%;
	background-color: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contact-box-chat:hover {
	cursor: pointer;
	background-color: #f1f1f1;
}

.user_active_in_chat {
	border-right: #fa61ff solid 5px;
	cursor: pointer;
	background-color: #f1f1f1;
}

.cbc_profile_img {
	height: 40px;
	width: 40px;
	overflow: hidden;
	min-width: 40px;
}

.cbc_profile_img img {
	height: 100%;
	width: 100%;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	object-fit: cover;
	border: 1px solid #ababab;
}

.cbc_lft {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	margin-left: 10px;
	text-align: left;
}

.cbc_name_pf {
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
	word-break: break-word;
}

.cbc_time_pf {
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
	word-break: break-word;
	max-width: 200px;
}

@media screen and (max-width: 994px) {
	.contact-box-chat {
		display: flex;
		padding: 10px;
		justify-content: flex-start;
	}

	.cbc_time_pf {
		font-size: 10px;
		/* text-align: center; */
		line-height: 13px;
	}

	.cbc_profile_img {
		height: 40px;
		width: 40px;
		min-width: 40px;
		min-height: 40px;
	}
}

@media screen and (max-width: 481px) {
	.chat_header_buttons .activeBtn svg {
		width: unset;
	}

	.resp_chat_name_show {
		display: flex;
		width: 100%;
		align-items: center;
		gap: 10px;
		padding: 5px 0 0 13px;
	}
}

@media screen and (min-width: 481px) {
	.resp_chat_name_show {
		display: none;
	}
}

@media screen and (max-width: 425px) {
	.contact-box-chat {
		display: flex;
		padding: 5px;
		gap: 10px;
		/* justify-content: center; */
	}

	/* .cbc_name_pf {
		text-align: center;
	  }
	  .cbc_time_pf {
		text-align: center;
	  } */
	.cbc_lft {
		margin-left: 0;
	}
}

.message-box-r {
	position: relative;
	margin: 15px 0;
}

.mbr_bw_node {
	background-color: #fff;
	padding: 15px 10px 15px 40px;
	font-size: 14px;
	color: #b7b7b7;
	font-weight: 400;
	line-height: 22px;
	white-space: pre-line;
	position: relative;
}

.contextMenu {
	position: fixed;
	background: #f5f5f5;
	/* top: 0; */
	z-index: 99999;
	display: flex;
	flex-direction: column;
	max-width: 100px;
	min-width: 110px;
	border: 1px solid #b6b6b6;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.contextMenu span {
	padding: 2px 10px;
	cursor: pointer;
}

.contextMenu span:hover {
	background: #6610f2;
	color: #fff;
}

.dir-left {
	border-top-left-radius: 50px;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	max-width: 600px;
	margin-right: auto;
	width: max-content;
}

.dir-right {
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	border-top-right-radius: 50px;
	max-width: 600px;
	margin-left: auto;
	width: max-content;
}

.prof-mig.dir-right {
	right: -15px !important;
	left: auto;
}

.mbr_bw_node.dir-right {
	padding: 15px 40px 15px 30px;
}

.attachment_mess_left {
	display: flex;
	flex-direction: column;
	left: -15px;
	right: auto;
	gap: 10px;
	margin: 10px;
}

.attachment_mess_right {
	display: flex;
	flex-direction: column;
	right: -15px;
	left: auto;
	gap: 10px;
	margin: 10px;
}

.reply_on_attachment {
	display: flex;
	width: 100%;
	max-width: 100%;
	gap: 10px;
	overflow-x: auto;
	overflow-y: hidden;
}

.reply_on_attachment::-webkit-scrollbar {
	/* display: none; */
	width: 8px;
	height: 8px;
}

.reply_on_attachment::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.reply_on_attachment::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}

.reply_on_attachment .images,
.attachment_mess_right .images,
.attachment_mess_left .images {
	width: 210px;
	height: 150px;
	padding: 1px;
	overflow: hidden;
	cursor: pointer;
	background-color: #ababab;
}

.reply_on_attachment .images img,
.attachment_mess_right .images img,
.attachment_mess_left .images img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.reply_on_attachment .videos,
.attachment_mess_right .videos,
.attachment_mess_left .videos {
	width: 210px;
	/* height: 120px; */
}

.reply_on_attachment .videos video,
.attachment_mess_right .videos video,
.attachment_mess_left .videos video {
	width: 100%;
	height: 100%;
}

.reply_on_attachment .documents .document_brd,
.attachment_mess_left .documents .document_brd,
.attachment_mess_right .documents .document_brd {
	gap: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #eeeeee;
	padding: 15px;
	background-color: #f7f7f7;
	border-radius: 7px;
}

.reply_on_attachment .documents .name,
.attachment_mess_left .documents .name,
.attachment_mess_right .documents .name {
	gap: 10px;
	display: flex;
	font-size: 16px;
}

.reply_on_attachment .documents .name .headi,
.attachment_mess_left .documents .name .headi,
.attachment_mess_right .documents .name .headi {
	font-weight: 500;
	color: #ababab;
	min-width: 36px;
}

.reply_on_attachment .documents .name .doc_name,
.attachment_mess_left .documents .name .doc_name,
.attachment_mess_right .documents .name .doc_name {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
	line-break: anywhere;
	word-break: break-word;
	max-width: 200px;
	width: 200px;
	color: #6610f2;
}

.reply_on_attachment .documents .down_load_ic,
.attachment_mess_left .documents .down_load_ic,
.attachment_mess_right .documents .down_load_ic {
	cursor: pointer;
}

/*  ------------- */

.reply_attachment {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.reply_attachment .document_brd {
	gap: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #eeeeee;
	border: 1px solid #e2e2e2;
	padding: 15px;
	border-radius: 7px;
}

.reply_attachment .name {
	gap: 10px;
	display: flex;
	font-size: 16px;
}

.reply_attachment .name .headi {
	font-weight: 500;
	color: #ababab;
	min-width: 36px;
}

.reply_attachment .name .doc_name {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
	line-break: anywhere;
	word-break: break-word;
	max-width: 200px;
	width: 200px;
	color: #ababab;
}

.prof-mig {
	position: absolute;
	left: -15px;
	top: 0;
	height: 45px;
	z-index: 1;
	width: 45px;
	overflow: hidden;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.prof-mig img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.profile-bx-whte {
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 9999;
	background-color: #fff;
	padding: 35px 30px 10px 30px;
	text-align: -webkit-center;
	width: 270px;
	box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.pimg_big {
	height: 100px;
	width: 100px;
	overflow: hidden;
}

.pimg_big:before {
	content: " ";
	position: absolute;
	right: 6px;
	top: 0;
	background-image: url("data:image/svg+xml,%3Csvg id='Group_2506' data-name='Group 2506' xmlns='http://www.w3.org/2000/svg' width='36.738' height='36.738' viewBox='0 0 36.738 36.738'%3E%3Ccircle id='Ellipse_582' data-name='Ellipse 582' cx='15.5' cy='15.5' r='15.5' transform='translate(2.738 2.587)' fill='%23fff'/%3E%3Cg id='crown' transform='translate(0 0)'%3E%3Cpath id='Path_4471' data-name='Path 4471' d='M18.369,0A18.369,18.369,0,1,0,36.738,18.369,18.389,18.389,0,0,0,18.369,0Zm10.7,15.375L27.4,25.069a1.148,1.148,0,0,1-1.131.954H10.466a1.148,1.148,0,0,1-1.131-.954L7.671,15.375a1.148,1.148,0,0,1,1.635-1.226l4.43,2.157,3.631-6.532a1.193,1.193,0,0,1,2.007,0L23,16.305l4.43-2.157a1.146,1.146,0,0,1,1.633,1.226Z' fill='%230edf3f'/%3E%3C/g%3E%3C/svg%3E%0A");
	height: 22px;
	width: 22px;
	background-repeat: no-repeat;
	background-size: cover;
}

.online_profile_chat {
	position: relative;
}

.online_profile_chat img {
	border-radius: 50px !important;
}

.online_profile_chat:after {
	content: " ";
	position: absolute;
	right: 6px;
	bottom: 3px;
	height: 18px;
	width: 18px;
	border-radius: 50px;
	background-color: #fa61ff;
}

.pimg_big img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.fnt-prof-sde {
	font-size: 22px;
	padding: 10px 0 0 0;
	color: #180095;
	font-weight: bold;
	line-height: 30px;
	text-align: center;
}

.CHATPROFbox_min230px {
	max-width: 200px;
	text-align: left;
}

.fnt_prof_sde {
	font-size: 16px;
	color: #8f8f8f;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
}

.fny_flag_flex {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #8f8f8f;
	font-weight: 400;
	line-height: 20px;
	justify-content: center;
}

.fny_flag_flex svg {
	margin: 0 10px 0 0;
}

.review_profile_tx {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #8f8f8f;
	font-weight: 400;
	line-height: 30px;
	justify-content: center;
}

.review_profile_tx svg {
	height: 17px;
	width: 17px;
	margin-right: 5px;
}

.prf_dise_btns {
	display: flex;
	justify-content: center;
	margin: 15px 0;
}

.prf_dise_btns button {
	background-color: #fff;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	height: 60px;
	width: 60px;
	padding: 10px;
}

.prf_dise_btns button:hover svg path,
.prf_dise_btns button:hover svg rect {
	fill: #6d2ef1 !important;
}

.prf_dise_btns button svg {
	height: 100%;
	width: 100%;
}

.file_link_cbtn {
	rotate: 45deg;
	cursor: pointer;
}

.attachment_icon_chat {
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.emoji_icon {
	font-size: 2rem;
	padding: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
	bottom: 2px;
	color: #ababab;
}

.emoji_picker {
	position: absolute;
	bottom: 100%;
	z-index: 99;
}

.file_link_cbtn:hover svg path {
	stroke: #ababab;
}

@media screen and (max-width: 994px) {
	.file_link_cbtn button {
		height: 44px;
		width: 41px;
	}

	.prof-mig {
		width: 32px;
		height: 32px;
	}

	.chat-bg .container,
	.container-sm {
		max-width: 731px;
	}
}

@media screen and (max-width: 991px) {
	.attachment_icon_chat {
		width: 100px !important;
	}
}

@media screen and (max-width: 767px) {
	.attachment_icon_chat {
		width: 100px !important;
	}
}

.fl_input_chat {
	position: relative;
	width: 100%;
	background-color: #fff;
	display: flex;
	align-items: center;
	overflow: hidden;
	border-radius: 40px;
	height: 56px;
}

.fl_input_chat .sendMsgBtn {
	background-color: transparent;
	border: none;
}

.fl_input_chat .cancle_edit_msg {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
}

.fl_input_chat .cancle_edit {
	min-height: 40px;
	max-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	max-width: 40px;
	transform: rotateX(42deg);
	color: #ababab;
	cursor: pointer;
	font-size: 24px;
	z-index: 99;
}

.fl_input_chat textarea {
	width: 100%;
	background-color: transparent;
	outline: none;
	border: none;
	border-radius: 0;
	resize: none;
	height: 44px;
	min-height: 44px;
	max-height: 148px;
	padding: 10px;
	font-size: 16px;
	line-height: 1.5;
}

.fl_input_chat textarea::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #f5f5f5;
}

.fl_input_chat textarea::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

.fl_input_chat textarea::-webkit-scrollbar-thumb {
	background-color: #aaa;
	border-radius: 10px;
	cursor: pointer;
}

@media screen and (max-width: 994px) {
	/* .fl_input_chat {
	  height: 45px;
	} */
	.fl_input_chat .sendMsgBtn {
		font-size: 14px;
		padding: 5px 23px;
	}
}

@media screen and (max-width: 767px) {
	.fl_input_chat .sendMsgBtn {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* .hide_user_list {
  display: none;
}

.show_user_chat {
  display: block;
} */

.bg_wrap_chat {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #0000007a;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup_boxwchat {
	background-color: #fff;
	max-width: 700px;
	width: 100%;
	min-height: 300px;
}

.header_pbcht {
	background-color: #f5f5f5;
	padding: 15px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.close_btnchatpoup button {
	background-color: #fba9c7;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
	border: none;
}

.hding_cbox {
	color: #170048;
	font-size: 25px;
}

.cat_popup_boxy {
	margin: 15px 20px;
}

.htname_inp {
	margin: 15px 0;
	display: flex;
	justify-content: center;
}

.htname_inp input {
	background-color: #f5f5fe;
	padding: 15px 30px;
	width: 100%;
	color: #170048;
	font-weight: 400;
	outline: none;
}

.bht_headingchat {
	font-size: 20px;
	font-weight: 400;
	color: #170048;
}

.pri_radiocwiu {
	margin: 0 15px 0 0;
}

.pri_radiocwiu input {
	height: 15px;
	width: 15px;
}

.pri_name_desc {
	display: flex;
	flex-direction: column;
}

.head_priname {
	line-height: 17px;
	font-size: 18px;
}

.hp_desc {
	font-size: 14px;
	line-height: 30px;
}

.bgcolor-dark {
	background-color: #170048 !important;
	border-color: #170048 !important;
}

.newchat-pad {
	padding-top: 13px !important;
	padding-bottom: 13px !important;
}

.prmision_rinput {
	margin: 10px 0;
}

.profile_pf_btn {
	display: flex;
	flex-direction: column;
	margin: 15px 0;
}

.profile_pf_btn button {
	min-width: max-content;
	border: #6d2ef1 solid 1px;
	border-radius: 4px;
	margin-bottom: 5px;
	background-color: transparent;
	padding: 9px 15px;
	color: #6d2ef1;
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
	width: 100%;
}

@media (max-width: 769px) and (min-width: 376px) {
	/* .profile_pf_btn {
	  align-items: center;
	} */
	.profile_pf_btn button {
		width: 300px !important;
	}
}

/* .profile_pf_btn button:hover {
    background-color: #6d2ef1;
    color: #fff;
  } */

.pd_flex .flex_pt {
	min-width: 45% !important;
	width: auto !important;
}

.videos_not_co {
	margin-bottom: 0 !important;
}

.myskill_prof_node {
	padding: 20px 0;
	/* border-bottom: 1px solid #707070; */
}

.profileDiplomaBottom {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #70707048;
}

.profileDiploma {
	display: flex;
	align-items: center;
	gap: 10px;
}

.DiplomaImg {
	height: 50px;
}

.common_class {
	padding-bottom: 15px;
}

.myskill_hdingn {
	display: flex;
	align-items: center;
	font-size: 17px;
	margin-bottom: 15px;
	font-weight: 600;
}

.profile_heading_mb {
	margin-bottom: 0 !important;
}

.myskill_hdingn button {
	margin-left: 10px;
	background-color: #fff;
	height: 32px;
	width: 32px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	border: #707070 solid 1px;
}

.myskill_hdingn button:hover {
	background-color: #fff;
}

.myskill_hdingn button:active {
	background-color: #fff !important;
}

.intro_video .myskill_hdingn {
	display: flex;
	align-items: center;
	font-size: 17px;
	margin-bottom: unset;
	font-weight: 500;
}

.intro_video {
	margin-bottom: 15px;
}

.unify_certificate_div .myskill_hdingn {
	margin-bottom: 5px;
}

.no_certificate {
	font-size: 16px;
}

.intro_vido_player {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.font-size-20px {
	font-size: 20px !important;
}

.myskill_hdingn button svg {
	height: 15px;
	width: 15px;
}

.category_select_in_profile {
	height: 40px;
	width: 100%;
	border: 1px solid #a5a5a5;
	margin: 10px 0;
	outline: none;
}

.profile_icon_25px button svg {
	height: 10px !important;
	width: 10px !important;
}

.profile_icon_25px button {
	height: 20px;
	width: 20px;
}

.ms_hdsmall {
	margin: 0 0 10px 0 !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.ms_hdsmall_visibility {
	margin: 0 0 0 0 !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.ms_hdsmall_visibility2 {
	margin: 0 0 5px 0 !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.profile_common {
	padding-top: 5px;
}

.profile_edu_list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 5px;
	padding-bottom: 5px;
}

.profile_edu_list:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.profile_edu_det {
	word-break: break-word;
	line-height: 22px;
}

.not_available {
	padding: 5px 0 5px 0;
}

.svgverifie {
	margin-left: 10px;
	margin-top: 2px;
}

.box-profile-bck {
	border: #cdcccc solid 1px;
	padding: 20px 20px;
	margin-bottom: 30px;
	position: relative;
	margin-top: -1px;
}

.portfolio {
	width: 33.3%;
	margin: 0 10px;
}

.client_fr_no_prot {
	color: #2d2f30;
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	white-space: pre-line;
	margin-top: 10px;
}

.imgbox_bccp {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	height: 166px;
	width: 100%;
	overflow: hidden;
}

@media screen and (max-width: 425px) {
	.portfolio {
		width: 100%;
		margin: 5px 10px;
	}
}

@media screen and (max-width: 375px) {
	.imgbox_bccp {
		height: 188px;
	}
}

@media screen and (max-width: 320px) {
	.imgbox_bccp {
		height: 150px;
	}
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
	.imgbox_bccp {
		height: 137px;
	}
}

@media screen and (max-width: 768px) and (min-width: 426px) {
	.imgbox_bccp {
		height: 137px;
	}
}

.imgbox_bccp .port_folio_icons {
	display: none;
}

.imgbox_bccp:hover .port_folio_icons {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.imgbox_bccp:hover img {
	transform: scale(1.1);
	transition: linear;
	transition-duration: 1s;
}

.port_folio_icons button {
	background-color: #fff;
	border: 1px solid #6610f2;
	opacity: 0.6;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	margin: 5px;
}

.port_folio_icons button:hover,
.port_folio_icons button:active {
	background-color: #fff !important;
	opacity: 0.8;
	border: none;
}

.skill_bxr_gry {
	margin: 5px 10px 5px 0;
	background-color: #e6dbfd;
	padding: 10px 50px;
	color: #170b2e;
	font-size: 14px;
	border-radius: 45px;
	width: max-content;
	/* cursor: pointer; */
	-webkit-border-radius: 45px;
	-moz-border-radius: 45px;
}

@media screen and (max-width: 376px) {
	.skill_bxr_gry {
		padding: 10px 35px;
		font-size: 11px;
	}
}

.skill_bxr_gry:hover {
	background-color: #6d2ef1;
	color: #fff;
}

.imgbox_bccp img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.bi-heart-fill {
	color: #6d2ef1;
}

/* .bpbck_txt {
    padding-left: 20px;
  } */

.link_circle_icon {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.pencile_icon_and_profile {
	display: flex;
	/* align-items: center; */
	gap: 10px;
}

.circle_linkClient {
	margin-left: 10px;
}

.circle_link svg {
	cursor: pointer;
	/* fill: #0777fd;
	  background: #fff; */
}

.img_min_bpck {
	margin-top: -30px;
}

.bpck_head {
	font-size: 24px;
	font-weight: 500;
}

.bpck_para {
	font-size: 14px;
	font-weight: 300;
}

.bpck_sm_txt_a {
	font-size: 15px;
	font-weight: 300;
	line-height: 30px;
}

.bpck_sm_txt_a a {
	color: #6d2ef1;
	font-size: 16px !important;
}

.heading_pcatlog {
	font-size: 21px;
	font-weight: 400;
	line-height: 35px;
}

.catactive_box {
	margin: 5px 10px 5px 0;
	background-color: #e6dbfd;
	padding: 10px 40px;
	color: #170b2e;
	font-size: 15px;
	border-radius: 45px;
	width: max-content;
	-webkit-border-radius: 45px;
	-moz-border-radius: 45px;
}

.delivery_time_bckp {
	color: #170b2e;
	font-size: 15px;
	display: flex;
	align-items: center;
}

.delivery_time_bckp svg {
	margin-right: 10px;
}

.catbgwhite_bxo {
	margin-top: 20px;
	background-color: #fff;
	padding: 8px 35px;
	color: #170b2e;
	border: #6d2ef1 solid 1px;
	font-size: 15px;
	border-radius: 45px;
	width: max-content;
	-webkit-border-radius: 45px;
	-moz-border-radius: 45px;
}

.line-height-30 {
	line-height: 30px !important;
}

.descri_pfro {
	color: #2d2f30;
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	/* padding-left: 20px; */
	white-space: pre-line;
}

.tabbar_profile {
	border-bottom: #707070 solid 1px;
	/* margin: 0 20px; */
	display: flex;
}

/* .work_historycard {
    padding: 0 20px;
  } */

.tabbar_profile button {
	background-color: transparent;
	padding: 14px 0 14px 0;
	margin-right: 20px;
	font-size: 15px;
	border-radius: 0 !important;
	font-weight: 700;
	line-height: 23px;
	outline: none;
	border: none;
	box-shadow: none !important;
	color: #000;
}

.tabbar_profile button.activetabbar_btn {
	border-bottom: #6d2ef1 solid 4px;
	color: #6d2ef1;
	background-color: #fff;
	outline: none;
}

.tabbar_profile button:hover {
	background-color: #fff !important;
	color: #6d2ef1 !important;
}

.ehistory_uxdes {
	color: #2d2f30;
	word-break: break-word;
	font-size: 15px;
	text-align: start;
	font-weight: 500;
	line-height: 23px;
	white-space: pre-line;
}

.ehistory_uxdes.emp {
	color: #9c9c9c;
	word-break: break-word;
	white-space: pre-line;
}

.pl-20 {
	padding-left: 20px;
}

.file_icon_lst {
	margin: 19px 0 !important;
}

.ehitory_dtine {
	margin-top: 10px;
	color: #2d2f30;
	font-size: 14px;
	word-break: break-word;
	text-align: start;
	font-weight: 400;
	line-height: 23px;
	white-space: pre-line;
}

.whtroy_head {
	font-size: 18px;
	font-weight: 700;
	line-height: 23px;
	margin: 15px 0 10px 0;
	white-space: pre-line;
	word-break: break-word;
}

.whistory_para {
	margin-top: 10px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	white-space: pre-line;
	word-break: break-word;
}

.star_wbtn_whistory {
	font-size: 14px;
	align-items: center;
	font-weight: 400;
	line-height: 22px;
}

.star_wbtn_whistory span {
	margin-right: 15px;
	margin-left: 15px;
}

.italic_whistry {
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: #000000;
	font-style: italic;
	padding-right: 20px;
	margin: 10px 0;
	text-transform: capitalize;
}

.minei400 {
	min-height: 250px;
}

.des_heading_dboard {
	font-size: 22px;
	margin-top: 50px;
	margin-bottom: 10px;
	font-weight: 600;
	color: #000;
	line-height: 22px;
}

.des_para_dboadr {
	font-size: 15px;
	font-weight: 300;
	color: #6b6b6b;
	line-height: 22px;
	white-space: pre-line;
	/* text-align: justify; */
	padding: 2px;
	word-wrap: break-word;
}

.vmode_slide {
	padding: 16px 0;
	border-top: #707070 solid 1px;
	border-bottom: #707070 solid 1px;
}

.vmode_heading {
	font-size: 16px;
	font-weight: 600;
	color: #000;
	line-height: 22px;
}

.border-top-none {
	border-top: none !important;
}

.hrate_li {
	font-size: 15px;
	font-weight: 400;
	margin: 8px 0;
	color: #000;
	line-height: 22px;
}

.dboard_bbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #223750;
	padding: 50px 20px;
	margin-top: 11px;
	/* flex-wrap: wrap; */
	position: relative;
}

.dbaors_h1 {
	font-size: 25px;
	font-weight: 400;
	color: #fff;
	line-height: 35px;
}

@media screen and (max-width: 1199px) {
	.dbaors_h1 {
		font-size: 20px;
	}
}

@media screen and (max-width: 767px) {
	.dboard_bbox {
		flex-wrap: wrap;
		gap: 10px;
		padding: 35px 20px;
	}
}

.welcome_ax_dboard {
	font-size: 25px;
	font-weight: 700;
	color: #000;
	line-height: 35px;
}

.wdesc_ax_dboard {
	font-size: 15px;
	font-weight: 400;
	color: #000;
	line-height: 24px;
}

.dbaors_btn button {
	background-color: #0777fd;
	padding: 14px 35px;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	white-space: nowrap;
}

.dbaors_btn button:hover {
	background-color: #fff;
	color: #0777fd;
}

.line-hei-1 {
	line-height: 2rem !important;
}

.minwidth-200px {
	min-width: 280px;
}

.hrly_dboard_ntxt .pt_title {
	font-size: 13px;
	color: #000;
	font-weight: 700;
}

.color-red {
	color: #f66c6c !important;
}

.color-blew {
	color: #5300ff !important;
}

.hourly_am sup {
	font-size: 15px;
	top: -1em;
	left: 7px;
}

.mt-min180px {
	margin-top: -180px !important;
}

@media screen and (max-width: 767px) {
	.mt-min180px {
		margin-top: 0 !important;
	}
}

.trytygutf {
	height: 220px;
	align-items: self-start;
}

@media screen and (max-width: 767px) {
	.trytygutf {
		height: auto;
	}
}

.ddown_mcotrct {
	box-shadow: 0 0 10px #0000001a;
	border: 2px solid #e8e7e7;
	border-radius: 3px;
	padding: 10px 0;
	position: absolute;
	right: 0;
	min-width: 220px;
	top: 0;
	background-color: #fff;
}

.ddwon_lis {
	padding: 10px 20px;
	font-size: 14px;
	font-weight: 500;
}

.ddown_psr {
	position: relative;
}

.ddownactive {
	position: relative;
	color: #6d2ef1;
}

.ddownactive:before {
	content: " ";
	position: absolute;
	left: 0;
	height: 60%;
	width: 4px;
	top: 20%;
	background-color: #6d2ef1;
}

.pd_n_fbspro button {
	font-size: 15px !important;
}

.wlistbtn_xe {
	padding: 10px 20px !important;
}

.wlistbtn_xe svg {
	height: 20px;
	width: 20px;
}

.btn_psnewrb {
	border-radius: 50px !important;
	height: 42px;
	width: 42px;
	text-align: -webkit-center;
	-webkit-border-radius: 50px !important;
	-moz-border-radius: 50px !important;
	margin-right: 15px !important;
}

.proposal_saved path {
	fill: #ff88fe !important;
}

.ps-absolute {
	position: absolute !important;
}

.why_create_agency_ {
	margin-top: 10px;
	font-size: 13px;
	font-weight: 500;
}

.why_create_agency_ span {
	color: #6610f2;
}

.psearch-pnpou {
	top: 45px !important;
	left: -90px;
	z-index: 10;
	box-shadow: 0 3px 30px #6d2ef121 !important;
	border: none !important;
}

.psearch-pnpou .ddwon_lis {
	color: #170048;
	font-weight: 300;
	font-size: 13px;
}

.psearch-pnpou .ddwon_lis:hover {
	background-color: #f1e8ff;
}

.ml_svg_cpn svg {
	margin-left: 15px;
}

/* .vpropo_cbrntn button {
    background-color: #6d2ef1;
    pointer-events: none;
    padding: 13px 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    min-width: 160px;
    border-radius: 45px;
  } */

.smtxt_selct_newug {
	font-size: 12px !important;
}

.dcard_htext {
	font-size: 18px;
	font-weight: 700;
}

.dcard_lghthead {
	font-size: 14px;
	font-weight: 400;
}

.dcard_lghthead span {
	color: #fa61ff;
}

.select_drd_mth .custom_css_select {
	width: 100%;
	min-width: 110px;
}

.minwidth210px_ttracker {
	min-width: 310px;
}

.minwidth480px_myreport {
	min-width: 480px;
}

.minwidth170px_myreport {
	min-width: 170px;
}

.minwidth100px_myreport {
	min-width: 90px;
}

.minwidth120px_myreport {
	min-width: 110px;
}

.tbl_overflos_cewuw {
	width: 100%;
	overflow: scroll;
}

.tbl_overflos_cewuw::-webkit-scrollbar {
	display: none;
}

.dcard_ltxt {
	color: #6d2ef1;
	font-size: 14px;
	font-weight: 400;
}

.profile_crign {
	height: 50px;
	width: 50px;
	/* padding: 5px; */
}

.profiledddd_crign {
	padding: 5px;
	height: 50px;
	width: 50px;
}

.profiledddd_crign img {
	height: 100%;
	width: 100%;
}

.profile_crign img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.card_pcwuguoequ {
	padding: 15px 0;
	border-bottom: #ebebeb solid 1px;
	align-items: center;
}

.dash_frl {
	display: flex;
	align-items: center;
}

.dash_fr_gp {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dash_cl_d {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dash_cl_d .pcrign_name {
	text-align: center;
}

@media screen and (max-width: 1399px) {
	.dash_fr_gp {
		justify-content: flex-start;
		gap: 5px;
	}
}

.pcrign_name {
	margin-left: 10px;
	word-break: break-word;
}

@media screen and (max-width: 375px) {
	.pcrign_name {
		margin-left: 5px;
	}
}

.rign_nem {
	font-size: 15px;
	font-weight: 600;
}

.rign_mali {
	font-size: 13px;
	font-weight: 400;
}

.cpink_ltxt {
	color: #fa61ff;
}

.efty_smtxt {
	font-size: 13px;
	font-weight: 600;
}

.css-9gakcf-option {
	background-color: transparent !important;
	color: inherit !important;
}

.eedy_smtxt {
	line-height: 10px;
	font-size: 12px;
	font-weight: 400;
}

.box_ylow_dboard {
	border: #f1a32e solid 1px;
	padding: 25px 30px;
}

.bylow_iconbox {
	margin-right: 20px;
}

.blow_hoen {
	font-size: 18px;
	font-weight: 400;
}

.bwos_heon {
	font-size: 14px;
	font-weight: 300;
}

.bbl_head_gay {
	background-color: #f2f8ff;
	padding: 13px 0 13px 10px;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 600;
}

.w-20 {
	width: 20% !important;
}

.box_bbl_data {
	/* border: #c6d9ef solid 1px; */
	padding: 13px 0;
	margin-top: 7px;
	border-radius: 4px;
}

.box_bbl_data .tr_bblhead {
	font-size: 15px;
	padding: 0 5px 0 10px;
	word-break: break-word;
}

.tblew_bbltr {
	color: #0777fd;
}

.progress_w10px {
	width: 80px;
	background-color: #0777fd;
	border-radius: 20px;
	height: 8px;
}

.rcomend_proj_pfile {
	height: 45px !important;
	width: 45px !important;
	background-color: #0777fd;
	border-radius: 50px;
}

.wwbkit-avaol {
	width: 100%;
}

.recome_blextxt {
	color: #0777fd;
	font-weight: 700;
	font-size: 17px;
}

.recome_ltctx {
	color: #6b6b6b;
	font-weight: 400;
	font-size: 15px;
}

.dotround_bbl {
	position: relative;
	display: flex;
	align-items: center;
	text-indent: 10px;
}

.dotround_bbl:before {
	position: absolute;
	left: 0;
	content: " ";
	height: 10px;
	width: 10px;
	border-radius: 50px;
	background-color: #ffcc33;
}

.dotround_bbl_success:before {
	background-color: #09e28b !important;
}

.recomen_bgraybox {
	background-color: #f2f8ff;
	padding: 15px 25px;
	border-radius: 50px;
}

.fname_recm_txe {
	font-size: 17px;
	font-weight: 800;
}

.fname_recm_txe span {
	font-weight: 400;
	font-size: 14px;
	color: #959595;
}

.font_recomproject {
	font-size: 18px;
	font-weight: 600;
}

.font_rec_ldesc {
	font-size: 15px;
	font-weight: 300;
	word-break: break-word;
	white-space: pre-line;
}

.dash_show_less_more {
	font-size: 14px;
	font-weight: 600;
	color: #6610f2;
	cursor: pointer;
}

.dash_show_less_more:hover {
	text-decoration: underline;
}

.recomen_bgraybox button {
	border: #6d2ef1 solid 3px;
	background-color: transparent;
	outline: none;
	border-radius: 50px;
	padding: 8px 10px;
	max-width: 200px;
	min-width: 120px;
	text-transform: uppercase;
	color: #6d2ef1;
	font-weight: 800;
	font-size: 16px;
}

.recomen_bgraybox button:hover {
	background-color: #fff;
	color: #6d2ef1;
}

.smtxt_selct_newug .css-14el2xx-placeholder {
	width: max-content;
}

@media only screen and (max-width: 426px) {
	.ertr4h6j65esdv {
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 768px) {
	.wdewcpo39wdbi {
		margin-top: 4px;
	}

	.img_min_bpck {
		margin-top: 15px;
	}

	.bpck_sm_txt_a,
	.bpck_sm_txt_a a {
		font-size: 15px !important;
		word-break: break-word;
		white-space: pre-line;
	}

	.bpck_head {
		font-size: 20px;
	}

	.mt-sesix-5 {
		margin-top: 15px;
	}

	.popup_boxwchat {
		margin: 0 10px;
	}

	.flex_reverse_768 {
		flex-flow: column-reverse;
	}

	.signup_social_btn_n button {
		max-width: 90% !important;
		margin: 5px 13px !important;
		font-size: 15px !important;
		padding: 5px 0 !important;
	}

	.sign_u_head h1 {
		font-size: 35px;
	}

	.tb_mob_f12px {
		font-size: 12px !important;
	}

	.question_h1 h1 {
		font-size: 35px;
		line-height: 50px;
	}

	.new_hbyh {
		margin-bottom: 0;
		margin-top: 10px;
	}

	.question_p p {
		font-size: 15px;
	}
}

@media only screen and (max-width: 550px) {
	.no-mar-mob-mrep {
		margin-top: 10px;
		margin-left: 0 !important;
	}

	.no-mar-mob-mrep .fo_btn_c {
		margin-left: 0;
	}

	.trx_tbl_freelancer tbody tr td {
		font-size: 14px !important;
	}

	.dboard_box_mr_mbo {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.bbl_head_gay {
		width: max-content;
		min-width: 486px;
	}

	.box_bbl_data {
		/* width: max-content; */
		min-width: 486px;
	}

	.card_rpeoject_iw {
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 520px) {
	.s_b_head h2 {
		font-size: 30px !important;
	}
}

@media only screen and (max-width: 430px) {
	.mstat_lbel {
		min-width: 100%;
	}

	.select_fex_lbelx {
		width: 100%;
	}

	.inp_w_haf {
		font-size: 14px;
	}

	.cewbrteve .chat_status_date {
		margin-left: 0;
	}

	.fpx_tright {
		width: 100%;
		text-align: -webkit-right;
	}

	.mjh_new_smtext {
		font-size: 22px !important;
		padding-bottom: 0 !important;
	}

	.f_sm_small_euvw {
		font-size: 14px !important;
		padding: 8px 20px !important;
	}

	.f_sm_min-w150px {
		min-width: 150px !important;
	}

	.daterangepicker {
		width: 250px;
	}
}

@media only screen and (max-width: 460px) {
	.mobfont-600-wei {
		font-weight: 600 !important;
	}

	.nopadmob-newi {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.fb_btns_s_pro button {
		margin-top: 7px;
	}

	/* .imgbox_bccp img {
		width: 85%;
	  } */
	.p_header_hding {
		font-size: 20px;
	}

	.cwiewyehkk button,
	.cwiewyehkk a {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 320px) {
	.fo_btn_c button {
		font-size: 13px;
	}
}

@media only screen and (max-width: 345px) {
	.cewbrteve .chat_status_date {
		margin-left: 0 !important;
	}
}

@media only screen and (max-width: 500px) {
	.close_pp_btn svg {
		height: 24px;
		width: 24px;
	}
}

@media only screen and (min-width: 991px) and (max-width: 1399px) {
	.des_para_dboadr,
	.rign_nem {
		font-size: 13px;
	}

	.lineheight_142 {
		line-height: 20px;
	}

	.dcard_htext {
		font-size: 16px;
	}

	.rign_mali {
		font-size: 11px;
	}

	.profile_crign {
		height: 35px;
		width: 35px;
	}

	.pcrign_name {
		margin-left: 5px;
	}

	.wwbkit-avaol {
		width: 90%;
	}

	.svgciweyiviw {
		/* margin-top: 15px; */
		align-items: flex-start !important;
	}

	/* .card_pcwuguoequ{
			height: 120px;
		} */
	.cwig9weioioi {
		justify-content: flex-start !important;
	}

	.svgciweyiviw svg {
		height: 23px;
		width: 23px;
	}

	.card_pcwuguoequ {
		flex-wrap: wrap;
	}
}

@media only screen and (min-width: 1024px) {
	.lg-flex-right-subs {
		display: flex;
		justify-content: flex-end;
	}

	.lg-flex--subs {
		display: flex;
	}

	.new_hbyh {
		min-height: 220px !important;
		min-width: 230px !important;
	}

	.row_quexenwt {
		margin-right: calc(-1.5 * var(--bs-gutter-x));
		margin-left: calc(-1.5 * var(--bs-gutter-x));
	}
}

@media only screen and (max-width: 991px) {

	.dcard_htext {
		font-size: 16px;
	}
}

.navv2verso_flxewd {
	display: none;
}

@media only screen and (max-width: 767px) {
	.nenav_bddowniu {
		padding: 20px 0 20px 0;
		width: 100%;
	}

	.nenav_bddowniu #navbarScrollingDropdown {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		color: #000;
	}

	.nenav_bddowniu .dropdown-menu {
		left: auto !important;
		right: 0 !important;
	}

	.trytygutf.minheifghtx_smmenu {
		min-height: 320px;
	}

	.active_mmnuckwu {
		color: #fff !important;
		background-color: #170048 !important;
	}

	.smbtn_logsignup {
		width: 100%;
		margin-top: 25px;
	}

	.smbtn_logsignup button {
		background-color: transparent;
		padding: 10px 40px;
		width: 170px;
		min-width: max-content;
		border: #00065a solid 1px;
		color: #170048;
		font-size: 15px;
		font-weight: 500;
		border-radius: 30px;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
	}

	/* .newnav_v2_wauto {
		width: max-content !important;
	  } */
	.navv2verso_flxewd {
		display: flex;
		align-items: center;
	}

	.navv2verso_flxewd .navbar_btn,
	.navbar_btn .dropdown-toggle {
		margin-left: 10px;
		margin-right: 10px;
	}

	.navv2verso_flxewd .online_profile::after {
		right: -3px;
		bottom: -3px;
		height: 12px;
		width: 12px;
	}

	.navv2verso_flxewd .navbar-toggle {
		padding-right: 0;
	}

	.navv2verso_flxewd .navbar-toggle-icon {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22' style='&%2310; stroke: %23fff;&%2310;'/%3E%3C/svg%3E");
	}

	.close_iconsvgciweuw .navbar-toggle-icon {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc' focusable='false' fill='%23fff' height='27' width='27' aria-hidden='true' viewBox='0 0 24 24' data-testid='CloseIcon'%3E%3Cpath d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'%3E%3C/path%3E%3C/svg%3E") !important;
	}

	.navv2verso_flxewd .nav_profile {
		height: 35px;
		width: 35px;
	}
}

@media only screen and (max-width: 481px) {
	.resp_agen_ch svg {
		width: 25px;
	}
}

@media only screen and (max-width: 400px) {
	.smbtn_logsignup button,
	.smbtn_logsignup a {
		width: 100%;
	}

	.smbtn_logsignup button {
		margin: 5px 0;
	}

	.smbtn_logsignup {
		flex-flow: column;
	}
}

@media only screen and (max-width: 991px) {
	.btn_group_be {
		margin: 5px 0 !important;
	}

	.vrerev3rwvry {
		justify-content: center !important;
		flex-flow: column;
	}
}

@media only screen and (min-width: 482px) {
	.back_arrow {
		display: none;
	}
}

@media only screen and (max-width: 481px) {
	.back_arrow {
		width: 30px;
	}

	.back_arrow img {
		max-width: 100%;
		height: auto;
	}
}

@media only screen and (max-width: 767px) {
	.cewbrtever {
		align-items: center;
		gap: 5px;
	}
}

@media only screen and (max-width: 481px) {
	.resp_chat_name {
		display: none !important;
	}
}

@media only screen and (max-width: 320px) {
	.navbarResp {
		/* left: 93% !important; */
	}
}

.chat-ul-area {
	height: 100%;
	max-height: calc(100vh - 207px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 50px 30px 10px 16px;
	margin-bottom: 60px;
}

.chat-ul-area::-webkit-scrollbar {
	/* display: none; */
	width: 8px;
	height: 8px;
}

.chat-ul-area::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.chat-ul-area::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}

@media screen and (max-width: 500px) {
	.chat-ul-area {
		padding-bottom: 70px;
	}
}

.count_unread_msg_usr {
	width: 20px;
	font-size: 12px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #6610f2;
	color: #fff;
	border-radius: 50%;
	margin-left: auto;
	min-width: 20px;
}

.chat_go_to_bottom {
	width: 30px;
	height: 30px;
	background: #fff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
	position: absolute;
	bottom: 80px;
	right: 18px;
	z-index: 9;
}

.ewliywbwe {
	border-bottom: #e6e6e6 solid 2px;
}

.ewliywbwe button {
	background-color: transparent;
	border: none;
	padding-bottom: 14px;
	margin-right: 20px;
	border-bottom: #fff0 8px solid;
}

.bt-ecnolwniweo {
	border-top: #e6e6e6 solid 1px;
}

.cwulqeoi {
	font-size: 15px;
	font-weight: 600;
	padding-bottom: 4px;
}

.mr-none-mrpo {
	margin-right: 0 !important;
}

.ewliywbwe .active_ewliy {
	border-bottom-color: #6d2ef1 !important;
}

.pouphed_skll {
	font-size: 16px;
	color: #000;
	font-weight: 500;
}

.popuphead_smparcr {
	font-size: 13px;
	color: #4d4d4d;
	font-weight: 400;
}

.catbox_rd_ofive {
	border: #707070 solid 1px;
	padding: 10px 15px;
	border-radius: 10px;
}

.catbox_rd_ofive .skill_bxr_gry {
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	font-size: 13px;
	align-items: center;
	margin-left: 0;
	margin-right: 5px;
}

@media screen and (max-width: 376px) {
	.catbox_rd_ofive .skill_bxr_gry {
		font-size: 11px;
	}
}

.font-size-13px {
	font-size: 13px !important;
}

.font-size-11px {
	font-size: 11px !important;
}

.font_size_14px {
	font-size: 14px !important;
}

.ulist_overpopup {
	margin-left: -1rem !important;
}

.ulist_overpopup li {
	padding: 2px 0;
}

.gbxewueyuien {
	border: #e8e7e7 solid 1px;
	font-size: 12px;
	color: #4d4d4d;
	font-weight: 400;
	padding: 15px !important;
}

.catbox_rd_ofive .skill_bxr_gry:hover button {
	color: #fff;
}

.maxlabel_atcxt {
	font-size: 13px;
	color: #9c9a9f;
	font-weight: 400;
}

.catbox_rd_ofive .skill_bxr_gry button {
	position: relative;
	right: -10px;
	background-color: transparent;
	border: none;
	padding-left: 7px;
	outline: none;
	padding-top: 0;
	padding-bottom: 0;
}

.agreement_ineoeu label {
	display: flex;
	align-items: flex-start;
	line-height: 16px;
}

.suggest_skills {
	position: absolute;
	background: #ececec;
	/* border: 1px solid #cecdcd; */
	max-height: 177px;
	/* width: 100%; */
	overflow-y: auto;
	top: 42px;
	left: 1px;
}

.suggest_skills::-webkit-scrollbar {
	width: 6px;
}

.suggest_skills::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.suggest_skills span {
	padding: 5px 10px;
	cursor: pointer;
	display: inline-block;
	width: 100%;
	height: 35px;
	border-bottom: 1px solid rgb(244 244 244);
}

.suggest_skills span:hover {
	background: #e2e2e2;
}

.agreement_ineoeu input {
	margin-right: 8px;
	height: 15px;
	width: 15px;
}

.headiwuewopiu {
	font-size: 16px;
	color: #4d4d4d;
	font-weight: 600;
}

.radiolablepopo {
	color: #4d4d4d !important;
	font-size: 14px !important;
	line-height: 18px !important;
	font-weight: 400 !important;
}

.radiolablepopo input {
	padding: 0;
}

.popup_form_element label {
	min-height: 35px;
}

.payment_confirm {
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 0;
}

.getpaidsve_btn {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.getpaidsve_btn button {
	width: 100%;
	text-transform: capitalize;
	font-weight: 600;
	margin-right: 0 !important;
	padding: 10px 45px;
}

.getpaidsve_btn button:hover {
	background-color: #fff;
	border: 1px solid #6610f2;
	color: #6610f2;
}

.payformelent input {
	padding: 9px 20px;
	border-radius: 5px !important;
}

.payformelent select {
	padding: 9px 20px;
	border-radius: 5px !important;
}

.addpay_header_hdin {
	color: #6d2ef1;
	font-size: 26px !important;
	line-height: 35px !important;
}

.addpayrhdin {
	margin-bottom: 10px;
}

.paypopradouy {
	border-radius: 5px;
}

.search_skilloiouo {
	color: #9c9a9f;
	outline: none;
	padding-left: 0;
}

.cardPopupExpire {
	display: flex;
	gap: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.popuphead_smparcr {
		font-size: 12px;
	}
}

@media only screen and (max-width: 768px) {
	.p_header_hding {
		font-size: 22px;
	}
}

@media only screen and (max-width: 425px) {
	.cardDetailsPopup {
		width: 100%;
	}

	.cardPopupExpire {
		display: flex;
		gap: 0;
	}

	.cardPopupExpire .payformelent {
		width: 100%;
	}
}

.profile_nceqoi_popup {
	max-width: 570px;
}

.signup_mlr_20px_a {
	margin-left: 20px;
	margin-right: 20px;
}

.mrright-gppnew {
	margin-right: 10px;
}

.text-blue-theme {
	color: #6d2ef1;
}

.inppadmore-newppup {
	padding: 11px 20px !important;
}

.freelancer_testimonials {
	padding: 5px 0;
}

.freelancer_testimonials .testimonial_img {
	display: flex;
	justify-content: center;
	align-items: center;
}

.freelancer_testimonials .testimonial_img img {
	width: 50px;
	height: auto;
	border-radius: 50%;
}

.freelancer_testimonials .user_details {
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.freelancer_testimonials .user_details .f_user_name {
	font-weight: 600;
}

.freelancer_testimonials .user_details .f_user_country {
	font-weight: 400;
	font-size: 14px;
}

.testimonial_box {
	border-bottom: 1px solid #d5d5d5;
	margin-bottom: 4px;
}

.testimonials_head {
	display: flex;
	align-items: center;
	gap: 10px;
}

.testimonials_head .f_user_name {
	font-weight: 600;
	text-transform: capitalize;
}

.testimonials_head .testimonial_time {
	font-size: 12px;
	font-weight: 300;
}

.testimonial_description {
	font-size: 14px;
	padding: 10px 0;
}

.visiblity_opts {
	position: absolute;
	display: flex;
	background: #e5e4e4;
	flex-direction: column;
	align-items: flex-start;
	top: 33px;
	left: 79px;
	width: 152px;
	min-width: 152px;
	z-index: 1;
	border: 1px solid #dbdbdb;
}

.visiblity_opts span {
	width: 100%;
	height: 27px;
	border-bottom: 1px solid #c1c1c1;
	padding: 3px 10px;
	cursor: pointer;
	font-size: 13px;
	font-weight: 400;
}

.visiblity_opts span:hover {
	background-color: #dedddd;
}

.visiblity_opts .visiblity_ops_arrow {
	display: inline-block;
	transform: rotate(90deg);
	position: absolute;
	top: -18px;
	font-size: 19px;
	font-weight: 100;
	left: 6px;
}

.experience_level_ops {
	position: absolute;
	display: flex;
	background: #e5e4e4;
	flex-direction: column;
	align-items: flex-start;
	top: 33px;
	left: 139px;
	width: 134px;
	min-width: 134px;
	z-index: 1;
	border: 1px solid #dbdbdb;
}

.experience_level_ops span {
	width: 100%;
	height: 27px;
	border-bottom: 1px solid #c1c1c1;
	padding: 3px 10px;
	cursor: pointer;
	font-size: 13px;
	font-weight: 400;
}

.experience_level_ops span:hover {
	background-color: #dedddd;
}

.experience_level_ops .experience_level_ops_arrow {
	display: inline-block;
	transform: rotate(90deg);
	position: absolute;
	top: -18px;
	font-size: 19px;
	font-weight: 100;
	left: 6px;
}

.portfolioPaginate {
	display: flex;
	list-style: none;
	width: 100%;
	justify-content: flex-end;
	margin: 0;
}

.paginationNext,
.paginationPrev,
.portfolioPageCount {
	margin: 0 10px;
	background-color: #6610f2;
	color: white;
	border: 1px solid #6610f2;
	width: 40px;
	cursor: pointer;
}

.paginationNext:hover,
.paginationPrev:hover,
.portfolioPageCount:hover {
	border: 1px solid #6610f2;
	background-color: #fff;
	color: #6610f2 !important;
}

.portfolioPageCount {
	display: none;
}

.disablePaginateBtn {
	cursor: not-allowed;
	background-color: rgb(173, 173, 173);
	border: rgb(186, 183, 183);
}

.paginationPrevA,
.paginationNextA {
	cursor: inherit;
	color: inherit;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.disablePaginateBtn:hover {
	cursor: not-allowed;
	background-color: rgb(173, 173, 173);
	border: rgb(186, 183, 183);
}

.ertr4h6j65esdv {
	justify-content: space-evenly;
}

.m_x_13_imp {
	margin: 0 13px !important;
}

@media screen and (max-width: 767px) {
	.all_contra_btn_res {
		position: absolute;
		top: -30px;
		right: 0;
	}
}

.message_attachment {
}

.chat_file_progress {
	max-width: 400px;
	padding: 10px;
	border-radius: 6px;
	margin: 10px 10px 10px auto;
	display: flex;
	gap: 10px;
	align-items: center;
	position: relative;
	border: 1px solid #d1d1d1;
}

.chat_file_progress .about {
	width: 100%;
	max-width: 100%;
}

.chat_file_progress .about .pro_file_name {
	color: #ababab;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
}

.chat_file_progress .about .progress_precent {
	width: 100%;
}

.chat_file_progress .about .progress_precent .progress {
	height: 4px;
	margin: 4px 0;
}

.chat_file_progress .about .progress_precent .progress-bar {
	background: #6610f2;
}

.chat_file_progress .cancle_chat_upload {
	height: 100%;
	min-width: 20px;
}

.chat_file_progress .cancle_chat_upload .cancel_icon {
	display: block;
	transform: rotateX(42deg);
	color: #ababab;
	cursor: pointer;
	font-size: 18px;
	border-radius: 50%;
	padding: 0 5px;
	z-index: 99;
}

.zoom_link_in_chat {
	margin: 10px 0;
	color: #b7b7b7;
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
	width: max-content;
	max-width: 260px;
}

.zoom_link_left {
	border-top-left-radius: 0;
	margin-right: auto;
}

.zoom_link_right {
	border-bottom-right-radius: 0;
	margin-left: auto;
	margin-right: 10px;
	background-color: #6610f2;
	color: #fff;
}

.zoom_link_in_chat .about {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
	font-size: 13px;
	font-weight: 600;
}

.zoom_link_in_chat .about svg {
	width: 25px;
	height: 22px;
}

.zoom_link_right .about svg {
	fill: #fff;
}

.zoom_link_right .about svg rect,
.zoom_link_right .about svg path {
	fill: #fff;
}

.zoom_link_in_chat .link {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	overflow: hidden;
	word-break: break-word;
	font-size: 12px;
}

.zoom_link_in_chat:hover .link {
	text-decoration: underline;
}


.message_box_right div::selection {
	background-color: #ffffff !important;
	color: #000 !important;
}

.search_icons_container {
	max-width: 150px;
}

@media screen and (max-width: 500px) {
	.search_icons_container {
		max-width: max-content;
	}
}

#see_user_btn {
	position: relative;
}

.create-group-popup {
	position: absolute;
	width: 350px;
	height: calc(100vh - 96px);
	background-color: #fff;
	top: -10px;
	right: -124px;
	z-index: 20;
	box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

.ChatGroupListHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 10px;
}

.ChatGroupListHeader h6 {
	margin-bottom: 0;
}

.ChatGroupListHeader .fa-chevron-left {
	margin-right: 15px;
}

.chatgroupitem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	position: relative;
}

.chatgroupitem h6 {
	margin-bottom: 0;
	font-size: 12px;
}

.chatgroupitem small {
	font-size: 12px;
}

.chatremove {
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: #fff;
	box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
	z-index: 21;
	padding: 5px 10px;
}

.chatremove h6 {
	margin-bottom: 0;
	font-size: 14px;
}

.chatremove h6 .fa-times {
	margin-right: 5px;
}
