
.navbar {
    z-index: 1;
}

.navbar-toggler {
    outline: none !important;
    border: none;

    &:focus {
        box-shadow: none;
    }
}

.navbar-brand img {
    width: 90px;
}

.primary-background {
    position: relative;
    background-image: url("../../public/assets/images/theme-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.header-no-bg {
    .navbar {
        background: none !important;
    }
}

.navbar_btn {
    margin: 0 8px;
    font-weight: 600;

    & a:hover {
        color: #170048;
    }

    * {
        color: #fff;
    }

    .dropdown-menu {
        box-shadow: 0 0 10px #0004;
        border-radius: 0;
        border: #c9c9c9 solid 1px;
        padding: 15px 10px;
        font-size: 14px;

        .dropdown-item {
            color: #393939;
            background: none;
            padding: 7px 12px;

            &:hover {
                color: #6d2ef1;
            }
        }
    }
}

.mobile-nav-container {
    display: none !important;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .navbar_btn {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }
}

@media only screen and (max-width: 767px) {
    .mobile-nav-container {
        max-width: 540px;
        display: flex !important;
        width: 100%;
        border-top: 1px solid #fff;
        margin-top: 10px;
    }
}

.b_job_l_sx .dropdown-menu::after,
.b_job_l_sx .dropdown-menu::before {
    content: "";
    position: absolute;
    right: 11px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom-color: #fff;
}

.b_job_l_sx .dropdown-menu::after {
    top: -19px;
    right: 6px;
    border: 12px solid transparent;
    border-bottom-color: #fff;
}

.b_job_l_sx .dropdown-menu::before {
    top: -15px;
    border-bottom-color: #0003;
    filter: blur(3px);
}

.b_job_l_sx .dropdown-menu {
    border-radius: 8px;
    border: none;
    right: 0;
    left: unset;
    margin-top: 10px;
    box-shadow: 0 0 10px #0004;
}

.navbar-nav {
    align-items: center;
    z-index: 20;
    position: relative;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
    color: #fff;
}

.nav-icon {
    margin: 0 12px;
}

.nav_dropdown_profile {
    height: 45px;
    width: 45px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
}

.nav_dropdown_profile > a::after,
.nav_dropdown_profile > a::before {
    display: none;
}

.nav_dropdown_profile .dropdown-toggle {
    height: 50px !important;
    width: 50px !important;
}

.nav_dropdown_profile > .dropdown-menu::after {
    right: 10px;
}

.nav_dropdown_profile > .dropdown-menu::before {
    right: 15px;
}

.navbar-toggle {
    border: none;
    padding: 0;
}
.navbar-toggle:focus {
    box-shadow: none;
}

.nav_pro_node {
    display: flex;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .responsiveContainer {
        position: relative !important;
    }
}

.navbar-collapse {
    display: none !important;
}

.mobile_slide_menu {
    background-color: #061534;
    padding: 15px 15px 70px 15px;
    z-index: 9999999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateY(-100%);
    transition: 0.5s all;
}

.external_nav_padding {
    padding: 18px 25px;
}

@media (max-width: 767px) {
    .responsive-navbar-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 15px 10px;
    }

    .external_nav_padding {
        padding: 18px 10px;
    }
}

.nav_profile {
    height: 45px;
    width: 45px;
    border: #fff solid 2px;
    border-radius: 50%;
    overflow: hidden;
    pointer-events: none;
    position: relative;
}

.nav_profile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.navbar-expand-md .navbar-nav .nav-link {
    height: fit-content;
    font-weight: 500;
    font-size: 15px;
    padding: 8px;
    cursor: pointer;
}

.navbar-expand-md .navbar-nav .dropdown .nav-link {
    padding-right: 30px;
}