:root {
	--tracker_point_width: 25px;
	--tracker_point_height: 25px;
}

.main_box_descr {
	margin: 20px 0;
	border: #e0e0e0 solid 1px;
	padding: 10px;
	border-radius: 10px;
}

.mbd_n_pd {
	padding: 30px 30px;
}

.text-right {
	text-align: right !important;
}

.category-popup-select {
	width: 100%;
	margin-top: 20px;
	height: 40px;
	border-radius: 3px;
	padding: 0 10px;
	border: 1px solid #b5b5b5;
	outline: none;
}

@media (max-width: 481px) {
	.category-popup-select {
		font-size: 12px;
	}
}

.top_sec_bb {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: #d4d4d5 solid 1px;
	padding: 15px 0;
}

.ts_h1 h1 {
	font-weight: bold;
	font-size: 25px;
	line-height: 30px;
	color: #00065a;
}

.disc_head_h1 h1 {
	font-weight: bold;
	font-size: 35px;
	line-height: 30px;
	color: #00065a;
	text-align: left;
}

.disc_para_h {
	font-weight: 500;
	font-size: 15px;
	line-height: 17px;
	color: #393939;
}

.color-puple {
	color: #6d2ef1 !important;
}

.disc_para_h a {
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #6d2ef1;
}

.fif_five {
	font-size: 15px !important;
	font-weight: 500 !important;
}

.post_job_btn_m button {
	background-color: #6d2ef1;
	padding: 12px 25px;
	color: #fff;
	border: #6d2ef1 solid 1px;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.search_reel_9_wi {
	max-width: 85% !important;
	width: 100% !important;
}

.mx_eity {
	max-width: 80% !important;
}

::-webkit-calendar-picker-indicator {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 24 24'%3E%3Cpath fill='%236D2EF1' d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/%3E%3C/svg%3E");
}

.ts_btn label {
	background-color: #6d2ef1;
	padding: 12px 25px;
	color: #fff;
	border: none;
	min-width: 160px;
	border-radius: 30px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	text-align: center;
}

.ts_btn_edit_client label {
	background-color: #6d2ef1;
	padding: 8px 14px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.ts_btn button {
	background-color: #6d2ef1;
	padding: 12px 25px;
	color: #fff;
	border: none;
	min-width: 180px;
	border-radius: 30px;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

@media screen and (max-width: 510px) {
	.post_job_btn_m button {
		padding: 8px 15px;
	}
}

@media screen and (max-width: 481px) {
	.ts_btn button {
		min-width: 200px;
		padding: 12px 6px;
		font-size: 15px;
	}
}

.b_bot_inp .selectImage {
	width: 100px;
	height: 100px;
	overflow: hidden;
	object-fit: cover;
}

.input_t_lab {
	font-weight: 600;
	font-size: 17px;
	line-height: 18px;
	margin: 10px 0;
	padding: 10px 0;
	color: #1f1f1f;
}

.job_t_inp textarea {
	height: 130px;
}

.job_t_inp input,
.job_t_inp textarea {
	padding: 12px 12px;
	border: #e8e7e7 solid 1px;
	color: #1f1f1f;
	background-color: #fff;
	font-size: 14px;
	width: 100%;
	font-weight: 400;
}

.sm_label_inp {
	padding: 8px 0;
	font-size: 12px;
	width: 100%;
	font-weight: 500;
	color: #878787;
}

.dis_t_pra_s {
	font-size: 12px;
	width: 100%;
	font-weight: 500;
	color: #878787;
}

.prof_saved_chck_s {
	font-size: 14px;
	width: 100%;
	text-indent: 10px;
	display: flex;
	align-items: center;
	font-weight: 500;
	color: #878787;
}

.line_hr_a {
	display: flex;
	align-items: center;
	margin: 0 10px;
}

.profile_sel_ceck_alne {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.profile_sel_ceck_alne input {
	height: 20px;
	width: 20px;
	outline: 1px solid #cfcfcf;
	box-shadow: none;
}

.search_tab_sm_a {
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #3b3b3b !important;
}

.p-left-none {
	padding-left: 0 !important;
}

.p-right-none {
	padding-right: 0 !important;
}

.prof_saved_chck_s input {
	margin: 0 5px 0 0;
}

.b_bot_inp {
	border-bottom: #d4d4d5 solid 1px;
	padding: 15px 0;
}

.input_t_par {
	font-size: 14px;
	width: 100%;
	font-weight: 500;
	color: #878787;
	padding-bottom: 10px;
}

.sm_span_icon {
	margin-right: 5px;
}

.sm_span_icon svg {
	fill: #6d2ef1;
}

.attach_f_btn {
	padding-top: 15px;
	margin: 10px 0;
	font-size: 13px;
	position: relative;
}

.attachsdgbtn {
	margin: 14px 0;
}

.attach_f_btn input {
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 0;
	left: 0;
	top: 0;
}

.attach_f_btn svg {
	fill: #fff;
	margin: 0 10px 0 0;
}

.cat_inp_j input {
	border: none;
	outline: none;
	max-width: 100%;
	letter-spacing: -0.5px;
	width: auto;
	min-width: 320px;
	font-weight: 500;
	padding-left: 0;
	padding-top: 0;
}

.btn_job_back button {
	background-color: #fff;
	padding: 11px 25px;
	color: #6d2ef1;
	margin-left: 5px;
	min-width: 160px;
	border: #6d2ef1 solid 1px;
	border-radius: 30px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

@media (max-width: 481px) {
	.btn_job_back button {
		margin-left: 0;
		min-width: 170px !important;
	}
}

.btn_job_back button:hover {
	background-color: #6d2ef1;
	color: #fff;
}

.next_b_btn_c button:hover {
	background-color: #6d2ef1;
	color: #fff;
}

.draf_jo_btnbn {
	background-color: #fff;
	padding: 12px 25px;
	color: #00065a;
	min-width: 160px;
	margin-right: 20px;
	border: none;
	border-radius: 30px;
	font-weight: bold;
	font-size: 18px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.post_job_btn {
	background-color: #6d2ef1;
	padding: 12px 25px;
	color: #fff;
	min-width: 160px;
	border: #6d2ef1 solid 1px;
	border-radius: 30px;
	font-weight: bold;
	font-size: 18px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.view_job_box {
	margin: 20px 0;
}

.vj_hea h1 {
	font-weight: 600;
	word-break: break-word;
	font-size: 25px;
	line-height: 30px;
	color: #00065a;
}

.vj_r_link a {
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #6d2ef1;
}

.reset_link_con a {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #6d2ef1;
}

.to_date_con {
	padding: 0 15px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #393939;
}

.vj_r_sm_t {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #1f1f1f;
	text-align: right;
}

.tab_btn_vs {
	padding: 12px 30px;
	background-color: #fff;
	border: none;
	outline: none;
	border-radius: 0;
	width: 25%;
	font-weight: 500;
	font-size: 17px;
	text-transform: uppercase;
	line-height: 25px;
	color: #3b3b3b;
	text-align: center;
	border-bottom: transparent solid 6px;
}

.active_bvs {
	border-bottom: #6d2ef1 solid 6px;
	color: #6d2ef1;
}

@media screen and (max-width: 1024px) {
	.tab_btn_vs {
		width: unset;
	}
}

@media screen and (max-width: 425px) {
	.relation1 {
		display: none;
	}

	.relation2 {
		display: none;
	}
}

.box_vs_m {
	border: 1px solid #d4d4d5;
	margin: 10px 0;
	padding: 10px;
}

.bvs_main_head {
	font-weight: 600;
	font-size: 15px;
	line-height: 26px;
	word-break: break-word;
	color: #6d2ef1;
}

.bvs_m_post_time {
	font-weight: 400;
	font-size: 15px;
	line-height: 35px;
	color: #545454;
	min-width: 150px;
}

.world_wide_t {
	font-weight: 400;
	font-size: 13px;
	line-height: 13px;
	color: #545454;
	display: flex;
	padding: 5px 0;
	margin-top: 5px;
	align-items: center;
	gap: 6px;
}

.world_wide_t svg {
	fill: #6d2ef1;
	margin-right: 4px;
}

.svs_b_bot {
	border-bottom: #d4d4d5 solid 1px;
	padding: 15px 0;
}

.svs_para {
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	padding-bottom: 15px;
	padding-top: 10px;
	color: #545454;
	white-space: pre-line;
	word-break: break-word;
}

/* .chat_users_scroll {
  height: 640px !important;
} */
.milestoneInfo span.milestoneAmount {
	margin-right: 10px;
}

.blueStars path {
	fill: #6d2ef1;
	stroke: #6d2ef1;
}

.blueStars path[fill="none"] {
	fill: none;
	stroke: #6d2ef1;
}

.completed_job .project_rating {
	display: flex;
	justify-content: flex-start;
	font-size: 12px;
}

.completed_job .projectDescription {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 12px;
}

.completed_job .projectBud span.amount {
	margin-right: 20px;
}

.completed_job .project_rating .reviews {
	margin-right: 10px;
}

.single_offer_type {
	display: flex;
	max-width: 350px;
	justify-content: space-between;
	/* gap: 40px; */
}

.bvs_bol {
	font-weight: 600;
}

.activite_ul {
	padding: 0;
	list-style: none;
}

.activite_ul li {
	display: flex;
	font-weight: 700;
	font-size: 15px;
	line-height: 19px;
	padding-bottom: 15px;
	padding-top: 10px;
	color: #000;
}

.activite_ul li span {
	padding: 0 10px;
}

.au_name {
	color: #878787;
	font-weight: 500;
	min-width: 200px;
}

.r_in_tab_h {
	display: flex;
	align-items: center;
	margin: 13px 0;
	cursor: pointer;
}

.r_in_tab_h h1 {
	font-weight: 600;
	font-size: 17px;
	line-height: 25px;
	color: #6f30f7;
	padding: 0;
	margin: 0;
}

.about_client_no {
	border-top: #d4d4d5 solid 1px;
	padding: 20px 0;
	margin: 30px 0;
	border-bottom: #d4d4d5 solid 1px;
}

.ab_t_c_h1 h1 {
	font-weight: 700;
	font-size: 22px;
	line-height: 22px;
	color: #1f1f1f;
	padding-top: 20px;
	margin: 0;
	border-top: #d4d4d5 solid 1px;
}

.p_not_verif {
	font-weight: 500;
	font-size: 14px;
	line-height: 19px;
	padding-bottom: 15px;
	padding-top: 10px;
	color: #545454;
}

.no_verify_icon {
	margin: 0 5px;
	position: relative;
}

.no_verify_icon svg {
	fill: #b9bdc1;
}

.tooltip_hourly_div {
	position: relative;
	/*display: inline-block; TODO: fix */
	display: none;
}

.tooltip_hourly_div .tooltipHourlytext {
	visibility: hidden;
	width: 280px;
	line-height: 20px;
	color: #000;
	border-radius: 4px;
	position: absolute;
	z-index: 21;
	top: 36px;
	left: -26px;
	opacity: 1;
	font-size: 12px;
	font-weight: 400;
}

.tooltipHourlytextnext {
	/* visibility: hidden; */
	width: 280px;
	line-height: 20px;
	color: #000;
	border-radius: 4px;
	position: absolute;
	z-index: 21;
	top: 36px;
	left: -26px;
	opacity: 1;
	font-size: 12px;
	font-weight: 400;
}

.thought {
	background-color: #fff;
	padding: 20px 30px;
	border-radius: 50px;
	position: relative;
}

.thought:before,
.thought:after {
	content: "";
	background-color: #fff;
	/* border-radius: 50%; */
	display: block;
	position: absolute;
	z-index: -1;
}

.thought:before {
	width: 15px;
	height: 15px;
	top: -5px;
	left: 45px;
	transform: rotateZ(45deg);
}

/* .thought:after {
  bottom: -10px;
  right: 26px;
  width: 30px;
  height: 30px;
  box-shadow: 40px -34px 0 0 #fff, -28px -6px 0 -2px #fff,
    -24px 17px 0 -6px #fff, -5px 25px 0 -10px #fff;
} */

.tooltipHourlytextnext .tooltip_message {
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	color: #000;
}

.tooltip_backdrop {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #0000001c;
	z-index: 19;
}

.tooltip_hourly_div .tooltipHourlytextHover {
	visibility: visible;
	width: 250px;
	line-height: 20px;
	background-color: #fff;
	color: #000;
	border-radius: 4px;
	padding: 8px 7px;
	position: absolute;
	z-index: 1;
	top: 25px;
	right: 0;
	left: -22%;
	opacity: 1;
	font-size: 12px;
	font-weight: 400;
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.22);
}

.tooltip_hourly_div .tooltip_btns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
	cursor: pointer;
}

.tooltip_hourly_div .tooltip_btns .nextbtnstooltip {
	background-color: #6d2ef1;
	letter-spacing: 0;
	color: #fff;
	padding: 5px 15px;
	border: none;
	outline: none;
	border-radius: 3px;
	margin-left: 5px;
	cursor: pointer;
	font-size: 12px;
}

.tooltip_hourly_div .tooltip_btns .backbtnstooltip {
	outline: none;
	padding: 4px 15px;
	background-color: #fff;
	text-align: center;
	font-size: 12px;
	border: #c9c9c9 solid 1px;
	letter-spacing: 0;
	color: #393939;
	border-radius: 3px;
}

.tooltip_hourly_div:hover .tooltipHourlytext {
	visibility: visible;
}

.cline_sm_area_i {
	margin: 10px 0;
}

.link_payment {
	color: green;
	font-size: 13px;
	font-weight: 500;
}

.whenWill {
	font-size: 13px;
	padding: 10px 0;
}

.tooltipDate {
	font-weight: 400;
}

.csai_name {
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	padding-top: 10px;
	color: #1f1f1f;
}

.csai_t {
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	color: #545454;
}

.ab_inpu_cop input {
	padding: 10px;
	border: #d4d4d5 solid 1px;
	width: 100%;
	margin: 12px 0;
}

.cp_link button {
	font-weight: 600;
	font-size: 14px;
	padding: 8px 20px;
	border: none;
	outline: none;
	border-radius: 5px;
	background-color: #6d2ef1;
	color: #fff;
}

.tab_m_nodea {
	border-bottom: #d4d4d5 solid 1px;
}

.project_searchValue {
	display: flex;
	align-items: center;
	padding-top: 50px;
	justify-content: space-between;
	border-bottom: #d4d4d5 solid 1px;
}

.project_searchValueSelect {
	background-color: #fff;
	padding: 10px 10px;
	border-bottom: #9c9c9c solid 1px;
	font-size: 15px;
	letter-spacing: 0;
	outline: none;
	line-height: 18px;
	color: #9c9c9c;
}

@media screen and (max-width: 1199px) {
	.project_searchValue {
		flex-wrap: wrap !important;
	}

	.resp_filter_job {
		margin-bottom: 15px;
		margin-top: 20px;
	}
}

@media screen and (max-width: 767px) {
	.resp_ser_fil_div .project_searchValue {
		display: flex;
		justify-content: flex-start;
		/* flex-wrap: wrap !important; */
	}

	.resp_ser_fil_div .resp_filter_job {
		justify-content: center;
		width: 100%;
	}
}

@media screen and (max-width: 350px) {
	.resp_ser_fil_div .resp_se_saved {
		display: flex;
	}
}

.freelancer_box_area_in {
	position: relative;
}

.search_input_in {
	margin: 10px 10px 10px 0;
	border: #e8e7e7 solid 1px;
	padding: 10px 15px;
	min-width: 60%;
	background-color: #fff;
	width: 60%;
	max-height: 50px;
	display: flex;
	align-items: center;
}

.selec_inp_ful_w {
	width: 76% !important;
}

.select_inp_in {
	width: 25%;
	margin-right: 10px;
	position: relative;
}

.disc_sel select {
	color: #b7b7b7;
	border: #e8e7e7 solid 1px;
	font-size: 15px;
	margin-left: 10px;
	background-color: transparent;
	font-weight: 500;
	width: 100%;
	min-width: 200px;
	max-height: 50px;
	padding: 10px 15px;
	height: 100%;
	outline: none;
}

.select_inp_in select {
	color: #828282;
	border: #e8e7e7 solid 1px;
	font-size: 15px;
	background-color: transparent;
	font-weight: 400;
	width: 100%;
	max-height: 50px;
	padding: 10px 15px;
	height: 100%;
	outline: none;
}

.wid_30_in {
	padding-top: 0 !important;
}

@media only screen and (max-width: 480px) {
	.sm_amhir_no_mar .amount_hir_in {
		margin-left: 0 !important;
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.mob_ele_wfull .select_inp_in,
	.mob_ele_wfull .search_input_in {
		width: 100% !important;
	}
}

.talent_dropdown_cs {
	position: relative;
}

.talent_dropdown_cs .dropdown-menu {
	right: 0 !important;
}

@media screen and (max-width: 1024px) {
	.talent_dropdown_cs .dropdown-menu {
		right: 0 !important;
	}
}

@media screen and (max-width: 768px) {
	.talent_dropdown_cs .dropdown-menu {
		right: 0 !important;
	}
}

.font-weight-600 {
	font-weight: 600 !important;
}

.new_w_pad_fl {
	min-width: 100px !important;
	padding-left: 45px;
	padding-right: 45px !important;
}

.width-max-content {
	width: max-content;
}

.new_w_pad_fl:nth-child(1) {
	padding-left: 0 !important;
}

.talent_dropdown_cs .dropdown-menu::before {
	content: "\25BA";
	color: #fff;
	position: absolute;
	top: 0;
	font-size: 20px;
	margin-top: -19px;
	right: 5px;
	transform: rotate(270deg);
}

.browseJobs_dropdown_cs .dropdown-menu::before {
	content: "\25BA";
	color: #fff;
	position: absolute;
	top: 0;
	font-size: 20px;
	margin-top: -19px;
	right: 5px;
	transform: rotate(270deg);
}

.browseJobs_dropdown_cs {
	position: relative;
}

.browseJobs_dropdown_cs .dropdown-menu {
	right: -160% !important;
}

.search_input_in form {
	width: 100%;
}

.search_input_in input {
	color: #000;
	font-size: 14px;
	background-color: transparent;
	font-weight: 500;
	width: 100%;
	padding: 0 7px;
	height: 100%;
	border: none;
	outline: none;
}

.search_area_in {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

@media (max-width: 481px) {
	.ContractsSearching {
		width: 100% !important;
	}
}

.search_icon_in {
	display: flex;
	align-items: center;
}

.search_icon_in svg {
	fill: #6d2ef1;
}

@media screen and (max-width: 550px) {
	.resp_search_div {
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;
	}

	.search_input_in {
		width: 100%;
	}

	.resp_cir_heart {
		position: absolute;
		top: -7px;
		right: 0;
	}
}

.transp_fil_btn {
	background-color: transparent !important;
	color: #6d2ef1 !important;
	margin-right: 10px;
	border: #6d2ef1 solid 1px !important;
}

.transp_fil_btn svg {
	fill: #6d2ef1;
}

.freelancer_box_in {
	background-color: #f8f8f8;
	padding: 20px;
	margin-bottom: 20px;
}

.img_pag_tex {
	font-size: 14px;
	color: #3b3b3b;
	text-align: right;
	background-color: transparent;
	font-weight: 400;
	padding: 6px 0;
}

.freel_box_in_img {
	max-width: 280px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.freel_box_in_img img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.freelancer_img_in_r {
	height: 60px;
	width: 60px;
	position: relative;
	border-radius: 50%;
	margin-bottom: 7px;
}

.freelancer_img_in_r img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.freelancer_ame_in {
	font-size: 15px;
	color: #3b3b3b;
	font-weight: bold;
	cursor: pointer;
	word-break: break-word;
}

.freelancer_exp_in {
	font-size: 12px;
	color: #6b6b6b;
	/* width: 90%; */
	font-weight: 500;
	word-break: break-word;
}

.freelancerExpEin {
	width: unset;
}

.freelancer_loc_in {
	padding: 5px 0 0 0;
}

.freel_det_bin {
	padding-left: 10px;
}

.resp_offer_s {
	width: 55%;
}

@media screen and (max-width: 767px) {
	.resp_offer_s {
		width: 100%;
	}

	.resp_padd {
		width: 80%;
	}

	.resp_padd2 {
		width: unset;
	}

	/* .resp_offer_align {
    width: 20%;
  }
  .resp_offer_align img {
    height: auto;
    max-width: 100%;
  } */
}

@media screen and (max-width: 550px) {
	.resp_off_wid {
		width: 65%;
	}
}

@media screen and (max-width: 481px) {
	.resp_gap_of_pr {
		/* width: 65%; */
		gap: 10px;
	}

	.freelancerBoxArea_in {
		flex-direction: column;
	}

	.freelDdetBin {
		width: unset;
		margin-left: 20px;
	}

	.attachBTbvttn span {
		margin: 0 20px !important;
	}
}

@media screen and (max-width: 424px) {
	.resp_offer_s {
		/* width: 65%; */
		flex-direction: column;
		align-items: flex-start;
	}

	.resp_padd {
		padding: 0;
	}

	.resp_padd2 {
		padding: 0;
		width: 100%;
	}

	.freelDdetBin {
		width: unset;
		margin-left: 20px;
	}

	.resp_amnt_ofr {
	}
}

.heart_roun_btn svg {
	fill: #6d2ef1 !important;
	height: 19px !important;
	width: 19px !important;
	margin: 0;
}

.plr-35px {
	padding-left: 35px !important;
	padding-right: 35px !important;
}

.heart_roun_btn {
	height: 45px !important;
	width: 45px !important;
	display: flex;
	text-align: center;
	padding: 0 !important;
	justify-content: center;
	align-items: center;
	border: #00065a solid 1px !important;
	border-radius: 50px !important;
	-webkit-border-radius: 50px !important;
	-moz-border-radius: 50px !important;
	cursor: pointer;
}

.freelancer_timin {
	font-size: 12px;
	color: #6b6b6b;
	font-weight: 500;
	padding: 10px 0;
}

@media screen and (max-width: 767px) {
	.saved_job_al {
		width: 70%;
	}

	.resp_top_margin {
		margin-top: 10px;
	}
}

@media (max-width: 481px) {
	.freelancer_timin {
		flex-direction: column;
	}

	.amount_hir_in {
		margin: 0 !important;
	}

	.transp_fil_btn {
		margin-right: 5px;
	}
}

@media screen and (max-width: 374px) {
	.freelancer_box_in {
		padding: 10px;
	}

	/* .freel_det_bin {
    padding-left: 0;
  } */
	.fre_img_name {
		flex-wrap: wrap !important;
		width: 50%;
		align-items: center;
		justify-content: flex-start;
	}
}

@media screen and (max-width: 350px) {
	.freelancer_box_in {
		padding: 10px;
	}

	.fre_img_name {
		flex-wrap: wrap !important;
		width: 60%;
		align-items: center;
		justify-content: flex-start;
	}
}

.freelancer_compl_in {
	font-size: 12px;
	color: #6b6b6b;
	font-weight: 400;
	padding: 10px 0;
	white-space: pre-line;
	word-break: break-word;
}

.freelancer_compl_in a {
	color: #6d2ef1;
	font-weight: 600;
	text-decoration: underline;
}

.freelancer_compl_in span {
	color: #6d2ef1;
	font-weight: 600;
	cursor: pointer;
}

.freelancer_compl_in svg {
	fill: #6d2ef1;
	margin-right: 5px;
}

/* .freelancer_compl_in.show_more p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
} */

.saved_talents_options {
	position: absolute;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	min-width: 120px;
	align-items: flex-start;
	top: 50px;
	right: 0;
	gap: 1px;
	border: 1px solid #c7c7c7;
}

.saved_talents_options span {
	display: flex;
	align-items: center;
	color: #000;
	font-size: 14px;
	width: 100%;
	padding: 5px 15px;
}

.saved_talents_options span:hover {
	background-color: rgb(211, 210, 210);
}

.freelancer_has_in {
	font-size: 13px;
	color: #6b6b6b;
	display: flex;
	align-items: center;
	font-weight: 500;
	padding: 10px 0;
}

.freelancer_has_in svg {
	fill: #b9bdc1;
	margin: 0 5px;
}

.freelancer_skill_sc {
	overflow-x: scroll;
	flex-wrap: wrap;
	width: 100%;
}

.r_scroll_w_10 {
	width: 100%;
	overflow-x: scroll;
}

.freelancer_skill_sc::-webkit-scrollbar,
.r_scroll_w_10::-webkit-scrollbar {
	display: none;
}

.searc_inv_w_fll .search_input_in {
	max-width: 100% !important;
	width: 100% !important;
}

.cover_letter_in {
	font-size: 13px;
	color: #6b6b6b;
	font-weight: 400;
	word-break: break-word;
	white-space: pre-line;
	margin: 10px 0;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.cover_letter_in b,
.amount_hir_in b {
	color: #1f1f1f;
}

.amount_hir_in {
	font-size: 13px;
	margin: 0 20px;
	color: #6b6b6b;
	font-weight: 400;
	padding: 10px 0;
}

.success_ratesuccess_rate {
	margin: 0;
}

.flex_over_scrll {
	display: flex;
}

.hir_no_in {
	align-items: center;
}

.hir_no_in span {
	font-size: 13px;
	color: #6b6b6b;
	font-weight: 500;
	padding: 10px 0;
}

.no_found_cen {
	/* display: flex; */
	flex-wrap: wrap;
	text-align: center;
	justify-content: center;
	padding: 20px 0;
	position: relative;
}

.Filter_Popup {
	position: absolute;
	background-color: white;
	border: 1px solid rgb(224, 224, 224);
	width: 70vw;
	padding: 10px 20px;
	top: 0;
	right: 0;
}

@media screen and (max-width: 1024px) {
	.Filter_Popup {
		width: 80vw !important;
	}
}

@media screen and (max-width: 426px) {
	.Filter_Popup {
		position: absolute;
		background-color: white;
		border: 1px solid rgb(224, 224, 224);
		width: 100% !important;
		padding: 10px 20px;
		top: 0;
		right: 0;
	}
}

.popup_up_arrow {
	position: absolute;
	top: -25px;
	color: #c7c7c7;
	transform: rotate(90deg);
	right: 153px;
	font-weight: 100;
	font-size: 25px;
}

.popup_border_none {
	position: absolute;
	top: -27px;
	right: 158px;
	color: white;
	font-size: 30px;
	font-weight: 600;
}

.no_found_img_cen {
	width: 15%;
	/* height: 160px; */
	margin: 15px auto;
}

.no_found_cenffff {
	height: 90px;
	margin-bottom: 10px;
}

.no_found_img_cen img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.no_found_head_ce h1 {
	font-size: 27px;
	color: #00065a;
	font-weight: bold;
	padding: 15px 0 0 0;
}

.no_found_head_ce .link {
	font-size: 18px;
	color: #6d2ef1;
	font-weight: 500;
}

.no_foun_para_ce {
	font-size: 13px;
	color: #393939;
	font-weight: 500;
}

.no_foun_para_ce a {
	color: #6d2ef1;
}

@media screen and (max-width: 481px) {
	.resp_cont_a {
		margin-bottom: 10px;
	}
}

.blue_ac_btn {
	background-color: #6d2ef1 !important;
	border-color: #6d2ef1 !important;
	color: #fff !important;
}

.ml-5 {
	margin-left: 10px;
}

.fire_headin {
	margin: 15px 0 0 0;
	font-size: 25px;
	color: #00065a;
	font-weight: bold;
}

.contrc_ter_head {
	margin: 15px 0 15px 0;
	font-size: 23px;
	color: #1f1f1f;
	font-weight: 700;
}

.pay_pro_par {
	font-size: 14px;
	color: #545454;
	font-weight: 400;
}

.pay_op_heading {
	font-size: 18px;
	color: #1f1f1f;
	padding: 5px 0;
	font-weight: 700;
}

.hourly_headin {
	font-size: 15px;
	color: #00065a;
	padding: 5px 0;
	font-weight: 600;
}

.max_prof_rt {
	font-size: 13px;
	color: #878787;
	padding: 8px 0;
	font-weight: 500;
}

.allow_freel_che {
	font-size: 13px;
	color: #545454;
	display: flex;
	align-items: flex-start;
	padding: 8px 0;
	font-weight: 500;
}

.start_d_headin {
	font-size: 18px;
	color: #1f1f1f;
	display: flex;
	align-items: center;
	padding: 8px 0;
	font-weight: 700;
}

.start_d_headin .start_d_option {
	font-weight: 500;
	font-size: 14px;
	color: #878787;
	margin: 0 5px;
}

.inpu_date {
	margin: 0 0 0 0;
}

.description_text_h textarea {
	height: 160px;
	resize: none;
	width: 100%;
	margin: 10px 0;
	border: #e8e7e7 solid 1px;
	padding: 10px 20px;
	color: #828282;
	font-size: 14px;
	font-weight: 500;
}

.custom_date_picker {
	border: #e8e7e7 solid 1px;
	padding: 10px 20px;
	color: #828282;
	font-size: 14px;
	font-weight: 500;
	min-width: 250px;
}

.online_profile {
	position: relative;
	overflow: visible !important;
}

.online_profile img {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}

@media screen and (max-width: 991px) {
	.resp_img_wid_usr {
		width: 100%;
	}

	.resp_img_wid_usr .resp_img_usr {
		width: 65px;
	}

	.resp_img_wid_usr .resp_wid_usr {
		width: 88%;
	}
}

@media screen and (max-width: 481px) {
	.resp_img_wid_usr {
		display: flex;
		flex-direction: column;
	}

	.resp_img_wid_usr .resp_img_usr {
		width: 60px;
	}

	.resp_img_wid_usr .resp_wid_usr {
		width: 100%;
		padding-left: 0;
	}
}

.text-capitalize {
	text-transform: capitalize !important;
}

.width-inherit {
	width: inherit;
}

.online_profile .before {
	position: absolute;
	right: 0;
	bottom: 0;
	height: 15px;
	width: 15px;
	border: #fff solid 2px;
	border-radius: 100%;
	background-color: #3dd419;
}

.online_profile .invisible {
	position: absolute;
	right: 0;
	bottom: 0;
	height: 15px;
	width: 15px;
	border: #fff solid 2px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	background-color: gray;
	content: " ";
	z-index: 10;
}

.inpu_date input {
	border: #e8e7e7 solid 1px;
	padding: 10px 20px;
	color: #828282;
	font-size: 14px;
	border-radius: 0 !important;
	font-weight: 500;
	min-width: 250px;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
}

.inpu_date select {
	border: #e8e7e7 solid 1px;
	padding: 10px 20px;
	color: #828282;
	font-size: 14px;
	font-weight: 500;
	min-width: 215px;
}

.setting_pay_inp {
	list-style: none;
	padding: 15px 0;
}

.setting_pay_inp li {
	padding: 5px 0;
	font-size: 14px;
	color: #545454;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.span_s_fon span {
	margin: 0 10px;
	font-size: 12px;
	color: #717171;
	font-weight: 400;
}

.span_s_fon span svg {
	fill: #b9bdc1;
	margin: 0 5px;
}

.chan_sub_n_s {
	padding: 10px 0;
	font-size: 16px;
	color: #6d2ef1;
	font-weight: 600;
	text-decoration: underline;
}

.setting_pay_inp li input {
	margin: 0 8px 0 0;
}

.setting_pay_inp li img {
	height: 15px;
}

.slide_n_tex {
	color: #1f1f1f;
	font-size: 15px;
	padding: 0;
}

.black_a_inp a {
	color: #1f1f1f !important;
	text-decoration: none !important;
	margin: 0 4px;
}

.allow_freel_che input {
	margin: 3px 5px;
}

.allow_freel_che a {
	color: #6d2ef1;
	font-weight: 600;
}


.main_hirefreelancer_bx {
	padding: 20px 0 !important;
}

.pay_pro_par a {
	text-decoration: none;
	color: #6d2ef1;
	font-weight: 600;
}

.b-bottom-gr {
	border-bottom: #cbcaca solid 1px;
}

.b-gr {
	border: #cbcaca solid 1px;
}

.parant_container {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
}

.dynamic_heigth {
	flex-grow: 1;
}

.flex_m_j_t {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 0;
}

.my_jo_headin {
	font-size: 40px;
	color: #00065a;
	font-weight: 700;
}

@media screen and (max-width: 374px) {
	.my_jo_headin {
		font-size: 35px;
	}
}

.pr-10 {
	padding-right: 10px;
}

.my_job_a a {
	font-size: 20px;
	color: #6d2ef1;
	font-weight: 500;
	padding: 10px 0;
}

.my_job_pos_tme {
	padding: 5px 0;
	font-size: 13px;
	color: #545454;
	font-weight: 400;
}

.my_job_h {
	padding: 10px 0 0 0;
	font-size: 12px;
	color: #545454;
	font-weight: 400;
}

.inv_all_post {
	width: 17%;
}

@media screen and (max-width: 1399px) {
	.inv_all_post {
		width: 19%;
	}
}

@media screen and (max-width: 1199px) {
	.inv_all_post {
		width: 23%;
	}
}

@media screen and (max-width: 991px) {
	.inv_all_post {
		width: 30%;
	}
}

@media screen and (max-width: 767px) {
	.inv_all_post {
		width: 100%;
	}
}

.my_job_flx {
	margin: 20px 0;
	/* position: relative; */
	/* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
}

.prom_to_box {
	margin-bottom: 10px;
	position: relative;
}

.promot_jobs_show {
	background: #6d2ef1;
	display: inline-block;
	position: absolute;
	top: -35px;
	left: 0;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	z-index: 1;
	padding: 5px 0 6px 10px;
	line-height: 17px;
}

.promot_jobs_show::after {
	content: "";
	position: absolute;
	border-color: #6d2ef1 transparent #6d2ef1 #6d2ef1;
	border-style: solid;
	border-width: 14px;
	left: 100%;
	width: 0;
	height: 0;
	top: 0;
}

.free_pr_j .promot_jobs_show {
	top: -15px;
}

.my_job_nm {
	padding: 5px 0 0 0;
	font-size: 20px;
	color: #1f1f1f;
	font-weight: 700;
}

.my_job_n_box {
	padding: 0 20px;
	min-width: 180px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.btn-hf {
	height: 45px !important;
	width: 45px !important;
}

@media screen and (max-width: 767px) {
	.resp_card {
		display: block !important;
	}
}

.blue_btn_smm {
	width: max-content;
	height: 50px;
	margin: 0 10px;
	max-height: 45px;
	background-color: #6d2ef1 !important;
	padding: 12px 25px;
	color: #fff !important;
	border: none;
	border-radius: 30px;
	font-weight: 600;
	font-size: 17px;
	line-height: 18px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.toggle_btn_dot {
	height: 45px;
	width: 45px;
	padding: 0;
	margin: 0;
	background-color: #fff;
	border: #707070 solid 1px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toggle_btn_dot svg {
	height: 20px;
	width: 20px;
}

.filter_headin_m {
	padding: 5px 0 0 0;
	font-size: 17px;
	color: #00065a;
	font-weight: 600;
}

.filter_select_m {
	margin: 15px 0;
}

.filter_select_m select {
	width: 100%;
	min-width: 250px;
}

.filter_select_m .inpu_date input {
	min-width: 210px !important;
}

@media screen and (max-width: 1399px) {
	.contract_spac {
		padding-right: 4px;
	}
}

@media screen and (min-width: 1024px) {
	.sort_by_pa.a0_total {
		margin-left: 5px;
	}
}

.filter_ul_m {
	padding: 0;
	margin: 15px 0;
	list-style: none;
}

.filter_ul_m li {
	padding: 0 0 8px 0;
	font-size: 15px;
	display: flex;
	align-items: center;
	color: #1f1f1f;
	font-weight: 400;
}

.filter_ul_m li input {
	margin: 0 8px 0 0;
}

.Filter_Popup .inpu_date select {
	min-width: 234px !important;
}

@media screen and (max-width: 320px) {
	.Filter_Popup .inpu_date select {
		min-width: 182px !important;
	}
}

.sort_by_pa {
	font-size: 13px;
	font-weight: 500;
	color: #828282;
}

.contract_sel .select_inp_in {
	margin-right: 10px;
}

.contract_sel .select_inp_in select option {
	color: #828282;
	font-size: 13px;
	background-color: transparent;
	font-weight: 400;
	width: 100%;
	line-height: 20px;
	height: 40px !important;
	padding: 15px 15px;
	min-height: 40px !important;
	outline: none;
}

.download_lnk_csx {
	color: #6d2ef1;
	font-weight: 500;
	cursor: pointer;
}

.download_lnk_csx a svg {
	margin: 0 5px;
}

.download_lnk_csx a {
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: #6d2ef1;
}

.disc_top_s_ar {
	margin: 20px 0;
}

.promoted_boxt {
	padding: 20px 20px;
	margin-top: 20px;
	border: #d4d4d5 solid 1px;
}

.promoted_boxtDiscover {
	padding: 20px 20px;
	margin-top: 20px;
	border: #d4d4d5 solid 1px;
}

.propt_head_n {
	width: 80%;
	word-break: break-word;
}

.propt_head_nCategory {
	padding-bottom: 15px;
}

.propt_head_n h2,
.propt_head_nCategory h2 {
	font-weight: 600;
	padding: 5px 0;
	font-size: 22px;
	line-height: 23px;
	color: #00065a;
	margin-bottom: 5px;
}

.propt_head_n .BasedONyourJob {
	font-weight: 600;
	font-size: 14px;
}

.propt_head_n .BasedONyourJob span {
	color: #6d2ef1;
}

.promo_box_a {
	width: 31.5%;
	height: 160px;
	border: #d4d4d5 solid 1px;
	padding: 15px 15px;
}

.mobileSize {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mobile-discover {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

@media screen and (max-width: 1199px) {
	.propt_head_n {
		width: 75%;
	}
}

@media screen and (max-width: 991px) {
	.propt_head_n {
		width: 65%;
	}

	.promo_box_a {
		width: 43%;
	}
}

@media (max-width: 768px) {
	.avai_btn_promo {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media (max-width: 767px) {
	.propt_head_n {
		width: 55%;
	}

	.promo_box_a {
		width: 100%;
	}
}

@media (max-width: 550px) {
	.propt_head_n {
		width: 100%;
	}

	.resp_tal_descover {
		display: flex;
		flex-wrap: wrap;
	}

	/* .propt_head_n {
    width: 55%;
  } */
}

@media (max-width: 481px) {
	.promo_box_a {
		width: 100%;
	}

	.mobileSize {
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: flex-start !important;
	}

	.BasedONyourJob {
		margin-bottom: 0;
	}

	.mobile-discover {
		margin-bottom: 20px;
		flex-direction: column;
	}

	.promo_box_a {
		margin-bottom: 20px;
	}

	.promoted_boxtDiscover {
		padding: 10px;
	}
}

.discover_prof_us {
	height: 50px;
	width: 50px;
	overflow: hidden;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.discover_prof_us img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.propmo_u_name {
	font-weight: bold;
	padding: 5px 0;
	font-size: 15px;
	line-height: 17px;
	cursor: pointer;
	color: #3b3b3b;
}

.promo_exper {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	color: #6b6b6b;
	word-wrap: break-word;
	white-space: pre-line;
}

.avai_btn_promo {
	margin: 10px 0;
}

.avai_btn_promo button {
	background-color: transparent;
	padding: 7px 23px;
	color: #6d2ef1;
	border: #6d2ef1 solid 1px;
	border-radius: 30px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.avai_btn_promo button:hover {
	background-color: transparent;
	color: #6d2ef1;
}

.heart_btn_promo {
	cursor: pointer;
}

.heart_btn_promo button {
	background-color: transparent;
	border: none;
}

.heart_btn_promo button:active {
	background-color: #6d2ef1 !important;
}

.heart_btn_promo button:active svg {
	fill: #fff;
}

.heart_btn_promo button:hover {
	background-color: transparent;
}

.heart_btn_promo button svg {
	fill: #00065a;
	height: 20px;
	width: 20px;
}

.hourly_amo_promo {
	margin-top: 10px;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	color: #1f1f1f;
}

.hourly_amo_promo span {
	font-weight: 500;
	color: #878787;
}

.blue_btn_pro {
	background-color: #6d2ef1;
	padding: 12px 50px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: bold;
	font-size: 19px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.blue_btn_pro:hover {
	background-color: #6d2ef1;
	color: #fff;
}

.blue_btn_pro:active {
	background-color: #6d2ef1 !important;
	color: #fff !important;
}

.hire_box_yh {
	min-height: 200px;
	height: auto;
	border: #707070 solid 1px;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.hi_bx_head2 {
	font-weight: 500;
	padding: 10px 0;
	font-size: 18px;
	line-height: 23px;
	color: #170048;
}

.hi_bx_head3 {
	font-weight: bold;
	padding: 0 -14px;
	font-size: 18px;
	line-height: 23px;
	color: #00065a;
	text-align: center;
}

.hire_b_im {
	height: 100px;
	padding: 10px;
}

.hire_b_im img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.d_pbtn_as {
	padding: 20px 0 30px 0;
}

.find_tal_btn svg {
	margin: 0 8px;
}

.find_tal_btn {
	margin: 15px 0;
	background-color: #6d2ef1;
	padding: 12px 30px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: bold;
	font-size: 18px;
	line-height: 20px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.r-box_setting {
	background-color: #f8f8f8;
	padding: 15px 12px 12px 12px;
	margin-bottom: 15px;
	width: 100%;
	/* overflow-x: scroll; */
}

.billingStripSection {
	display: flex;
	justify-content: space-between;
}

.billingStripTab {
	display: flex;
	flex-direction: column;
}

.billingStripLogo {
	width: 100px;
}

.billingStripText {
	font-size: 11px;
	color: #555;
}

.scroll_table {
	overflow-x: auto;
}

.scroll_table .r-box_setting {
	min-width: 450px;
}

/* .width-max-content {
  width: max-content !important;
} */
.setting_b_head_s {
	font-weight: 600;
	padding: 5px 0;
	font-size: 20px;
	line-height: 23px;
	color: #1f1f1f;
}

.min-width-246 {
	min-width: 246px !important;
}

@media screen and (max-width: 360px) {
	.resp_agency_width button {
		min-width: 218px !important;
		font-size: 13px !important;
	}
}

.f-size-35 {
	font-size: 35px !important;
}

.setting_pro_r_big {
	height: 65px;
	width: 65px;
	overflow: hidden;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.setting_pro_r_big img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.user_nme_set {
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	padding-top: 13px;
	padding-left: 10px;
	color: #1f1f1f;
}

.mr-0 {
	margin-right: 0 !important;
}

.user_mal_set {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	padding-left: 10px;
	color: #1f1f1f;
}

.bg-transparent {
	background-color: transparent !important;
}

.h-color-b:hover {
	color: #6d2ef1 !important;
}

.c_name_sett {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	padding-top: 10px;
	color: #1f1f1f;
}

.acc_inf_c_s {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	padding-top: 10px;
	color: #1f1f1f;
}

.sett_y_b {
	font-weight: 400;
	font-size: 15px;
	line-height: 25px;
	color: #1f1f1f;
}

.c_name_s_v {
	font-weight: 500;
	font-size: 17px;
	word-wrap: break-word;
	color: #1f1f1f;
}

.set_tem_para_s {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #393939;
	margin: 0 0 20px 0;
}

.s_tble_s {
	display: flex;
	align-items: center;
	margin: 8px 0 !important;
	justify-content: space-between;
	border-bottom: #dedede solid 1px;
	padding-bottom: 7px;
	overflow: scroll;
}

.s_tble_s::-webkit-scrollbar {
	display: none;
}

.blue_trans_btn {
	border: #6d2ef1 solid 1px !important;
}

.blue_trans_btn svg {
	fill: #6d2ef1 !important;
}

.s_th_s {
	width: 25%;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: #393939;
	padding: 5px 0;
}

.s_td_s {
	font-weight: 400;
	font-size: 14px;
	padding: 0 0 !important;
	color: #1f1f1f !important;
}

.w-15 {
	width: 10% !important;
}

.w-20 {
	width: 20% !important;
}

@media only screen and (max-width: 768px) {
	.cewfoueqjs {
		font-size: 30px !important;
	}

	.cewuohq {
		font-size: 16px !important;
	}
}

@media only screen and (max-width: 483px) {
	.sm_serch_fll_n .select_inp_in,
	.sm_serch_fll_n .selec_inp_ful_w {
		width: 100% !important;
	}
}

.inp_w_haf {
	width: 50%;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	color: #1f1f1f;
	padding: 10px 0;
	display: flex;
	align-items: center;
	text-indent: 10px;
}

.inp_w_haf input {
	margin: 0 10px 0 0;
}

@media only screen and (max-width: 770px) {
	.inp_w_haf {
		width: 100% !important;
	}
}

.forgot_passw {
	display: flex;
	justify-content: flex-end;
	padding: 0 25px;
}

.forgot_passw span {
	color: #6d2ef1;
}

.alert_txt_con_serv {
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	color: #e93232;
	padding: 1px 0;
}

.social_icon_ss img {
	height: 30px;
}

.active_c_ch:active {
	color: #6d2ef1 !important;
}

.s_btn_ns {
	font-size: 16px;
	line-height: 20px;
	color: #1f1f1f;
	text-transform: capitalize;
}

.active_bvss {
	color: #6d2ef1;
}

.pr-0 {
	padding-right: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.w-80 {
	width: 75% !important;
}

.w-20 {
	width: 20% !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.height-100-p {
	height: 100%;
}

.tm_s_s_name {
	padding: 5px 0 0 0;
	font-size: 18px;
	color: #00065a;
	font-weight: bold;
	margin: 5px 0;
}

.support_bg {
	height: 400px;
	width: 100%;
	background-image: url("/public/assets/images/pexels-andrea-piacquadio-3768595.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bg_opac {
	background-color: #00000085;
}

.mn_six_hun {
	min-width: 600px;
}

.find_sol_headin {
	text-align: center;
	font-weight: 500;
	font-size: 50px;
	line-height: 70px;
	color: #fff;
}

.find_sol_para {
	font-weight: 400;
	text-align: center;
	font-size: 15px;
	line-height: 22px;
	color: #fff;
}

.suppott_inp {
	background-color: #fff;
	padding: 15px 15px;
	margin-top: 20px;
	align-items: center;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.suppott_inp svg {
	fill: #6d2ef1;
}

.suppott_inp input {
	background-color: transparent;
	width: 100%;
	outline: none;
	box-sizing: unset;
	border: none;
	padding-left: 7px;
	color: #b7b7b7;
	font-weight: 400;
	font-size: 13px;
	line-height: 14px;
}

.chose_acc_type_p {
	font-weight: bold;
	text-align: center;
	font-size: 35px;
	padding: 20px 0;
	line-height: 40px;
	color: #00065a;
}

.all_bt_fif {
	width: 100%;
}

.all_bt_fif button {
	width: 25% !important;
}

.bg-gray_bd {
	background-color: #f8f8f8;
	padding: 25px 0;
	margin-top: 20px;
}

.browse_head_h {
	font-weight: bold;
	text-align: center;
	font-size: 35px;
	line-height: 40px;
	color: #00065a;
}

@media screen and (max-width: 767px) {
	.browse_head_h {
		font-size: 30px;
		justify-content: center !important;
	}

	/* .swiper-slide {
    width: 100% !important;
  } */
}

@media screen and (max-width: 426px) {
	.ImFreelancer_btn {
		justify-content: center !important;
	}

	.ImFreelancer_btn button {
		width: 170px !important;
		min-width: 170px !important;
		font-size: 13px;
	}

	.ImFreelancer_btn .fo_btn_c button {
		width: 170px !important;
		min-width: 170px !important;
	}
}

@media screen and (max-width: 424px) {
	.resp_client_pro {
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
}

.help_icon_rou {
	background-color: #efebf8;
	height: 110px;
	width: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}

.help_box_h {
	margin-top: 15px;
	padding: 10px 10px;
	margin-bottom: 5px;
	text-align: center;
	text-align: -webkit-center;
}

.help_ame_h {
	font-weight: bold;
	text-align: center;
	font-size: 20px;
	line-height: 22px;
	color: #000;
	padding: 12px 0;
}

.help_val_h {
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	line-height: 20px;
	color: #474747;
}

.help_icon_rou svg {
	height: 55px;
	width: 55px;
	fill: #6d2ef1;
}

.help_icon_rou img {
	height: 50px;
	width: 50px;
	object-fit: contain;
}

.acc_bxo_h {
	border: #d6d6d6 solid 1px;
	padding: 20px 15px;
	margin: 20px 0;
	text-align: center;
	text-align: -webkit-center;
}

.acc_icon_h {
	height: 75px;
}

.acc_icon_h svg {
	height: 50px;
	width: 50px;
	fill: #b9bdc1;
}

.support_d_btn {
	padding: 12px 30px !important;
	background-color: #00065a !important;
}

.support_d_btn svg {
	margin-right: 10px;
}

.work_diary_back {
	display: flex;
	flex-direction: column;
	height: 70vh;
	margin-right: 0;
	position: relative;
	transition: margin 0.1s ease-in;
}

.blue_bg_mb {
	/* background-color: #3f4289; */
	background-image: url("../../public/assets/images/theme-bg.webp");
	padding: 10px 5px;
	background-size: cover;
	margin: 30px 0;
	flex-grow: 1;
	position: relative;
}

.box_board_sli_mb {
	/* background-color: #ebecf0; */
	padding: 10px 10px;
	background-color: #ebecf0;
	border-radius: 3px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	position: relative;
	white-space: normal;
	width: 100% !important;
}

.bb_s_mb_flex {
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	text-align: center;
	font-size: 14px;
	line-height: 20px;
	color: #0e1f3e;
	position: relative;
	cursor: pointer;
}

.cards_list {
	flex: 1 1 auto;
	min-height: 0;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 4px;
	z-index: 1;
}

.card_composer {
	/* padding-bottom: 8px; */
}

.list_card {
	border-radius: 3px;
	color: inherit;
	cursor: pointer;
	display: block;
	margin-bottom: 8px;
	max-width: 300px;
	min-height: 20px;
	position: relative;
	z-index: 0;
}

.modal_List {
	position: absolute;
	background: #ffffff 0 0 no-repeat padding-box;
	box-shadow: 0 3px 14px #8484845c;
	opacity: 1;
	width: 190px;
	display: flex;
	z-index: 99999;
	right: -9px;
	bottom: -46px;
}

.close_modal_icon img {
	height: 25px;
	cursor: pointer;
}

.delete_list {
	color: #172b4d;
	text-align: start;
	font-size: 15px;
	line-height: 30px;
	padding: 0 0 0 20px;
}

.add_another_list {
	display: flex;
	font-weight: bold;
	text-align: center;
	font-size: 14px;
	gap: 5px;
	align-items: center;
	line-height: 20px;
	color: #0e1f3e;
	cursor: pointer;
}

.body_scroll {
	bottom: 0;
	left: 5px;
	margin-bottom: 8px;
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 8px;
	position: absolute;
	right: 0;
	top: 10px;
	-webkit-user-select: none;
	user-select: none;
	white-space: nowrap;
}

.card_wrapper {
	box-sizing: border-box;
	display: inline-block;
	height: 100%;
	margin: 0 4px;
	vertical-align: top;
	white-space: nowrap;
	width: 272px;
}

/* work in progress popup start  */

.progress_popup {
	background: #f4f5f7 0 0 no-repeat padding-box;
	opacity: 1;
	width: 60%;
	margin: auto;
	position: relative;
	/* display: flex; */
	padding: 5px 20px;
}

.progress_head {
	display: flex;
	align-items: baseline;
	gap: 5px;
	padding-top: 20px;
	width: 100%;
}

.progress_top_bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.work_Test {
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0;
	color: #42526e;
	text-transform: capitalize;
	opacity: 1;
}

.show_details_btn {
	background: #eaecf0 0 0 no-repeat padding-box;
	border-radius: 4px;
	border: none;
	padding: 7px 12px;
	font-size: 15px;
	line-height: 26px;
	letter-spacing: 0;
	color: #172b4d;
}

.input_comment {
	width: 100%;
	padding-top: 10px;
}

.input_comment input {
	width: 100%;
	background: #ffffff 0 0 no-repeat padding-box;
	border: 1px solid #c3c3c3;
	outline: none;
	height: 40px;
	padding: 2px 10px;
}

.input_comment input::placeholder {
	font-size: 14px;
}

.screen_img {
	width: 4%;
	padding-top: 3px;
}

.screen_img img {
	width: 20px;
}

.test_toDo {
	width: 100%;
}

.list_toDo {
	/* display: flex; */
	flex-direction: column;
	flex-wrap: wrap;
	gap: 10px;
}

.list_toDo .document {
	display: flex;
	gap: 10px;
}

.list_toDo .document .about .name {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-weight: 600;
}

.list_toDo .document .about .actions {
	display: flex;
	gap: 10px;
	margin-top: 5px;
}

.list_toDo .document .about .actions .action {
	cursor: pointer;
	font-size: 14px;
	color: #695565;
}

.list_toDo .document .about .actions .action:hover {
	color: #6d2ef1;
}

.in_List {
	color: #42526e;
}

.to_do_bold {
	font-size: 16px;
	text-decoration: underline;
}

.left_column_margin {
	margin-top: 20px;
}

.close_popup_tab {
	border-radius: 50%;
	-webkit-border-radius: 50%;
}

.end_close_icon {
	display: flex;
	justify-content: flex-end;
}

.cross_close {
	width: 26px;
	cursor: pointer;
}

.cross_close svg {
	fill: #838ea0;
}

.descr_div {
	width: 100%;
}

.activity_show {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.save_cancle {
	display: flex;
	align-items: center;
	gap: 15px;
}

.svae_btn_popup {
	background: #0079bf 0 0 no-repeat padding-box;
	border-radius: 3px;
	font-size: 14px;
	line-height: 34px;
	letter-spacing: 0;
	color: #f4f5f7;
	border: none;
	padding: 0 12px;
	font-weight: 400;
}

.cancle_btn_popup {
	font-size: 15px;
	line-height: 31px;
	color: #172b4d;
	cursor: pointer;
}

.desc_save_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 8px;
}

.try_editor {
	text-decoration: underline;
	font-size: 15px;
	line-height: 34px;
	letter-spacing: 0;
	color: #5e6c84;
	font-weight: 400;
	cursor: pointer;
}

.textarea_div {
	width: 100%;
	padding-top: 10px;
}

.dropTask {
	background: #ada5a5;
	border: 2px dashed #fff;
}

.textarea_div textarea {
	width: 100%;
	background: #ffffff 0 0 no-repeat padding-box;
	border: 1px solid #c3c3c3;
	outline: none;
	height: 150px;
	resize: none;
	padding: 5px 10px;
}

.textarea_div textarea::placeholder {
	font-size: 14px;
}

.card_add_popup {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

.add_card {
	font-size: 15px;
	line-height: 34px;
	color: #42526e;
	font-weight: 400;
}

.pencle_lable {
	display: flex;
	align-items: center;
	gap: 13px;
	background: #eaecf0 0 0 no-repeat padding-box;
	padding: 5px 16px;
	cursor: pointer;
}

.pencle_lable span {
	font-size: 16px;
	line-height: 31px;
	font-family: Segoe UI, sans-serif;
	letter-spacing: 0;
	font-weight: 400;
	color: #172b4d;
}

.pencle_lable img {
	width: 15px;
}

.pencle_lable input {
	display: none;
}

.labels_card_popup {
	position: absolute;
	top: 0;
	right: 0;
	background: #ffffff 0 0 no-repeat padding-box;
	box-shadow: 0 12px 20px #4e4e4e24;
	width: 285px;
}

.labels_head {
	display: flex;
	align-items: center;
	background: #ffffff 0 0 no-repeat padding-box;
	box-shadow: 0 2px 0 #00000012;
	margin-top: 5px;
	padding: 3px 5px;
}

.labels_text {
	text-align: center;
	font-size: 15px;
	line-height: 30px;
	font-family: Roboto, sans-serif;
	color: #838ea0;
}

.choose_color_show {
	background: #f4f5f7;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.show_real_color {
	/* height: 50px; */
}

.search_labels_inp {
	width: 100%;
	padding-top: 8px;
}

.search_labels_inp input {
	width: 100%;
	outline: none;
	border: 1px solid #c3c3c3;
	padding: 6px 2px;
	margin-top: 6px;
	font-size: 13px;
}

.search_labels_inp input::placeholder {
	font-size: 12px;
}

.labels_h {
	font-size: 14px;
	line-height: 30px;
	font-family: Roboto, sans-serif;
	color: #0e1f3e;
}

.select_color {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin-top: 7px;
}

.color_checkbox {
	width: 21px;
	height: 25px;
	accent-color: #6d2ef1;
	cursor: pointer;
}

.label_colors {
	display: flex;
	width: 100%;
	position: relative;
}

.label_colors .label_title {
	position: absolute;
	top: 50%;
	font-size: 14px;
	transform: translate(30px, -50%);
}

.color_div {
	position: relative;
	background-repeat: no-repeat;
	background-clip: padding-box;
	width: 100%;
	height: 35px;
	opacity: 0.5;
	border-radius: 2px;
}

.color_circle {
	position: absolute;
	background-repeat: no-repeat;
	background-clip: padding-box;
	width: 19px;
	height: 20px;
	border-radius: 50%;
	left: 6px;
	top: 50%;
	transform: translate(0px, -50%);
}

.label_colors .label_edit_input {
	height: 35px;
	border: 1px solid #b2b2b2;
	outline: none;
	/* padding: 0 8px; */
}

.edit_label {
	cursor: pointer;
}

.create_label_div {
	padding-top: 10px;
	padding-bottom: 15px;
	border-bottom: 1px solid #d9dadc;
}

.create_label {
	font-size: 16px;
	font-family: Roboto, sans-serif;
	line-height: 10px;
	letter-spacing: 0;
	color: #585f6b;
	background: #f5f6f8 0 0 no-repeat padding-box;
	padding: 12px 10px;
	text-align: center;
	cursor: pointer;
}

.enable_friendly {
	padding-bottom: 8px;
	padding-top: 10px;
}

.give_us_feedback {
	padding-bottom: 15px;
}

.create_btn {
	padding: 10px 0;
	margin-top: 10px;
}

.label_colors_choose {
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;
	flex-wrap: wrap;
}

.color_choose {
	background: #c6e9c2 0 0 no-repeat padding-box;
	width: 18%;
	height: 35px;
	cursor: pointer;
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.color_choose .tooltiptext {
	visibility: hidden;
	width: 70px;
	background-color: #2e3744;
	color: #fff;
	text-align: center;
	border-radius: 4px;
	padding: 3px 0;
	position: absolute;
	z-index: 1;
	top: 40px;
	right: 0;
	left: -22%;
	font-size: 11px;
}

.greater_tag {
	cursor: pointer;
}

.color_choose:hover .tooltiptext {
	visibility: visible;
}

.Input_field {
	position: relative;
}

.Input_field input {
	width: 100%;
	border: none;
	background: #ffffff 0 0 no-repeat padding-box;
	box-shadow: 0 2px 0 #0000000a;
	border-radius: 4px;
	outline: none;
	font-size: 12px;
	padding: 7px 6px;
}

.Input_field input::placeholder {
	font-size: 12px;
}

.Input_field button {
	background: #0079bf 0 0 no-repeat padding-box;
	border-radius: 4px;
	border: none;
	font-size: 12px;
	line-height: 21px;
	letter-spacing: 0;
	color: #f4f5f7;
	padding: 4px 10px;
}

.Input_field textarea {
	overflow: hidden;
	overflow-wrap: break-word;
	border: none;
	box-shadow: none;
	height: auto;
	max-height: 162px;
	min-height: 54px;
	overflow-y: auto;
	outline: none;
	width: 100%;
	padding: 3px 8px;
	font-size: 14px;
	resize: none;
	border-radius: 3px;
}

.close_add_list {
	width: 20px;
	cursor: pointer;
}

.add_list_btn {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 5px;
}

.dynamic_image {
	height: 80px;
	width: 100px;
}

.dynamic_image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.selected_img_name_mana {
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-weight: 600;
	cursor: pointer;
}

.selected_img_name_mana:hover {
	color: #6d2ef1;
}

@media screen and (max-width: 1199px) {
	.add_another_list {
	}

	.pencle_lable {
		gap: 5px;
	}
}

@media screen and (max-width: 1100px) {
	.progress_popup {
		width: 70%;
	}

	.test_toDo {
		padding-left: 3px;
	}
}

@media screen and (max-width: 991px) {
	.left_column_margin {
		margin-top: 0;
	}

	.card_add_popup {
		margin-top: 0;
	}

	.close_popup_tab {
		margin-bottom: 20px;
	}

	.test_toDo {
		margin-left: 7px;
	}

	.add_card {
		font-weight: 500;
	}
}

@media screen and (max-width: 768px) {
	.progress_popup {
		width: 80%;
	}
}

@media screen and (max-width: 540px) {
	.desc_save_btn {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.save_cancle {
		width: 100%;
		justify-content: space-between;
	}
}

@media screen and (max-width: 500px) {
	.progress_popup {
		width: 90%;
	}
}

@media screen and (max-width: 425px) {
	.textarea_div textarea {
		height: 100px;
	}

	.progress_popup {
		padding: 5px 10px;
	}

	.test_toDo {
		margin-left: 10px;
	}

	.descr_div {
		margin-bottom: 15px !important;
	}

	.show_details_btn {
		padding: 5px 8px;
	}
}

/* work in progress popup end  */

.descr_div .comments {
	display: flex;
	gap: 10px;
	margin-top: 20px;
}

.descr_div .comments .left .imge {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: rgb(0, 122, 166);
	font-weight: 600;
	color: #fff;
}

.descr_div .comments .right {
	width: 100%;
}

.descr_div .comments .right .about {
	display: flex;
	gap: 10px;
	align-items: center;
}

.descr_div .comments .right .about .name {
	font-weight: 600;
}

.descr_div .comments .right .about .time {
	font-size: 14px;
	color: #9a9a9a;
}

.descr_div .comments .right .comment_on {
	width: 100%;
	background: #fff;
	padding: 10px;
	border: 1px solid #e9e9e9;
}

.descr_div .comments .right .comment_on .image_name {
	color: #6d2ef1;
	font-weight: 500;
}

.descr_div .comments .right .comment_on .commen {
	font-size: 15px;
	color: #696969;
}

.descr_div .comments .right .comment_actions {
	display: flex;
	gap: 10px;
	font-size: 15px;
	color: #696969;
}

.descr_div .comments .right .comment_actions .edit,
.descr_div .comments .right .comment_actions .delete {
	cursor: pointer;
}

.descr_div .comments .right .comment_actions .edit:hover,
.descr_div .comments .right .comment_actions .delete:hover {
	color: #6d2ef1;
}

.selected_labels {
	display: flex;
	flex-direction: column;
	margin-left: 40px;
	margin-top: 10px;
	gap: 5px;
}

.selected_labels .selected_label_colors {
	display: flex;
	gap: 10px;
}

.selected_labels .selected_label_colors span {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.add_card_ee {
	display: flex;
	align-items: center;
	font-weight: 600;
	text-align: center;
	font-size: 13px;
	line-height: 20px;
	color: #474747;
	user-select: none;
	cursor: pointer;
}

.add_card_ee svg {
	margin-right: 5px;
}

.card_scrollbar_height {
	min-height: 0;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 4px;
	z-index: 1;
}

.content_ox_bb {
	background-color: #fff;
	padding: 8px 10px;
	margin: 5px 0;
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-radius: 3px;
}

.img_card_ee img {
	height: 100%;
	width: 100%;
}

.name_img_ee {
	height: 25px;
	width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	text-align: center;
	font-size: 10px;
	color: #3f485e;
	background-color: #dfe1e6;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}

.mi-width-2 {
	min-width: 160px !important;
}

.pnew {
	padding: 12px 30px !important;
	font-size: 13px !important;
	border-color: #6d2ef1 !important;
	font-weight: 600 !important;
}

.help_swip .swiper-button-prev,
.help_swip .swiper-rtl .swiper-button-next {
	height: 28px;
	width: 28px;
	left: 0 !important;
	top: 55%;
	border-radius: 50%;
	border: #6d2ef1 solid 1px;
}

.help_swip .swiper-button-prev:after,
.help_swip .swiper-rtl .swiper-button-next:after {
	font-size: 12px;
	color: #6d2ef1;
}

.help_swip .swiper-button-next,
.help_swip .swiper-rtl .swiper-button-prev {
	height: 28px;
	width: 28px;
	top: 55%;
	right: 0 !important;
	border-radius: 50%;
	border: #6d2ef1 solid 1px;
}

.help_swip .swiper-button-next:after,
.help_swip .swiper-rtl .swiper-button-prev:after {
	font-size: 12px;
	color: #6d2ef1;
}

.rot_svg_oety {
	position: relative;
	font-size: 16px;
	font-weight: 500;
}

.rot_svg_oety svg {
	transform: rotate3d(1, 1, 1, 45deg);
	-ms-transform: rotate3d(1, 1, 1, 45deg);
}

.mt-30-px {
	margin-top: 30px;
}

.css-1s2u09g-control {
	border-radius: 0 !important;
	min-height: 40px !important;
}

.psr-relative {
	position: relative;
}

.psr_abs_c_icon {
	position: absolute;
	right: 0;
	margin: 12px;
	display: flex;
	align-items: center;
}

.help_swip .swiper-slide-duplicate-prev .acc_bxo_h {
	margin-right: 15px !important;
}

.help_swip .swiper-slide-active .acc_bxo_h {
	margin-left: 15px !important;
}

.help_swip .acc_bxo_h {
	min-height: 128px;
}

.a25_px_oxpm {
	margin: 10px 10px 10px 0;
	height: 22px;
	width: 22px;
	display: flex;
}

.a25_px_oxpm img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.mr-righht-10 {
	margin-right: 10px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
	.st_med_umecw .inpu_date {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.mob_60_rr {
		width: 60px !important;
	}
}

.ew_cstom_inp_dat {
	border: hsl(0 0% 80%) solid 1px;
}

.ew_cstom_inp_dat input {
	border: none !important;
	padding: 10px 15px;
	font-size: 13px;
	width: 100%;
	outline: none;
}

.ew_cstom_inp_dat .first_chi {
	padding-right: 0 !important;
}

.ew_cstom_inp_dat .secon_chi {
	padding-left: 0 !important;
}

.ew_cstom_inp_dat .first_chi::-webkit-calendar-picker-indicator {
	opacity: 0;
}

.date_range_nod input {
	border: #e8e7e7 solid 1px;
	/* padding: 10px 20px; */
	outline: none;
	color: #828282;
	font-size: 14px;
	font-weight: 500;
	min-width: 250px;
}

.psr-relative {
	position: relative;
}

.cal_icon_psr_abs {
	position: absolute;
	padding: 7px 10px;
	right: 0;
	top: 0;
	/* pointer-events: none; */
	cursor: pointer;
}

.cal_icon_psr_abs:hover {
	cursor: pointer;
}

.border_blue_wwifth {
	border: #6d2ef1 solid 1px !important;
	min-width: 200px;
}

.report_left_caewe .dropdown-menu {
	right: 0 !important;
}

@media screen and (max-width: 425px) {
	.report_left_caewe .dropdown-menu {
		right: 0 !important;
	}
}

@media screen and (max-width: 320px) {
	.report_left_caewe .dropdown-menu {
		right: 0 !important;
	}
}

.signInError {
	position: absolute;
	top: 43px;
	left: -3px;
	color: #ea2121;
	margin: 0 10px 0 -12px;
	font-size: 12px;
	font-weight: 300;
}

.edit_posting_box {
	margin: 10px;
}

.edit_posting_box .form_box_bor {
	padding: 0 20px;
}

.edit-posting-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.btn-save_post {
	background-color: #6d2ef1;
	padding: 12px 25px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

.edit-posting-heading .heading {
	color: #170048;
	font-weight: 600;
	font-size: 30px;
}

@media screen and (max-width: 767px) {
	.edit-posting-heading .heading {
		color: #170048;
		font-weight: 600;
		font-size: 22px;
		text-align: center;
	}
}

@media screen and (max-width: 386px) {
	.edit-posting-heading .heading {
		font-weight: 600;
		font-size: 18px;
	}

	.btn-save_post {
		background-color: #6d2ef1;
		padding: 12px 15px;
		color: #fff;
		border: none;
		border-radius: 30px;
		font-weight: 700;
		font-size: 15px;
		line-height: 16px;
		-webkit-border-radius: 30px;
	}

	.border_blue_wwifth {
		border: #6d2ef1 solid 1px !important;
		min-width: 150px;
	}
}

.edit-posting-heading {
	padding-bottom: 15px;
	border-bottom: #cbcaca solid 1px;
	margin: 20px 0;
}

.title-edit_posting .title {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 18px !important;
	margin: 10px 0;
}

.title-edit_posting .title_content {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 18px !important;
	margin-top: 10px;
	border: #cbcaca solid 1px;
	padding: 10px 15px;
	outline: none;
}

.title-edit_posting > p {
	font-size: 12px;
	color: #838383;
}

.title-edit_posting {
	border-bottom: #cbcaca solid 1px;
	padding: 10px 0 15px 0;
	margin: 10px 0;
}

.describe_edit_posting {
	margin: 20px 0 5px 0;
}

.describe_edit_posting .header {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 18px !important;
	margin: 10px 0;
}

.describe_edit_posting > p {
	font-size: 12px;
	color: #838383;
}

.describe_edit_posting > p > span {
	font-weight: 600;
	color: #6d2ef1;
	text-decoration: underline;
}

.describe_edit_posting textarea {
	resize: none;
	width: 100%;
	height: 150px;
	border: #cbcaca solid 1px;
	outline: none;
	padding: 14px;
	font-size: 12px;
}

.describe_edit_posting textarea::placeholder {
	font-size: 12px;
	color: #838383;
}

.describe_edit_posting .why_textarea {
	display: flex;
	justify-content: space-between;
}

.describe_edit_posting .why_textarea p {
	font-size: 12px;
	color: #838383;
}

.describe_edit_posting .why_textarea p > i {
	font-size: 12px;
	color: #6d2ef1;
	cursor: pointer;
}

.edit_posting-attach-file {
	border-bottom: #cbcaca solid 1px;
	padding-bottom: 20px;
	margin: 10px 0 10px 0;
}

.edit-posting-category .heading {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 20px !important;
	margin: 10px 0 5px 0;
}

.edit-posting-category .category {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 16px !important;
}

.edit-posting-category .category > i {
	border: 1px solid #cbcaca;
	padding: 10px;
	border-radius: 50%;
	color: #cbcaca;
	cursor: pointer;
}

.edit-posting-category {
	border-bottom: #cbcaca 1px solid;
	margin: 10px 0 10px 0;
	padding: 15px 0;
}

.edit-posting-skills .heading {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 20px !important;
}

.edit-posting-skills .skills {
	font-weight: 600 !important;
	color: #1f1f1f !important;
	font-size: 16px !important;
}

.edit-posting-category .skills > i {
	border: 1px solid #cbcaca;
	padding: 10px;
	border-radius: 50%;
	color: #cbcaca;
}

.edit-posting-skills {
	margin: 10px 0 10px 0;
	padding: 10px 0;
}

.edit_posting_save_btn {
	display: flex;
	justify-content: flex-end;
	margin: 25px 0;
	padding: 5px 15px;
}

@media screen and (max-width: 426px) {
	.edit_posting_save_btn {
		justify-content: center;
	}
}

.draft_btns {
	display: flex;
	margin: 25px 0;
	padding: 5px 15px;
	justify-content: space-between;
	flex-wrap: wrap;
}

.draft_btns .save_as_draft {
	color: #00065a;
	cursor: pointer;
	margin: 10px 15px;
	font-weight: 700;
}

.draft_btns .draft_back_btn {
	background-color: white;
	padding: 11px 60px;
	color: #6d2ef1;
	border: 1px solid #6d2ef1;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	min-width: 170px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

.draft_btns .draft_back_btn:hover {
	background-color: #6d2ef1;
	padding: 11px 60px;
	color: #fff;
	border: 1px solid #6d2ef1;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

.draft_btns button {
	background-color: #6d2ef1;
	padding: 12px 40px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

.edit_posting_save_btn button {
	background-color: #6d2ef1;
	padding: 15px 40px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

@media screen and (max-width: 767px) {
	.draft_btns {
		gap: 12px;
		padding: 5px 0;
	}

	.draft_dsfdfback_btn {
		min-width: 180px !important;
		padding: 11px 0 !important;
	}

	.draf_jfddddo_btnbn {
		min-width: 180px !important;
		padding: 12px 0 !important;
	}
}

@media screen and (max-width: 481px) {
	.draft_btns button {
		padding: 12px 20px;
	}
}

@media screen and (max-width: 550px) {
	.resp_btn_edit_draft {
		width: 100%;
	}

	.cancle_post_draft {
		width: 100%;
	}

	/* .draft_back_btn {
    width: 100%;
  } */
	.resp_save_post {
		width: 100%;
		gap: 5px;
	}
}

/* menu_bar */

.menu_btn {
	position: relative;
}

.menu_btn .menu_bar {
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: white;
	border: 1px solid rgb(217 217 217);
	align-items: flex-start;
	padding: 10px 10px;
	width: 200px;
	right: 55px;
}

.menu_btn .menu_bar_teams {
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: white;
	border: 1px solid rgb(217 217 217);
	align-items: flex-start;
	padding: 3px 10px;
	width: 150px;
	top: 1px;
	right: 86px;
}

@media screen and (max-width: 1440px) {
	.menu_btn .menu_bar_teams {
		top: 1px;
		right: 72px;
	}
}

@media screen and (max-width: 1199px) {
	.menu_btn .menu_bar_teams {
		top: 1px;
		right: 58px;
	}
}

@media screen and (max-width: 1023px) {
	.menu_btn .menu_bar_teams {
		top: 1px;
		right: 56px;
	}

	.my_job_n_box {
		min-width: 120px;
	}
}

@media screen and (max-width: 767px) {
	.menu_btn #menu_bar1 {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: white;
		border: 1px solid rgb(217 217 217);
		align-items: flex-start;
		padding: 10px 10px;
		width: 200px;
		right: unset;
		top: 0;
		left: -210px;
	}

	.menu_btn #menu_bar3 {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: white;
		border: 1px solid rgb(217 217 217);
		align-items: flex-start;
		padding: 10px 10px;
		width: 175px;
		top: -140px;
		z-index: 99;
		right: 50px;
		line-height: 15px;
	}

	.my_job_n_box {
		min-width: 40px;
		padding: 0 0;
	}

	.resp_three_dots {
		position: absolute;
		bottom: 0;
		top: -140px;
		right: 0;
	}

	.menu_btn #menu_btn_arrow1 {
		position: absolute;
		top: 5px;
		color: #cacaca;
		font-size: 23px;
		right: -8px;
		transform: rotate(0deg);
		font-weight: 100;
	}
}

@media screen and (max-width: 375px) {
	.menu_btn #menu_bar2 {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: white;
		border: 1px solid rgb(217 217 217);
		align-items: flex-start;
		padding: 10px 10px;
		width: 200px;
		right: unset;
		top: 0;
		left: -210px;
	}

	.menu_btn #menu_btn_arrow2 {
		position: absolute;
		top: 5px;
		color: #cacaca;
		font-size: 23px;
		right: -8px;
		transform: rotate(0deg);
		font-weight: 100;
	}
}

@media screen and (max-width: 320px) {
	.resp_job_title {
		width: 80%;
	}
}

.menu_btn_arrow {
	position: absolute;
	top: 16px;
	right: -6px;
	width: 10px;
	height: 10px;
	background: #fff;
	border: 1px solid rgb(217, 217, 217);
	border-left: none;
	border-bottom: none;
	transform: rotate(45deg);
}

/* Add Company Address */

.add_comp_address {
	margin: 30px auto;
}

.add_comp_address .comp_address_title {
	font-size: 25px;
	width: 100%;
	letter-spacing: -1px;
	color: #00065a;
	font-weight: bold;
	margin: 20px 0 0 0;
	padding-top: 10px;
	padding-bottom: 0;
}

.add_comp_address .back_to_offer {
	font-size: 12px;
	width: 100%;
	font-weight: 600;
	color: #6d2ef1;
	margin: 6px 0 10px 0;
	padding-bottom: 0;
}

/* col_left */

.add_comp_address .col_left {
	padding: 35px;
	height: 100%;
}

.add_comp_address .col_left .page_title {
	font-weight: 700;
}

.add_comp_address .add_address_inp .css-1okebmr-indicatorSeparator {
	display: none;
}

.add_comp_address .add_address_inp {
	display: flex;
	flex-direction: column;
}

.add_comp_address .add_address_inp span {
	font-size: 15px;
	font-weight: 600;
	margin: 30px 0 10px 0;
}

.add_comp_address .add_address_inp label {
	font-size: 15px;
	font-weight: 600;
	margin: 30px 0 10px 0;
}

.add_comp_address .add_address_inp > input {
	width: 100%;
	height: 42px;
	border: #cbcaca solid 1px;
	outline: none;
	padding: 7px;
	border-radius: 2px;
}

.add_comp_address .add_address_inp .custom_css_select {
	border: #cbcaca solid 1px !important;
	border-radius: 2px !important;
}

.add_comp_address .add_address_inp .custom_css_select div {
	border: none !important;
	border-radius: 2px !important;
}

.add_comp_address .add_details .save_Address {
	background-color: #6d2ef1;
	margin: 40px 0;
	padding: 10px 80px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

/* col-right */
._col_right {
	height: 100%;
}

._col_right .Add_Address_profile {
	padding: 5px 0;
	border-bottom: #cbcaca solid 1px;
}

._col_right .Add_Address_profile {
	display: flex;
	/* align-items: center; */
}

@media (max-width: 481px) {
	.endContractButtonInSingle {
		flex-direction: column;
		justify-content: space-between !important;
		align-items: flex-start !important;
		height: 115px;
	}
}

._col_right .Add_Address_profile img {
	width: 60px;
	height: 60px;
	object-fit: cover;
}

._col_right .Add_Address_profile p {
	font-size: 12px;
	color: #777474;
	word-break: break-word;
	margin-bottom: 10px;
}

._col_right .Escrow_deposit_box {
	padding: 10px 0;
	border-bottom: #cbcaca solid 1px;
}

._col_right .Escrow_deposit_box .Escrow_deposit {
	font-size: 15px;
	font-weight: 700;
	margin: 5px 0;
}

._col_right .Escrow_deposit_box .Escrow_deposit > span {
	border-radius: 50%;
	font-size: 9px;
	padding: 0 4px !important;
	color: white;
	background-color: #b5b5b5;
}

._col_right .Escrow_deposit_box .subtotal {
	display: flex;
	margin: 5px 0;

	justify-content: space-between;
	font-size: 14px;
	color: #a1a1a1;
}

._col_right .Escrow_deposit_box .subtotal ._amount {
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	color: black;
}

._col_right .market_place_fee_box {
	padding: 10px 0;
	/* border-bottom: #cbcaca solid 1px; */
}

._col_right .market_place_fee_box .market_place {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	margin: 5px 0;
}

._col_right .market_place_fee_box .market_place ._amount {
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	color: black;
}

._col_right .market_place_fee_box .see_more {
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: #6d2ef1;
	margin: 5px 0;
}

._col_right .Estimated_taxes_box {
	padding: 10px 0;
	border-bottom: #cbcaca solid 1px;
}

._col_right .Estimated_taxes_box .Estimated_taxes {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	margin: 0 0 5px 0;
}

._col_right .Estimated_taxes_box .Estimated_taxes ._amount {
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	color: black;
}

._col_right .Estimated_taxes_box .see_more {
	font-weight: 700;
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: #6d2ef1;
}

._col_right .Estimated_total_box {
	padding: 10px 0;
}

._col_right .Estimated_total_box .Estimated_total {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	font-weight: 700;
}

._col_right .find_and_hire_button {
	display: flex;
	justify-content: center;
}

._col_right .find_and_hire_button button {
	background-color: #6d2ef1;
	color: white;
	margin: 20px auto;
	padding: 15px 20px;
	border: 1px solid #6d2ef1;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

._col_right .Edit_Addr button {
	background-color: #6d2ef1;
	color: white;
	margin: 20px auto;
	padding: 15px 20px;
	border: none;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

._col_right .add_Addr button {
	background-color: #f8f8f8;
	color: #b9bdc1;
	margin: 20px auto;
	padding: 15px 20px;
	border: none;
	border-radius: 30px;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

/* Edit company Address */
.Edit_comp_address .page_title {
	font-size: 20px;
	font-weight: 600;
}

.Edit_comp_address .col_left .Edit_details {
	margin: 2px 0;
	padding: 2px 0;
	border-bottom: #e2e0e0 solid 1px;
}

.Edit_comp_address .col_left .Edit_details ._edit {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	margin: 2px 0;
	padding: 2px 0;
}

.Edit_comp_address .col_left .Edit_details ._edit > ._edit_btn {
	font-weight: 700;
	font-size: 15px;
	color: #6d2ef1;
}

.stripClientLogo {
	height: 43px;
}

.clientStrip {
	font-size: 15px;
	line-height: 16px;
	letter-spacing: 0;
	color: #393939;
}

@media (max-width: 1024px) {
	.clientStrip {
		font-size: 14px;
	}
}

.clientPayBtn .clientCardbtn {
	min-width: 125px;
	margin: 5px 5px 5px 0;
	padding: 10px 5px;
}

.Select_payment_method_box .clientCardList {
	background: #f8f8f8 0 0 no-repeat padding-box;
	min-height: 115px;
	margin-bottom: 20px;
	padding: 16px;
}

.clientCardDet {
	display: flex;
	align-items: center;
	gap: 10px;
}

.clientCardList h5 {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 0;
	color: #1f1f1f;
	padding-bottom: 10px;
}

.cardLogoSize {
	height: 20px;
}

.clientCardDetList {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.clientCardDetList span {
	font-size: 14px;
}

/* About Fees popup css start */
.fees_popup {
	max-width: 45%;
	width: 95%;
	padding: 0 22px;
	background: #ffffff 0 0 no-repeat padding-box;
	opacity: 1;
	min-height: 200px;
	max-height: 95vh;
	/* overflow-y: scroll; */
}

.cost_popup_head {
	font-weight: bold;
	font-family: Segoe UI, sans-serif;
	font-size: 20px !important;
	line-height: 27px !important;
	letter-spacing: 0;
	color: #707070;
}

.fees_popup .background_border {
	background: #ffffff 0 0 no-repeat padding-box;
	border: 1px solid #6d2ef1;
	width: 30%;
	margin-top: 10px;
}

.fees_popup .the_marketplace {
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	font-family: Segoe UI, sans-serif;
	letter-spacing: 0;
	color: #443f3f;
	padding-top: 15px;
}

.fees_popup .for_example {
	font-weight: 600;
	font-size: 14px;
	font-family: Segoe UI, sans-serif;
	line-height: 19px;
	letter-spacing: 0;
	color: #443f3f;
	padding-top: 20px;
}

.fees_popup .cost_popup_box {
	background: #ffffff1a 0 0 no-repeat padding-box;
	border: 1px solid #707070;
	margin-top: 25px;
	padding: 18px 20px 25px 18px;
}

.cost_popup_box .cost_round_pointer {
	font-weight: bold;
	font-family: Segoe UI, sans-serif;
	font-size: 29px !important;
	line-height: 30px !important;
	letter-spacing: 0;
	color: #58923d;
}

.cost_popup_box .cost_box_content {
	font: italic normal 600 14px/23px Segoe UI;
	letter-spacing: 0;
	color: #443f3f;
	padding: 10px;
}

.cost_popup_box .cost_box_content .here_link {
	font-weight: 600;
	font-size: 14px;
	font-family: Segoe UI, sans-serif;
	line-height: 23px;
	letter-spacing: 0;
	color: #6d2ef1;
}

.fees_popup .cost_popup_benefits {
	font: normal normal bold 19px/24px Segoe UI;
	letter-spacing: 0;
	color: #707070;
	margin-top: 15px;
}

.fees_popup .many_features {
	font: normal normal bold 14px/24px Segoe UI;
	letter-spacing: 0;
	color: #707070;
	margin-top: 15px;
}

.cost_ul_list {
	font-weight: 500;
	font-size: 15px;
	font-family: Segoe UI, sans-serif;
	line-height: 24px;
	letter-spacing: 0;
	color: #707070;
	margin-top: 18px;
	margin-bottom: 10px;
}

/* responsive view start */
@media screen and (max-width: 991px) {
	.fees_popup {
		max-width: 65%;
	}
}

@media screen and (max-width: 767px) {
	.fees_popup {
		max-width: 85%;
		overflow-y: scroll;
	}
}

@media screen and (max-width: 426px) {
	.fees_popup {
		max-width: 90%;
		overflow-y: scroll;
	}
}

/* responsive view start */
/* About Fees popup css end */

@media screen and (max-width: 1024px) {
	.Edit_comp_address .Edit_details ._address p {
		font-size: 14px !important;
	}
}

.Select_payment_method_box {
	margin: 20px 0;
	padding: 2px 0;
	/* border-bottom: #e2e0e0 solid 1px; */
}

.Select_payment_method_box ._payment_methods .setting_pay_inp {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 40%;
}

.Select_payment_method_box .payment_btn .pay_with_btn {
	border: 2px solid #6d2ef1;
	margin: 0 0 25px 0;
	padding: 10px 40px;
	background-color: transparent;
	color: #6d2ef1;
	border-radius: 30px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	-webkit-border-radius: 30px;
}

.Select_payment_method_box .payment_btn .pay_with_btn:hover {
	background-color: #6d2ef1;
	font-weight: 500;
	color: #fff;
}

.Edit_comp_address ._payment_alert {
	font-size: 12px;
	color: #a4a3a3;
}

.otp_container,
.reset_pass_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 650px;
	width: 95%;
	padding: 20px;
	background: #ffffff 0 0 no-repeat padding-box;
	min-height: 200px;
	border-radius: 10px;
	box-shadow: 0 14px 46px rgba(51, 51, 51, 0.15);
}

.otp_container .heading {
	color: #170048;
	font-size: 25px;
	font-weight: 700;
	margin: 20px 0;
}

.otp_container .otp_inputs {
	margin: 15px 0;
}

.otp_container .otp_submit_btn button {
	background-color: #170048;
	padding: 10px 60px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: 500;
	font-size: 15px;
	margin: 20px 0;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.otp_container .suggetion_resend {
	color: #170048;
	font-size: 12px;
	font-weight: 500;
}

.otp_container .suggetion_resend span {
	color: #b49df4;
	cursor: pointer;
	font-size: 12px;
}

.otp_container input {
	outline: none;
	border: 1px solid #d1d1d1;
	width: 50px !important;
	height: 50px !important;
	border-radius: 5px;
	font-size: 24px;
	font-weight: 200;
}

@media screen and (max-width: 425px) {
	.otp_container {
		padding: 10px;
	}

	.otp_container .heading {
		color: #170048;
		font-size: 15px;
		margin: 20px 0;
	}

	.otp_container input {
		width: 40px !important;
		height: 40px !important;
	}

	.otp_container .otp_inputs {
		margin: 0;
	}
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
	background-image: none;
}

/* Reset Password */
.reset_pass_container .heading {
	color: #170048;
	font-size: 25px;
	font-weight: 700;
	margin: 10px 0;
}

.reset_pass_container .pass_inputs {
	width: 95%;
}

.reset_pass_container .pass_inputs .pass_inp .showInpPass {
	top: 57px;
}

.reset_pass_container .pass_inp {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	width: 100%;
	color: #170048;
	font-size: 15px;
	font-weight: 500;
}

.reset_pass_container .pass_inp input {
	width: 100%;
}

.reset_pass_container .pass_inp input {
	outline: none;
	border: none;
	background-color: #ebebf7;
	height: 45px;
	width: 100%;
	margin: 10px 0;
	padding: 0 15px;
}

.reset_pass_container .pass_inp input::placeholder {
	color: #170048;
	font-weight: 500;
}

.reset_pass_container .reset_pass_btn button {
	background-color: #170048;
	padding: 10px 60px;
	color: #fff;
	border: none;
	border-radius: 30px;
	font-weight: 500;
	font-size: 15px;
	margin: 20px 0;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
}

.edit_contact_inp {
	display: flex;
}

.edit_contact_inp input {
	border: none;
	width: 100%;
	outline: none;
	padding: 0 11px;
	height: 40px;
	font-size: 14px;
}

.edit_contact_inp input::placeholder {
	color: rgb(184, 184, 184);
}

.edit_contact_inp select {
	border: none;
	width: 100%;
	outline: none;
	padding: 0 11px;
	height: 40px;
	font-size: 14px;
}

._cancle_submit button {
	min-width: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6d2ef1;
	font-weight: bold;
	border-radius: 20px !important;
	text-transform: uppercase;
	padding: 8px 45px;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

._cancle_submit button:hover {
	min-width: 160px;
	display: flex;
	background-color: #6d2ef1;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: bold;
	border-radius: 20px !important;
	text-transform: uppercase;
	padding: 8px 45px;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.save-submit button {
	min-width: 160px;
	display: flex;
	background-color: #6d2ef1;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: bold;
	border-radius: 20px !important;
	text-transform: uppercase;
	padding: 8px 45px;
	margin-top: 5px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	-webkit-border-radius: 60px !important;
}

.save-submit .hover-transparent:disabled {
	background-color: #c7c6c9;
	color: rgb(74 72 72);
	border: 1px solid rgb(74 72 72);
}

.filter_scr {
	position: absolute;
	width: 500px;
	background-color: #f8f8f8;
	top: 176px;
	left: 33px;
	padding: 10px;
	border: 1px solid #e0e0e0;
	box-shadow: rgba(0, 0, 0, 0.22) 0 5px 15px;
}

@media screen and (max-width: 425px) {
	.filter_scr {
		width: 371px;
		top: 201px;
	}
}

@media screen and (max-width: 375px) {
	.filter_scr {
		width: 322px;
	}
}

@media screen and (max-width: 321px) {
	.filter_scr {
		width: 269px;
	}
}

@media screen and (max-width: 425px) {
	.addition_acc_media {
		flex-wrap: wrap;
	}
}

.find_tal_btn:hover,
.ts_btn button:hover {
	color: #fff;
	background-color: #39478f;
}

.find_tal_btn:active {
	color: #fff !important;
	background-color: #39478f !important;
}

.all_contract_tabs {
	display: flex;
	justify-content: space-evenly;
	background: rgb(245, 245, 245);
	height: 50px;
	align-items: center;
	border-radius: 3px;
	width: 100%;
}

.all_contract_overflow {
	width: 100%;
	overflow-x: auto;
	border-bottom: 1px solid rgb(195, 194, 194);
	margin: 20px 0;
}

.all_contract_tabs .contract_tab {
	width: calc(100% / 5);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-weight: 500;
	font-size: 16px;
	height: 100%;
}

.all_contract_tabs .contract_tab:hover {
	border-block-end: 5px solid #6d2ef1;
	color: #6d2ef1;
}

.active_contract {
	border-block-end: 5px solid #6d2ef1 !important;
	color: #6d2ef1 !important;
}

.no_contract_data {
	text-align: center;
	margin: 40px 0 20px 0;
}

.milestone_overview_left {
	border: 1px solid rgb(153, 153, 153);
	margin: 20px 10px;
	padding: 20px;
	border-radius: 4px;
	min-height: 400px;
}

.milestone_overview_left .top_btns {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.milestone_overview_left .top_btns > div {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	min-width: 120px;
	color: #6d2ef1;
	font-size: 22px;
	font-weight: 500;
}

.milestone_overview_left .top_btns button {
	cursor: pointer;
	display: flex;
	width: 90px;
	min-width: 90px;
	justify-content: space-evenly;
	height: 40px;
	align-items: center;
	border-radius: 50px;
	border: 1px solid #6d2ef1;
	font-size: 16px;
	background: #fff;
	color: #6d2ef1;
	font-weight: 600;
}

.milestone_overview_left .top_btns button:hover {
	border: 1px solid #6d2ef1;
	background: #6d2ef1;
	color: #fff;
}

.milestone_overview_left .top_btns input {
	width: 18px;
	height: 18px;
	background-color: #fff;
	border-radius: 50%;
	vertical-align: middle;
	border: 2px solid #6d2ef1;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.milestone_overview_left .top_btns input:checked {
	width: 18px;
	height: 18px;
	padding: 2px !important;
	outline: 2px solid #6d2ef1;
	border: 2px solid #fff;
	background-color: #6d2ef1;
}

.milestone_overview_right {
	border: 1px solid rgb(153, 153, 153);
	margin: 20px 10px;
	padding: 20px;
	border-radius: 4px;
	/* min-height: 500px; */
}

.milestone_overview_right .top_heading {
	font-size: 22px;
	color: #6d2ef1;
	height: 40px;
	display: flex;
	align-items: center;
}

.milestone_overview_right .timesheet_time {
	margin-top: 20px;
}

.milestone_overview_right .timesheet_msg {
	/* font-size: 15px; */
	margin: 10px 0 30px 0;
}

.milestone_overview_right .timesheet_msg span {
	color: #6d2ef1;
}

.milestone_overview_right .timesheet_progress .progress {
	margin: 4px 0 8px 0;
	height: 10px;
}

.milestone_overview_right .earnings_progress {
	margin: 20px 0;
}

.milestone_overview_right .earnings_progress div {
	display: flex;
	justify-content: space-between;
	color: rgb(106, 106, 106);
	align-items: center;
	padding: 5px 0;
}

.milestone_overview_right .earnings_progress div > span {
	display: flex;
	align-items: center;
	gap: 7px;
}

.milestone_overview_right .earnings_progress div .received_color {
	display: inline-block;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background-color: #6d2ef1;
}

.milestone_overview_right .earnings_progress div .funded_color {
	display: inline-block;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background-color: #3dd419;
}

.milestone_overview_right .earnings_progress div .project_price_color {
	display: inline-block;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background-color: #aeaeae;
}

.milestone_overview_right .timesheet_progress .bg-success {
	--bs-bg-opacity: 1;
	background-color: #6d2ef1 !important;
}

.milestone_overview_left .milestone_overview_left_icons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.milestone_overview_left .milestone_overview_left_icons .to_do_icon {
	width: 60%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.milestone_overview_left .milestone_overview_left_icons .to_do_icon p {
	text-align: center;
	margin-top: 20px;
	color: rgb(130, 130, 130);
}

.milestone_overview_left .milestone_overview_left_icons .foldar_icon {
	width: 80%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
}

.milestone_overview_left .milestone_overview_left_icons .foldar_icon p {
	text-align: center;
	margin-top: 40px;
	color: rgb(130, 130, 130);
}

/*Milestone timeline start */

.timeline {
	position: relative;
}

.milestone_timeline {
	margin: 20px 0;
	border: 1px solid rgb(190, 190, 190);
	padding: 40px;
	border-radius: 4px;
}

@media (max-width: 768px) {
	.milestone_timeline {
		padding: 20px;
	}
}

.milestone_timeline .heading {
	font-size: 20px;
	font-weight: 600;
}

.milestone_timeline .progress_steps {
	display: flex;
	margin-bottom: 20px;
	position: relative;

	height: 100%;
}

.milestone_timeline .about_step {
	margin-top: 10px;
	width: calc(100% - var(--tracker_point_width));
	margin-left: 20px;
	color: rgb(111, 111, 111);
	font-size: 14px;
}

.project_cancle_error {
	width: calc(100% - var(--tracker_point_width));
	color: rgb(253 43 43);
	font-size: 14px;
}

/* div#progress_step2:last-child > div:nth-child(1) {
  display: none;
}
div#progress_step2:last-child > div:nth-child(2) {
  display: none;
} */
/* .milestone_timeline .about_step p {
  margin-top: 10px;
} */
.milestone_timeline .timeline_link {
	font-size: 14px;
	color: #6d2ef1;
	font-weight: 600;
	cursor: pointer;
	text-decoration: underline;
}

.timelineLLLLink {
	border: none;
	background-color: #fff;
}

.timelineLLLLink:disabled {
	cursor: not-allowed;
}

.milestone_timeline .about_step .p_heading {
	/* margin-top: 10px; */
	font-size: 14px;
	font-weight: 600;
	color: #000;
}

.milestone_timeline .progress_steps .about_step .is_completed_steps > div {
	display: flex;
	margin: 5px 0;
}

.milestone_timeline .progress_steps .about_step .is_completed_steps > div input {
	margin-right: 10px;
}

.milestone_timeline .paid_btn button,
.milestone_timeline .pending_btn button,
.milestone_timeline .active_and_paid_btn button {
	background-color: #fff;
	border: 1px solid #6d2ef1;
	border-radius: 30px;
	padding: 2px 15px;
	margin: 10px 0;
	color: #6d2ef1;
	cursor: auto;
}

/* .milestone_timeline .paid_btn button:hover, */
.milestone_timeline .pending_btn button:hover,
.milestone_timeline .active_and_paid_btn button:hover {
	background-color: #6d2ef1;
	color: #fff;
}

.milestone_timeline .submit_work_btn button {
	background-color: #6d2ef1;
	border: 1px solid #6d2ef1;
	border-radius: 30px;
	padding: 10px 40px;
	color: #fff;
	cursor: pointer;
	margin: 10px 10px 10px 0;
}

.milestone_timeline .submit_work_btn button:disabled {
	background-color: #c5c5c5;
	border: 1px solid #c5c5c5;
	color: #fff;
}

.milestone_timeline .progress_steps .fill_ {
	display: flex;
	width: var(--tracker_point_width);
	height: var(--tracker_point_height);
	background-color: #fff;
	justify-content: center;
	align-items: center;
	color: #170048;
	border-radius: 50%;
	border: 1px solid #d2d2d2;
	margin-top: 10px;
	z-index: 9;
}

@media (max-width: 481px) {
	.milestone_timeline {
		padding: 20px;
	}

	.milestone_timeline .submit_work_btn button {
		padding: 10px 16px;
		font-size: 12px;
	}

	.milestone_timeline .about_step {
		margin-left: 10px;
	}

	.milestone_timeline .heading {
		margin-bottom: 20px;
	}
}

.filled_background {
	background-color: #fffffff4;
}

.fa-checkBackground {
	background-color: #6d2ef1 !important;
}

.fa-checkBackground .fa-check {
	font-size: 12px;
}

.milestone_overview_left .file_show {
	display: flex;
	border-top: 1px solid rgb(229 229 229);
	border-bottom: 1px solid rgb(229 229 229);
	padding: 0 10px;
	align-items: center;
}

.milestone_overview_left .file_show .menu_dot {
	margin-left: auto;
	font-size: 20px;
	font-weight: 700;
}

.milestone_overview_left .file_show .menu_dot span {
	transform: rotate(90deg);
	width: 30px;
	height: 30px;
	/* border: 1px solid; */
	padding-bottom: 10px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.milestone_overview_left .file_show .file_type_icon {
	border: 1px solid rgb(229 229 229);
	padding: 2px;
	margin: 10px;
}

.milestone_overview_left .file_show .about_file {
	margin: 0 10px;
}

.milestone_overview_left .file_show .about_file .file_info {
	display: flex;
	color: rgb(150, 150, 150);
	font-size: 14px;
}

.milestone_overview_left .file_show .about_file .file_info .file_size {
	margin-right: 20px;
}

/*Milestone timeline end */

/* Time Sheet  */

.timesheet {
	margin-top: 20px;
	border: 1px solid rgb(192, 192, 192);
	border-radius: 20px;
	padding: 20px;
}

.timesheet .timesheet_times {
	display: flex;
	justify-content: space-evenly;
}

.timesheet .timesheet_times .timesheet_time {
	display: flex;
	flex-direction: column;
	width: calc(100% / 4);
}

.timesheet .timesheet_times .timesheet_time .time_limit {
	color: rgb(182, 181, 181);
	font-size: 10px;
}

.timesheet .timesheet_times .timesheet_time .headings {
	font-size: 12px;
	font-weight: 600;
}

@media screen and (max-width: 425px) {
	.timesheet .timesheet_times {
		flex-wrap: wrap;
		gap: 20px;
	}

	.timesheet .timesheet_times .timesheet_time {
		min-width: 110px;
	}
}

.timesheet .work_diary .heading_and_btn {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid rgb(192, 192, 192);
	margin-bottom: 20px;
	padding: 5px 0;
}

.timesheet .work_diary .time_sheet_time_btn {
	background-color: #fff;
	border: 1px solid #6d2ef1;
	border-radius: 30px;
	padding: 5px 20px;
	margin: 10px 0;
	color: #6d2ef1;
	min-width: 120px;
}

.timesheet .work_diary .time_sheet_time_btn:hover {
	background-color: #6d2ef1;
	color: #fff;
}

.timesheet .work_diary .time_sheet_time_btn:disabled {
	background-color: #d1d1d1;
	border: 1px solid #ffffff;
	color: #ffffff;
}

.timesheet .work_diary .time_sheet_time_btn:disabled:hover {
	background-color: #d1d1d1;
	border: 1px solid #ffffff;
	color: #ffffff;
}

.timesheet .work_diary .timesheet_time_input {
	width: 80px;
	height: 40px;
	outline: none;
	border: 1px solid #d8d8d8;
	padding: 0 12px;
	margin: 0 5px;
	text-align: center;
}

.timesheet .work_diary .heading_and_btn > span {
	font-size: 20px;
	font-weight: 500;
}

.timesheet .heading {
	font-size: 20px;
	font-weight: 600;
}

.timesheet .day_timesheet {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid rgb(240 240 241);
	margin: 10px 0;
}

.timesheet .day_timesheet .day_timesheet_date {
	width: 150px;
}

.tracked.tracked1 {
	width: 130px;
}

@media screen and (max-width: 425px) {
	.timesheet .work_diary .heading_and_btn {
		flex-direction: column;
	}
}

@media screen and (max-width: 375px) {
	.timesheet .day_timesheet {
		flex-direction: column;
	}

	.timesheet .heading {
		text-align: center;
	}
}

.timesheet .progress_timesheet {
	width: 60%;
}

.timesheet .progress_timesheet .progress {
	height: 10px;
}

.timesheet .timesheet_tracked {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	width: 56%;
}

.timesheet .timesheet_tracked .tracked {
	display: flex;
	flex-direction: column;
	/* margin-left: -17px; */
}

.timesheet .timesheet_tracked .tracked .heading {
	font-size: 16px;
	font-weight: 600;
}

.timesheetDetails {
	border: 1px solid rgb(235, 235, 235);
	margin: 20px 0;
	padding: 20px;
	border-radius: 8px;
}

.timeSheetDetailsHead {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
}

.timeSheetLine {
	line-height: 20px;
}

.timesheetDetails .timeDethourly {
	font-weight: 600;
	font-size: 16px;
	line-height: 30px;
	color: #1f1f1f;
	text-transform: capitalize;
}

.timesheetDetails .detailsBtn {
	margin: 10px 0;
	color: #6d2ef1;
	background-color: #fff;
	border: #6d2ef1 solid 1px;
	border-radius: 25px;
	padding: 10px 22px;
	font-size: 15px;
}

.detailsPTag {
	font-weight: 300;
	font-size: 14px;
	line-height: 24px;
	color: #3f4548;
	white-space: pre-line;
	word-break: break-word;
}

.detailsLinkOff {
	font-weight: 500;
	font-size: 14px;
	color: #6d2ef1;
	line-height: 20px;
	cursor: pointer;
	margin-bottom: 10px;
}

.timesheetDetails .comapanyHead {
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 21px;
	line-height: 30px;
}

.timesheetDetails .verifiedLogoDiv {
	display: flex;
	gap: 10px;
	align-items: center;
}

.timesheetDetails .compnyVerifiedMethod {
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0;
	color: #5d5d5d;
}

.timesheetDetails .companyRating {
	display: flex;
	align-items: center;
	gap: 10px;
	padding-top: 10px;
}

.timesheetDetails .comapnyRatingStar {
	display: flex;
	align-items: center;
	gap: 4px;
}

.timesheetDetails .ratingReview {
	font-size: 15px;
	line-height: 26px;
	letter-spacing: 0;
	/* font-weight: 400; */
	color: #462d7a;
}

.timesheetDetails .compDet {
	font-size: 18px;
	line-height: 20px;
	font-weight: 500;
	color: #6d2ef1;
}

.timesheetDetails .compLoc {
	margin-top: 30px;
}

.timesheetDetails .compDetList {
	padding-top: 18px;
}

.timesheetDetails .companyDetText {
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0;
	color: #462d7a;
	margin-top: 15px;
}

.timesheetDetails .compSize {
	margin-top: 30px;
}

.timesheetDetails .feedbackLogo {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 120px;
}

.timesheetDetails .feedBackLogoCenter {
	max-width: 100%;
}

.timesheetDetails .feedBackLogoCenter svg {
	width: 100px;
	height: 85px;
}

.timesheetDetails .feedBackSubHead {
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
	padding-top: 25px;
}

.timesheetDetails .feedBackPara {
	font-size: 16px;
	line-height: 22px;
	padding-top: 5px;
	white-space: pre-line;
	word-break: break-word;
}

.timesheetDetails .feedbackBtnDiv {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}

.timesheetDetails .feedbackBtn {
	background: #6d2ef1;
	border-radius: 25px;
	border: none;
	color: #fff;
	padding: 10px 32px;
}

.timesheetDetails .feedbackBtn:disabled {
	background: #cfcfcf;
}

/* frrelancer all contracts timesheet end */

.selected_skills_filter_jobs {
	display: flex;
	flex-wrap: wrap;
}

.selected_skills_filter_jobs .skill {
	background: #e6dbfd;
	border-radius: 30px;
	padding: 5px 15px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 3px;
}

.selected_skills_filter_jobs .skill span {
	border: none;
	padding: 0 5px;
	font-size: 12px;
}

.selected_skills_filter_jobs .skill button {
	border: none;
	padding: 0 5px;
}

.selected_skills_filter_jobs .skill button:hover {
	border: none;
}

.suggessted_skills {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	max-height: 155px;
	z-index: 99;
	overflow-y: auto;
}

.suggessted_skills span {
	border-bottom: 1px solid rgb(191, 191, 191);
	width: 100%;
	padding: 3px 10px;
	background-color: rgb(239, 239, 239);
	cursor: pointer;
}

.suggessted_skills span:hover {
	background-color: rgb(244, 244, 244);
}

.languages_overflow {
	overflow-y: scroll;
	max-height: 310px;
}

.languages_overflow::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
	overflow-x: auto;
}

.forgot_otp_error {
	font-size: 12px;
	color: rgb(255 104 104);
	font-weight: 400;
	margin-bottom: -4px;
	padding: 0;
}

.languages_overflow::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: #f5f5f5;
	overflow-x: auto;
}

.languages_overflow::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #6d2ef1;
	overflow-x: auto;
}

/*  reports overview start */
.reports_overview .top {
	display: flex;
	justify-content: space-between;
	padding: 10px 10px;
}

.reports_overview .top .heading {
	font-weight: 600;
}

.reports_overview .top .link {
	color: #6d2ef1;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
}

.reports_overview .data_container.timesheet_pending .headings {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f9f9f9;
	padding: 10px;
	margin: 10px 0;
	font-weight: 500;
}

.reports_overview .data_container.timesheet_pending .headings .job_heading {
	width: 20%;
}

.reports_overview .data_container.timesheet_pending .headings .days_headings {
	display: flex;
	justify-content: space-evenly;
	width: 50%;
}

.reports_overview .data_container.timesheet_pending .headings .heading {
	min-width: 100px;
	text-align: start;
}

.reports_overview .data_container.timesheet_pending .headings .heading .days {
	display: flex;
	flex-direction: column;
	min-width: 40px;
	align-items: center;
}

.reports_overview .data_container.timesheet_pending .data_rows {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background-color: #f9f9f9; */
	padding: 10px;
	border-bottom: 1px solid #f0f0f0;
	font-weight: 500;
}

.reports_overview .data_container.timesheet_pending .data_rows .name_row {
	width: 20%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.reports_overview .data_container.timesheet_pending .data_rows .name_row .name {
	font-weight: 600;
	text-align: start;
	text-transform: capitalize;
	word-break: break-word;
}

.reports_overview .data_container.timesheet_pending .data_rows .days_row {
	width: 50%;
	display: flex;
	justify-content: space-evenly;
}

.reports_overview .data_container.timesheet_pending .data_rows .data_row {
	min-width: 100px;
	text-align: start;
}

.reports_overview .data_container.timesheet_pending .data_rows .data_row .days {
	display: flex;
	flex-direction: column;
	min-width: 40px;
	align-items: center;
}

.reports_overview .data_container.timesheet_pending .total_row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 10px;
	border-bottom: 1px solid #f0f0f0;
	font-weight: 600;
}

.reports_overview .data_container.timesheet_pending .total_row > div {
	min-width: 100px;
	text-align: start;
}

.reports_overview .data_container.timesheet_pending .total_row .empt_job {
	width: 20%;
}

.reports_overview .data_container.timesheet_pending .total_row .empt_days {
	width: 50%;
}

.reports_overview .fixed_pending {
	margin-top: 40px;
}

.reports_overview .data_container.fixed_pendin .headings {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f9f9f9;
	padding: 15px 10px;
	margin: 10px 0;
	font-weight: 600;
}

.reports_overview .data_container.fixed_pendin .headings .heading {
	min-width: 100px;
	text-align: start;
}

.reports_overview .data_container.fixed_pendin .headings .heading.job {
	width: 20%;
	text-align: start;
}

.reports_overview .data_container.fixed_pendin .data_rows {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px;
	margin: 10px 0;
	font-weight: 500;
	border-bottom: 1px solid #f0f0f0;
}

.reports_overview .data_container.fixed_pendin .data_row {
	text-align: start;
	min-width: 100px;
}

.reports_overview .data_container.fixed_pendin .data_row.amt {
	opacity: 0.6;
	font-weight: 700;
}

.reports_overview .data_container.fixed_pendin .data_rows .name_row {
	width: 30%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.reports_overview .data_container.fixed_pendin.new .data_rows .name_row {
	width: 20%;
}

.reports_overview .data_container.fixed_pendin .data_rows .name_row .image {
	display: flex;
	/* background-color: #6d2ef1; */
	color: #fff;
	font-weight: 600;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.overProfileIamgeDiv {
	width: 50px !important;
	height: 50px !important;
}

.image {
	min-width: 50px;
	min-height: 50px;
}

.about {
	word-break: break-word;
}

.reports_overview .data_container.fixed_pendin .data_rows .name_row .image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.reports_overview .data_container.fixed_pendin .data_rows .name_row .name {
	font-weight: 600;
	text-align: start;
	text-transform: capitalize;
}

.reports_overview .data_container.fixed_pendin .data_rows .name_row .about {
	font-size: 14px;
	text-align: start;
	font-weight: 400;
	word-break: break-word;
	text-transform: capitalize;
}

.reports_overview .data_container.fixed_pendin .total_row {
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid #f0f0f0;
	padding: 15px 10px;
}

.reports_overview .data_container.fixed_pendin .total_row .total {
	min-width: 100px;
	font-weight: 600;
	text-align: start;
}

.contract_ov_report_note {
	text-align: start;
	margin: 20px 10px 10px;
	color: #000;
	font-weight: 600;
}

.contract_ov_report_note .headin {
	color: #000;
}

.recent_transaction_btn {
	display: flex;
	gap: 10px;
	font-size: 16px;
	font-weight: 600;
	margin: 20px 0;
}

.recent_transaction_btn .payment_schedule {
	border: 1px solid #6d2ef1;
	color: #6d2ef1;
	border-radius: 40px;
	padding: 12px 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.recent_transaction_btn .get_paid_now {
	border: 1px solid #6d2ef1;
	color: #fff;
	background-color: #6d2ef1;
	border-radius: 40px;
	padding: 12px 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.get_paid_now {
	border: 1px solid #dfdddd;
	margin: 0;
	padding: 30px;
	border-radius: 5px;
}

.get_paid_now .page_heading {
	font-size: 24px;
	font-weight: 600;
	color: #192326;
}

.get_paid_now .avail_bal {
	font-size: 18px;
	font-weight: 600;
	color: #000000;
	margin: 10px 0;
}

.get_paid_now .amt {
	font-size: 14px;
	font-weight: 600;
	color: #6d2ef1;
}

.get_paid_now .payment_method {
	display: flex;
	flex-direction: column;
	margin: 20px 0;
}

.get_paid_now .payment_method label {
	color: #4d4d4d;
}

.get_paid_now .payment_method select {
	height: 40px;
	border: 1px solid #d0d0d0;
	color: #8f8f8f;
	padding: 0 10px;
	margin: 5px 0;
	outline: none;
}

.get_paid_now button {
	padding: 14px 45px !important;
}

.overview_spaces_div .overview_spaces {
	font-size: 16px;
	font-weight: 600;
	padding: 8px 25px;
}

/*  reports overview end */

@media screen and (min-width: 768px) {
	.tracked.tracked1 {
		min-width: 90px;
	}
}

@media screen and (max-width: 375px) {
	.tracked.tracked1 {
		width: unset !important;
	}
}

.task_board_errors {
	border: 1px solid red !important;
}

.task_board_errors::placeholder {
	color: red !important;
}

.attachm_ico {
	display: flex;
	gap: 5px;
	cursor: pointer;
}

.attachm_ico img {
	width: 12px;
}

.due_date_ic {
	display: flex;
	gap: 5px;
	cursor: pointer;
}

.due_date_ic,
.attachm_ico,
.commen_cont {
	display: flex;
	align-items: center;
	gap: 2px;
}

.btn_sm_eee {
	cursor: pointer;
}

.btn_sm_eee img {
	fill: #596d8d;
	width: 13px;
}

.commen_cont img {
	height: 14px;
	width: 14px;
}

.select_due_date_for_task {
	height: 40px;
	border: 1px solid #c6c6c6;
	outline: none;
	padding: 10px;
	color: #817878;
	width: 220px;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
a,
label,
p {
  word-break: break-word;
  white-space: pre-line;
}*/

.transec_filt_select {
	width: 100%;
	height: 42px;
	border: 1px solid #bbb;
	outline: none;
}

.disable_next_pag_:disabled {
	color: #fff;
	background-color: #cbcbcb;
	border: none;
}

.clear_cont_filter {
	color: #fff;
	background-color: #6d2ef1;
	border: none;
	border-radius: 50px;
	padding: 8px 20px;
	font-size: 14px;
	font-weight: 500;
}

@media screen and (max-width: 425px) {
	.clear_cont_filter {
		margin-top: 10px;
	}
}

.chat_users_scroll {
	overflow-y: auto;
	/* Hide scrollbar for IE 10+ */
	-ms-overflow-style: none;
	/* max-height: 68vh; */

	/* Hide scrollbar for Firefox */
	/* transition: linear 1s; */
}

.chat_users_scroll::-webkit-scrollbar {
	/* display: none; */
}

@media screen and (max-width: 481px) {
	.chat_users_container {
		position: absolute;
		z-index: 10;
		box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
		background-color: #fff;
		margin-right: 12px;
	}
}

.send_message_area {
	position: absolute;
	width: 100%;
	bottom: 0;
	border-radius: 40px;
	z-index: 9;
}

.send_message_area .replyOnMessage {
	position: absolute;
	width: 100%;
	color: #b7b7b7;
	max-width: 100%;
	background-color: #fff;
	padding: 20px;
	border-bottom: 1px solid #ababab;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
	display: flex;
	z-index: 999;
	bottom: 100%;
}

.send_message_area .replyOnMessage .reply_on_msg {
	word-break: break-word;
	white-space: pre-wrap;
	max-height: 200px;
	overflow-y: auto;
}

.send_message_area .replyOnMessage .icon {
	width: 50px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	right: 13px;
}

.send_message_area .replyOnMessage .close_icon {
	display: none;
}

.copied_link {
	font-size: 12px;
	padding: 0 10px;
	background: #ababab;
	color: #fff;
	border-radius: 30px;
	min-height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.send_message_area .replyOnMessage:hover .close_icon {
	display: block;
	position: absolute;
	right: 0;
	top: -2px;
	transform: rotateX(42deg);
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	border: 1px solid rgb(147, 147, 147);
	border-radius: 50%;
	padding: 0 5px;
	z-index: 99;
	background: #000;
}

.send_message_area .replyOnMessage .about_msg {
	font-size: 12px;
	/* position: relative; */
	margin-top: 10px;
	display: flex;
	gap: 10px;
	bottom: -6px;
}

.send_message_area .is_typing {
	font-size: 12px;
	min-height: 16px;
	position: absolute;
	z-index: 9;
	bottom: 100%;
	width: 100%;
	max-width: 100%;
}

/* Chat Attachemnt start */
.chat_attachements {
	display: flex;
	max-width: 100%;
	gap: 10px;
	padding: 20px;
	background: #fff;
	position: relative;
	border-radius: 3px;
	z-index: 9;
	width: max-content;
	overflow-x: auto;
	margin-bottom: 2px;
}

.chat_attachements::-webkit-scrollbar {
	width: 2px;
	height: 8px;
	background-color: #f5f5f5;
}

.chat_attachements::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 20px;
}

.chat_attachements::-webkit-scrollbar-thumb:hover {
	background-color: #999;
}

.chat_attachements::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

.chat_attachements::-webkit-scrollbar-track:hover {
	background-color: #eee;
}

.chat_attachement_cont {
	max-width: 100%;
	width: max-content;
	position: absolute;
	bottom: 100%;
}

.chat_attachement_cont .message_with_attachment {
	display: flex;
	position: absolute;
	bottom: 0;
	z-index: 9;
	height: 40px;
	width: 100%;
}

.chat_attachement_cont .message_with_attachment input {
	width: 100%;
	height: 100%;
	border: 1px solid #ababab;
	outline: none;
	padding-right: 40px;
}

.chat_attachement_cont .message_with_attachment .send_mesg {
	position: absolute;
	right: 0;
	width: max-content;
	padding: 3px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat_attachement_cont .message_with_attachment .send_mesg button {
	border: none;
	background: transparent;
	height: 100%;
	border-radius: 10px;
	width: 100%;
}

.chat_attachements .images {
	width: 100px;
	height: 80px;
	min-width: 100px;
	border: 1px solid #fff;
	position: relative;
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
	text-align: center;
}

.chat_attachements .images:hover img {
	opacity: 0.5;
}

.chat_attachement_cont .images:hover .close_icon {
	display: block;
	position: absolute;
	right: 0;
	top: -2px;
	transform: rotateX(42deg);
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	border: 1px solid rgb(147, 147, 147);
	border-radius: 50%;
	padding: 0 5px;
	z-index: 99;
	background: #000;
}

.chat_attachements .images img {
	width: auto;
	max-width: 100%;
	height: 100%;
	object-fit: cover;
}

.chat_attachement_cont .close_icon {
	display: none;
}

.chat_attachements .documents {
	width: 240px;
	/* background: #c8c5c5; */
	border-radius: 4px;
	padding: 10px;
	height: 60px;
	position: relative;
	color: #ababab;
	border: 1px solid #e9e9e9;
	display: flex;
	align-items: center;
	gap: 10px;
}

.chat_attachement_cont .documents:hover .close_icon {
	display: block;
	position: absolute;
	right: 0;
	top: -2px;
	transform: rotateX(42deg);
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	border: 1px solid rgb(147, 147, 147);
	border-radius: 50%;
	padding: 0 5px;
	z-index: 99;
	background: #000;
}

.chat_attachements .documents .file {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	gap: 10px;
}

.chat_attachements .documents .file .heading {
	font-size: 14px;
	font-weight: 500;
}

.chat_attachements .documents .file .file_name {
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	color: #6d2ef1;
}

.chat_attachements .documents .file .file_name:hover {
	text-decoration: underline;
	color: #6d2ef1;
}

.chat_attachements .videos {
	position: relative;
	border: 1px solid #e5e5e5;
	background: #ababab;
	width: 200px;
	height: 150px;
	min-width: 200px;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat_attachements .audios {
	position: relative;
	display: flex;
	align-items: center;
}

.chat_attachement_cont .audios:hover .close_icon {
	display: block;
	position: absolute;
	right: 0;
	top: -2px;
	transform: rotateX(42deg);
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	border: 1px solid rgb(147, 147, 147);
	border-radius: 50%;
	padding: 0 5px;
	z-index: 99;
	background: #000;
}

.chat_attachements .videos video {
	width: 100%;
	max-height: 100%;
}

.chat_attachement_cont .videos:hover .close_icon {
	display: block;
	position: absolute;
	right: 0;
	top: -2px;
	transform: rotateX(42deg);
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	border: 1px solid rgb(147, 147, 147);
	border-radius: 50%;
	padding: 0 5px;
	z-index: 99;
	background: #000;
}

/* Chat Attachemnt end */

/* chat message start */
.single_msg_box {
	margin: 10px 0;
	color: #b7b7b7;
	position: relative;
	max-width: 70%;
}

.message_box_right {
	position: relative;
	width: max-content;
	margin-left: auto;
	background-color: #fff;
	border-radius: 10px;
}

.message_box_left {
	position: relative;
	width: max-content;
	margin-right: auto;
	background-color: #fff;
	border-radius: 10px;
}

.message_box_left.single_msg_box {
	color: #000 !important;
}

.message_box_left .usernamename {
	position: absolute;
	top: -30px;
	width: 300px;
	font-size: 14px;
}

.message_box_right .usernamename {
	position: absolute;
	top: -30px;
	right: 0;
	width: 300px;
	text-align: right;
	font-size: 14px;
	color: #000;
}

.message_box_left .message_edited {
	position: absolute;
	right: -30px;
	top: 50%;
	transform: translateY(-50%);
}

.message_box_right .message_edited {
	position: absolute;
	left: -25px;
	top: 50%;
	transform: translateY(-50%);
}

.message_box_right {
	border-top-right-radius: 0;
	background-color: #6610f2;
	color: #fff;
}

.message_box_left {
	border-top-left-radius: 0;
}

.single_msg_box .original_mesg {
	white-space: pre-wrap;
	word-break: break-word;
	position: relative;
	font-size: 12px;
}

.message_box_right .original_mesg {
	position: relative;
	padding: 15px 65px 15px 30px;
	margin-right: 10px;
}

.message_box_left .original_mesg a {
	color: #212529;
}

.message_box_right .original_mesg a {
	color: #fff;
}

.single_msg_box .original_mesg a:hover {
	text-decoration: underline;
}

.message_box_left .original_mesg {
	position: relative;
	padding: 15px 30px 15px 30px;
}

.single_msg_box .original_mesg .profile_ig {
	position: absolute;
	width: 30px;
	height: 30px;
	z-index: 1;
	overflow: hidden;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.single_msg_box .original_mesg .profile_ig img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.message_box_right .original_mesg .profile_ig {
	right: -15px;
	top: -10px;
}

.message_box_left .original_mesg .profile_ig {
	left: -15px;
	top: -10px;
}

.single_msg_box .rpl_on_msg {
	cursor: pointer;
	border-bottom: 1px solid #b9b9b9;
	margin-bottom: 0;
	padding: 10px 10px 10px 30px;
}

.single_msg_box .rpl_on_msg .reply_icon {
	position: relative;
	right: 20px;
}

.single_msg_box .rpl_on_msg .msg_repl_on {
	white-space: pre-line;
	word-break: break-word;
}

.single_msg_box .rpl_on_msg .about {
	display: flex;
	gap: 10px;
	font-size: 12px;
	line-height: 16px;
	position: relative;
	top: 5px;
}

.message_box_right .about {
	display: flex;
	gap: 10px;
	font-size: 10px;
	line-height: 16px;
	position: absolute;
	bottom: -20px;
	min-width: max-content;
	right: 0;
	color: #ababab;
}

.message_box_left .about {
	display: flex;
	gap: 10px;
	line-height: 16px;
	font-size: 10px;
	bottom: -20px;
	position: absolute;
	min-width: max-content;
	color: #ababab;
	left: 0;
}

.new_message {
	background-color: #f2f8ff;
	display: flex;
	gap: 10px;
	margin: 10px 0;
	border-radius: 10px;
	padding: 15px 0;
}

.other_activities {
	/* background-color: #f2f8ff; */
	display: flex;
	gap: 20px;
	margin: 10px 0;
	border-radius: 10px;
	max-width: 100%;
}

.other_activities .options_ico {
	cursor: pointer;
}

.new_message .options_ico {
	cursor: pointer;
}

.new_message:hover {
	background-color: #eff6ff;
}

.message_deleted_left {
	display: flex;
	align-items: center;
	gap: 5px;
	background: #fff;
	width: max-content;
	max-width: 100%;
	padding: 5px 15px;
	margin: 10px auto 10px 0;
	border-radius: 0 40px 40px 40px;
	color: #b7b7b7;
}

.message_deleted_right {
	display: flex;
	align-items: center;
	gap: 5px;
	background: #fff;
	width: max-content;
	max-width: 100%;
	padding: 5px 15px;
	border-radius: 40px 40px 0 40px;
	color: #b7b7b7;
	margin: 20px 10px 10px auto;
}

.original_message.activity_title {
	font-weight: 500;
	color: #767676;
}

.original_message {
	font-size: 13px;
	font-weight: 300;
}

.message_box_left .acivity_statuc span {
	font-weight: 600;
	color: #212529;
}

.message_box_right .acivity_statuc span {
	font-weight: 600;
	color: #fff;
}

/* chat message end */

.out_of_office_popup .out_of_office {
	padding: 20px 0;
}

.out_of_office_popup .out_of_office .on_off {
	display: flex;
	gap: 10px;
	align-items: center;
	font-size: 14px;
	margin-bottom: 10px;
}

.out_of_office_popup .out_of_office .heading {
	font-weight: 500;
	margin-top: 20px;
	margin-bottom: 5px;
	color: #ababab;
}

.out_of_office_popup .out_of_office .dates input {
	height: 40px;
	width: 100%;
	border: 1px solid #e1e1e1;
	outline: none;
	color: #ababab;
}

.out_of_office_popup .out_of_office .out_of_office_msg textarea {
	width: 100%;
	height: 120px;
	border: 1px solid #e1e1e1;
	outline: none;
	resize: none;
}

.search_inp_chat_single {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	background-color: transparent;
}

.search_inp_chat_single input {
	outline: none;
	border: 1px solid #e5e2e2;
	height: 100%;
	width: 100%;
	background-color: transparent;
	padding-right: 35px;
}

.round-icon {
	width: 28px;
	height: 28px;
	background: #6d2ef1;
	color: #fff;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}