
h1 {
    font-weight: 700;
    font-size: 40px;
    letter-spacing: 0;
    text-align: center;
    color: #00065a;
    position: relative;
}

h1.title-line {
    overflow: hidden;

    &:before {
        z-index: -1;
        content: " ";
        position: absolute;
        top: 34px;
        left: 24%;
        width: 284px;
        background-color: #ff88fe;
        height: 26px;
    }
}