.responsiveSocialButton button {
	padding: 3px 20px;
}

@media (max-width: 1199px) {
	.learn_and_earn_section1 {
		width: 83%;
	}

	.learn_and_earn_section5right {
		display: none;
	}

	.learn_and_earn_section5left {
		display: none;
	}
}

@media (max-width: 1023px) {
	.learn_and_earn_section1 {
		width: 100%;
	}

	.learn_and_earn_section1 h1 {
		font-size: 53px;
		width: 715px;
	}

	.learn_and_earn_section1 h1 .home_number_one {
		font-size: 281px;
		top: -184px;
	}

	.learn_and_earn_section1 .pink-line-freelancer {
		right: 35px;
	}

	.home_second_middle {
		flex-direction: column;
		align-items: center;
		margin-right: 0;
		justify-content: center;
	}

	.home_second_middle2 {
		flex-direction: column;
		align-items: center;
		margin-left: 0;
		justify-content: center;
	}

	.home_second_middle5 {
		flex-direction: column;
		align-items: center;
		margin-right: 0;
		justify-content: center;
	}

	.home_second_middle .home_left_div {
		width: 100%;
		margin-top: 80px;
		text-align: center;
	}

	.home_second_middle .home_right_div {
		width: 100%;
	}

	.home_second_middle2 .home_left_div2 {
		width: 100%;
		order: 2;
	}

	.home_second_middle2 .home_right_div2 {
		width: 100%;
		margin-left: 0;
		order: 1;
	}

	.home_second_middle5 .home_left_div5 {
		width: 100%;
	}

	.home_second_middle5 .home_right_div5 {
		width: 100%;
	}

	.home_second_middle .home_text_h3 {
		width: 400px;
		margin: 0 auto;
	}

	.home_second_middle .home_left_div_parathree {
		margin: 30px auto;
	}

	.home_second_middle .home_number_three {
		right: 0;
		font-size: 250px;
		left: 0;
	}

	.home_second_middle2 .home_text_h4 {
		text-align: center;
		margin: 0 auto;
	}

	.home_second_middle2 .home_left_div_para4 {
		margin: 30px auto;
	}

	.home_second_middle5 .home_text_h5 {
		text-align: center;
		margin: 0 auto;
		width: 400px;
	}

	.home_second_middle5 .home_left_div_parafive {
		margin: 30px auto;
	}

	.home_second_middle5 .pink-linefive {
		left: 40px;
	}

	.home_second_middle2 .home_text_h4 {
		font-size: 47px;
		width: 450px;
	}

	.home_second_middle2 .pink-linefour {
		width: 270px !important;
		left: 135px;
	}

	.learn_and_earn_section1 .pink-line {
		width: 312px;
		left: 118px;
	}

	.home_second_middle .home_text_h3freelancer {
		margin: 0 auto;
	}

	.home_second_middle2 .home_text_h4freelancer {
		margin: 0 auto;
		text-align: center;
	}

	.home_second_middle5 .home_number_five {
		left: 0;
		right: 0;
	}

	.overlayback {
		display: none;
	}

	.clients_sec_medium {
		width: 100% !important;
	}

	.section_three_wrapper {
		margin-bottom: 50px;
	}

	.section_three_wrapper .section_three {
		margin-left: 0;
		height: 100%;
	}

	.section_three_wrapper .section_three_list {
		margin-left: 0;
		margin-right: 0;
	}

	.section_three_wrapper .section_three_item_heading {
		font-size: 20px;
		line-height: 28px;
	}

	.section_three_wrapper .section_three_list .section_three_item {
		padding: 25px 10px;
		height: unset;
	}

	.section_three_wrapper .section_three_item_para {
		padding: 0 5px;
		font-size: 16px;
	}

	.section_three_wrapper .box_area_border_section_three {
		display: none;
	}

	.section_three_wrapper .section_three {
		background-image: unset !important;
	}

	.section_three_wrapper .section_three_info .section_three_heading {
		width: 700px;
		font-size: 62px;
		margin: 0 auto;
		text-align: center;
	}

	.section_three_wrapper .section_three_info .section_three_para {
		margin: 30px auto;
	}

	.section_three_wrapper .section_three_numberPnik {
		width: 500px;
	}

	.section_three_wrapper .section_three_number {
		left: 0;
		right: 0;
	}

	.section_three_wrapper .section_three_list {
		margin-top: 50px;
	}

	.showonresponsiveimage {
		display: block;
		width: 100%;
		height: 100%;
		margin-top: 50px;
	}

	.home_second_middle2 .home_number_four {
		left: 0;
		right: 0;
	}

	.home_top_header .home_left {
		margin-top: 40px !important;
	}

	.home_top_header .home_left .heading {
		font-size: 42px;
		line-height: 50px;
	}

	.freelancer_pink::before {
		width: 375px;
	}

	.pink-line-freelancer-6 {
		width: 358px;
	}
}

@media (max-width: 767px) {
	.responsiveSocialButton {
		flex-direction: column !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.responsiveSocialButton button {
		width: 68% !important;
		margin: 5px auto !important;
	}

	.learn_and_earn_section1 h1 {
		font-size: 50px;
		width: 470px;
	}

	.learn_and_earn_section1 .pink-line {
		height: 18px;
		width: 204px;
		top: 26px;
		left: 57px;
	}

	.learn_and_earn_section1 p {
		padding: 0;
		margin-top: 30px;
	}

	.learn_and_earn_section1 h1 .home_number_one {
		font-size: 250px;
		top: -184px;
	}

	.home_second_middle .home_text_h3 {
		width: 400px;
	}

	.home_second_middle .home_left_div_parathree {
		width: 100%;
	}

	.home_second_middle .home_number_three {
		font-size: 200px;
		top: -120px;
	}

	.home_second_middle {
		padding-top: 0;
	}

	.learn_and_earn_section1 h1 .home_number_one {
		font-size: 200px;
		top: -140px;
	}

	.home_second_middle .home_text_h3 {
		width: 330px;
		font-size: 48px;
	}

	.home_second_middle .pink-linethree {
		width: 325px;
	}

	.home_second_middle2 .home_number_four {
		font-size: 200px;
		top: -140px;
	}

	.home_second_middle2 .home_left_div_para4 {
		width: 100%;
	}

	.home_second_middle2 .home_number_four {
		left: 85px;
	}

	.home_second_middle5 .home_text_h5 {
		width: 450px;
		font-size: 50px;
	}

	.home_second_middle5 .home_number_five {
		left: 0;
		top: -135px;
		right: 0;
		font-size: 200px;
	}

	.home_second_middle5 .pink-linefive {
		left: 15px;
		width: 330px;
		height: 20px;
	}

	.home_second_middle5 .home_left_div_parafive {
		width: 100%;
	}

	.learn_and_earn_section5 .home_last_btn {
		width: 63%;
	}

	.home_second_middle2 .home_text_h4freelancer {
		width: 470px;
		font-size: 48px;
	}

	.home_second_middle .home_text_h3freelancer {
		width: 450px;
		font-size: 50px;
	}

	.home_second_middle5 .pink-line-freelancer-5 {
		left: 50px;
		width: 350px;
	}

	.home_second_middle2 .pink-line-freelancer-4 {
		height: 25px !important;
		width: 250px !important;
		top: 35px;
		right: 0;
	}

	.learn_and_earn_section1 .pink-line-freelancer {
		width: 350px;
		top: 39px;
		left: 37px;
		height: 20px;
	}

	.home_second_middle .pink-line-freelancer-3 {
		width: 230px;
		right: 40px;
	}

	.section_three_wrapper .section_three_list {
		width: 100%;
		flex-direction: column;
		margin: 50px auto 0;
	}

	.section_three_wrapper .section_three_list .section_three_item {
		width: 100%;
	}

	.section_three_wrapper .section_three {
		height: 100%;
	}

	.section_three_wrapper .section_three_item_heading {
		min-height: 35px;
	}

	.section_three_wrapper .section_three_info .section_three_para {
		width: 460px;
	}

	.section_three_wrapper .section_three_info .section_three_heading {
		width: 460px;
		text-align: center;
		font-size: 50px;
		line-height: 60px;
		padding-top: 100px;
	}

	.section_three_wrapper .section_three_number {
		font-size: 300px;
		left: 0;
		right: 0;
	}

	.section_three_wrapper .section_three_numberPnik {
		width: 240px;
		bottom: 60px;
	}

	.jobppostbackbutton {
		min-width: 170px !important;
	}

	.jobppostdraftbutton {
		padding: 10px 26px !important;
	}

	.jobppostpostpostbutton {
		padding: 10px 16px !important;
	}

	.service_heading_div {
		width: 470px;
		font-size: 45px;
	}

	.blue_ater_h_min0202 {
		width: 286px;
		top: 35px;
	}

	.service_heading_div_02 {
		font-size: 300px;
		top: -128px;
	}

	.learn_and_earn_section1 .textPageDatatitle6 {
		font-size: 40px;
		width: 470px;
	}

	.pink-line-freelancer-6 {
		width: 256px;
		left: 0;
		bottom: 40px;
	}
}

@media (max-width: 481px) {
	.learn_and_earn_section1 .textPageDatatitle6 {
		font-size: 40px;
		width: 280px;
	}

	.learn_and_earn_section1 .pink-line-freelancer {
		width: 280px;
		top: 30px;
		left: 0;
		height: 20px;
	}

	.responsiveSocialButton button {
		width: 90% !important;
	}

	.toggle_btn_dot {
		width: 35px;
		height: 35px;
	}

	.learn_and_earn_section1 h1 .home_number_one {
		font-size: 200px;
		top: -140px;
	}

	.learn_and_earn_section1 {
		padding: 150px 0 60px 0;
	}

	.home_second_middle .pink-linethree {
		width: 276px !important;
	}

	.home_second_middle .home_text_h3 {
		width: 275px;
		font-size: 40px;
	}

	.home_second_middle2 .home_text_h4 {
		font-size: 30px;
		width: 450px;
	}

	.home_second_middle2 .pink-linefour {
		width: 270px !important;
		left: 135px;
	}

	.home_second_middle2 .home_text_h4 {
		width: 300px;
	}

	.home_second_middle2 .home_number_four {
		left: 0;
		right: 0;
	}

	.home_second_middle2 .pink-linefour {
		width: 167px !important;
		left: 93px;
		height: 20px !important;
	}

	.home_second_middle5 .home_text_h5 {
		font-size: 40px;
		width: 280px;
	}

	.home_second_middle5 .pink-linefive {
		left: 11px;
		width: 212px;
		height: 20px;
		top: 20px;
	}

	.learn_and_earn_section5 .home_last_btn {
		width: 300px;
	}

	.home_top_header .home_left .heading {
		font-size: 37px;
		line-height: 39px;
		width: 300px;
	}

	.learn_and_earn_section1 h1 {
		width: 280px;
		font-size: 40px;
	}

	.section_three_wrapper .section_three_info .section_three_heading {
		width: 300px;
		line-height: 45px;
		font-size: 38px;
	}

	.section_three_wrapper .section_three_number {
		font-size: 230px;
		left: 0;
		top: 120px;
	}

	.section_three_wrapper .section_three_numberPnik {
		width: 300px;
		bottom: -5px;
	}

	.section_three_wrapper .section_three_info .section_three_para {
		width: 300px;
	}

	.service_heading_div {
		width: 300px;
		font-size: 30px;
	}

	.blue_ater_h_min0202 {
		width: 186px;
		top: 22px;
		height: 18px !important;
	}

	.service_heading_div_02 {
		font-size: 215px;
		top: -100px;
	}

	.freelancer_pink::before {
		width: 195px !important;
	}

	.home_second_middle .home_text_h3freelancer {
		width: 280px;
		font-size: 38px;
	}

	.home_second_middle .pink-line-freelancer-3 {
		width: 175px;
		right: 0;
		left: unset;
		height: 20px;
	}

	.home_second_middle2 .home_text_h4freelancer {
		width: 280px;
		font-size: 42px;
	}

	.home_second_middle2 .pink-line-freelancer-4 {
		height: 20px !important;
		width: 220px !important;
		top: 78px;
		left: 32px;
	}

	.home_second_middle5 .pink-line-freelancer-5 {
		left: 0;
		width: 280px;
		height: 20px;
	}

	.pink-line-freelancer-6 {
		height: 20px;
		width: 280px;
		left: 0;
		bottom: 90px;
	}
}
