
.footer {
    background-image: linear-gradient(180deg, #39478f 0, #371779 100%);
    padding: 50px 0 0 0;
    text-shadow: 0 0 10px #371779;
}

.footer-head h3 {
    font-size: 22px;
    letter-spacing: 2px;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
}

.footer_ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;

    li {
        margin: 5px 0;

        a {
            font-size: 13px;
            text-decoration: none;
            line-height: 23px;
            color: #ffffff;
            font-weight: 300;
            display: inline-block;
            position: relative;
            transition: 0.2s;
            z-index: 0;

            &:before {
                content: "";
                display: block;
                top: 0;
                bottom: 0;
                left: -10px;
                right: -10px;
                background: #6f38e187;
                position: absolute;
                opacity: 0;
                transition: 0.2s;
                border-radius: 20px;
                z-index: -1;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

.footer_border {
    border-bottom: #ffffff88 solid 1px;
}

.copy_right_row {
    padding: 20px 0;
    align-items: center;
}

.copy_right_para {
    font-size: 11px;
    line-height: 12px;
    color: #ffffff;
}

.copy_ri_ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
}

.copy_ri_ul li {
    margin-right: 10px;
}

.copy_ri_ul li a {
    font-size: 11px;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    line-height: 0;
}

.social_links a {
    margin: 0 10px;
    color: #fff;
    font-size: 18px;
}