.remove-posting-popup {
	max-width: 500px !important;
}

.show_support_popup {
	max-width: 350px !important;
	border-radius: 20px;
}

.remove-posting-popup h5 {
	color: #6d2ef1;
	font-weight: 600;
}

.popup-radio {
	list-style: none;
	margin: 0;
	padding: 0;
}

.popup-radio label {
	font-size: 15px;
	margin: 5px 0;
	display: flex;
}

.popup-radio input {
	accent-color: #6d2ef1;
	margin-right: 5px;
}

.remove-posting-popup > p {
	font-size: 12px;
	margin-top: 10px;
	color: rgb(128 123 123);
}

.remove-posting-popup > p > span {
	font-size: 12px;
	color: #6d2ef1;
}

.remove-posting-popup .reason-to-remove-posting h6 {
	font-weight: 600;
	font-size: 1.1em;
}

.remove-posting-popup .reason-to-remove-posting .popup-radio label {
	display: flex;
	align-items: center;
}

.remove-posting-popup .reason-to-remove-posting .popup-radio label span {
	margin: 0 4px;
}

.remove-posting-popup .reason-to-remove-posting li {
	font-size: 14px;
	color: #000000;
	padding: 2px 0;
}

.remove-draft-popup .btn_foot_sec {
	padding: 12px 0;
	margin: 0;
}

.remove-draft-popup > p {
	margin: 2rem 0 3rem 0;
}

.remove-posting-popup .remove-posting-btns {
	border: 1px solid rgb(155, 151, 151);
	font-weight: 500;
}

.remove-posting-popup .active_btn_blue {
	font-weight: 500 !important;
}

.close_account_hear {
	font-size: 12px;
	/* margin-top: 10px; */
	color: rgb(128 123 123);
}

/* change passsword */

.change-password-popup {
	width: 600px !important;
}

.popup_wid {
	width: 88%;
}

@media (max-width: 650px) {
	.change-password-popup {
		width: unset !important;
	}
}

.change-password-popup h5 {
	color: #6d2ef1;
	font-weight: 700;
}

.change-password-popup .invite-toJob-wrapper {
	display: flex;
	padding: 20px 0;
}

.change-password-popup .invite-toJob-wrapper .invite-toJob-image {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
}

.change-password-popup .invite-toJob-wrapper .invite-toJob-info {
	margin-top: 10px;
	margin-left: 10px;
	word-break: break-word;
}

.change-password-popup .invite-input-wrapper .invite-heading {
	margin-bottom: 10px;
}

/* .change-password-popup .invite-input-wrapper {
  width: 400px;
} */
.change-password-popup .invite-input-wrapper textarea {
	width: 100%;
	border: 1px solid #9b9696;
	border-radius: 5px;
	outline: none;
	padding: 10px;
	font-size: 14px;
}

.change-password-popup .invite-input-wrapper textarea::placeholder {
	font-size: 14px;
}

.select_control_width {
	/* font-size: 20px; */
}

.option_width {
	word-wrap: break-word;
	/* overflow: hidden;
	width: 200px; */
	/* max-width: 50%; */
	/* font-size: 12px; */
}

@media screen and (max-width: 644px) {
	.change-password-popup {
		min-width: 200px;
	}
}

.change-password-popup .password-inp .inp-label {
	font-size: 14px;
	color: black;
	font-weight: 600;
	padding: 5px 0;
}

.change-password-popup .password-inp .input-grp input {
	outline: none;
	border: 1px solid #cbcaca;
	width: 100%;
	height: 40px;
	margin: 5px 0;
	padding: 3px 38px 3px 10px;
}

.change-password-popup .alert-inp {
	font-size: 12px;
	margin: 5px 0;
	display: flex;
	align-items: center;
	position: relative;
}

.change-password-popup .alert-inp input {
	accent-color: #6d2ef1;
	margin-right: 4px;
}

@media screen and (max-width: 424px) {
	.change-password-popup .alert-inp {
		align-items: flex-start;
	}

	.change-password-popup .alert-inp input {
		margin-top: 3px;
	}
}

.change-password-popup .password-inp .input-grp {
	position: relative;
}

.showInpPass {
	cursor: pointer;
	position: absolute;
	top: 17px;
	right: 10px;
	color: #aaaaaa;
}

.showPassFreelancer {
	cursor: pointer;
	position: absolute;
	top: 42px;
	right: 10px;
	color: #aaaaaa;
}

.react-responsive-modal-closeButton {
	fill: #b2b2b2;
}

.set-varification-popup h5 {
	color: #6d2ef1;
	font-weight: 700;
	font-size: 19px;
}

.set-varification-popup .varification-first {
	margin: 25px 0;
}

.set-varification-popup .varification-first h6 {
	font-size: 16px;
	font-weight: 600;
}

.set-varification-popup .varification-first .popup-radio label {
	display: flex;
	align-items: center;
	margin: 5px 0;
}

.set-varification-popup .varification-first .popup-radio label span {
	margin: 0 10px;
}

.set-varification-popup .varification-first .popup-radio {
	color: #9b9696;
	font-weight: 300;
	font-size: 13px;
}

.set-varification-popup .when-confirm {
	margin: 25px 0 0 0;
}

.set-varification-popup .when-confirm h6 {
	font-size: 16px;
	font-weight: 600;
}

.set-varification-popup .when-confirm .popup-radio {
	color: #535252;
	font-weight: 500;
	font-size: 13px;
}

@media screen and (max-width: 426px) {
	.set-varification-popup .when-confirm .popup-radio label {
		align-items: flex-start !important;
	}

	.set-varification-popup .when-confirm input {
		margin-top: 3px !important;
	}
}

.set-varification-popup .when-confirm .popup-radio label {
	display: flex;
	align-items: center;
	margin: 5px 0;
}

.set-varification-popup .when-confirm .popup-radio label span {
	margin: 0 5px;
}

.team_name label {
	display: flex;
	flex-direction: column;
	font-weight: 700;
	font-size: 15px;
}

.team_name label > input {
	outline: none;
	border: 1px solid #cbcaca;
	width: 100%;
	height: 42px;
	margin: 5px 0;
	padding: 3px 38px 3px 10px;
}

.create_new_team_popup h5 {
	color: #6d2ef1;
	font-weight: 600;
}

.create_new_team_popup > p {
	font-size: 12px;
	font-weight: 500;
	color: rgb(128 123 123);
	margin: 20px 0;
}

.create_new_team_popup > p > span {
	font-size: 12px;
	color: #6d2ef1;
}

.edit_team_popup h5 {
	color: #6d2ef1;
	font-weight: 600;
}

.edit_team_popup label {
	margin: 20px 0;
}

/* invite user */
.invite_user_popup {
	padding: 30px;
}

.invite_user_popup h5 {
	color: #6d2ef1;
	font-weight: 700;
	font-size: 25px !important;
}

.invite_user_popup .invite_users {
	margin: 20px 0;
}

.invite_user_popup .invite_users > label {
	display: flex;
	flex-direction: column;
}

.invite_user_popup .invite_users > label span {
	font-weight: 600;
	font-size: 17px;
}

.invite_user_popup .invite_users h6 {
	font-weight: 600;
	font-size: 17px;
}

.invite_user_popup .invite_users > label input {
	outline: none;
	border: 1px solid #cbcaca;
	width: 100%;
	height: 42px;
	margin: 5px 0;
	padding: 3px 38px 3px 10px;
}

.invite_user_popup .invite_users > label textarea {
	resize: none;
	outline: none;
	border: 1px solid #cbcaca;
	width: 100%;
	height: 90px;
	margin: 5px 0;
	padding: 3px 38px 3px 10px;
}

.invite_user_popup .invite_users p {
	font-size: 12px;
	font-weight: 400;
	color: rgb(121 121 121);
	margin: 0;
}

.invite_user_popup .invite_users li p {
	margin: 0 0 0 18px;
}

.invite_user_popup .css-1okebmr-indicatorSeparator {
	display: none;
}

.invite_user_popup .custom_css_select div {
	border: none !important;
	border-radius: 2px !important;
}

.invite_user_popup .custom_css_select {
	border: #cbcaca solid 1px !important;
	border-radius: 2px !important;
}

.intro_video_popup {
	padding: 1.2rem;
}

@media screen and (min-width: 768px) {
	.intro_video_popup {
		min-width: 700px;
		height: calc(56.6% + 50px);
	}

	.intro_video_popup .intro_video {
		width: 100%;
		height: calc(100% - 47px);
	}

	.intro_video_popup .intro_video iframe {
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 768px) {
	.intro_video_popup {
		width: auto;
		height: calc(56.6% + 50px);
	}

	.intro_video_popup .intro_video {
		width: 100%;
		height: calc(100% - 47px);
	}

	.intro_video_popup .intro_video iframe {
		width: 100%;
		height: 100%;
	}
}

.confirm_close_popup {
	font-size: 16px;
	margin-top: 20px;
	color: rgb(128 123 123);
}

.confirm_close_popup .take_me_support {
	color: #6d2ef1;
	font-size: 16px;
	font-weight: 500;
}

.promoted_jobs_popup {
	max-width: 950px !important;
	border-radius: 20px;
	padding: 0 !important;
	overflow: hidden;
}

.promoted_left {
	background: rgb(253 179 181);
	padding: 20px 30px 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.promoted_left .boost_job_cont {
	font-size: 26px;
	font-weight: 600;
	color: #fff;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 10px;
}

.promoted_left .learn_prom {
	font-size: 16px;
	font-weight: 600;
	color: #6d2ef1;
	padding-top: 10px;
	text-align: center;
}

.promoted_left .prom_img {
	text-align: center;
}

.promoted_right {
	background: #fff;
	padding: 50px 30px 30px 18px;
}

.upgrade_p_t {
	font-size: 22px;
	font-weight: 600;
	padding: 0;
}

.promt_for {
	font-size: 14px;
	font-weight: 500;
	padding-bottom: 5px;
}

.invite_bold {
	font-size: 16px;
	font-weight: 500;
}

.promoted_sub {
	list-style: none;
	margin: 0;
	padding: 0;
}

.promoted_sub li {
	display: flex;
	align-items: flex-start;
	padding: 5px 0;
}

.promoted_sub li .fa-check {
	margin-top: 5px;
	color: #6d2ef1;
	font-size: 20px;
	padding-right: 10px;
}

.promted_method_btn {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
	padding: 0 25px 0 0;
}

.promted_method_btn .add_payment {
	background: #6d2ef1;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	padding: 8px 12px;
	border: none;
	border-radius: 30px;
}

.promted_method_btn .close_promted_btn {
	border: 1px solid #6d2ef1;
	font-size: 16px;
	font-weight: 500;
	color: #6d2ef1;
	background: #fff;
	border-radius: 31px;
	padding: 8px 12px;
}

.checkbox_pro_box {
	padding-top: 30px;
	padding-bottom: 70px;
	font-size: 16px;
	border-top: 1px solid gray;
	margin-top: 50px;
}

.che_prom_job {
	margin-right: 5px;
}

.checkbox_pro_box .terms_ch {
	color: #6d2ef1;
	font-weight: 500;
}

/* .promoted_sub li:before {
  content: "✓";
  color: #6d2ef1;
  padding-right: 10px;
  font-weight: 900;
  font-size: 18px;
} */

.check_payment_popup {
	max-width: 350px !important;
	border-radius: 20px;
}

.check_payment_popup .react-responsive-modal-closeButton {
	display: none;
}

.payment_method_btn {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
	padding-top: 10px;
}

.payment_method_btn .add_payment {
	background: #6d2ef1;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	padding: 8px 8px;
	border: none;
	border-radius: 8px;
}

.addPaymentDis {
	background: #d4d4d5;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	padding: 8px 8px;
	border: none;
	border-radius: 30px;
}

.payment_method_btn .close_payment_btn {
	border: 1px solid #6d2ef1;
	font-size: 16px;
	font-weight: 500;
	color: #6d2ef1;
	background: #fff;
	border-radius: 8px;
	padding: 8px 8px;
}

.payment_check_cont {
	font-weight: 400;
	color: #000;
	margin-top: 5px;
}

.close_acc_resoan_box {
	width: 100%;
}

.close_acc_resoan_box textarea {
	width: 100%;
	padding: 10px;
	outline: none;
	font-size: 14px;
}
