.pd_head_h1 h1 {
	font-size: 25px;
}

.wn_freelancer {
	font-size: 13px;
}

.pd_evf_n {
	font-size: 18px;
	line-height: 21px;
}

.pd_n_blpara p {
	font-size: 13px;
	line-height: 20px;
}

.pad_n_pdd {
	padding: 13px 20px !important;
}

.pad_n_pdd a:hover {
	color: white !important;
}

.pd_n_bh h3 {
	font-size: 22px;
}

.pd_n_bss .b_skil {
	font-size: 12px;
	padding: 11px 17px;
}

.pd_n_vbbtn button {
	padding: 11px 20px;
	font-size: 13px;
}

.pd_n_wii svg {
	height: 90px;
	width: 90px;
}

.pd_n_fbspro button {
	padding: 14px 20px;
}

.ps-relative {
	position: relative;
}

.ipdlar_sign {
	position: absolute;
	right: 10px;
	font-size: 17px;
	top: 7px;
	font-weight: 700;
	color: #6d2ef1;
}

.pd_n_sendp {
	min-width: 170px;
	font-size: 13px !important;
}

.price_ar_jjob h1 {
	color: #170048 !important;
}

.price_ar_jjob p {
	color: #b9b9b9 !important;
}

.propo_box {
	border-radius: 7px;
}

.pro_cli_sm_te {
	font-size: 11px;
}

.pd_par_for p {
	font-size: 13px;
	line-height: 19px;
}

.agree_term_b input {
	height: 18px;
	width: 18px;
}

.ps_n_sef h3 {
	font-size: 18px !important;
}

.ps_n_sefp p {
	font-size: 11px !important;
}

.s_na_h4 h4 {
	font-size: 14px !important;
	line-height: 20px;
}

.s_na_inpu input,
.s_na_inpu select {
	font-size: 12px !important;
}

.s_na_categ label {
	font-size: 11px !important;
}

.s_na_categ input {
	height: 15px;
	width: 15px;
}

.s_na_categ {
	align-items: center;
}

.uf_n_abh h1 {
	font-size: 40px !important;
}

.uf_n_msh h3 {
	font-size: 25px !important;
}

.uf_dmpn {
	font-size: 15px !important;
	line-height: 20px !important;
}

.pt_num {
	font-size: 21px !important;
}

.pt_n1 {
	color: #0777fd !important;
}

.pt_n2 {
	color: #8d7b9b !important;
}

.pt_n3 {
	color: #f66c6c !important;
}

.pt_n4 {
	color: #6cd3db !important;
}

.my_skil_head h3 {
	line-height: 20px !important;
}

.border_bx_sec {
	padding: 0 25px !important;
}

.agencyborder_bx_sec {
	padding: 0 10px !important;
}

.no_sudo:after {
	display: none !important;
}

.no_sudo:before {
	display: none !important;
}

.bg_wrapper_popup_new {
	position: fixed;
	height: 100%;
	width: 100%;
	background: #000000a1;
	top: 0;
	right: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup_box_bpn {
	max-width: 501px;
	width: 95%;
	padding: 10px 30px;
	background: #ffffff 0 0 no-repeat padding-box;
	opacity: 1;
	min-height: 200px;
	max-height: 95vh;
	overflow-y: scroll;
}

.popup_box_bpn::-webkit-scrollbar {
	display: none;
}

@media only screen and (max-width: 767px) {
	.popup_box_bpn {
		width: 100%;
		height: 100%;
		max-height: none;
	}
}


.transparant_box {
	opacity: 0.8;
}

.popup_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
}

.p_header_hding {
	color: #6d2ef1;
	font-weight: bold;
	font-size: 22px;
}

.securityPopupSubHead {
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0;
	color: #393939;
	padding-top: 10px;
}

.securityPopup2ndHead {
	color: #271943;
	font-size: 18px;
	font-weight: 500;
}

.securityHeading {
	color: #271943;
	font-size: 14px;
}

@media screen and (max-width: 425px) {
	.securityRespFirstAns .popup_form_element {
		width: 100%;
	}
}

.close_pp_btn {
	height: 25px;
	width: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	-webkit-border-radius: 50%;
}

.close_pp_btn svg {
	fill: #b2b2b2;
}

.close_pp_btn:hover {
	cursor: pointer;
}

.popup_form_element {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.popup_form_element label {
	text-align: left;
	font-weight: bold;
	font-size: 15px;
	line-height: 35px;
	color: #828282;
}

.popup_form_element input,
.popup_form_element textarea,
.popup_form_element select {
	border: 2px solid rgb(235, 235, 235);
	border-radius: 5px;
	outline: none;
	resize: none;
	padding: 10px 10px;
}
.popup_form_element textarea:focus,
.popup_form_element input:focus {
	border: 2px solid rgb(109, 46, 241);
}

.popup_form_element > select,
.popup_form_element > input {
	height: 40px;
}

.popup_form_element > .language_sel {
	height: 40px;
	color: #1e1e1e;
	border: 1px solid #e8e7e7 !important;
	border-radius: 3px;
	width: 100%;
	outline: none;
	padding: 7px 20px;
	-webkit-border-radius: 3px;
}

.popup_form_element select {
	color: rgb(105, 105, 105);
	border: 2px solid #e8e7e7;
	font-size: 13px;
	padding: 0 20px 0 10px;
}

.popup_form_element select option {
	max-height: 20px !important;
}

.font-weight-400 {
	font-weight: 400 !important;
}

.popup_form_element textarea {
	height: 100px;
}

.langPopupHeight {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.langPopupHeight::-webkit-scrollbar {
	width: 10px;
}

.langPopupHeight::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.langPopupHeight::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	/* outline: 1px solid slategrey; */
	border-radius: 10px;
	border: 1px solid darkgrey;
	box-shadow: inset 0 0 6px rgba(101, 101, 101, 0.3);
}

.langPopupWidth {
	margin-right: 15px;
}

@media screen and (max-width: 767px) and (min-width: 482px) {
	.col-sm-9 {
		flex: 0 0 auto;
		width: 64%;
	}

	.col-sm-3 {
		flex: 0 0 auto;
		width: 36%;
	}
}

/* @media screen and (max-width: 320px) {
  .col-sm-9 {
    flex: 0 0 auto;
    width: 62%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 36%;
  }
} */

@media screen and (max-width: 425px) {
	.popup_form_element {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		width: 56%;
		/* padding: 10px; */
	}

	.startIntroIn h5 {
		color: #00065a;
		font-weight: 700;
		text-align: center;
		font-size: 25px;
		line-height: 32px;
	}
}

@media screen and (max-width: 375px) {
	.popup_form_element {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		width: 100%;
	}

	.startIntroIn h5 {
		color: #00065a;
		font-weight: 700;
		text-align: center;
		font-size: 23px;
		line-height: 32px;
	}
}

@media screen and (max-width: 425px) and (min-width: 320px) {
	.popup_form_element {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		width: 46%;
	}

	.popup_form_element {
		width: 100%;
	}

	.whatdoyoudo .heading {
		font-size: 20px !important;
	}
}

._profile_overview textarea {
	resize: none;
	padding: 5px !important;
	font-size: 12px;
	color: #4d4d4d;
	text-align: justify;
	font-weight: 400;
}

._profile_overview textarea::-webkit-scrollbar {
	display: none;
}

._profile_overview textarea::placeholder {
	font-size: 12px;
	color: #4d4d4d;
	text-align: justify;
	font-weight: 400;
}

.popup_radio_element {
	display: flex;
	align-items: center;
	color: #393939 !important;
	padding: 0 20px 0 0;
}

.popup_radio_element input {
	height: 18px;
	margin-right: 10px;
	width: 18px;
	margin-top: 12px;
}

.popup_btns_new {
	display: flex;
	padding: 10px 0 0 0;
	justify-content: space-between;
}

.popup_btns_new button {
	background-color: #6d2ef1;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	border-radius: 60px;
	text-transform: uppercase;
	padding: 10px 45px;
	margin-top: 5px;
	min-width: 140px;
	border: #6d2ef1 solid 1px;
}

@media screen and (max-width: 321px) {
	.popup_btns_new button {
		padding: 10px 30px;
	}
}

/* .popup_btns_new button:hover {
  background-color: #fff;
  color: #6d2ef1;
} */
.blue_btncb {
	background-color: #6d2ef1 !important;
	color: #fff !important;
}

/* .btnhovpple:hover {
  background-color: #6d2ef1 !important;
  color: #fff !important;
  border-color: #6d2ef1 !important;
} */
.btnhovpple:disabled {
	background-color: #e4ebe4 !important;
	color: #9aaa97 !important;
	cursor: not-allowed;
	border: none !important;
	min-width: 200px;
	padding: 15px 0;
}

.hov_whitepple:hover {
	background-color: #fff !important;
	color: #6d2ef1 !important;
	border-color: #6d2ef1 !important;
}

.popup_btns_new button:disabled {
	background-color: #b1b1b1;
	color: #fff;
	font-size: 14px !important;
	font-weight: 600;
	border-radius: 60px !important;
	text-transform: uppercase;
	padding: 10px 45px;
	margin-top: 5px;
	min-width: 140px;
	border: none;
}

.popup_btns_new .trans_btn {
	background-color: transparent !important;
	color: #6d2ef1;
}

.mile_sutone_amoun {
	padding: 15px 0;
	padding-bottom: 0 !important;
	display: flex;
	align-items: center;
	width: 60%;
	margin-left: auto;
}

.edit_certificate_frln {
	width: 60%;
}

.edit_certificate_frln img {
	max-width: 100%;
}

/* .mile_sutone_amoun input::placeholder {
  text-align: center;
} */

.mile_sutone_amoun h2 {
	text-align: center;
	margin: 0;
	font-weight: bold;
	font-size: 50px;
	line-height: 50px;
	color: #1f1f1f;
}

.add_miestone_lbel {
	color: #6d2ef1 !important;
	font-size: 13px !important;
	font-weight: bold !important;
	display: flex;
	align-items: center;
}

.total_box_oioup {
	padding: 15px 0;
	border-top: #e8e7e7 solid 1px;
	margin-top: 15px;
	padding-bottom: 0 !important;
}

.flex_jus_cen_new {
	display: flex;
	justify-content: space-between;
}

.free_unofy_smtext {
	font-size: 15px;
	line-height: 30px;
	color: #828282;
}

.yr_rve_at_ltext {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #393939;
}

.amount_popup_body .popup_form_element {
	margin-top: 5px !important;
}

.addMoreLanguageBtn {
	font-weight: 500;
	text-transform: uppercase;
	padding: 5px 5px;
	margin-top: 5px;
	background-color: #6d2ef1;
	color: #ffffff;
	min-width: 100px;
	font-size: 12px;
	margin-right: 15px;
	border: #6d2ef1 solid 1px;
	border-radius: 20px;
}

.addMoreLanguageBtn[disabled] {
	border: none;
	color: #fff;
	background-color: #a7a6aa;
}

.freelancer_all_contract {
	font-size: 27px !important;
	color: #000 !important;
	font-weight: 600 !important;
}

.text-transform-uppercase {
	text-transform: uppercase !important;
}

.hire_box_yh {
	position: relative;
}

.hire_box_yh .sub_radio {
	top: 20px;
}

.dirc_hourly_container .dir_hor_contr {
	display: flex;
	justify-content: space-between;
	padding: 30px 0 10px 0;
	border-top: 1px solid #b2b2b2;
	margin-top: 25px;
}

.dirc_hourly_container .mile_sutone_amoun_hr {
	padding-bottom: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dirc_hourly_container .hourl_amnt {
	font-size: 18px;
	font-weight: 500;
}

.dirc_hourly_container .hourly_client_depo {
	border-top: 1px solid #b2b2b2;
	padding-top: 25px;
	margin-top: 20px;
}

.dirc_hourly_container .client_hou {
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 5px;
}

.dirc_hourly_container .client_hou i {
	color: #6d2ef1;
	font-weight: 500;
}

.hour_pencil i {
	color: #6d2ef1;
}

.dirc_hourly_container .dir_hour_co {
	color: #b2b2b2;
	font-weight: 400;
}

.dirc_hourly_container .client_depo_direct {
	border-bottom: 1px solid #b2b2b2;
	padding-top: 20px;
	padding-bottom: 40px;
}

.client_depo_direct .client_depo_head {
	font-size: 18px;
	font-weight: 500;
}

.client_depo_direct .dir_cla_rup {
	text-align: center;
	border: 1px solid #b2b2b2;
	margin-top: 10px;
	padding: 20px;
}

.client_depo_direct .total_hr {
	font-size: 22px;
	font-weight: 500;
	border-bottom: 1px solid #b2b2b2;
	padding-bottom: 10px;
}

.client_depo_direct .calult_hour_am {
	font-size: 16px;
	font-weight: 500;
	padding-top: 15px;
}

.hourly_client_end_date {
	border-bottom: 1px solid #b2b2b2;
	padding: 25px 0;
}

.dir_hor_tim_log {
	padding-top: 25px;
	border-bottom: 1px solid #b2b2b2;
}

.dir_hor_tim_log .time_log {
	font-size: 16px;
	font-weight: 500;
}

.specif_date_dir input {
	padding: 3px 6px;
}

.revi_hourly_client_depo {
	border-top: 1px solid #b2b2b2;
	padding-top: 20px;
	margin-top: 25px;
	/* padding-bottom: 20px; */
}

.revi_hourly_client_depo .client_hou {
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 5px;
}

.revi_hourly_client_depo .client_hou i {
	color: #6d2ef1;
	font-weight: 500;
}

.revi_hourly_client_depo .unify_pay {
	color: #6d2ef1;
	font-weight: 500;
}

.hourl_dir_cbox {
	width: 100%;
}

.hourl_dir_cbox .hourl_dir {
	width: 100%;
}

.hourl_dir_cbox .horly_inp {
	width: 50%;
}

.new_hbyh {
	min-height: 250px !important;
	margin-top: 20px;
	/* margin-bottom: 20px; */
}

.skip_question_btn_frel {
	display: flex;
	justify-content: right;
	align-items: center;
	padding: 20px 0;
}

.skip_question_btn_frel button {
	color: #6d2ef1;
	border-radius: 0 !important;
	box-shadow: none;
	border: none;
	background-color: transparent;
	font-weight: 800;
	font-size: 20px;
	line-height: 30px;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
}

.pst_earning_hdd_free {
	font-size: 23px !important;
	color: #000 !important;
	font-weight: 400 !important;
	line-height: 30px;
}

.pst_earning_hdd_free span {
	color: #6d2ef1;
	font-weight: 500 !important;
}

.color_gray {
	height: 29px !important;
	width: 29px !important;
	border: #707070 solid 1px !important;
}

.color_gray svg {
	fill: #707070 !important;
}

.border-r-7 {
	border-radius: 7px;
}

.form_goals {
	margin: 20px 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.form_goals .formg_label {
	min-width: 200px;
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0;
	color: #0e1f3e;
}

.form_goals .formg_inp {
	width: calc(100% - 200px);
}

@media only screen and (max-width: 550px) {
	.form_goals .formg_inp {
		width: 100% !important;
	}
}

.form_goals .formg_inp input,
.form_goals .formg_inp select {
	padding: 9px 20px;
	border: 1px solid #e8e7e7;
	width: 100%;
	font-size: 14px;
	line-height: 26px;
	color: #b7b0b0;
	outline: none;
}

.form_goals .formg_inp label input {
	width: 14px;
	height: 14px;
	margin: 0 10px 1px 0;
}

.iclosed_ctract {
	margin-left: 30px;
	text-indent: 7px;
	font-size: 14px !important;
}

@media screen and (max-width: 1024px) {
	.iclosed_ctract.all_ctrct {
		margin-left: 0;
	}
}

.form_goals .formg_inp label {
	font-weight: 600;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: 0;
	color: #0e1f3e;
	margin-right: 15px;
	display: flex;
	align-items: center;
}

.form_goals .half_width_fi {
	max-width: 40% !important;
}

.transaction_head_n {
	border-bottom: 1px solid #e8e7e7;
	padding-bottom: 30px;
}

.mjh_new_smtext {
	font-size: 26px !important;
	padding-bottom: 10px;
	color: #000 !important;
	font-weight: 600 !important;
	line-height: 35px;
}

.only_textstyle {
	border: none !important;
	background-color: transparent !important;
	color: #6d2ef1 !important;
}

.only_textstyle svg {
	fill: #6d2ef1 !important;
}

.trx_tbl_freelancer thead {
	border-color: transparent !important;
}

.trx_tbl_freelancer thead tr th {
	color: #1f1f1f !important;
	text-align: center !important;
	font-size: 16px !important;
	line-height: 26px !important;
}

.trx_tbl_freelancer tbody tr {
	height: 60px;
}

.trx_tbl_freelancer tbody tr td {
	color: #847777 !important;
	margin-top: 5px;
	text-align: center !important;
	font-size: 16px !important;
	line-height: 26px !important;
}

.trx_tbl_freelancer tbody tr .tableData {
	width: 40%;
	word-break: break-word;
}

.trx_tbl_freelancer tbody tr td span {
	color: #6d2ef1;
}

.table > :not(caption) > * > * {
	padding: 0.7rem 0.5rem !important;
}

.awd_label {
	font-size: 14px;
	line-height: 0;
	color: #828282;
}

.overv_sm_thead {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #1f1f1f;
	padding-bottom: 5px;
}

.overv_number {
	font-size: 20px;
	font-weight: 800;
	line-height: 20px;
	letter-spacing: 0;
	color: #1f1f1f;
}

.box_overv_new {
	padding: 25px 20px;
	width: 200px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.box_overv_new.active {
	border-bottom: #6d2ef1 solid 4px;
}

@media screen and (max-width: 768px) {
	.box_overv_new {
		padding: 10px 10px;
	}

	.overv_sm_thead {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}
}

@media screen and (max-width: 425px) {
	.box_overv_new {
		padding: 10px 10px;
		min-width: 170px;
	}
}

@media screen and (max-width: 375px) {
	.box_overv_new {
		padding: 10px 10px;
		min-width: 170px;
	}
}

@media screen and (min-width: 1440px) {
	.overv_sm_thead {
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		color: #1f1f1f;
		padding-bottom: 5px;
	}
}

.overv_lst_nme {
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 0;
	color: #1f1f1f;
}

.progress_box_frl img {
	height: 100%;
	width: 100%;
}

.pd_progress {
	padding-left: 22px;
}

.report_tbl_frl {
	width: 100%;
}

.report_tbl_frl thead {
	border-top: #e2e2e2 solid 1px;
}

.single {
	right: 10px !important;
}

.daterangepicker.opensright:before {
	display: none !important;
}

.daterangepicker.opensright::after {
	display: none !important;
}

.report_tbl_frl thead tr th {
	padding: 12px 12px;
	color: #1f1f1f;
	font-weight: 600;
	font-size: 15px !important;
	line-height: 26px !important;
	border-right: #e2e2e2 solid 1px;
}

.report_tbl_frl tbody tr {
	border-bottom: #e2e2e2 solid 1px;
}

.filter_svg_btnwni {
	padding-top: 11px !important;
	padding-bottom: 11px !important;
}

.filter_svg_btnwni svg {
	fill: #fff;
	margin-right: 10px;
}

.report_tbl_frl tbody tr td {
	padding: 18px 20px;
	color: #1f1f1f;
	font-size: 15px;
	line-height: 26px;
	border-right: #e2e2e2 solid 1px;
}

.amount_tr_frl {
	font-weight: 600 !important;
	font-size: 15px !important;
	line-height: 26px !important;
}

.table_label {
	background-color: #f9f9f9;
	padding: 12px 0;
	color: #1f1f1f;
	font-weight: 600 !important;
	font-size: 15px !important;
	line-height: 26px !important;
}

.p_my_report32 {
	padding-left: 30px;
	padding-right: 30px;
}

.tbl_profile_frl {
	height: 40px;
	width: 40px;
	overflow: hidden;
	margin-right: 10px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.tbl_profile_frl img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.tbl_pfrl_name {
	color: #000;
	font-weight: 600;
	font-size: 13px !important;
	line-height: 26px !important;
}

.tb_prl_des_res {
	color: #000;
	font-size: 12px;
	line-height: 13px;
}

.me_header_sections {
	padding: 50px 0 30px 0;
}

.me_hed_h2_new {
	display: flex;
}

.me_hed_h2_new h2 {
	font-weight: 600;
	font-size: 25px;
	line-height: 34px !important;
	margin-bottom: 0;
	word-break: break-word;
	margin-right: 10px;
}

.me_hed_verif_stus {
	font-size: 14px !important;
	line-height: 20px !important;
	color: #324047;
}

.me_hed_verif_stus svg {
	fill: #6d2ef1;
	margin-right: 7px;
}

.check_box_single_offer {
	display: flex;
	align-items: center;
	gap: 8px;
	color: #324047;
}

.check_box_single_offer svg {
	fill: #6d2ef1;
	/* margin-right: 7px; */
}

@media screen and (max-width: 425px) {
	.me_hed_verif_stus {
		margin: 10px 0;
	}

	.me_hed_h2_new h2 {
		font-weight: 600;
		font-size: 21px !important;
		line-height: 26px !important;
		margin-bottom: 5px;
	}
}

.prof_round_me {
	height: 55px;
	width: 55px;
	margin-right: 10px;
	overflow: hidden;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.prof_round_me img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.prof_name_ne {
	font-weight: 600;
	font-size: 17px !important;
	line-height: 20px !important;
}

.prof_sm_me {
	font-size: 13px !important;
	line-height: 20px !important;
	color: #697a7e;
}

.box_gray_ml_ncw {
	background-color: #f8f8f8;
	padding: 20px 25px;
	min-width: 200px;
	margin: 20px 0;
}

.name_bgmn {
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 20px !important;
	color: #1f1f1f;
}

.amount_bgmn {
	font-weight: 600;
	font-size: 23px;
	line-height: 22px !important;
	color: #1f1f1f;
	padding: 10px 0 0 0;
}

.blue_bgmln {
	background-color: #6d2ef1;
}

.blue_bgmln .amount_bgmn,
.blue_bgmln .name_bgmn {
	color: #fff !important;
}

.milestone_nbdr_tl thead {
	border-top: 0 !important;
	border-bottom: #d8d8d8 solid 1px;
}

.milestone_nbdr_tl thead th {
	padding-left: 5px !important;
}

.tg_mile_ml_new {
	padding: 20px 0;
	align-items: center;
	border-top: #d8d8d8 solid 1px;
	border-bottom: #d8d8d8 solid 1px;
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 22px !important;
}

.tg_mile_ml_new p {
	margin-bottom: 0;
}

.ad_earning-node {
	font-weight: 500 !important;
	font-size: 18px !important;
	line-height: 22px !important;
}

.adtime_txtccwd {
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 22px !important;
}

.adtime_txtccwd .toggle_btn_dot {
	margin-left: 15px;
	cursor: pointer;
}

.mileStone_daterangepicker {
	top: 617px !important;
}

.no_txn_box_nwdi {
	padding: 13px 0;
	text-align: center;
	font-size: 14px !important;
	line-height: 22px !important;
	color: #1f1f1f;
	border-bottom: #d8d8d8 solid 1px;
}

.bg-gray_inp {
	background-color: #fafafa !important;
	width: 100% !important;
	max-width: 100% !important;
}

.mess_dy_albel {
	margin: 15px 0;
	width: 100%;
	color: #697a7e;
	border: #d8d8d8 solid 1px;
	font-size: 14px !important;
	line-height: 22px !important;
	padding: 10px 20px;
}

.mess_box_cw {
	padding: 20px 0;
	display: flex;
}

.mess_prof_cw {
	height: 50px;
	width: 50px;
	overflow: hidden;
	margin-right: 15px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.mess_prf_name {
	font-weight: 600;
	font-size: 17px;
	line-height: 24px !important;
	color: #1f1f1f;
}

.wmn__50px {
	width: calc(100% - 50px);
	font-size: 13px !important;
	line-height: 22px !important;
	color: #192326;
}

.mess_prof_cw img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.mess_footer_cm {
	width: 100%;
	display: flex;
	margin: 20px 0;
	justify-content: space-between;
}

.link_btn_mfc {
	background-color: transparent;
	border: none !important;
	outline: none;
	height: 50px;
	width: 50px;
}

.link_btn_mfc svg {
	height: 35px;
	width: 35px;
}

.mess_footer_cm input {
	background-color: #f6f6f6;
	border: #bbbbbb solid 1px;
	font-size: 14px !important;
	line-height: 22px !important;
	padding: 15px 20px;
	width: 80%;
	margin: 0 10px;
}

.send_btn_mfc {
	background-color: #6d2ef1;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	color: #fff;
	padding: 10px 30px;
	border: #6d2ef1 solid 1px;
}

.attact_file_inp {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.mess_files_g_bx {
	background-color: #f9f9f9;
	padding: 20px 0;
	margin-top: 15px;
}

.mfgb_header {
	font-weight: 600 !important;
	font-size: 17px !important;
	line-height: 22px !important;
	padding: 0 20px;
	margin-bottom: 15px;
}

.file_bx_mfgb {
	border: #e9e9e9 solid 1px;
	font-size: 14px !important;
	line-height: 22px !important;
	padding: 13px 20px;
}

.fiel_con {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	background-color: #f0ecf9;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.time_btm_mfgb {
	display: flex;
	align-items: flex-end;
	font-size: 12px;
	line-height: 22px;
}

.font_wcfw_600 {
	font-weight: 600 !important;
}

.f_scew_12 {
	font-size: 13px;
}

.bbottome_none {
	border-bottom: none !important;
}

.trm_hdn {
	font-weight: 700 !important;
	font-size: 22px !important;
	line-height: 35px !important;
	padding: 10px 0;
}

.trm_desm_txt {
	font-size: 14px !important;
	line-height: 22px !important;
	color: #3f4548;
}

.c_info_bx_nexa {
	background-color: #f6f6f6;
	padding: 20px 20px;
	margin: 55px 0;
	border: #e2e2e2 solid 1px;
}

.cib_hde {
	font-weight: 600 !important;
	font-size: 18px !important;
	line-height: 35px !important;
}

.fe_scets_tx {
	padding: 25px 0;
	border-bottom: #d8d8d8 solid 1px;
}

.fe_scets_smtx h2 {
	font-weight: 600 !important;
	font-size: 21px !important;
	line-height: 30px !important;
}

.fe_scets_tx h2 {
	font-weight: 600;
	font-size: 23px;
	line-height: 30px;
	margin: 0;
}

.fesm_cea {
	font-size: 14px;
	line-height: 30px;
}

.ci_td_list {
	position: relative;
	margin: 10px 20px 10px 0;
	padding-left: 15px;
	font-size: 14px !important;
	line-height: 35px !important;
}

.ci_td_list:before {
	content: " ";
	background-color: #6d2ef1;
	height: 10px;
	width: 10px;
	top: 12px;
	position: absolute;
	left: 0;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.ci_td_list b {
	margin-right: 10px;
}

.f_new_contact_info {
	font-weight: 500 !important;
	font-size: 20px !important;
	padding-bottom: 5px !important;
}

.font-color-light {
	color: #393939 !important;
	font-weight: 300 !important;
}

.trans_bg_btns_paym {
	display: flex;
}

.trans_bg_btns_paym button {
	color: #393939 !important;
	background-color: transparent;
	border: none !important;
	margin: 0 10px;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 35px !important;
}

.label_cnwy {
	font-size: 15px;
	line-height: 17px !important;
	color: #393939;
	min-height: 30px;
}

.sbh_sm_cewouwun {
	font-size: 14px !important;
	line-height: 35px !important;
	color: #393939;
}

.ccewotcy {
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 35px !important;
}

.head_mystat_h2 h2 {
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 35px !important;
}

.head_mystat_js {
	font-weight: 500;
	font-size: 17px !important;
	line-height: 22px !important;
}

.mystst_sm_ceq {
	font-size: 15px !important;
	line-height: 35px !important;
}

.h_tr_pls_ncew,
.mys_stst_flex {
	display: flex;
	margin: 5px 0;
	align-items: center;
	flex-wrap: wrap;
}

.mys_stst_flex {
	font-size: 14px;
	line-height: 30px;
	color: #1f1f1f;
}

.mstat_lbel {
	min-width: 280px;
}

.lne_height_stst_nrm {
	line-height: 24px !important;
}

.mystst_sm_ceq svg {
	margin-right: 8px;
}

.be_hed_s_ew {
	font-size: 14px;
	line-height: 30px;
	color: #1f1f1f;
}

.be_hed_s_ew a {
	text-decoration: none;
	color: #6d2ef1;
}

.btn_group_be {
	display: flex;
	justify-content: center;
}

.btn_group_be button {
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	border: none;
	padding: 8px 20px;
}

/* .btn_group_be button:hover {
  background-color: #fff;
} */

.active_btn_be {
	background-color: #6d2ef1;
	color: #fff;
}

.bxo_be_nac {
	padding: 15px 0 15px 10px;
	border-bottom: #f9f6ff solid 1px;
	cursor: pointer;
	width: 100%;
}

.bill_earn_tbl thead {
	border: 0 !important;
	border-bottom: #e2e2e2 solid 1px !important;
}

.w-40 {
	width: 40% !important;
}

.rtcmwou_be tbody tr td {
	padding: 12px 18px 25px !important;
}

.active_bxo_nxc {
	border-right: #6d2ef1 solid 5px;
	background-color: #f9f6ff;
}

.active_bxo_nxc .bxo_be_nam {
	color: #6d2ef1;
}

.bxo_be_nam {
	color: #000;
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 17px;
	line-height: 22px;
}

.bxo_be_pri {
	font-weight: 500;
	font-size: 17px;
	line-height: 22px;
	color: #000;
}

.st_bgbe {
	border-radius: 6px 0 0 6px;
}

.sec_bgbe {
	border-radius: 0 6px 6px 0;
}

.h_tr_pls_ncew svg {
	margin-right: 5px;
	height: 20px;
}

.h_tr_pls_ncew {
	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	text-transform: uppercase;
}

.success_progrss_msts {
	height: 5px;
	position: relative;
	width: 100%;
	background-color: #e6e6e6;
}

.success_progrss_msts:before {
	content: " ";
	width: 80%;
	height: 100%;
	position: absolute;
	left: 0;
	background-color: #6d2ef1;
}

.select_fex_lbel {
	display: flex;
	flex-direction: column;
	margin: 20px 0;
}

.select_fex_lbelx {
	width: 47%;
	margin: 0 10px 0 0;
}

.select_cscew {
	margin-right: 10px;
	border: 1px solid #dfdfdf;
	border-radius: 3px;
	font-size: 13px !important;
	line-height: 35px !important;
	width: 100%;
	color: #393939;
	background-color: transparent;
	outline: none;
	padding: 9px 15px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}

.tbl_bgtxt_be {
	font-size: 18px !important;
}

.cemwouu_be {
	margin-top: 100px;
}

.bee_lgtext {
	font-size: 17px !important;
}

.propo_box_sp {
	border: #dddddd solid 1px;
	padding: 25px;
	margin-bottom: 30px;
	position: relative;
}

.send_propo_hdsp {
	color: #170048;
	word-break: break-word;
	font-weight: 600;
	font-size: 28px !important;
	line-height: 35px !important;
}

.ssmtxto_hdsp {
	font-size: 15px;
	line-height: 35px !important;
}

.hover_me {
	cursor: pointer;
	position: relative;
	display: inline-block;
}

.iconTooltiptext {
	visibility: hidden;
	width: 200px;
	line-height: 20px;
	background-color: #4e5156;
	color: #fff;
	text-align: center;
	border-radius: 4px;
	padding: 3px 5px;
	position: absolute;
	z-index: 1;
	top: 30px;
	right: 0;
	left: -22%;
	opacity: 1;
	font-size: 11px;
}

.hover_me:hover .iconTooltiptext {
	visibility: visible;
}

.sm_select_hdsp {
	margin: 15px 0;
}

.sm_select_hdsp select {
	padding: 13px 20px;
	width: 100%;
	border: #707070 solid 1px !important;
	color: #5d5d5d;
}

.ssmtxto_hdsp svg {
	margin: 0 5px;
}

.send_prp_blew_hsp {
	color: #6d2ef1;
	font-weight: 600;
	font-size: 20px !important;
	line-height: 33px !important;
}

.blue_bx_sp_ct {
	font-weight: 600;
	font-size: 13px !important;
	line-height: 28px !important;
	border: #6d2ef1 solid 0px;
	padding: 4px 20px;
	border-radius: 50px;
	color: #6d2ef1;
	background: #f2ecff;
}

.trs_css-76 {
	color: #5d5d5d;
	font-weight: 500;
	font-size: 13px !important;
	line-height: 28px !important;
	padding: 4px 20px;
}

.pbsp_prs {
	color: #5d5d5d;
	font-size: 15px !important;
	line-height: 22px !important;
	padding: 8px 0;
	white-space: pre-line;
	word-break: break-word;
	overflow: hidden;
}

.bbsp_linkn a {
	color: #000000;
	text-decoration: underline;
	font-size: 14px !important;
	line-height: 22px !important;
	padding: 15px 0;
}

.bright_gay {
	border-right: #c7c7c7 solid 1px;
}

.box_bgay {
	padding: 40px 20px 40px 20px;
}

.br_sc_gay {
	margin: 15px 0;
}

.br_sec_name_cwss {
	font-weight: 500 !important;
	font-size: 17px !important;
	line-height: 22px !important;
	padding: 0 8px;
	color: #6d2ef1;
}

.br_sec_val_cwss {
	font-weight: 300;
	font-size: 15px;
	line-height: 25px;
	padding: 0 8px;
	color: #462d7a;
}

.cwcss_cw .blue_bx_sp_ct {
	margin-right: 15px;
	margin-top: 10px;
}

.skll_hding {
	color: #170048;
	font-weight: 600 !important;
	font-size: 18px !important;
	line-height: 33px !important;
}

.bbtm_gay {
	border-bottom: #c7c7c7 solid 1px;
	padding: 0 0 30px 0;
}

.bg_gray_sp {
	background-color: #f2f8ff;
}

@media screen and (max-width: 991px) {
	.bright_gay {
		border-right: unset;
	}

	.box_bgay {
		padding: 0;
	}

	.bbtm_gay {
		padding: 0 0 10px 0;
	}

	/* .blue_bx_sp_ct {
	  padding: 2px 12px;
	} */
	.send_propo_hdsp {
		font-weight: 600 !important;
		font-size: 20px !important;
		line-height: 32px !important;
	}
}

.img_bx_protection {
	height: 250px;
}

.img_bx_protection img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.bc-vrw-protection {
	padding: 50px 20px !important;
}

.css-name-protection {
	color: #304e71;
	text-align: center;
	font-size: 15px;
	line-height: 18px !important;
}

.css-rve-prt {
	color: #6d2ef1;
	text-align: center;
	font-weight: 600;
	font-size: 13px !important;
	line-height: 30px !important;
	cursor: pointer;
}

.sphd_btext_protection {
	font-size: 17px !important;
	line-height: 30px !important;
	color: #304e71;
}

.pbx_pdd_sp {
	padding: 15px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: #c2d4e9 solid 1px;
}

.sli_bdg_pddsp {
	font-size: 15px !important;
	line-height: 30px !important;
	color: #170048;
}

.hraet_pdd_sp {
	font-weight: 600;
	font-size: 15px !important;
	line-height: 30px !important;
	color: #170048;
}

.preview-profile-user {
	color: #878787;
	font-size: 12px;
	font-weight: 400;
	white-space: pre-line;
}

.tamoun_pdd_sp {
	font-size: 13px !important;
	color: #170048;
	word-break: break-word;
	white-space: pre-line;
}

.inp_bdg_pdsp {
	display: flex;
	font-weight: 600;
	font-size: 14px;
	line-height: 30px !important;
	color: #170048;
	align-items: center;
}
.inp_bdg_pdsp input {
	width: 100%;
	background-color: transparent;
	border: none;
	outline: none;
	text-align: right;
	margin: 0 4px;
}
.inp_bdg_pdsp input:disabled {
	background-color: transparent;
}
.inp_bdg_pdsp input::placeholder {
	color: #000;
}

.popupSlider_div {
	width: 100%;
	max-width: 85%;
}

.popup_slider_box {
	width: 100%;
	max-width: 100%;
}

@media screen and (max-width: 481px) {
	.popupSlider_div {
		max-width: 100%;
	}
}

#inputrange {
	border-radius: 8px;
	height: 8px;
	outline: none;
	-webkit-appearance: none;
}

#inputrangepopup {
	border-radius: 8px;
	height: 8px;
	outline: none;
	-webkit-appearance: none;
}

#changeTermRange {
	border-radius: 8px;
	height: 8px;
	outline: none;
	width: 210px;
	-webkit-appearance: none;
}

@media screen and (max-width: 374px) {
	#changeTermRange {
		width: 100%;
	}
}

#bid_amount1 {
	border-radius: 8px;
	height: 8px;
	/* width: 150px; */
	outline: none;
	-webkit-appearance: none;
}

.slider_range_class {
	width: 344px;
}

input[type="range"]::-webkit-slider-thumb {
	width: 14px;
	-webkit-appearance: none;
	height: 14px;
	background: #ff88fe;
	border-radius: 20px;
}

.input_range_color input {
	/* --thumb-color: #6610f2;
	--track-color: #6610f2; */
	/* -webkit-appearance: none;
	appearance: none;
	background: linear-gradient(to right, var(--thumb-color), var(--thumb-color))
	  center;
	border-radius: 5px;
	height: 10px; */
	/* width: 100%; */
}

.hourly-rateDisableInp {
	background-color: #e9ecef6e !important;
	opacity: 1;
}

.cvr_ltr_textbox textarea {
	height: 170px;
	width: 100%;
	background-color: #fff;
	border: none;
}

.drag_file_bx span {
	color: #6d2ef1;
	font-weight: 500;
	margin: 0 5px;
}

.drag_file_bx input {
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 0;
}

.send_pros_btn:hover {
	background-color: #fff;
	color: #0e1f3e;
}

.send_pros_btn {
	background-color: #0e1f3e;
	padding: 12px 40px;
	color: #fff;
	border: #0e1f3e solid 1px;
	font-weight: 600;
	font-size: 16px !important;
	line-height: 30px !important;
	border-radius: 50px;
	text-transform: uppercase;
}

.send_pros_btn:disabled {
	background-color: #c9c9c9;
	border: 1px solid #c9c9c9;
	color: #fff;
}

.drag_file_bx {
	position: relative;
	background-color: #e1edfb;
	font-size: 14px !important;
	line-height: 30px !important;
	height: 80px;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.slsh_rh {
	padding: 0 20px;
}

.tracker_header {
	padding: 15px 0;
	box-shadow: rgb(33 35 38 / 10%) 0 10px 10px -10px;
	margin-bottom: 20px;
}

.wm_3_hdr {
	padding: 10px 0;
}

.wod_hding {
	font-weight: 500 !important;
	font-size: 23px !important;
	line-height: 30px !important;
}

.bnt_trackre button {
	background-color: transparent;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 30px !important;
	padding: 10px 20px;
	border: none;
	border-bottom: transparent solid 5px;
	color: #7d7f80;
}

.active_bnt_trak {
	border-bottom: #6d2ef1 solid 5px !important;
}

.inpptracker input {
	padding: 12px 20px;
	width: 100%;
	font-size: 14px !important;
	line-height: 30px !important;
	background-color: #f9f9f9;
	border: #f2f2f2 solid 1px;
}

.css-etxt-trak {
	margin: 0 20px;
	color: #7e6e9f;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 30px !important;
}

.th_bthns_tracker .play_btn_blew {
	background-color: #6d2ef1;
	padding: 12px;
	position: relative;
}

.th_bthns_tracker .play_btn_blew:before {
	content: " ";
	position: absolute;
	margin: auto;
	height: calc(100% + 10px);
	width: calc(100% + 10px);
	border-radius: 50px;
	border: #707070 solid 1px;
}

.th_bthns_tracker .play_btn_blew svg {
	fill: #fff;
	height: 30px;
	width: 30px;
}

.th_bthns_tracker button {
	background-color: transparent;
	margin: 10px 10px;
	border-radius: 50px;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.th_bthns_tracker button svg {
	fill: #7e6e9f;
	height: 22px;
	width: 22px;
}

.timerackbox {
	border: #e2e2e2 solid 1px;
}

.track_th_hme {
	color: #000000;
	font-weight: 600;
	font-size: 14px !important;
	line-height: 20px !important;
}

.tth_bgheading {
	color: #000000;
	font-weight: 600;
	font-size: 14px !important;
	line-height: 20px !important;
}

.timerackbox table thead tr th {
	height: 58px;
	min-width: 75px;
	border-right: #e2e2e2 solid 1px;
}

.nme_profe_track {
	font-weight: 600;
	font-size: 14px !important;
	line-height: 20px !important;
	padding-left: 10px;
}

.nme_pero_tranes {
	font-weight: 300 !important;
	font-size: 13px !important;
	line-height: 20px !important;
	padding-left: 10px;
}

.ewon_track_rc {
	display: flex;
	align-items: center;
}

.ewon_track_rc svg {
	margin: -1px 7px;
}

.cewi_trak {
	padding-top: 20px;
}

.ewon_track_rc {
	font-size: 14px !important;
	line-height: 20px !important;
	padding-left: 25px;
	text-transform: uppercase;
}

.cewoh_ew {
	font-size: 15px !important;
	line-height: 30px !important;
}

.timerackbox table tbody tr td {
	height: 75px;
	border-right: #e2e2e2 solid 1px;
}

.active_trlight {
	background-color: #f6f6f6;
}

.awd_btnrnd button {
	background-color: white;
	padding: 11px;
	height: 45px;
	width: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: #e8e7e7 solid 1px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
}

.timerackbox table tbody {
	margin: 2px 0 !important;
}

.slide_box_tracker_tbl td {
	height: 60px !important;
}

.timerackbox table tbody tr {
	border-color: transparent;
}

.timerackbox table tbody tr:hover {
	background-color: #f6f6f6;
}

.timerackbox table > :not(caption) > * > * {
	padding: 0.7rem 1rem !important;
}

.border-r-none {
	border-right: none !important;
}

.w-4 {
	width: 4% !important;
}

.w-30 {
	width: 30% !important;
}

.w-8 {
	width: 8% !important;
}

.thh_smheading {
	color: #000000;
	font-weight: 300 !important;
	font-size: 11px !important;
	line-height: 15px !important;
}

.s_na_inpu {
	position: relative;
}

.pls_s_na_input {
	position: absolute;
	right: 12px;
	height: 25px;
	pointer-events: none;
	width: 25px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	top: 7px;
	color: #fff;
	line-height: 20px;
	background-color: #6d2ef1;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
}

.s_na_inpu .enterIcon {
	z-index: 99;
	cursor: pointer;
	pointer-events: auto;
	width: 16px;
	height: 16px;
	font-size: 14px;
	margin-top: 5px;
}

.durationIcon {
	position: absolute;
	right: 12px;
	height: 25px;
	pointer-events: none;
	width: 25px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;;
	font-size: 20px;
	top: 7px;
	color: #fff;
	line-height: 20px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
}

.jobDurationInp {
	border: none;
}

.projectDurationOption option {
	border: none;
	outline: none;
}

.jobPriceRange {
	display: flex;
	gap: 10px;
}

.jobPriceRange .inp_bdg_pdsp input::placeholder {
	color: rgb(183, 183, 183) !important;
	font-size: 14px;
	font-weight: 400;
}

.priceStartRange {
	padding: 5px 6px;
	background-color: #fff;
	border: #f2f2f2 solid 1px;
}

.priceStartRange span {
	color: #66646494;
	display: flex;
	align-items: center;
}

.font-12-px {
	font-size: 12px !important;
}

.font-14-px {
	font-size: 14px !important;
}

.font-size-15px {
	font-size: 15px !important;
}

.font-size-20px {
	font-size: 20px !important;
}

.sflex_spance li {
	margin-right: 50px;
}

.select_no_bder .css-1s2u09g-control {
	background-color: transparent !important;
	border: none !important;
}

.close_account_btn {
	display: flex;
	justify-content: flex-end;
}

.close_account_btn button {
	background-color: #fff;
	border-radius: 27px;
	-webkit-border-radius: 27px;
	-moz-border-radius: 27px;
	border: #6d2ef1 solid 1px;
	padding: 8px 30px;
	font-size: 15px;
	color: #6d2ef1;
	font-weight: 500;
	text-align: center;
}

.close_account_btn button:hover {
	background-color: #6d2ef1;
	border-radius: 27px;
	-webkit-border-radius: 27px;
	-moz-border-radius: 27px;
	border: #6d2ef1 solid 1px;
	padding: 8px 30px;
	font-size: 15px;
	color: #fff;
	font-weight: 700;;
	text-align: center;
}

.invoice_download_btn {
	cursor: pointer;
}

.invoice_download_btn i {
	color: #6d2ef1;
}

.invoice_download_btn i:active {
	color: #c6b0f6;
}

/* ================== image popup start ====================== */
.image_open_popup {
	width: 95%;
	background: transparent 0 0 no-repeat padding-box;
	opacity: 1;
	max-height: 95vh;
	z-index: 9999;
	/* overflow: hidden; */
}

.image_open_popup .down_load_ic {
	cursor: pointer;
	position: absolute;
}

.image_open_popup .image {
	width: 100%;
	height: 95vh;
	max-height: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.image_open_popup .image img {
	width: auto;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	border-radius: 5px;
}

.image_open_popup .close_btn_image_popup {
	position: absolute;
	right: 6px;
	top: 8px;
}

.image_open_popup .close_pp_btn svg {
	fill: #fff !important;
}

/* ================== image popup end ====================== */

/* ====================== document open popup start ======================= */
.document_open_popup {
	width: 95%;
	background: transparent 0 0 no-repeat padding-box;
	opacity: 1;
	height: 100%;
	max-height: 95vh;
	z-index: 9999;
	/* overflow: hidden; */
}

.document_open_popup .document {
	width: 100%;
	max-height: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.document_open_popup .document iframe {
	height: 100%;
}

.document_open_popup .close_btn_image_popup {
	position: absolute;
	right: 6px;
	top: 8px;
}

.document_open_popup .close_pp_btn svg {
	fill: #fff !important;
}

/* ====================== document open popup end ======================= */

.bzns_mngr {
	cursor: pointer;
}

.jobSuccessRange {
	width: 100%;
	border-radius: 5px 0 0 5px;
	height: 5px;
	/* position: absolute; */
	left: 0;
	background-color: #6d2ef1;
}

.remainingjobSuccessRange {
	display: block;
	width: 100%;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 5px;
	/* position: absolute; */
	left: 0;
	background-color: #e5e5e5;
}

.everyTimeRange {
	width: 100%;
	border-radius: 5px 0 0 5px;
	height: 5px;
	/* position: absolute; */
	left: 0;
	background-color: #6d2ef1;
}

.remainingeveryTimeRange {
	display: block;
	width: 100%;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 5px;
	/* position: absolute; */
	left: 0;
	background-color: #e5e5e5;
}

.inDayRange {
	width: 100%;
	border-radius: 5px 0 0 5px;
	height: 5px;
	left: 0;
	background-color: #6d2ef1;
}

.remaininginDayRange {
	display: block;
	width: 100%;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 5px;
	left: 0;
	background-color: #e5e5e5;
}

@media (max-width: 767px) {
	.progressbar_disabled,
	.progressbar_item {
		width: 20px;
		height: 20px;
	}
	.progress_disabled,
	.progress_bar {
		height: 8px;
	}
}

.job-save-button {
	background-color: #ffffff !important;
	border-color: #dddddd !important;
	padding: 10px 20px !important;
}

.job-save-button.saved {
	border-color: var(--color-accent) !important;
}

.job-save-button svg {
	width: 20px;
	height: 20px;
	color: #dddddd;
}

.job-save-button.saved svg {
	color: var(--color-accent);
}