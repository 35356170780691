@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "reset";
@import "variables";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";

@import "elements";
@import "form";
@import "header";
@import "footer";
@import "home";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font);
}

.full-height-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;

    > .container {
        flex: 1 1 0;
    }
}

.sign_in_social_btn button:hover {
    color: #fff !important;
}

.next_b_btn_c button:hover {
    background-color: #fff;
    color: #6d2ef1;
}

.post_job_btn_m button:hover {
    color: #fff;
    background-color: #6d2ef1;
    border-color: #6d2ef1;
}

.post_job_btn_m button {
    border: #6d2ef1 solid 1px;
}

.hover_white_io:hover {
    color: #fff;
}

.toggle_btn_dot:active {
    background-color: transparent !important;
}

.toggle_btn_dot:hover {
    border-color: #6d2ef1;
    background-color: #fff;
}

.toggle_btn_dot:hover svg {
    fill: #6d2ef1;
}

.toggle_btn_dot svg {
    fill: #6d2ef1;
}

.ml-10-n {
    width: 60%;
    margin-left: 10px;
}

.active_btn {
    background-color: #170048;
    color: #fff;
    padding: 14px 28px !important;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    border-radius: 30px;
    border: 1px solid #170048;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    transition: 0.2s;
}

@media screen and (max-width: 1024px) {
    .navbar-nav .active_btn {
        padding: 10px 28px !important;
    }
}

.sliderbtn {
    display: flex;
    gap: 12px;
}

.logout_btn_nav {
    font-weight: 500 !important;
    margin: 0 20px !important;
    background-color: #170048;
    color: #fff;
    padding: 8px 20px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
}

.logout_btn_nav:hover {
    background-color: #fff !important;
    color: #170048 !important;
}

.active_btn:hover {
    background-color: transparent;
    color: #170048 !important;
    border: 1px solid #170048;
}

@media only screen and (max-width: 425px) {
    .sliderbtn {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
    }

    .active_btnM {
        margin: 0 auto;
        /* width: 195px; */
    }
}

@media only screen and (max-width: 500px) {
    .sliderbtn {
        position: relative;
        top: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .sliderbtn {
        position: relative;
        top: 0;
    }
}

@media only screen and (max-width: 768px) {
    .sliderbtn {
        display: flex;
    }

    .active_btn {
        padding: 9px 15px !important;
        font-size: 15px;
    }
}

@media only screen and (max-width: 499px) {
    .client_imag {
        width: 100% !important;
        justify-content: center;
    }

    .copy_right_para {
        text-align: center;
    }

    .copy_ri_ul {
        text-align: center;
        justify-content: space-between;
        width: 100%;
    }

    .right_align {
        text-align: center;
        display: block !important;
    }
}

.side_nav {
    margin-top: 10px;
}

.font-20 {
    font-size: 20px !important;
}

.f-16 {
    font-size: 15px !important;
}

@media only screen and (max-width: 767px) {
    .width_100_sm {
        width: 100%;
        display: flex;
        padding: 0 20px;
        justify-content: center;
    }
}

@media only screen and (max-width: 670px) {
    .mn_six_hun {
        min-width: auto !important;
    }

    .nin_vh {
        height: 43vh !important;
    }

    .wallet_icon svg {
        fill: #eeeeee !important;
    }

    .flex_ab_et {
        justify-content: center;
    }

    .ab_icon svg {
        fill: #eeeeee !important;
    }

    .slider_center {
        text-align: center !important;
        justify-content: center !important;
    }

    .as_used {
        text-align: center;
        margin: 10px 0;
    }

    .shuuter_man {
        margin-top: 230px;
        background-position: bottom center !important;
    }

    .clients_sec::before {
        display: none !important;
    }

    .shuuter_man::after {
        right: -50px !important;
    }

    .slider_get_btn {
        position: absolute;
        bottom: 0;
        margin-bottom: -80px;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 30px 0;
    }

    .slider_headin {
        margin-top: -210px;
    }
}

.ri_twh {
    right: 140px;
}

.position_ab {
    position: absolute;
    top: 0;
}

.slider_headin {
    font-size: 45px;
    line-height: 49px;
    color: #170048;
    font-weight: 700;
    height: 100%;
    display: inline-grid;
    align-content: center;
}

.slider_subheadin {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 15px;
    color: #393939;
}

.slider_get_btn button {
    width: 300px;
    height: 70px;
    border-radius: 60px;
    font-size: 27px;
    border-color: #170048;
    color: #ffffff;
    line-height: 24px;
    font-weight: 800;
    text-transform: uppercase;
}

.slider_get_btn button:hover {
    background-color: transparent !important;
    /* border: #fff solid; */
}

.navbar_logo img {
    width: 110px;
}

@media screen and (max-width: 769px) {
    .navbar_logo img {
        width: 100px;
    }
}

.clients_sec {
    background-color: #fff;
    padding: 25px 0;
    border-bottom: #e9b6e5 10px solid;
    position: relative;
    margin-top: 0;
    z-index: 10;
}

.clients_sec_skills {
    background-color: #fff;
    padding: 25px 0;
    position: relative;
    margin-top: 0;
    z-index: 10;
}

.clients_sec_skills hr {
    width: 90%;
    margin: 20px auto;
}

.clients_sec::before {
    content: " ";
    border-bottom: 80px solid white;
    border-left: 100vw solid transparent !important;
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    width: 100%;
    margin-top: -79px;
    z-index: 1;
}

.as_used {
    font-size: 25px;
    line-height: 25px;
    color: #180095;
    font-weight: 600;
    position: relative;
    z-index: 1;
    /* margin-bottom: 30px; */
}

@media screen and (max-width: 425px) {
    .as_used {
        text-align: center;
        margin-bottom: 30px;
    }

    .clients_sec_skills hr {
        width: 80%;
    }
}

.browseJobsLeft {
    margin-top: 76px;
}

.filter_area {
    background-color: #fafafaa6;
    padding: 16px 20px;
    margin-top: 50px;
}

.browse_jobs .filter_area {
    /* background-color: transparent; */
    padding: 16px 20px;
    margin-top: 20px;
}

.clearAllBtn {
    cursor: pointer;
}

.client_imag {
    width: 120px;
    height: 70px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.top_main_c_job {
    margin-top: 40px;
}

.top_main_c_job a {
    margin-top: 40px;
    color: #6610f2;
}

.client_imag img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 120px;
}

.flex_client {
    display: flex;
    align-items: center;
}

.services_sec {
    padding: 80px 0;
    /* margin-bottom: 50px; */
}

@media (max-width: 768px) {
    .services_sec {
        padding: 50px 0;
    }

    .services_sec_heading {
        padding: 30px 0;
        margin-top: 100px;
    }

    .btn_services {
        margin: 50px 0 0 0 !important;
    }
}

.services_sec_heading {
    position: relative;
    padding: 100px 0 50px 0;
}

.zer_thr {
    -webkit-text-stroke-color: #000;
    opacity: 0.05;
    top: -160px !important;
}

.f_b_border {
    border-right: #70707026 solid 8px;
}

.zer_to {
    height: 200px;
    position: absolute;
    top: 0;
    padding: 20px 0;
    z-index: -100;
    text-align: center;
    font-size: 300px;
    line-height: 200px;
    -webkit-text-stroke-color: #000;
    opacity: 0.05;
}

.services_sec_heading h2 {
    position: relative;
    font-size: 61px;
    text-align: center;
    width: 50%;
    letter-spacing: -1px;
    color: #00065a;
    font-weight: bold;
    margin: 0 auto;
}

.service_box {
    text-align: center;
}

.service_icon {
    height: 121px;
    margin: 20px 0;
}

@media screen and (max-width: 1399px) {
    .services_sec_heading h2 {
        width: 80%;
    }

    .blue_ater_h_min::before {
        left: 484px !important;
    }
}

@media screen and (max-width: 376px) {
    .slider_get_btn {
        padding: 5px 0;
    }

    .shuuter_man::after {
        width: 170px;
        top: 60%;
    }

    .shuuter_man::after {
        right: -90px !important;
    }

    .shuuter_man {
        min-height: 500px;
    }

    .clients_sec {
        margin-top: -50px;
    }
}

.service_icon img {
    height: 100%;
    width: 121px;
}

.service_head {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 33px;
    color: #37373b;
    font-weight: 700;
}

.service_para {
    font-size: 20px;
    letter-spacing: 0;
    color: #474747;
    font-weight: 500;
}

.btn_services {
    margin: 80px 0 0 0;
}

.btn_services button {
    background-color: #fff;
    border: #180095 solid 2px;
    color: #180095;
    padding: 18px 70px;
    border-radius: 50px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bolder;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
}

@media screen and (max-width: 426px) {
    .btn_services button {
        padding: 18px 30px;
    }
}

.btn_services button:hover {
    background-color: transparent;
    color: #180095;
}

.flex_cen {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .flex_cen {
        margin-top: 50px;
    }
}

/* .flex_cen .container {
  margin: auto;
  max-width: 1600px;
} */

.freelance_section {
    padding: 0 0 0 0;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: 70% 100%;
    background-position: right;
    z-index: 1;
    position: relative;
}

@media screen and (max-width: 768px) {
    .freelance_section {
        padding: 40px 0 0 0;
    }
}

.freelance_section .telent_image {
    position: relative;
    margin-left: auto;
    width: 50%;
    z-index: -2;
}

@media screen and (max-width: 1024px) {
    .freelance_section .telent_image {
        width: 85%;
    }
}

@media screen and (max-width: 500px) {
    .freelance_section .telent_image {
        width: 100%;
    }
}

.freelance_section .telent_image img {
    width: 100%;
    height: auto;
}

.sp-right-0 {
    padding-right: 0;
    margin-right: 0;
}

.free_sec_head h2 {
    font-size: 65px;
    letter-spacing: -1px;
    line-height: 67px;
    color: #00065a;
    font-weight: 700;
    width: 866px;
    margin: auto;
}

.active_btn_text {
    font-size: 16px;
    color: rgba(23, 0, 72, 1);
    /* margin-top: 9px; */
    margin-bottom: 0;
}

.free_sec_headFreeLancer::before {
    position: absolute !important;
    left: -640px !important;
    background-color: #ff88fe !important;
    height: 27% !important;
    width: 500px !important;
    z-index: -1 !important;
    top: 73px !important;
}

.free_sec_para {
    padding: 25px 0;
    width: 720px;
    margin: auto;
    position: relative;
    right: 200px;
}

.free_sec_para p {
    font-size: 22px;
    color: #393939;
    font-weight: 600;
}

.box_area_freelanec {
    background-color: #2b397b;
    width: 90%;
    margin: auto;
    position: relative;
    /* bottom: 0; */
    transform: translate(0%, -150%);
    /* right: 50%; */
    z-index: 2;
}

.box_area_border {
    background-color: #fff;
    width: 90%;
    height: 10px;
    position: absolute;
    right: 0;
}

.blue_ater_s {
    position: relative;
}

.blue_ater_s::before {
    content: " ";
    position: absolute;
    background-color: #ff88fe;
    height: 27%;
    width: 327px;
    z-index: -1;
    bottom: 73px;
    right: -219px;
}

@media (max-width: 1200px) {
    .blue_ater_s::before {
        right: 10px;
    }
}

@media (max-width: 1024px) {
    .blue_ater_s::before {
        left: -201px !important;
        height: 50% !important;
        width: 200px !important;
        top: 28px !important;
    }
}

.box_area_border::before {
    content: " ";
    position: absolute;
    left: 50%;
    background-color: #ff88fe;
    height: 200%;
    width: 20%;
}

.box_area_border::after {
    content: " ";
    position: absolute;
    right: 0;
    background-color: #6d2ef1;
    height: 200%;
    width: 40%;
}

@media screen and (max-width: 426px) {
    .box_area_border::after {
        left: 60%;
    }
}

.freelance_box {
    padding: 5px 30px;
    text-align: center;
    width: 100%;
    height: 100%;
}

.freelance_box p,
.freelance_box h2 {
    padding: 25px 30px;
    text-align: center;
    max-width: 260px;
    margin-inline: auto;
}

.freelance_head {
    height: 80px;
}

/* home top header start */
.home_top_header {
    position: relative;
    height: 700px;
    display: flex;
    justify-content: space-between;
}

.home_top_header .home_left {
    margin: 155px 0 0 0;
    z-index: 1;
    width: 63%;
}

.home_top_header .home_left .heading {
    font-size: 59px;
    color: #170048;
    font-weight: 700;
    line-height: 70px;
    width: 700px;
    max-width: 100%;
}

.home_top_header .home_left .description {
    color: #131433;
    font-weight: 500;
    font-size: 20px;
    width: 500px;
    margin-top: 10px;
}

.home_top_header .home_right {
    max-width: 800px;
    z-index: 0;
    position: absolute;
    right: -200px;
    max-height: 680px;
    /* overflow-y: auto; */
    /* padding-top: 100px; */
}

.home_top_header .home_right::-webkit-scrollbar {
    display: none;
}

.home_top_header .home_right img {
    max-width: 100%;
    height: auto;
}

.svg_sup1 {
    position: absolute;
    bottom: 50px;
    left: 30%;
}

@media screen and (max-width: 500px) {
    .svg_sup1 {
        display: none;
    }
}

/* home categories start */
.home_categories {
    padding: 30px;
    border-radius: 20px;
    margin: 10px auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 250px;
    justify-content: center;
}

.home_categories:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.home_categories .category_logo {
    width: 50px;
    margin: 5px 0;
    height: 50px;
}

.home_categories .category_logo img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.home_categories .category_title {
    font-size: 24px;
    font-weight: 700;
    color: #170048;
    margin: 15px 0;
}

.home_categories .category_image img {
    max-width: 100%;
}

@media screen and (min-width: 767px) {
    .home_categories .category_title {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
    }
}

.home_categories .category_about {
    width: max-content;
    display: flex;
    background: #f8f6f6;
    border-radius: 40px;
    font-size: 14px;
    padding: 5px 20px;
}

.home_categories .rating {
    font-weight: 600;
    color: #898989;
}

.home_categories .saprator {
    color: #898989;
    margin: 0 10px;
}

.home_categories span {
    display: flex;
    color: #898989;
    align-items: center;
}

.home_categories .skills_count {
    color: #898989;
    font-weight: 600;
    margin-right: 10px;
}

/* home categories end */

/* trusted brands start */
.brands {
    padding: 30px;
    margin: 20px;
}

.brands .brands_container {
    max-width: 450px;
    margin: auto;
}

.brands_background {
    position: relative;
    background-color: #ebf4ff;
    padding: 0 0 50px 0;
}

.brands .brand_icon {
    width: 90px;
    min-width: 90px;
    margin: auto;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brands .brand_icon img {
    width: 100%;
    height: auto;
    margin: auto;
}

.brands .brand_title {
    color: #393939;
    font-size: 18px;
    font-weight: 600;
    max-width: 320px;
    margin: 15px auto;
    text-align: center;
}

.brands .brand_officer {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #3b3b3b;
}

.brands .Results {
    color: #777777;
    border-top: 1px dashed #b5b5b5;
    margin-top: 10px;
}

.brands .Results .result_sec {
    padding: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0,
    rgba(0, 0, 0, 0.1) 0 0 1px 0;
    border-radius: 5px;
}

.brands .Results .result {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.brands .Results .projects {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.brands .Results .projects > div {
    display: flex;
    flex-direction: column;
}

.brands .Results .projects .brands_bord {
    background-color: #eeeeee;
    height: 100%;
    width: 1px;
}

.brands .Results .projects > div .count {
    font-size: 18px;
    font-weight: 700;
    color: #170048;
}

.brands .Results .projects > div .title {
    font-size: 12px;
    color: #777777;
}

/* trusted brands end */

@media screen and (max-width: 1024px) {
    .home_top_header {
        height: 500px;
    }

    .home_top_header .home_left .heading {
        font-size: 40px;
        line-height: 45px;
    }

    .home_top_header .home_left .description {
        font-size: 22px;
        width: 472px;
    }

    .home_top_header .home_right img {
        max-width: 400px;
        height: auto;
        position: relative;
        left: -25px;
    }

    .home_top_header .home_right {
        bottom: 42px;
        right: -70px;
    }

    .home_top_header .home_left {
        margin-top: 100px;
    }
}

@media screen and (max-width: 769px) {
    .home_top_header .home_left .heading {
        font-size: 32px;
        line-height: 45px;
    }

    .home_top_header .home_left .description {
        font-size: 16px;
        width: 425px;
    }

    .home_top_header .home_right img {
        max-width: 300px;
        height: auto;
        position: relative;
    }

    .home_top_header {
        height: 330px;
    }
}

@media screen and (max-width: 767px) {
    .home_top_header .home_left {
        margin-top: 100px;
        width: 100%;
        text-align: center;
    }

    .home_top_header .home_right {
        display: none;
    }

    .home_top_header .home_left .description {
        font-size: 16px;
        width: 100%;
    }

    .sliderbtn {
        justify-content: center;
    }
}

@media screen and (max-width: 500px) {
    .home_top_header .home_left {
        margin: 27px 5px 0 5px;
    }

    .home_top_header {
        flex-direction: column;
        justify-content: unset;
        align-items: center;
    }

    /* .home_top_header .home_right {
    max-width: 300px;
    height: auto;
    position: relative;
    left: 0;
  } */
    .slider_get_btn {
        bottom: 90px !important;
        left: 0;
    }

    .home_top_header .home_left .description {
        /* width: 400px; */
        text-align: center;
        padding-top: 10px;
        margin: 0 auto;
    }

    .home_top_header .home_left .heading {
        font-size: 32px;
        line-height: 37px;
        text-align: center;
        width: 100%;
        margin: 0 auto;
    }

    /* .home_top_header .home_right img {
    max-width: 270px;
  }

  .home_top_header .home_right {
    margin-top: 15px;
  } */
    .slider_get_btn {
        bottom: 180px !important;
    }

    .slider_get_btn button {
        width: 270px;
        height: 60px;
    }

    .home_top_header .home_left {
        width: 100%;
    }
}

/* @media screen and (max-width: 390px) {
  .home_top_header .home_left .description,
  .home_top_header .home_left .heading {
    width: 350px;
  }
} */
/* @media screen and (max-width: 320px) {
  .home_top_header .home_left .description,
  .home_top_header .home_left .heading {
    width: 296px;
  }
} */

.freelance_head h2 {
    font-size: 22px;
    letter-spacing: 0;
    padding: 15px 0;
    line-height: 23px;
    color: #ffffff;
    font-weight: 500;
}

.question_img_br {
    height: 240px;
    padding-bottom: 20px;
}

.question_img_br img {
    height: 100%;
}

.freelance_para p {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    color: #fff;
    opacity: 40%;
    margin-top: -12px;
    padding-bottom: 0;
    font-weight: 300;
}

.find_frelance {
    /* margin-top: 200px; */
    padding: 60px 0 0;
    position: relative;
    margin-bottom: 0 !important;
}

@media screen and (max-width: 1024px) {
    .find_frelance {
        margin-top: 60px;
    }
}

@media screen and (max-width: 768px) {
    .find_frelance {
        margin-top: 10px;
    }
}

.find-freel-head h1 {
    font-size: 60px;
    letter-spacing: -1px;
    line-height: 62px;
    color: #00065a;
    font-weight: 700;
    text-align: center;
    position: relative;
    margin: 0 auto;
    width: 1000px;
}

@media screen and (max-width: 1024px) {
    .find-freel-head h1 {
        margin: 0 auto;
        width: 760px;
        font-size: 50px;
        line-height: 52px;
    }

    .find-freel-para p {
        margin: 0 auto;
        width: 600px !important;
    }
}

@media screen and (max-width: 768px) {
    .find-freel-head h1 {
        font-size: 52px;
        line-height: 55px;
    }
}

@media screen and (max-width: 500px) {
    .find-freel-head h1 {
        font-size: 38px;
        line-height: 46px;
        /* width: 330px !important; */
    }

    .find-freel-para p {
        width: 330px !important;
    }

    .freelance_head h2 {
        font-size: 16px;
        line-height: 18px;
    }
}

@media screen and (max-width: 320px) {
    .find-freel-head h1 {
        /* width: 280px !important; */
    }

    .find-freel-para p {
        width: 280px !important;
    }
}

.ffh {
    position: relative;
}

.ffh::before {
    content: " ";
    position: absolute;
    top: -10px;
    right: 0;
    width: 250px;
    background-color: #ff88fe;
    height: 27px;
    z-index: -1;
}

@media screen and (max-width: 425px) {
    .find-freel-head h1 {
        font-size: 35px;
        line-height: 40px;
    }
}

@media screen and (max-width: 767px) {
    .ffh::before {
        display: none;
    }
}

.tropee_svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
}

.tropee_svg img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.f_freel_swip {
    background-color: #f8f8f8;
    padding: 70px 0;
}

.find-freel-para {
    padding: 30px 0;
    margin-bottom: 30px;
}

.find-freel-para p {
    font-size: 20px;
    color: #393939;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    width: 1000px;
}

.freelance_card {
    padding: 20px 10px;
}

.freelance_card_profile {
    text-align: center;
    display: flex;
    align-items: flex-end;
}

.freelancer_pro {
    height: 100px;
    border-radius: 50%;
    width: 100px;
    border: #cecece solid 3px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.frel_name {
    font-size: 25px;
    letter-spacing: 0;
    color: #3b3b3b;
    font-weight: 700;
}

.pro_name_drop_u {
    padding: 0 0 0 0;
    font-size: 16px;
    letter-spacing: 0;
    color: #1f1f1f;
    font-weight: 500;
    text-transform: capitalize;
    word-break: break-word;
}

.drop_p_o_i {
    display: flex;
    margin-top: 5px;
    padding: 10px 0;
    justify-content: center;
}

.drop_li_poi {
    font-size: 14px;
    text-indent: 8px;
    cursor: pointer;
    letter-spacing: 0;
    color: #393939;
    margin: 10px 5px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.drop_li_poi:hover {
    color: #6d2ef1;
}

.drop_p_o_i .active_drop_poi {
    background-color: #6d2ef1;
    color: #fff;
}

.drop_p_o_i button {
    padding: 10px 20px;
    background-color: #fff;
    text-align: center;
    font-size: 13px;
    border: #c9c9c9 solid 1px;
    letter-spacing: 0;
    color: #393939;
    font-weight: 500;
}

.pro_o_nme {
    /* text-align: center; */
    font-size: 14px;
    letter-spacing: 0;
    color: #393939;
    font-weight: 400;
}

.frel_loca {
    font-size: 18px;
    letter-spacing: 0;
    color: #3b3b3b;
    font-weight: 600;
}

.freelancer_pro img {
    height: 100%;
    width: 100%;
    margin: -7px 0 0 -25px;
    object-fit: cover;
    border-radius: 50%;
}

.freel_badge {
    font-size: 16px;
    width: 93px;
    height: 29px;
    border-radius: 14px;
    line-height: 29px;
    background-color: #ff88fe;
    letter-spacing: 0;
    color: #ffffff;
    font-weight: 300;
}

.find_swip .swiper-pagination {
    bottom: 0 !important;
}

.find_swip {
    padding: 0 0 0 0 !important;
}

.t_r_freelancers .swiper-pagination {
    bottom: 0 !important;
}

.t_r_freelancers {
    padding: 0 0 50px 0 !important;
}

.swiper-pagination-bullet {
    border-radius: 30px !important;
    background: #0777fd !important;
    opacity: 1 !important;
    height: 10px !important;
    width: 10px !important;
}

.swiper-pagination-bullet-active {
    width: 30px !important;
    opacity: 1 !important;
}

.frel-serv p {
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    color: #3b3b3b;
    font-weight: 400;
}

.frel_job {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 10px;
    color: #3b3b3b;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.frel_job span {
    font-weight: 400;
}

.font-35 {
    font-size: 35px !important;
}

@media (max-width: 481px) {
    .font-35 {
        font-size: 30px !important;
    }
}

.font-30 {
    font-size: 30px !important;
}

.right_align {
    display: flex;
    justify-content: right;
}

.selectedImageCrop {
    z-index: 999999;
    position: absolute;
    top: 0;
    border: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.selectedImageCrop button.closeCropPopup_btn.btn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    font-size: 35px;
    color: #fff;
}

.selectedImageCrop button.saveCrop_btn.btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    background: #fff;
}

.selectedImageCrop .reactEasyCrop_Container .reactEasyCrop_Contain {
    background: #fff;
}

.selectedImageCrop .reactEasyCrop_Container {
    background: #000;
}

.subscriptions .back-button {
    display: flex;
    align-items: baseline;
    cursor: pointer;

    span {
        font-size: 20px;
        margin-left: 5px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
    .navbar-expand-md .navbar-collapse {
        min-width: 570px;
    }

    .navbar-expand-md .navbar-nav {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

.dropdown-toggle:empty::after {
    display: none;
}

.dropdown-toggle::after,
.dropdown-toggle::before {
    content: "";
    width: 7px;
    height: 2px;
    background: #fff;
    position: absolute;
    right: 14px;
    top: 19px;
    border: none;
}

@media (max-width: 767px) {
    .dropdown-toggle::after,
    .dropdown-toggle::before {
        top: 12px !important;
    }
}

.dropdown-toggle::before {
    transform: rotate(-45deg);
    margin-right: -2px;
}

.dropdown-toggle::after {
    transform: rotate(45deg);
    margin-right: 2px;
}

.han_oad {
    padding-left: 10px;
}

.border_bx_sec {
    border: #cdcccc solid 1px;
    padding: 0 12px;
    margin: 10px 0;
    margin-top: 0 !important;
    border-radius: 10px;
    position: relative;
}

.user_profile_bg {
    width: 85px;
    height: 85px;
    border-radius: 50px;
    overflow: hidden;
}

.user_profile_bg img {
    height: 100%;
    width: 100%;
    border-radius: 50px;
    object-fit: cover;
}

.freelancer_referral {
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    color: #6d2ef1;
    font-weight: 400;
    padding-top: 8px;
}

.freelancer_referral .referral_code {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    color: #6d2ef1;
    font-weight: 700;
}

.freelancer_referral .referral_icon {
    cursor: pointer;
}

.freelancer_referral .copy_message {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
}

.freelancer_name {
    font-size: 15px;
    letter-spacing: 0;
    color: #6d2ef1;
    font-weight: 700;
    word-break: break-word;
}

.freelancer_work {
    padding: 10px 0;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    color: #8f8f8f;
    font-weight: 400;
}

.freelance_map {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
    color: #8f8f8f;
    font-weight: 400;
}

.flex_stars {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile_box {
    position: relative;
}

.profile_thumb {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 60px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.Agencyprofile_box {
    width: 30%;
}

.Agencyhan_oad {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1440px) {
    .Agencyprofile_box {
        width: 40% !important;
    }
}

@media (max-width: 1200px) {
    .Agencyflex_profile_frel {
        gap: 5px !important;
        flex-direction: unset !important;
    }
}

@media (max-width: 1024px) {
    .Agencyprofile_box {
        width: 85px !important;
    }
}

@media (max-width: 767px) {
    .Agencyprofile_box {
        width: 85px !important;
    }

    .Agencyhan_oad {
        width: 60%;
    }
}

@media (max-width: 481px) {
    .Agencyprofile_box {
        width: 85px !important;
    }
}

.agency_title_div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.AgencyTitleandOverview {
    font-size: 18px;
    color: #9c9c9c;
    margin-right: 10px;
}

.AgencyTitleandOverviewNoAdded {
    font-size: 18px;
    color: #9c9c9c;
    cursor: default;
}

.Agencymyskill_hdingn {
    margin-bottom: 0 !important;
}

.pt_num {
    font-size: 18px;
    line-height: 13px;
    color: #0777fd;
    font-weight: 500;
}

.my_skill_sec {
    border-bottom: 1px solid #707070;
    padding: 20px 0;
}

.award_sec {
    padding: 20px 0;
}

.heat_lef {
    position: absolute;
    left: -1px;
    margin: 0;
    top: -1px;
    height: 55px;
    width: 55px;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='61.913' viewBox='0 0 64 61.913'%3E%3Cpath id='Path_4452' data-name='Path 4452' d='M0,0H64L0,61.913Z' fill='%236d2ef1'/%3E%3C/svg%3E%0A");
}

.heat_lef img {
    height: 17px;
    margin-top: -21px;
    margin-left: 5px;
    width: 20px;
}

.award_box {
    border-bottom: 1px solid #707070;
    padding: 10px 0;
}

.ab_et_ne {
    font-size: 9px;
    line-height: 16px;
    color: #b9b9b9;
    font-weight: 500;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.ab_icon {
    height: 40px;
}

.ab_icon svg {
    height: 40px;
    width: 40px;
    fill: #f3f3f3;
}

.r_ri {
    padding-left: 10px;
}

.flex_ab_et {
    display: flex;
    align-items: center;
    padding: 2px 0;
    margin-right: 25px;
}

.ab_et_val {
    font-size: 15px;
    line-height: 13px;
    color: #6b6b6b;
    font-weight: 500;
}

.about_head_ma {
    padding: 30px 0;
}

.about_head_ma h1 {
    font-size: 25px;
    letter-spacing: 0;
    color: #363636;
    font-weight: 700;
}

.award_img {
    height: 60px;
    width: 60px;
}

.award_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.award_con {
    display: flex;
    padding-left: 10px;
    padding-top: 5px;
}

.awrd_flex {
    display: flex;
}

.awrd_date {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
    color: #4d4d4d;
    font-weight: 600;
}

.awrd_name {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    color: #4d4d4d;
    font-weight: 600;
}

.flex_pt {
    border-bottom: 1px solid #707070;
    display: flex;
    padding: 8px 0;
    margin-right: 5px;
    width: 95%;
    min-width: 100px;
    align-items: center;
}

.skil_nam p {
    font-size: 9px;
    letter-spacing: 0;
    line-height: 15px;
    margin: 0 0 5px;
    color: #4d4d4d;
    font-weight: 500;
}

.prr_box {
    margin: 8px 0;
}

.skil_rr p {
    font-size: 9px;
    letter-spacing: 0;
    line-height: 15px;
    margin: 0 0 5px;
    color: #4d4d4d;
    font-weight: 500;
}

.prr_area_line {
    height: 7px;
    border-radius: 3px;
    background-color: #f5f5f5;
    position: relative;
}

.prr_area_line::before {
    position: absolute;
    content: " ";
    border-radius: 3px;
    left: 0;
    background-color: #0777fd;
    width: 60%;
    height: 100%;
}

.my_skil_head h3 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 15px;
    color: #363636;
    font-weight: 600;
}

.want_to h3 {
    font-size: 16px;
}

.pd_flex {
    display: flex;
    /* justify-content: flex-end; */
    width: 100%;
}

.profile_detail {
    padding: 30px 0;
    border-bottom: 1px solid #707070;
}

.pt_title {
    font-size: 10px;
    line-height: 17px;
    color: #b9b9b9;
    padding-right: 10px;
    font-weight: 500;
}

.profile_thumb svg {
    fill: #fff;
}

.review_par {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review_par p {
    font-size: 9px;
    line-height: 30px;
    margin: auto;
    color: #b9b9b9;
    font-weight: 500;
}

.review_star {
    margin: 0 3px;
}

.star_paddin {
    padding: 8px 0;
}

.review_star svg {
    fill: #ff88fe;
    stroke: #ff88fe;
}

.flex_profile_frel {
    display: flex;
    /* justify-content: center; */
    padding: 30px 0 0 0;
}

@media screen and (max-width: 1199px) {
    .res_padding {
        padding: 0 10px !important;
    }

    .flex_profile_frel {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .resp_profile_div {
        padding-left: 0;
    }
}

@media screen and (max-width: 991px) {
    .flex_profile_frel {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;
    }
}

@media screen and (max-width: 767px) {
    .view_proposed_attachement {
        /* padding-right: 0 10px !important; */
        padding: 0 15px !important;
    }

    .single_active_proposal .cover_letter {
        margin-bottom: 10px;
        padding: 5px 18px 10px 18px !important;
    }
}

.hire_now_node {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

.hourly_amout_node {
    display: flex;
    align-items: self-end;
    padding: 14px 0;
    max-width: 85%;
    margin: 0 auto;
    border-bottom: 1px solid #7070703b;
}

.hourly_amout_nodeUnicon {
    display: flex;
    align-items: self-end;
    padding: 15px 0;
}

.dashboardEarning {
    display: flex;
}

.dashboardHead {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;
}

.dashboardWalletIcon {
    display: flex;
    align-items: self-end;
    padding: 10px 0;
    max-width: 96%;
    margin: 0 auto;
    border-bottom: 1px solid #7070703b;
}

.dashboradTotalProject {
    display: flex;
    flex-direction: column;
}

.dashboard_Resp_Icon {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 425px) {
    .dashboardWalletIcon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 0;
        max-width: 96%;
        margin: 0 auto;
        border-bottom: 1px solid #7070703b;
    }

    .dashboard_icon_resp {
        width: 400px !important;
    }

    .dashboard_Resp_Icon {
        display: flex;
        margin-left: 0;
    }
}

.b-bottom-b {
    border-bottom: #d4d4d5 solid 1px;
}

.strokeWidth-2 {
    stroke-width: 2px;
}

.comp_name_p {
    padding-bottom: 10px;
}

.wallet_icon svg {
    height: unset;
    width: 80px;
    fill: #eeeeee;
    padding: 0 10px;
}
.wallet_icon svg * {
    fill: #ddd;
}


.hourly_am {
    padding-left: 10px;
}

.hourly_am h1 {
    font-size: 32px;
    margin: 0;
    letter-spacing: -2px;
    line-height: 35px;
    color: #3b3b3b;
    font-weight: 700;
    padding-bottom: 5px;
    word-break: break-word;
}

.hourly_am span {
    font-size: 10px;
    line-height: 15px;
    color: #6b6b6b;
    font-weight: 500;
    display: flex;
}

@media screen and (max-width: 1199px) {
    .wallet_icon svg {
        width: 60px;
    }

    .totl_uni {
        padding-left: 0;
    }

    .dashboardWalletIcon {
        max-width: 100%;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .resp_dash_gr .chat_resp_bar {
        width: 100% !important;
    }
}

.hire_now_node button {
    width: 224px;
    height: 35px;
    border-radius: 5px;
    background-color: #6d2ef1;
    border: 2px solid #6d2ef1;
    font-size: 13px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.hire_now_node button:hover {
    background-color: #fff;
    color: #6d2ef1;
    border-color: #6d2ef1;
}

.servi_box {
    margin: 10px 0;
    background-color: #fff;
    position: relative;
}

.ser_hei {
    height: 220px;
}

.ser_hei img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.mar_min {
    margin-top: -30px;
}

.nin_vh {
    height: 90vh;
}

.description_my_p {
    font-size: 10px;
    line-height: 15px;
    color: #6b6b6b;
    font-weight: 400;
    white-space: pre-line;
    padding-bottom: 30px;
}

.flex_f_pro {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.sm_upb {
    height: 60px;
    width: 60px;
}

.f_para {
    font-size: 15px;
    line-height: 20px;
    color: #979797;
    font-weight: 600;
    padding: 5px 0;
}

.priec_b {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 3px 0;
    letter-spacing: -1px;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
}

.st_fro {
    font-size: 10px;
    line-height: 15px;
    color: #6b6b6b;
    font-weight: 400;
}

.st_pri {
    font-size: 18px;
    line-height: 15px;
    color: #170048;
    font-weight: 600;
    text-align: right;
}

.bg-transparent {
    background-color: transparent !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.nav_box {
    padding: 20px 30px;
    background-color: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    border: none;
    position: relative;
    transition: 0.1s;
    box-shadow: 0 0 1px #0003;
    margin: 0 0 2px;

    .s_nav_title,
    .s_nav_icon svg * {
        transition: 0.1s;
    }

    &:hover {
        .s_nav_title {
            color: #6d2ef1;
        }

        .s_nav_icon svg * {
            fill: #6d2ef1;
        }
    }
}

.nav_box.active_s_nav {
    &::before {
        content: " ";
        position: absolute;
        right: 0;
        height: 100%;
        width: 8px;
        background-color: #fa61ff;
    }

    .s_nav_title {
        color: #6d2ef1;
    }

    .s_nav_icon svg,
    .s_nav_icon svg * {
        fill: #6d2ef1;
    }

    .s_nav_icon svg .no_fill {
        fill: none !important;
    }
}

.s_nav_icon {
    height: 25px;
    width: 25px;
}

.s_nav_icon img,
.s_nav_icon svg {
    height: 100%;
    width: 100%;
    fill: #bec7cf;
}

.bl_apra {
    padding: 15px 0;
}

.no_juscenter_inmob {
    justify-content: flex-start !important;
}

.s_nav_body {
    background-color: #fff;
    padding: 34px;
    margin-top: 10px;
}

.s_b_head {
    padding: 10px 0;
    border-bottom: 8px solid #e4edf6;
}

.s_b_head_progress {
    padding: 10px 0;
    /* border-bottom: 8px solid #e4edf6; */
}

.client_progress_bar .progress-bar {
    background-color: #fa61ff;
}

.how_would {
    font-size: 16px;
    color: #6b6b6b;
    font-weight: 500;
    padding-top: 10px;
}

.s_b_head h2 {
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 40px;
    color: #170048;
    font-weight: 600;
}

.s_nav_title {
    padding-left: 20px;
    font-size: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0;
    margin-top: 5px;
    color: #bec7cf;
    font-weight: 500;
}

.mr_2_sm {
    padding: 10px 0;
}

.s_b_r {
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin-right: 10px;
}

.s_b_r label,
.form-label {
    margin-bottom: inherit;
}

.s_b_r p {
    font-size: 18px;
    line-height: 22px;
    color: #6b6b6b;
    margin: 0;
    padding-left: 10px;
    font-weight: 400;
}

.label_apra .litt_sm_para {
    font-size: 15px;
}

.s_b_r .months_6 {
    font-size: 15px;
}

.btn_foot_sec {
    margin-top: 15px;
    border-top: #cbcaca solid 1px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.select_cards {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    padding: 10px 0 20px 0;
}
.select_card {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 30px 0;
    width: 100%;
    text-align: center;
    border: 1px solid #d6d6d6;
    max-width: 300px;
}

.job_title_box {
    text-align: start !important;
    padding: 0 15px;
}

.sho_tit {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    color: #170048;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}

.carrt_css_select {
    background-image: linear-gradient(45deg, transparent 50%, #6d2ef1 50%),
    linear-gradient(135deg, #6d2ef1 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    padding-right: 4em !important;
    appearance: none;
}

.sm_seds {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #6b6b6b;
    font-weight: 500;
    text-align: center;
    padding: 0 10px;
}

.sel_icon {
    height: 40px;
    margin-bottom: 20px;
    text-align: center;
}

.sel_icon svg {
    fill: #e4eaef;
    height: 40px;
    width: 40px;
}

.fo_btn_c button {
    background-color: #fff;
    border-radius: 27px;
    border: #6d2ef1 solid 1px;
    padding: 10px 30px;
    font-size: 15px;
    color: #6d2ef1;
    font-weight: 600;
    text-align: center;
}

.SaveJobPostDisabled {
    background-color: #e4ebe4 !important;
    color: #9aaa97 !important;
    cursor: not-allowed;
    border: none !important;
    min-width: 200px;
}

.s_select {
    margin: 5px 0;
}

.s_select select {
    background-color: #fff;
    padding: 7px 6px;
    border: #f2f2f2 solid 2px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    color: #3b3b3b;
    font-weight: 400;
}

.s_on {
    font-size: 12px;
    line-height: 13px;
    color: #6b6b6b;
    font-weight: 500;
}

.bl_head h3 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 15px;
    color: #170048;
    font-weight: 600;
}

.t_area_ob {
    margin: 10px 0;
}

.t_area_ob textarea {
    border: #f2f2f2 solid 2px;
    padding: 20px 30px;
    width: 100%;
}

.t_area_ob input {
    border: #f2f2f2 solid 2px;
    padding: 8px 15px;
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #1f1f1f;
    outline: none;
}

.t_area_ob.pr_inp_a input {
    padding: 8px 15px 8px 38px;
}

.t_inp_bt_text {
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0;
    color: #878787;
}

.see_cat_link {
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.18px;
    color: #6d2ef1;
    cursor: pointer;
}

.poi_box {
    margin: 12px 0;
    list-style: none;
    padding: 0;
}

.point_li {
    margin: 6px 0;
    font-size: 13px;
    line-height: 13px;
    color: #6b6b6b;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.point_li::before {
    content: "•";
    color: #828282;
    margin: 0 5px;
    line-height: 23px;
    font-size: 25px;
}

.bl_apra p {
    font-size: 10px;
    line-height: 15px;
    color: #6b6b6b;
    font-weight: 400;
    white-space: pre-line;
    overflow: hidden;
}

.see_all_cat {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    color: #6d2ef1;
    font-weight: 700;
}

.project_f_inpu_box {
    border: 3px dotted #ff88fe;
    border-radius: 12px;
    background-color: #ffffff;
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
}

.project_f_inpu_box input {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    opacity: 0;
}

.project_f_inpu_box label {
    font-size: 13px;
    line-height: 13px;
    color: #6b6b6b;
    font-weight: 500;
}

.project_f_inpu_box label span {
    color: #6d2ef1;
}

.sef_box {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
}

.sef_na_ea h3 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 15px;
    color: #3b3b3b;
    margin: 0;
    font-weight: 600;
}

.sef_p_c {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sef_p_c p {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    margin: 0;
    cursor: pointer;
    color: #ff88fe;
    font-weight: 500;
}

/*
.s_na_box {
  margin: 0 0 20px 0;
} */

.desc_hin p {
    font-size: 11px;
    text-align: center;
    letter-spacing: 0;
    line-height: 17px;
    color: #3b3b3b;
    font-weight: 400;
}

.fr_btn {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.fr_btn button {
    border-radius: 5px;
    background-color: #6d2ef1;
    padding: 10px 20px;
    border: #6d2ef1 solid 1px;
    width: 100%;
    max-width: 200px;
    font-size: 13px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 600;
}

.fr_btn button:hover {
    background-color: #fff;
    color: #6d2ef1;
}

.s_na_h4 h4 {
    padding: 10px 0 0 0;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    color: #3b3b3b;
    font-weight: 400;
}

.s_cat_bo {
    border-bottom: #9c9c9c3d solid 1px;
    padding: 20px 0;
}

.box_web_req {
    position: relative;
}

.abso_cen {
    position: absolute;
    right: 0;
    top: -23px;
}

.s_na_inpu input,
.s_na_inpu select {
    background-color: #fff;
    padding: 10px 10px;
    border: #f2f2f2 solid 1px;
    width: 100%;
    font-size: 9px;
    letter-spacing: 0;
    outline: none;
    line-height: 18px;
    color: #9c9c9c;
    font-weight: 400;
}

.s_na_categ {
    display: flex;
    margin: 3px 0;
    align-items: center;
}

.s_na_categ input {
    background-color: transparent;
    border: #707070 solid 2px;
}

.s_na_categ label {
    padding-left: 10px;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
    color: #9c9c9c;
    font-weight: 400;
    margin-bottom: 0 !important;
}

.ran_fl_inp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.ran_fl_inp input {
    width: 80%;
    padding: 10px;
    margin-right: 4px;
    border: #f2f2f2 solid 1px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    color: #9c9c9c;
    font-weight: 400;
}

.job_box_card {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    padding: 25px 30px;
    border: #000 solid 1px;
    border-radius: 10px;
}

@media screen and (max-width: 425px) {
    .job_box_card {
        border: #dadada solid 1px;
        padding: 15px 10px;
    }
}

.com_name_s::before {
    content: " ";
    position: absolute;
    left: 0;
    height: 10px;
    background-color: #e9e9e9;
    width: 10px;
    border-radius: 50px;
}

.com_name_s {
    display: flex;
    align-items: center;
    font-size: 11px;
    letter-spacing: 0;
    position: relative;
    text-indent: 15px;
    line-height: 15px;
    color: #747474;
    font-weight: 500;
    padding: 5px 0;
}

.job_head_s h2 {
    font-size: 18px;
    color: #170048;
    font-weight: 600;
    word-break: break-word;
    line-height: 1;
}

.browse_jobs .job_head_s h2 {
    font-size: 22px;
}

.job_head_s h3 {
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    color: #170048;
    font-weight: 600;
}

.dlex_sk_block {
    display: flex;
    margin: 20px 0;
}

.b_skil {
    background-color: #fff;
    padding: 8px 13px;
    font-size: 9px;
    margin-top: 5px;
    line-height: 13px;
    color: #6d2ef1;
    font-weight: 500;
    border-radius: 30px;
    margin-right: 10px;
    border: 1px solid #6d2ef1;
}

.job_d_par p {
    font-size: 12px;
    line-height: 17px;
    color: #6b6b6b;
    font-weight: 400;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
}

.price_ar_jjob {
    text-align: right;
    /* padding-top: 20px; */
}

@media screen and (max-width: 425px) {
    .price_ar_jjob {
        text-align: left;
    }
}

.price_ar_jjob h1 {
    font-size: 20px;
    letter-spacing: -1px;
    line-height: 20px;
    color: #3b3b3b;
    font-weight: bold;
}

.price_ar_jjob p {
    font-size: 11px;
    line-height: 15px;
    color: #6b6b6b;
    font-weight: 500;
}

.jb_foot {
    padding: 20px 0 0 0;
    border-top: 1px solid #dcdcdc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_itm {
    display: flex;
    gap: 45px;
}

.f_b_obx {
    border-right: 1px solid #dcdcdc;
    margin-right: 20px;
    padding-right: 10px;
}

.ex_name_fb {
    font-size: 11px;
    line-height: 19px;
    color: #b9b9b9;
    font-weight: 500;
}

.ex_val_f {
    font-size: 11px;
    line-height: 13px;
    color: #6b6b6b;
    font-weight: 500;
    text-transform: capitalize;
}

.pd_head_on {
    padding: 0 0 0 0;
}

.bg-trans_s_pro {
    background-color: transparent !important;
    border-color: transparent !important;
}

.inp_0_radio input {
    padding: 0 !important;
}

.heart_btn path {
    fill: #fff;
}

.fb_btns_s_pro button:hover {
    background-color: #6d2ef1;
    color: #fff;
}

.fb_btns_s_pro button {
    border-radius: 6px;
    background-color: #6d2ef1;
    border: #6d2ef1 solid 1px;
    margin: 0 2px;
    color: #ffffff;
    font-weight: 600;
    line-height: 16px;
    padding: 12px 30px;
    font-size: 14px;
}

@media screen and (max-width: 320px) {
    .fb_btns_s_pro_res {
        display: flex;
        flex-wrap: wrap;
    }

    .top_main_c_job a {
        margin-top: 13px;
    }
}

.milestone_btn_s button {
    border-radius: 6px;
    background-color: #6d2ef1;
    border: #6d2ef1 solid 1px;
    margin: 0 2px 0 0;
    color: #ffffff;
    font-weight: 600;
    line-height: 16px;
    padding: 12px 11px;
    font-size: 14px;
}

@media screen and (max-width: 1024px) {
    .milestone_btn_s button {
        border-radius: 6px;
        background-color: #6d2ef1;
        border: #6d2ef1 solid 1px;
        margin: 0 2px 20px 0;
        color: #ffffff;
        font-weight: 600;
        line-height: 16px;
        padding: 12px 11px;
        font-size: 12px;
    }
}

@media screen and (max-width: 376px) {
    .milestone_btn_s button {
        padding: 12px 8px;
    }
}

.pagination_node {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
    gap: 10px;
}

.pagi_butt:hover {
    background-color: #6d2ef1;
    color: #fff;
}

.pagi_butt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #707070;
    margin: 0 7px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    color: #3b3b3b;
    font-weight: 600;
    text-align: center;
}

.pagination_dots {
    line-height: 32px;
    margin: 0 10px;
}

.fl_end_b {
    display: flex;
    justify-content: flex-end;
}

.pd_head_on h1 {
    font-size: 21px;
    letter-spacing: 0;
    line-height: 26px;
    color: #170048;
    font-weight: 600;
    word-break: break-word;
}

.over_s_wor_area {
    display: flex;
    border-bottom: #dcdcdc solid 1px;
    padding: 5px 0 15px 0;
}

.proj_det_li {
    padding: 15px 0;
}

.proj_det_li .f_b_obx {
    border: none !important;
}

.fle_wor_box {
    display: flex;
    margin-right: 20px;
    align-items: center;
}

.wor_svg {
    display: flex;
}

.wor_svg svg {
    fill: #ff88fe;
}

.wor_name {
    font-size: 10px;
    padding-left: 5px;
    letter-spacing: 0;
    line-height: 25px;
    color: #747474;
    font-weight: 500;
}

.s_trans_bos {
    background-color: transparent;
    border: #cccccc solid 1px;
    border-radius: 10px;
}

@media screen and (max-width: 991px) {
    .resp_job_det {
        margin-top: 25px;
    }

    .resp_job_det .resp_pad {
        margin-bottom: 0 !important;
    }
}

@media screen and (max-width: 490px) {
    .flex_itm {
        gap: 10px;
    }
}

.proj_proposal_box {
    background-color: #f2f8ff;
    padding: 30px 15px;
    margin-top: 20px;
}

.phead_h3 h3 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 15px;
    color: #170048;
    font-weight: 600;
}

.p_inp_icon {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: #c9dcf2;
    color: #fff;
}

.propo_box {
    background-color: #fff;
    padding: 15px 20px;
    margin: 20px 0;
}

.projec_cli_prof {
    height: 50px;
    width: 50px;
}

.projec_cli_prof img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.pro_cli_det {
    margin-left: 10px;
}

.space_wa {
    margin-right: 5px;
}

.rev_aox {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.rev_aox p {
    margin: 2px 10px 0;
}

.pro_cli_sm_te {
    display: flex;
    align-items: center;
}

.pro_cli_sm_te span svg {
    fill: #ff88fe;
    stroke: #ff88fe;
    margin: 0 1px;
}

.pro_cli_det {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pro_cli_det h3 {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    color: #170048;
    font-weight: 600;
}

.pro_cli_sm_te {
    font-size: 9px;
    line-height: 13px;
    color: #b9b9b9;
    font-weight: 500;
}

.flex_pro_bo {
    display: flex;
}

.bo_bot_pad {
    border-bottom: #707070 solid 1px;
    padding-bottom: 10px;
}

.fill-none {
    fill: #fff !important;
}

.proposal_boxes {
    border-bottom: #9dc1e8 solid 1px;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.send_proposal_num_inp.is-invalid {
    border: none !important;
    padding: 0.375rem 0.75rem;
    background-image: unset !important;
}

.send_proposal_num_inp.is-invalid:focus {
    box-shadow: unset !important;
}

.sent_proposal_pre_img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
}

.sent_proposal_pre_img img {
    width: 100px;
    height: auto;
    object-fit: cover;
}

.invalid-feedback {
    font-weight: 400 !important;
}

.project_details_Num_inp::placeholder {
    color: gray !important;
}

.project_details_Num_inp::-webkit-outer-spin-button,
.project_details_Num_inp::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input_from_tlab input[type="number"] {
    -moz-appearance: textfield;
}

.pd_par_for p {
    margin-top: 10px;
    font-size: 11px;
    line-height: 16px;
    color: #6b6b6b;
    font-weight: 400;
    word-break: break-word;
}

.inp_label {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    color: #828282;
    margin: 8px 0;
    font-weight: 500;
}

.inp_box {
    margin-top: 20px;
    margin-right: 20px;
}

.inp_w_100 .inp_box {
    width: 100%;
}

.inp_input textarea {
    background-color: #fff;
    padding: 10px 20px;
    width: 100%;
    resize: none;
    height: 150px;
    border: #fff solid 1px;
}

.bg_li_grey {
    background-color: #eeeeee !important;
}

.inp_input input {
    background-color: #fff;
    padding: 8px 20px;
    width: 100%;
    max-width: 200px;
    border: #fff solid 1px;
}

.flex_inp_b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.agree_term_b input {
    background-color: #fff;
    padding: 3px;
    margin-right: 5px;
    border: #fff solid 1px !important;
    border-radius: 0;
}

.agree_term_b {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    color: #828282;
    font-weight: 400;
    display: flex;
    padding: 20px 0;
}

.f_agre_fot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
}

.hm_bud {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15px;
    color: #747474;
    margin: 20px 0;
    font-weight: 500;
}

.d_left {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    color: #0777fd;
    font-weight: 600;
    text-align: right;
}

.coevr_photo_no {
    height: 200px;
    width: 100%;
}

.coevr_photo_no img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.no_pad {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.asxee_pad {
    padding: 20px 10px;
}

.job_details_client.about_the_client {
    padding: 0 20px 20px 20px;
}

.job_details_client.about_the_client .heading {
    color: #170048;
    font-weight: 700;
}

.job_details_client.about_the_client .is_varified {
    display: flex;
    align-items: center;
    margin: 10px 0;
    gap: 8px;
}

.job_details_client.about_the_client .is_varified .msg {
    color: #5d5d5d;
    font-size: 12px;
}

.job_details_client.about_the_client .reviews {
    display: flex;
    align-items: center;
    gap: 3px;
    margin: 10px 0;
}

.job_details_client.about_the_client .reviews .client_review svg {
    width: 16px;
}

.job_details_client.about_the_client .about {
    margin-top: 10px;
    margin-bottom: 15px;
}

.job_details_client.about_the_client .about .about_heading {
    color: #6d2ef1;
    font-size: 15px;
    font-weight: 500;
}

.job_details_client.about_the_client .about .about_desc {
    color: #462d7a;
    font-size: 13px;
}

.job_details_client.about_the_client .about2 {
    color: #462d7a;
    font-size: 13px;
    font-weight: 500;
    margin: 15px 0;
}

/* client_recent_history start */
.client_recent_history {
    padding: 20px 20px 0 20px;
}

@media screen and (max-width: 650px) {
    .client_recent_history {
        padding: 0 20px 0 20px;
    }
}

.feeedback_not_given {
    font-weight: 700;
}

.client_recent_history .heading {
    color: #170048;
    font-weight: 700;
    padding-bottom: 8px;
}

.client_recent_history .project {
    padding: 15px 0;
    border-bottom: 1px solid #d8d8d8;
}

.client_recent_history .project:last-child {
    border-bottom: unset;
}

.client_recent_history .project .name {
    color: #363636;
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0;
    word-break: break-word;
    white-space: pre-line;
}

.client_recent_history .project .rating {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 5px;
}

.client_recent_history .project .rating .stars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70px;
}

.client_recent_history .project .rating .date {
    font-size: 13px;
    color: #363636;
}

.client_recent_history .project .desc {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin: 15px 0 0 0;
    white-space: pre-line;
}

.client_recent_history .project .rate_date {
    font-size: 17px;
    color: #000;
    font-weight: 600;
    display: flex;
    gap: 60px;
    margin: 10px 0;
}

/* client_recent_history end */
.mak_prof_img {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    overflow: hidden;
}

.mak_prof_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.fle_xent_p {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.comp_name_h h3 {
    font-size: 18px;
    letter-spacing: -1px;
    line-height: 15px;
    color: #3b3b3b;
    font-weight: 700;
    text-align: center;
}

.view-p_btn {
    display: flex;
    justify-content: center;
}

.view-p_btn button {
    background-color: transparent;
    border: #6d2ef1 solid 1px;
    padding: 9px 20px;
    border-radius: 3px;
    width: 80%;
    font-size: 9px;
    line-height: 13px;
    color: #6d2ef1;
    font-weight: 700;
    text-align: center;
}

.comp_name_p p {
    font-size: 10px;
    margin-top: 20px;
    line-height: 15px;
    color: #6b6b6b;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 400;
    text-align: center;
}

.jp_h3_no h3 {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 37px;
    color: #170048;
    font-weight: 600;
}

.jp_btn_m_no {
    display: flex;
    justify-content: flex-end;
}

.jp_btn_m_no button {
    padding: 12px 30px;
    background-color: transparent;
    border: 2px solid #6d2ef1;
    margin: 5px 5px 0;
    border-radius: 25px;
    font-size: 15px;
    line-height: 13px;
    color: #6d2ef1;
    font-weight: 700;
    text-align: center;
}

.jp_btn_m_no button:hover {
    background-color: #6d2ef1 !important;
    color: #fff !important;
}

.bg-body-wrapper {
    background-image: url("../../public/assets/images/theme-bg.webp");
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 60px;
}

.bg_body {
    min-height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign_form_shapb {
    background-image: url("../../public/assets/images/dots-bg.svg");
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    bottom: 50px;
    height: 45%;
    width: 350px;
}

.verifyOtpHeding {
    display: flex;
    align-items: center;
    gap: 10px;
}

.sign_form_shap {
    background-image: url("../../public/assets/images/Path 4401.svg");
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    top: 50px;
    height: 30%;
    width: 120px;
}

.sign_box {
    box-shadow: 0 14px 46px #33333329;
    background: #ffffff 0 0 no-repeat padding-box;
    padding: 25px 30px;
    margin: 40px auto;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
}

.sign_in_box {
    max-width: 700px !important;
}

.sign_in_box .form-control:focus,
.sign_box .form-control:focus {
    background: none;
}

.sign_u_head h1 {
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    color: #170048;
    margin: 20px 0;
}

.input_bo {
    background: #f5f5fe;
    padding: 11px 15px;
    display: flex;
    align-items: center;
    width: 100%;
}

.input_icoa {
    padding: 0 5px;
    display: flex;
    align-items: center;
}

.input_nodxq {
    position: relative;
    width: 100%;
}

.input_nodxq input::-webkit-input-placeholder {
    color: #170048;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: capitalize !important;
}

.min-width-100 {
    min-width: 100% !important;
}

.input_nodxq input,
.input_nodxq select {
    background-color: transparent;
    border-radius: 0 !important;
    border: none;
    outline: none;
    height: 32px;
    font-size: 16px !important;
    line-height: 30px;
    letter-spacing: 0;
    color: #170048;
    width: 100%;
    padding: 10px;
}

.input_nodxq input[type=file] {
    padding: 4px 10px;
    line-height: initial;
}

.row_quexenwt .skip_btn {
    text-align: end;
    width: 75%;
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
    .slider_headin {
        font-size: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .inpu_date select {
        min-width: 200px !important;
    }
}

@media (max-width: 1200px) {
    .sign_form_shapb {
        display: none;
    }

    .sign_form_shap {
        display: none;
    }
}

@media (max-width: 1023px) {
    .sign_box {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 670px) {
    .freelance_section {
        margin-top: 0;
    }
}

@media only screen and (max-width: 768px) {
    .service_icon img {
        height: 90%;
        width: 85px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .service_icon img {
        width: 90px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .service_icon img {
        width: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .signupRespError {
        top: 34px !important;
    }
}

@media only screen and (max-width: 640px) {
    .help_swip .swiper-slide-active .acc_bxo_h {
        margin-right: 15px !important;
    }
}

@media only screen and (max-width: 570px) {
    .input_group,
    .sign_form_btn_subm {
        flex-wrap: wrap;
    }

    .input_group {
        margin: 0 5px !important;
    }

    .input_group .input_bo {
        margin: 10px 0;
    }

    .sign_legal_not {
        margin-left: 7px !important;
    }
}

@media only screen and (max-width: 550px) {
    .sign_box {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .sign_in_social_btn button {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    .next_b_btn_c button {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .a_fl_with_css a {
        width: 100% !important;
    }

    .a_fl_with_css .fo_btn_c {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .fo_btn_c {
        /* width: 100% !important; */
        margin-right: 10px;
    }

    .signupRespError {
        top: 34px !important;
    }
}

@media only screen and (max-width: 376px) and (min-width: 374px) {
    .signupRespError {
        top: 50px !important;
    }
}

@media only screen and (max-width: 375px) and (min-width: 320px) {
    .signupRespError {
        top: 50px !important;
    }
}

.input_group {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 5px;
}

.input_bo {
    margin: 5px 20px;
}

.sign_form_sm_head h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #170048;
    text-align: center;
    line-height: 40px;
}

.sign_buttons_group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
}

.sign_buttons_group button:hover {
    background-color: #6d2ef1;
    color: #fff;
}

.form-check-input {
    vertical-align: inherit;
    box-shadow: none !important;
}

.form-select:focus,
.form-control:focus {
    box-shadow: inherit;
}

.sign_buttons_group button {
    border-radius: 0;
}

.form-check-input {
    vertical-align: inherit;
}

.sign_buttons_group button {
    border-radius: 0;
}

.sign_buttons_group .userType {
    border: 1px solid #9ba0f161;
    background-color: transparent;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    color: #170048;
    margin: 5px 10px;
    /* margin-left: 0 !important; */
    /* margin-right: 0 !important; */
    max-width: 210px;
    width: 100%;
    min-width: 210px;
}

.sign_buttons_group .userTypeActive {
    border: 1px solid #9ba0f161;
    background-color: #6d2ef1;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    color: #fff;
    margin: 5px 10px;
    max-width: 210px;
    width: 100%;
}

.sign_l_n_text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.sign_l_n_text input {
    border: 1px solid #f3a2cf;
    border-radius: 2px;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.sign_l_n_text label {
    font-size: 12px;
    letter-spacing: 0;
    color: #170048;
    margin-left: 10px;
    line-height: 16px;
    margin-bottom: 4px;
}

.sign_legal_not {
    margin-left: 28px;
}

.sign_l_n_text span {
    color: #f3a2cf;
}

.sign_form_btn_subm {
    display: flex;
    justify-content: center;
    margin: 30px 20px 10px 20px;
}

.sign_form_btn_subm button {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0;
    color: #ffffff;
    text-transform: uppercase;
    background: #210e49 0 0 no-repeat padding-box;
    border-radius: 47px;
    border: #170048;
    line-height: 40px !important;
    padding: 8px 0;
    max-width: 320px;
    width: 100%;
    transition: background-color 0.2s;

    &:hover {
        background: #39285d;
        color: #fff;
    }
}

@media (max-width: 430px) {
    .sign_form_btn_subm button {
        line-height: 37px !important;
        font-size: 15px !important;
        max-width: 318px;
        padding: 10px 0;
    }
}

.sign_in_or {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    color: #170048;
    position: relative;
    margin: 20px 20px;
}

.sign_in_or::before {
    position: absolute;
    left: 0;
    width: 46%;
    height: 1px;
    content: " ";
    margin-top: 16px;
    background-color: #9da0f2;
}

.sign_in_or::after {
    position: absolute;
    right: 0;
    width: 46%;
    height: 1px;
    content: " ";
    margin-top: 16px;
    background-color: #9da0f2;
}

.job_details_or {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    color: #170048;
    position: relative;
    margin: 20px 60px;
}

.job_details_or::before {
    position: absolute;
    left: 0;
    width: 46%;
    height: 1px;
    content: " ";
    margin-top: 16px;
    background-color: #9da0f2;
}

.job_details_or::after {
    position: absolute;
    right: 0;
    width: 46%;
    height: 1px;
    content: " ";
    margin-top: 16px;
    background-color: #9da0f2;
}

.sign_in_social_btn button {
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0;
    color: #ffffff;
    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    margin: 5px 15px;
    font-weight: 300;
}

.sign_in_social_btn button.apple_btn {
    border: #000 solid;
    background-color: #000;
}

.sign_in_social_btn button.google_btn {
    background-color: #4285f4;
    border: #4285f4 solid;
}

.sign_in_social_btn button svg {
    margin: 0 10px 0 0;
}

.sin_dont_accou {
    text-align: center;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0;
    color: #170048;
    margin-top: 30px;
}

.link_don_acc {
    color: #f3a2cf;
}

.chose_cat_box {
    border: 1px solid #d4d4d5;
    margin: 30px 0;
}

.hi_mari h2 {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0 !important;
    color: #00065a !important;
    line-height: 40px;
}

.hi_mari {
    border: none !important;
    padding: 0;
}

.get_insp h3 {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0;
    color: #00065a;
    line-height: 25px;
    margin: 0;
}

.flex_ccb_list {
    display: flex;
    flex-wrap: wrap;
}

.flex_ccb_list .s_b_r {
    margin: 0 40px 0 0;
}

.input_box_ccb {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #d6d6d6;
}

.ccb_inp {
    width: 100%;
}

.ccb_inp input {
    background-color: transparent;
    border: none;
    padding: 0 10px;
    width: 100%;
    outline: none;
    font-size: 12px;
    letter-spacing: 0;
    color: #b7b7b7;
    line-height: 22px;
}

.show_less_likn {
    margin: 15px 0;
}

.show_less_likn a {
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.18px;
    color: #6d2ef1;
}

.ccb_next_btn button {
    background-color: #6d2ef1;
    color: #fff;
    padding: 10px 0;
    max-width: 200px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.panel_pag {
    border: none !important;
}

.panel_pag h3 {
    font-size: 23px;
    line-height: 25px;
    letter-spacing: 0;
    color: #393939;
    font-weight: 400;
}

.panel_pag span {
    color: #00065a;
    font-weight: bold;
}

.categor_bo {
    border: 1px solid #d6d6d6;
    padding: 20px 12px;
    width: 100%;
    margin-bottom: 20px;
}

.cr_cat_name {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0;
    color: #1f1f1f;
}

.cr_cat_rat {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    margin: 30px 0 15px 0;
    color: #393939;
    display: flex;
    align-items: center;
}

.cr_cat_rat svg {
    margin-right: 6px;
}

.cr_sm_ro_bxx {
    height: 55px;
    width: 55px;
    margin-right: -10px;
    border: #fff solid 3px;
    overflow: hidden;
    margin-top: 5px;
    border-radius: 50px;
}

.cr_sm_ro_bxx img {
    height: 100%;
    width: 100%;
    border-radius: 50px;
    object-fit: cover;
}

.flex_cr_im {
    display: flex;
}

.flex_crr_bo {
    display: flex;
    flex-wrap: wrap;
}

.some_el_cr {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0;
    color: #393939;
}

.some_el_cr a {
    color: #6d2ef1 !important;
    font-weight: 600;
}

.no-border {
    border: none !important;
}

.question_box {
    margin: 50px 0;
    text-align: center;
    text-align: -webkit-center;
    padding: 30px 20px;
}

.sub_question_box {
    text-align: center;
    text-align: -webkit-center;
    padding: 30px 20px;
}

.question_h1 h1 {
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0;
    text-align: center;
    color: #00065a;
    overflow: hidden;
    position: relative;
}

.question_h1 h1::before {
    z-index: -1;
    content: " ";
    position: absolute;
    top: 34px;
    left: 30%;
    width: 284px;
    background-color: #ff88fe;
    height: 26px;
}

.question_h2 {
    display: flex;
    justify-content: center;
}

.question_h2 h1::before {
    z-index: -1;
    content: " ";
    position: absolute;
    top: 34px;
    left: 24%;
    width: 284px;
    background-color: #ff88fe;
    height: 26px;
}

@media screen and (max-width: 768px) {
    .question_h2 h1 {
        font-size: 38px;
    }
}

.question_p p {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #393939;
}

.question_p a {
    color: #6d2ef1;
    font-weight: bold;
}

.questionHeading {
    max-width: 74%;
    margin: 0 auto;
}

@media only screen and (max-width: 1440px) {
    .question_p p {
        text-align: justify;
    }

    .question_h1 h1 {
        font-size: 52px;
    }

    .question_h1 h1::before {
        top: 41px;
        left: 26%;
        width: 343px;
        height: 32px;
    }
}

@media only screen and (max-width: 1024px) {
    .question_p p {
        text-align: justify;
    }

    .question_h1 h1 {
        font-size: 44px;
    }

    .question_h1 h1::before {
        top: 37px;
        left: 19%;
        width: 319px;
        height: 29px;
    }
}

@media only screen and (max-width: 820px) {
    .questionHeading {
        max-width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .questionHeading {
        max-width: 100%;
        margin: 0 auto;
    }

    .question_p p {
        text-align: center;
    }

    .question_h1 h1 {
        font-weight: bold;
        font-size: 40px;
    }

    .questionOnebtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .question_h1 h1::before {
        top: 33px;
        left: 18%;
        width: 247px;
        background-color: #ff88fe;
        height: 27px;
    }

    .questionOnebtn {
        width: 100%;
    }
}

@media only screen and (max-width: 425px) {
    .questionHeading {
        max-width: 100%;
        margin: 0 auto;
    }

    .question_p p {
        text-align: justify;
    }

    .question_h1 h1 {
        font-weight: bold;
        font-size: 36px;
    }

    .question_h2 h1 {
        font-size: 28px;
    }

    .questionOnebtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .question_h1 h1::before {
        left: 0;
        width: 203px;
        height: 22px;
    }

    .row_quexenwt {
        justify-content: unset !important;
    }

    .get-startedQuestion {
        width: 150px;
        text-align: center;
        background-color: #6d2ef1;
        color: #fff;
        padding: 10px 0;
        border-radius: 30px;
        text-decoration: none;
        cursor: pointer;
    }

    .questionOnebtn {
        width: 100%;
    }

    .theme_btnsQuestionTwo {
        justify-content: unset !important;
        flex-direction: column;
    }
}

@media only screen and (max-width: 375px) {
    .question_h1 h1 {
        font-size: 24px;
    }

    .question_h1 h1::before {
        top: 33px;
        left: -40%;
        width: 276px;
        height: 18px;
    }

    .question_h2 h1 {
        font-size: 20px;
    }

    .questionHeading {
        text-align: justify;
    }
}

.directContractText {
    letter-spacing: 0;
    color: #393939;
    font-weight: 600;
}

.box_border {
    border: 1px solid #d4d4d5;
}

.questio_heading {
    border-bottom: 1px solid #d4d4d5;
    padding-bottom: 10px;
}

.questioHHHHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questioHHHHeading button {
    border-radius: 30px;
    background-color: #6d2ef1;
    padding: 12px 40px;
    border: #6d2ef1 solid 1px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
}

.f-ffff {
    font-size: 55px !important;
}

.questio_heading h1 {
    text-align: left;
    font-weight: bold;
    font-size: 40px;
    line-height: 35px;
    letter-spacing: 0;
    color: #00065a;
}

@media screen and (max-width: 481px) {
    .Notificationsheadingf {
        font-size: 30px !important;
    }

    .questioHHHHeading {
        flex-direction: column;
    }

    .questioHHHHeading button {
        padding: 8px 30px;
    }
}

@media screen and (max-width: 426px) {
    .questio_heading h1 {
        text-align: left;
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0;
        color: #00065a;
    }

    .blue_lin h1::before {
        z-index: -1;
        content: " ";
        position: absolute;
        top: 25px;
        left: 22% !important;
        width: 220px;
        background-color: #ff88fe;
        height: 14px;
    }
}

@media screen and (max-width: 400px) {
    .blue_lin h1::before {
        left: 15% !important;
    }
}

.on_time_h3 {
    border-bottom: 1px solid #d4d4d5;
    padding: 13px 0;
    text-align: left;
    display: flex;
    align-items: center;
}

.on_time_h3 h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 23px;
    letter-spacing: 0.26px;
    color: #170048;
    margin: 0;
}

@media screen and (max-width: 510px) {
    .resp_serach_freelancer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }

    .resp_serach_freelancer .search_inp_re {
        width: 100% !important;
    }
}

.hovbord-blew {
    border: #6d2ef1 solid 1px !important;
}

.round_b_btn {
    margin: 0 10px;
    background-color: transparent;
    border-radius: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #666666;
    width: 40px;
    transition: background-color .2s;

    &:hover {
        border: 1px solid #6d2ef1;
        background: #e4d6ff;
    }

    svg {
        fill: #6d2ef1;
    }
}

.bi-pencil-square {
    color: #d4d4d5;
}

.mrr_riii {
    margin-right: 30px;
}

.padd_fou {
    padding: 30px 0;
}

.litt_sm_para {
    font-size: 14px;
    letter-spacing: 0;
    color: #878787;
    margin-top: 8px;
    line-height: 22px;
}

.flex_aling {
    display: flex;
    align-items: center;
}

.f-size-to {
    font-size: 21px !important;
}

.label_apra {
    padding-left: 28px;
}

.label_apra p {
    padding: 4px 0 0 0;
}

.tell_bus_p {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    color: #393939;
}

.wel_b_headn {
    font-size: 40px !important;
}

.b_si_hea {
    text-align: left;
    font-weight: bold;
    font-size: 23px;
    line-height: 25px;
    letter-spacing: 0;
    color: #00065a;
    padding: 5px 0;
}

.pad_top_bot {
    padding: 20px 0;
}

.bs_s_card {
    padding: 5px 10px 20px 5px;
    border: var(--input-border);
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;

    .image_cl_pma {
        width: 60px;
        height: 60px;
    }

    img {
        width: 40px;
    }

    svg {
        color: #E4EAEF;
        width: 40px;
        height: 40px;
    }
}

label.bs_s_card:has(input:checked) {
    border-color: var(--color-accent);
}

.bs_s_inpu {
    text-align: right;
}

.bg_hei_n_i input {
    height: 18px;
    width: 18px;
    margin-top: 10px;
}

.font-fiftie {
    font-size: 50px !important;
}

.bs_s_h4 {
    padding: 10px 0;
}

.bs_s_h4 h4 {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    color: #00065a;
}

.blue_lin h1 {
    position: relative;
}

.blue_lin h1::before {
    z-index: -1;
    content: " ";
    position: absolute;
    top: 25px;
    left: 39%;
    width: 220px;
    background-color: #ff88fe;
    height: 14px;
}

.subscriptions .subscription_box_r {
    background-color: #fff;
    position: relative;
    border-radius: 20px;
    padding: 45px 30px 100px 30px;
    text-align: left;
    border: 2px solid #fff;
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    transition: 0.2s;
}

.activePlan_chose {
    border: 2px solid #6610f2 !important;
    box-shadow: #6610f2 0 2px 8px 0 !important;
}

.activePlan_chose .btn_chose_pl {
    border: 1px solid #6610f2 !important;
    color: #fff !important;
    background-color: #6610f2 !important;
}

.buttons_for_choose_plan {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.subscription_body_s {
    background-color: #f2f8ff;
    position: relative;
    z-index: 0;
}

@media (max-width: 1024px) {
    .subscriptions .subscription_box_r {
        padding: 45px 15px 100px 15px;
    }
}

.plan_icon {
    display: flex;
    justify-content: center;
}

.subscription_box_popup {
    border: 1px solid #6d2ef1;
    border-radius: 20px;
    padding: 45px 30px 20px 30px;
    position: relative;
    width: 230px;
    text-align: left;
}

@media only screen and (max-width: 425px) {
    /* .blue_lin h1::before {
    display: none;
  } */
    .font-fiftie {
        font-size: 40px !important;
    }
}

@media only screen and (max-width: 320px) {
    .subscriptionResp {
        width: unset;
        max-width: 110%;
    }

    .font-fiftie {
        font-size: 40px !important;
    }

    .blue_lin h1::before {
        z-index: -1;
        content: " ";
        position: absolute;
        top: 24px;
        left: 18%;
        width: 220px;
        background-color: #ff88fe;
        height: 14px;
    }
}

.job_creation {
    display: flex;
    position: relative;
    align-items: center;
}

.job_desc_box {
    padding: 0 30px 0 0;
}

.sel_icon_box {
    height: 40px;
    /* margin-bottom: 20px; */
    text-align: center;
    padding: 0 10px 0 10px;
}

.sel_icon_box svg {
    fill: #e4eaef;
    height: 40px;
    width: 40px;
}

.sub_radio {
    position: absolute;
    right: 10px;
}

.sub_col_c {
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: center;
}

.sub_radio input {
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #707070;
}

.sub_radio input:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

.sub_sm_tex {
    font: medium 20px/20px;
    letter-spacing: 2.2px;
    color: #bbbbbb;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    margin: 10px 0;
}

.plan_price_new_sub {
    font-size: 50px;
    font-weight: 700;
}

.sub_pric {
    font-weight: bold;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0.8px;
    color: #6d2ef1;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .sub_pric {
        font-size: 24px;
    }
}

.sub_pric span {
    color: #4a4a4a;
    font-size: 24px;
    margin-top: 5px;
}

.sub_list_ul {
    list-style: none;
    padding: 0;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.freeSubsBtn {
    margin-top: 17px;
}

.sub_list_ul li {
    letter-spacing: 0;
    position: relative;
    display: flex;
    line-height: 17px;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
}

.btn_chose_pl {
    background: #fff;
    color: #170048;
    border-radius: 60px;
    padding: 12px 0;

    width: 250px;
    border: 1px solid #170048;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
}

.form_box_bor {
    border: 1px solid #d4d4d5;
    padding: 25px 20px;
    text-align: left;
}

.services_in_plan {
    display: flex;
    gap: 10px;
    width: 250px;
}

.form_box_bor_not {
    border: 1px solid #d4d4d5;
    padding: 25px 0;
    text-align: left;
}

.input_bg_labe {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    color: #1f1f1f;
    margin-top: 10px;
    margin-bottom: 10px;
}

.input_bg_i {
    margin: 5px 0;
}

.input_bg_i input,
.input_bg_i textarea {
    background-color: transparent;
    padding: 10px 20px;
    border: 2px solid #e8e7e7;
    border-radius: 3px;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0;
    outline: none;
    color: #393939;
    width: 100%;
}

.input_bg_i textarea {
    height: 100px;
    padding: 15px 20px;
    resize: none;
}

.inp_agree_che label {
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0;
    color: #545454;
    display: flex;
    padding: 5px 0;
    align-items: center;
}

.inp_agree_che input {
    height: 16px;
    width: 16px;
    accent-color: #6d2ef1;
}

.inp_agree_che p {
    margin: 0 5px;
}

.inport_lin {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0;
    color: #6d2ef1;
    padding: 15px 0;
    cursor: pointer;
    user-select: none;
}

.tb_p_none {
    padding-top: 0;
}

.fbr_h1 h1 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0;
    color: #1f1f1f;
}

.br_para {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #545454;
}

.hei_100 {
    height: 100%;
}

.not_head {
    text-align: left;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0;
    color: #1f1f1f;
    padding: 0 20px;
    margin-bottom: 30px;
}

.notification_box {
    padding: 20px;
    border-bottom: 1px solid #d4d4d5;
    display: flex;
    justify-content: space-between;

    &:last-child {
        border: none;
    }
}

.notification_text {
    text-align: left;
    word-break: break-word;
    letter-spacing: 0;
    color: #1f1f1f;
    cursor: pointer;
    font-size: 15px;
}

.notification_time {
    text-align: left;
    letter-spacing: 0;
    color: #545454;
    font-size: 12px;
}

.cross_btn_r {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 50px;
    cursor: pointer;
}

.cross_btn_r:hover {
    border: 1px solid #d4d4d5;
}

.bg-fff {
    background-color: #fff;
}

.profileDashboard {
    background-color: #0777fd12;
    flex-grow: 1;
}

.min_pad_m {
    padding: 35px 0;
}

@media screen and (max-width: 424px) {
    .resp_all_post_head {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
    }
}

.search_h3_ip h3 {
    text-align: left;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.26px;
    color: #170048;
}

.input_icon_l {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
}

.pr_inp_a {
    position: relative;
}

.s_slides {
    border-bottom: 1px solid #cbcaca;
    padding: 10px 0;
}

.flex_slide_ta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;
}

.YourHiresBorderWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.YourHiresBorder {
    width: 31%;
    border: 1px solid #707070;
    padding: 40px 20px;
    border-radius: 10px;
    text-align: center;
}

.YourHiresBorder h5 {
    font-weight: 600;
    font-size: 20px;
    color: #170048;
    margin-top: 20px;
}

.sli_ta_name {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0;
    color: #000;
}

.sli_ta_nameActive {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0;
    color: #00065a;
}

.slide_btnss {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media (max-width: 767px) {
    .slide_btnss {
        gap: 10px;
    }
}

.client_first_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.next_b_btn_c button {
    min-width: 170px;
    margin: 0 5px 5px 0;
}

.same_size_190px button {
    min-width: 190px;
    margin: 0 5px 5px 0;
}

.min_width_220px button {
    min-width: 220px;
    margin: 0 5px 5px 0;
}

.skills_button {
    border: 1px solid #6d2ef1;
    color: #6d2ef1;
    padding: 5px 20px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
}

.active_btn_blueDiabled {
    background-color: #e4ebe4 !important;
    border: none !important;
    cursor: not-allowed;
    padding: 11px 0 !important;
}

.active_btn_blueDiabledswdfd {
    background-color: #e4ebe4 !important;
    border: none !important;
    cursor: not-allowed;
    padding: 12px 0 !important;
}

.invitationSentDisabled {
    background-color: #e4ebe4 !important;
    color: #9aaa97 !important;
    border: none !important;
    cursor: not-allowed;
    height: 47px;
    font-size: 16px !important;
    margin-top: 6px;
    padding: 8px 0 !important;
}

.active_btn_blue {
    background-color: #6d2ef1 !important;
    color: #fff !important;
    border: #6d2ef1 solid 1px !important;
}

.active_btn_blue:disabled {
    border: none !important;
}

.active_btn_blue a:hover {
    color: #fff !important;
}

.no-bg-image {
    background-image: none !important;
}

.slide_btnss button {
    margin-right: 6px;
    background-color: #eaeef2;
    border-radius: 60px;
    border: 2px #eaeef2 solid;
    padding: 6px 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0;
    color: #393939;
}

.slide_btnss button:hover {
    background-color: transparent;
    border: 1px solid #eaeef2;
}

.slider_shutter {
    display: none;
}

.scop_head {
    margin: 20px 0 10px;
}

.form_card_label {
    width: 100%;
    text-align: center;
}

.job_box_div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.job_boxes {
    width: 65%;
}

.or_on_box {
    display: flex;
    align-items: center;
}

@media (max-width: 1199px) {
    .job_boxes {
        width: 75%;
    }
}

@media (max-width: 767px) {
    .job_box_div {
        width: 45%;
    }

    .job_boxes {
        width: 100%;
    }
}

@media (max-width: 481px) {
    .or_in_resp {
        justify-content: center;
    }

    .job_box_div {
        width: 100%;
    }

    .job_boxes {
        width: 100%;
    }
}

.input_t_lab {
    display: flex;
    align-items: center;
}

.input_from_tlab {
    border: 1px solid #e8e7e7;
    border-radius: 3px;
    color: #828282;
    letter-spacing: 0.2px;
    max-width: 200px;
    font-size: 18px;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
}

.input_ft {
    padding-right: 50px;
}

@media (max-width: 481px) {
    .input_ft {
        padding-right: 5px;
    }
}

.ft_par {
    color: #878787;
    font-size: 14px;
    line-height: 25px;
}

.bi-hebi-heart {
    margin-top: 8px;
}

.input_from_tlab input {
    border: none;
    background-color: transparent;
    outline: none;
    color: #828282;
    font-size: 18px;
    width: 100%;
    text-align: right;
}

.input_from_tlab input::-webkit-outer-spin-button,
.input_from_tlab input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input_from_tlab input[type="number"] {
    -moz-appearance: textfield;
}

.ft_form_linki {
    color: #6d2ef1;
    font-size: 18px;
    padding: 15px 0;
    font-weight: 600;
}

.input_hour {
    padding-left: 5px;
}

.kwxnow h1 {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #393939;
}

.kwxnow {
    border-bottom: #dcdcdc solid 1px;
    padding-bottom: 15px;
}

.all_pos_tex {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: #6d2ef1;
}

.tabs_v_one {
    padding: 20px 0 0 0;
    border-bottom: #dcdcdc solid 1px;
    display: flex;
}

.v_tab_btn {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
    line-height: 30px;
    color: #3b3b3b;
    padding: 10px 15px;
}

.contractor_nam {
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    color: #3b3b3b;
}

.contrc_data {
    margin-left: 8px;
}

.contract_post_tm {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6b6b6b;
}

.contractor_profil {
    height: 55px;
    width: 55px;
    overflow: hidden;
    border-radius: 50px;
}

.contractor_profil img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.v_on_tab_active {
    font-weight: bold;
    color: #6d2ef1;
    border-bottom: #6d2ef1 solid 8px;
}

.tab_data_li {
    border-bottom: #dcdcdc solid 1px;
    padding: 30px 0 0;
}

.draft_time {
    line-height: 20px !important;
}

.tab_data_draft {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.post_head_h1 h1 {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    color: #170048;
}

.post_pric {
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: #6b6b6b;
}

.post_uptime {
    font-size: 12px;
    font-weight: 500;
    line-height: 50px;
    color: #b9b9b9;
}

.count_nam {
    font-size: 12px;
    font-weight: 600;
    line-height: 50px;
    color: #b9b9b9;
}

.count_os {
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    color: #b9b9b9;
}

.pos_fle_ni {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.count_post_dta {
    width: max-content;
    display: flex;
    justify-content: right;
}

.box_con_pos {
    padding: 5px 20px;
}

.draf_menu_icon {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.draf_menu_icon span {
    height: 7px;
    width: 7px;
    margin: 0 2px;
    background-color: #dcdcdc;
    border-radius: 50px;
}

.viewoffbtn {
    padding: 8px 20px;
    border: #6d2ef1 solid 1px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
    color: #6d2ef1;
    background-color: transparent;
    margin-bottom: 20px;
    margin-top: 10px;
}

.offertime_sen {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #6b6b6b;
}

.pending_con_node {
    flex-wrap: wrap;
}

.milestone_text {
    font-size: 14px;
    padding: 10px 0;
    font-weight: 400;
    line-height: 20px;
    color: #6b6b6b;
}

.pay_now_btn {
    border: #6d2ef1 solid 1px;
    background-color: #6d2ef1;
    border-radius: 20px;
    color: #fff;
}

.contract_price {
    font-size: 30px;
    text-align: right;
    padding: 10px 0;
    font-weight: bold;
    line-height: 25px;
    color: #3b3b3b;
}

.in_acc_text {
    font-size: 16px;
    text-align: right;
    font-weight: 500;
    line-height: 25px;
    color: #6b6b6b;
}

.side_box_port {
    border: #707070 solid 1px;
    position: relative;
}

.bg-img-port {
    height: 250px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.bg-img-port img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.port_name {
    font-size: 25px;
    padding: 10px 0;
    text-align: center;
    font-weight: bold;
    line-height: 50px;
    color: #3b3b3b;
}

.port_para {
    font-size: 15px;
    padding: 10px 20px;
    text-align: center;
    font-weight: 400;
    line-height: 17px;
    color: #6b6b6b;
}

.port_lern_btn {
    font-size: 15px;
    padding: 10px 20px;
    text-align: center;
    font-weight: bold;
    line-height: 17px;
    color: #6d2ef1;
    background-color: white;
    border: #6d2ef1 solid 1px;
    border-radius: 6px;
}

.text-left {
    text-align: left !important;
}

.p_lef_20 {
    padding-left: 20px !important;
}

.port_tabs_box {
    border: #bec7cf solid 1px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #bec7cf;
}

.port_tabs_box svg {
    fill: #bec7cf;
}

.custom_nav_profile_drp .dropdown-menu {
    left: -325% !important;
    border-radius: 0 !important;
    border: #c9c9c9 solid 1px;
    padding: 15px 10px;
    width: 250px;
}

.nav_p_d_profile {
    height: 45px;
    width: 45px;
    margin-right: 10px;

    img {
        height: 45px !important;
        width: 45px !important; 
    }
}

.nav_p_d_profile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50px;
    cursor: pointer;
}

.job_haed_m {
    font-size: 25px;
    width: 100%;
    letter-spacing: -1px;
    color: #00065a;
    font-weight: bold;
    margin: 20px 0;
    padding-top: 10px;
    padding-bottom: 0;
}

.new_para_m {
    font-size: 14px;
    color: #545454;
    font-weight: 400;
    white-space: pre-line;
    word-break: break-word;
    overflow: hidden;
}

.f_l_b {
    font-size: 16px !important;
    color: #1f1f1f;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    line-height: 10px !important;
}

.trans_h_b_n {
    font-size: 14px !important;
    color: #1f1f1f;
    font-weight: 400;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    line-height: 10px !important;
}

.trans_h_b_n span {
    color: #6d2ef1 !important;
    font-weight: 600 !important;
}

.hideen_csv_do_btn {
    background-color: #f8f8f8 !important;
    color: #b9bdc1 !important;
    font-weight: bold !important;
    border: #f8f8f8 solid 2px !important;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.my_job_sm_text {
    color: #1f1f1f !important;
    font-size: 20px !important;
    margin: 0 !important;
    line-height: 20px !important;
    text-transform: capitalize;
}

.trans_btn_myjo {
    background-color: #fff !important;
    color: #6d2ef1 !important;
    border: #6d2ef1 solid 1px !important;
}

.mr_btn {
    margin-right: 10px;
}

.ml_btn {
    margin-left: 10px;
}

.yourp_box {
    border: #d4d4d5 solid 1px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.yourp_boxDescription {
    border: #d4d4d5 solid 1px;
    margin-bottom: 20px;
}

.job_na_bol {
    font-weight: 600 !important;
    color: #1f1f1f !important;
    -webkit-box-orient: vertical;
    font-size: 18px !important;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    word-break: break-word;
}

@media screen and (max-width: 767px) {
    .yourp_box .job_na_bol {
        width: 80%;
    }
}

@media screen and (max-width: 424px) {
    .yourp_box .resp_job_post {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
    }

    .resp_job_post .headin_yourp_b {
        width: 100%;
        text-align: center;
    }
}

.headin_yourp_b {
    color: #170048;
    font-weight: 600;
    font-size: 22px;
}

.see_ps_ing a {
    font-size: 16px;
    color: #6d2ef1;
    font-weight: 600;
    text-decoration: underline;
}

.inner_a_sty a {
    font-weight: 600;
    color: #6d2ef1;
    text-decoration: underline;
}

.blue_coloe_brn {
    height: 50px;
    width: 50px;
    display: flex;
    text-align: center;
    padding: 0 !important;
    justify-content: center;
    align-items: center;
    border-color: #6d2ef1 !important;
}

.blue_coloe_brn svg {
    fill: #6d2ef1 !important;
    height: 20px !important;
    width: 20px !important;
    margin: 0;
}

.md_btn_getstarted {
    display: none;
}

.novisible {
    visibility: hidden !important;
}

.msm_active {
    transform: translateX(0%) !important;
}

.nav_close_btn {
    background-color: transparent;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    min-width: 35px;
    outline: none;
    border: none;
}

.nav_close_btn svg {
    fill: #fff;
    height: 30px;
    width: 30px;
}

.navdata_li a {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
}

.navdata_li {
    padding: 13px 20px;
    border-bottom: #fff solid 1px;
    text-align: center;
    color: #fff;
    width: 80%;
    margin-top: 5px;
}

.logout_btn_mob {
    width: 75%;
    margin-top: 15px;
}

.logout_btn_mob button {
    padding: 12px 20px;
    background-color: #6d2ef1;
    text-align: center;
    border-radius: 30px;
    color: #fff;
    font-weight: 700;
    width: 75%;
    border: none !important;
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 22px;
}

.find_swip .swiper-button-next:after,
.find_swip .swiper-rtl .swiper-button-prev:after,
.find_swip .swiper-button-prev:after,
.find_swip .swiper-rtl .swiper-button-next:after {
    /* visibility: hidden; */
}

.t_r_freelancers .swiper-button-next:after,
.t_r_freelancers .swiper-rtl .swiper-button-prev:after,
.t_r_freelancers .swiper-button-prev:after,
.t_r_freelancers .swiper-rtl .swiper-button-next:after {
    visibility: hidden;
}

.find_swip .swiper-button-next,
.find_swip .swiper-button-prev {
    top: 46% !important;
}

.find_swip .swiper-button-next {
    right: -5px !important;
}

.find_swip .swiper-button-prev {
    left: -5px !important;
}

.find_swip .swiper-button-next:after,
.find_swip .swiper-rtl .swiper-button-prev:after,
.find_swip .swiper-button-prev:after,
.find_swip .swiper-rtl .swiper-button-next:after {
    font-size: 25px;
    font-weight: bolder;
    visibility: visible !important;
}

.services_swip .swiper-button-next:after,
.services_swip .swiper-rtl .swiper-button-prev:after,
.services_swip .swiper-button-prev:after,
.services_swip .swiper-rtl .swiper-button-next:after {
    visibility: hidden;
}

.fworld_swip .swiper-button-next:after,
.fworld_swip .swiper-rtl .swiper-button-prev:after,
.fworld_swip .swiper-button-prev:after,
.fworld_swip .swiper-rtl .swiper-button-next:after {
    visibility: hidden;
}

.blue_ater_h_min {
    position: relative;
}

.blue_ater_h_min::before {
    position: absolute;
    top: -108px;
    left: 579px;
    height: 25px !important;
    content: "";
    background-color: #ff88fe;
    width: 390px;
    z-index: -1;
}

@media (max-width: 100px) {
    .services_sec_heading h2 {
        width: 70%;
    }

    .blue_ater_h_min::before {
        top: -108px !important;
        left: 397px !important;
    }

    .services_sec_heading {
        padding: 0 0 70px 0;
    }
}

@media (max-width: 1024px) {
    .blue_ater_h_min::before {
        top: -90px !important;
        left: 296px !important;
        width: 310px;
    }

    .services_sec_heading h2 {
        width: 100%;
        font-size: 50px;
    }

    .blue_ater_h_min::before {
        position: unset;
        display: none;
    }
}

@media (max-width: 481px) {
    .services_sec_heading h2 {
        font-size: 30px !important;
    }

    .services_sec_heading {
        padding: 0 0 30px 0;
    }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
    .shuuter_man {
        width: 105%;
    }
}

@media only screen and (max-width: 768px) {
    .slider_headin {
        font-size: 35px;
        font-weight: 800;
    }

    .free_sec_para {
        padding-left: 10px;
    }

    /* .find-freel-para {
    margin-top: 20px;
  } */
}

@media screen and (max-width: 425px) {
    .find-freel-para {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .fworld_swip .swiper-button-prev,
    .fworld_swip .swiper-rtl .swiper-button-next {
        left: 25px;
    }

    .fworld_swip .swiper-button-next,
    .fworld_swip .swiper-rtl .swiper-button-prev {
        right: 25px;
    }

    .fworld_swip .swiper-button-next:after,
    .fworld_swip .swiper-rtl .swiper-button-prev:after,
    .fworld_swip .swiper-button-prev:after,
    .fworld_swip .swiper-rtl .swiper-button-next:after {
        font-size: 26px;
        color: #e2e2e261;
        font-weight: bolder;
        visibility: visible !important;
    }
}

@media screen and (max-width: 1440px) {
    .free_sec_head h2 {
        font-size: 55px;
    }
}

@media only screen and (max-width: 1200px) {
    .free_sec_para {
        right: unset;
    }
}

@media only screen and (max-width: 1100px) {
    .free_sec_head h2 {
        font-size: 40px;
        line-height: 47px;
        width: 606px;
    }

    .free_sec_para p {
        font-size: 16px;
        margin: auto;
        /* width: 470px; */
    }
}

@media only screen and (max-width: 991px) {
    .freelance_section {
        background-size: contain !important;
    }

    .free_sec_head h2 {
        font-size: 55px;
        line-height: 60px;
        /* width: 100%; */
    }

    .free_sec_para p {
        font-size: 22px;
        /* width: 465px; */
    }
}

@media only screen and (max-width: 768px) {
    .free_sec_para p {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .blue_ater_s::before {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .home_page_resp_over {
        display: none;
    }

    .svg_sup1 img {
        width: 200px;
    }
}

@media only screen and (min-width: 1024px) {
    .svg_sup1 img {
        width: 200px;
    }
}

@media only screen and (max-width: 767px) {
    .svg_sup1 {
        bottom: 30px;
        left: 0;
    }

    .svg_sup1 img {
        width: 200px;
    }

    .services_sec_heading {
        padding: 40px 0;
    }

    .services_sec {
        padding: 0 0;
    }

    .freelance_section {
        padding: 0 0 0 0;
    }

    .free_sec_head2 {
        width: 100%;
        text-align: center;
        padding-bottom: 15px;
    }

    .free_sec_head2 h2 {
        font-size: 25px;
        letter-spacing: 0;
        line-height: 32px;
        color: #00065a;
        font-weight: 700;
    }

    .telent_image2 {
        width: 100%;
    }

    .telent_image2 img {
        width: 100%;
        height: auto;
    }

    .free_sec_para2 p {
        margin-top: 20px;
    }

    .free_sec_para2 p {
        font-size: 16px;
        color: #393939;
        font-weight: 600;
        text-align: justify;
    }

    .find-freel-head h1 {
        width: 100%;
    }

    .find-freel-para p {
        width: 100% !important;
    }

    .f_b_border {
        border: none;
    }

    /* .home_page_resp_over {
    display: none;
  } */
}

@media only screen and (max-width: 670px) {
    .services_sec_heading h2 {
        font-size: 40px;
    }

    .service_icon {
        height: 80px;
    }

    .service_head {
        font-size: 30px;
    }

    .service_para {
        font-size: 23px;
    }

    .md_btn_getstarted {
        display: flex;
        margin-bottom: 100px;
    }

    .freelance_card_profile {
        justify-content: center;
    }

    @media only screen and (max-width: 670px) {
        .find_swip .swiper-button-next,
        .find_swip .swiper-button-prev {
            top: 46% !important;
        }
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: 6px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: 8px;
        right: auto;
    }

    .fworld_swip .swiper-button-prev,
    .fworld_swip .swiper-rtl .swiper-button-next {
        left: 25px;
    }

    .fworld_swip .swiper-button-next,
    .fworld_swip .swiper-rtl .swiper-button-prev {
        right: 25px;
    }

    .fworld_swip .swiper-button-next:after,
    .fworld_swip .swiper-rtl .swiper-button-prev:after,
    .fworld_swip .swiper-button-prev:after,
    .fworld_swip .swiper-rtl .swiper-button-next:after {
        font-size: 26px;
        color: #e2e2e261;
        font-weight: bolder;
        visibility: visible !important;
    }

    .services_swip .swiper-button-next:after,
    .services_swip .swiper-rtl .swiper-button-prev:after,
    .services_swip .swiper-button-prev:after,
    .services_swip .swiper-rtl .swiper-button-next:after {
        color: #e2e2e2;
        font-weight: bolder;
        visibility: visible !important;
    }

    .shuuter_man::before {
        width: 115px;
        top: 25%;
        z-index: 1;
        transform: rotate(157deg);
        left: 0;
        -ms-transform: rotate(157deg);
    }

    .zer_to {
        font-size: 290px !important;
    }

    .freelance_section {
        background-size: cover;
        background-image: none;
        padding-bottom: 0 !important;
    }

    .free_sec_para p {
        text-align: center;
    }

    .find-freel-para {
        padding-left: 5px;
        padding-right: 5px;
    }

    .free_sec_para {
        /* padding: 30px 5px 250px 5px; */
        background-position: bottom center;
        background-size: contain;
    }

    .find-freel-para p {
        font-size: 15px;
    }

    .find_frelance {
        margin-top: 30px !important;
        padding: 80px 0 0 0;
    }

    .zero_four {
        font-size: 200px !important;
    }

    .free_sec_para p br,
    .find-freel-para p br {
        display: none;
    }

    .zer_thr {
        top: -35px !important;
        font-size: 200px;
        text-align: center;
        -webkit-text-stroke-color: #fff;
        opacity: 0.5;
    }

    .mdp-0 {
        padding: 0 !important;
    }

    .ri_twh {
        right: auto !important;
    }

    .free_sec_head h2 {
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .free_sec_head h2 {
        font-size: 25px;
        line-height: 28px;
        text-align: center;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .freelance_section {
        width: 100%;
        padding: 5px 0;
        /* margin-bottom: 120px; */
    }

    .blue_ater_s::before {
        width: 130px;
    }

    .free_sec_para {
        width: 100%;
        margin: 0 auto;
        padding: 0 !important;
    }

    .free_sec_para p {
        font-size: 14px;
        width: unset;
    }
}

.f-ffive {
    font-size: 45px !important;
}

.or-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pnew_bud {
    position: relative;
    padding: 20px 25px 35px 25px !important;
}

@media (max-width: 767px) {
    .pnew_bud {
        padding: 20px 5px 15px 5px !important;
    }
}

.bud_news_para {
    padding: 10px 0 0 0;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
    color: #3b3b3b;
    font-weight: 500;
    word-break: break-word;
}

.bud_news_para span {
    font-weight: 700;
}

.home_web_and_tab {
    position: absolute;
    top: 10%;
    z-index: 2;
    padding: 0 30px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .home_web_and_tab {
        top: 20%;
    }
}

.freelance_section_gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    background: linear-gradient(90deg, #fff 50%, #0000);
}

.bud_new_l_tex {
    font-weight: 400 !important;
    color: #828282 !important;
    font-size: 16px !important;
    margin: 0 !important;
}

.lign_tex {
    color: #828282 !important;
    font-size: 16px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.new_sub_rad {
    right: 20px !important;
    top: 25px !important;
}

.tab_scroll_cont {
    flex-wrap: nowrap !important;
    width: 100%;
    position: relative;
}

.tab_scroll_cont::-webkit-scrollbar {
    display: none;
}

.tab_scroll_cont .tab_btn_vs:hover,
.tab_btn_vs:hover {
    background-color: #fff;
    border-bottom-color: #6d2ef1;
    color: #6d2ef1;
    cursor: pointer;
}

.tab_scroll_cont .tab_btn_vs {
    min-width: max-content;
}

.tab_scroll_cont .tab_btn_vs:active {
    background-color: #fff !important;
    color: #6d2ef1 !important;
}

.text-transform-cap {
    text-transform: capitalize !important;
}

.contract_right_btns {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: -3px 0 0 6px;
}

.contract_right_btns .three_dots {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    font-weight: 600;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    padding: 0 0 12px 0;
    cursor: pointer;
    font-size: 20px;
}

@media screen and (max-width: 370px) {
    .contract_right_btns {
        height: 100%;
        align-items: center;
    }

    .contract_right_btns .three_dots {
        width: 30px;
        height: 30px;
    }
}

.contract_right_btn {
    position: absolute;
    right: 50px;
    display: flex;
    z-index: 99;
    flex-direction: column;
    width: 150px;
    border: 1px solid #d1cece;
    background-color: #fff;
    padding: 4px 0;
}

.contract_right_btn a,
.contract_right_btn button {
    border: none;
    text-align: start;
    padding: 5px 14px;
    width: 100%;
    appearance: none;
    background: #fff;
}

.contract_right_btn a:hover,
.contract_right_btn button:hover {
    background-color: #f2f2f2;
}

.fill-black-imp {
    fill: #000 !important;
}

.mr_sign_in_n {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.signup_social_btn_n button {
    max-width: 100% !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    margin: -4px 0 !important;
}

@media only screen and (max-width: 550px) {
    .sme_next_bbtn button {
        height: 40px !important;
        max-width: 360px !important;
    }

    .mob_jus_cent {
        display: flex;
        justify-content: center !important;
    }
}

@media screen and (max-width: 767px) {
    .box_area_freelanec {
        /* transform: translate(50%, -10%); */
        position: relative;
        transform: unset;
        right: unset;
        width: 100%;
    }

    .freelance_box p,
    .freelance_box h2 {
        max-width: none;
        padding: 2px 30px;
    }

    .freelance_head {
        height: 60px;
    }

    .free_sec_para {
        width: unset;
    }

    .home_web_and_tab {
        top: 5%;
    }
}

@media screen and (max-width: 500px) {
    /* .box_area_freelanec {
    transform: translate(50%, 20%);
  } */
    .freelance_box p,
    .freelance_box h2 {
        max-width: none;
        padding: 2px 6px;
    }
}

@media screen and (max-width: 375px) {
    /* .box_area_freelanec {
    transform: translate(50%, 40%);
  } */
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    fill: #6d2ef1;
}

.signup_custom_select .css-1s2u09g-control {
    border: none !important;
    background-color: transparent !important;
}

.signup_custom_select .css-1okebmr-indicatorSeparator {
    display: none;
}

.signup_custom_select .css-tlfecz-indicatorContainer {
    color: #170048;
}

.signup_custom_select .css-1pahdxg-control {
    border: none !important;
    background-color: transparent;
    box-shadow: none;
}

.signup_custom_select .css-1pahdxg-control:hover {
    border: none !important;
    background-color: transparent;
    box-shadow: none;
}

.signup_custom_select .css-6j8wv5-Input {
    padding: 0 !important;
}

.selec_n_cs {
    padding: 7px 15px;
}

.signup_custom_select .signInError {
    left: -3px;
    top: 48px;
}

.borderError {
    border: 1px solid #ea2121;
}

.input_nodxq input::-webkit-input-placeholder {
    color: #808080;
}

.original_mess .greet,
.message_from_client,
.client_name_ {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 15px;
    padding-top: 10px;
    color: #545454;
}

.my_reports_ {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 10px;
    border-bottom: #ebebed solid 1px;
}

.my_reports_ .report_name .report_heading {
    color: #6d2ef1;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;
}

.my_reports_ .report_name .report_time {
    font-size: 14px;
}

.testimonial_bg {
}

.testimonial_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 650px;
    width: 95%;
    padding: 40px 30px;
    background: #ffffff 0 0 no-repeat padding-box;
    min-height: 200px;
}

.testimonial_bg .testimonial_heading {
    max-width: 650px;
    width: 95%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 425px) {
    .testimonial_heading h2 {
        font-size: 20px;
    }
}

.testimonial_container {
    display: unset;
}

.testimonial_container .about_requester {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.testimonial_container .testimonial_about {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.testimonial_container .testimonial_about .test_name {
    color: #6d2ef1;
}

.testimonial_container .testimonial_about .test_designation {
    color: rgb(55, 54, 54);
}

.testimonial_container .about_requester .testimonial_img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    overflow: hidden;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
}

.testimonial_container .about_requester .testimonial_img img {
    height: 100%;
    border: 1px solid #707070;
    width: 100%;
    border-radius: 50px;
    object-fit: cover;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
}

.submit_testimo_inp {
    margin-top: 30px;
}

.submit_testimo_inp label {
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
}

.submit_testimo_inp textarea {
    width: 100%;
    height: 110px;
    resize: none;
    border: 1px solid rgb(190, 190, 190);
    outline: none;
    font-size: 12px;
    padding: 10px;
    border-radius: 0;
}

.submit_testimo_inp textarea::placeholder {
    font-size: 12px;
}

.your_info {
    margin: 35px 0;
}

.your_info .heading {
    font-weight: 500;
    margin-bottom: 10px;
}

.your_info .name,
.your_info .title {
    display: flex;
    font-size: 14px;
    align-items: center;
    /* justify-content: space-between; */
}

.your_info .edit_name,
.your_info .edit_title {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
}

.your_info .name span,
.your_info .title span {
    width: 50%;
}

.your_info .edit_name input,
.your_info .edit_title input {
    border: 1px solid #c9c8c8;
    outline: none;
    margin: 5px 0;
    height: 40px;
    padding: 0 10px;
}

.your_info .heading a {
    font-weight: 500;
    color: #6d2ef1;
    margin-left: 10px;
}

.your_info .heading a:hover {
    color: #2e72f1;
}

.testimonial_btn {
    margin: 10px 0;
}

.testimonial_btn button {
    min-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #6d2ef1;
    font-weight: 600;
    border-radius: 20px !important;
    text-transform: uppercase;
    padding: 8px 40px;
    margin-top: 5px;
    margin-right: 15px;
    border: #6d2ef1 solid 1px;
    -webkit-border-radius: 60px !important;
}

.testimonial_info_btn button {
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6d2ef1;
    background-color: #fff;
    font-weight: 600;
    border-radius: 20px !important;
    text-transform: uppercase;
    padding: 6px 45px;
    margin-top: 5px;
    margin-right: 15px;
    border: #6d2ef1 solid 1px;
    -webkit-border-radius: 60px !important;
}

.testimonial_info_btn button:hover {
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #6d2ef1;
    font-weight: 600;
    border-radius: 20px !important;
    text-transform: uppercase;
    padding: 6px 45px;
    margin-top: 5px;
    margin-right: 15px;
    border: #6d2ef1 solid 1px;
    -webkit-border-radius: 60px !important;
}

.testimonial_info {
    font-size: 12px;
    max-width: 650px;
    width: 95%;
    margin-top: 6px;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
    background-color: #fff;
    color: #6d2ef1;
}

/* .hov_pple:hover,
.popup_btns_new .trans_btn:hover {
  background-color: #6d2ef1 !important;
  color: #fff !important;
} */

.form-check-input:checked {
    background-color: #6d2ef1 !important;
    border-color: #6d2ef1 !important;
}

/* .hover-transparent:hover {
  background-color: #fff !important;
  color: #6d2ef1 !important;
  border: #6d2ef1 solid 1px !important;
} */

.b-blew-bnt {
    border: transparent solid 1px !important;
}

.btn_group_be button {
    border: transparent solid 1px !important;
}

/* .btn_group_be button:hover {
  background-color: #6d2ef1 !important;
  border: #6d2ef1 solid 1px;
  color: #fff !important;
} */

.btn_group_be .active_btn_be:hover {
    background-color: #6d2ef1 !important;
    color: #fff !important;
}

.min-w-2hpx {
    min-width: 200px !important;
}

/* job post pagination */

.PageActive {
    background-color: #6610f2 !important;
    color: white !important;
}

.signup-error {
    color: red;
    font-weight: 300 !important;
    font-size: 12px !important;
}

/* Freelancer intro start */

.container_border {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: 30px 0;
}

.whatdoyoudo .heading {
    color: #00065a;
    font-size: 26px;
    font-weight: 700;
    margin: 10px 0;
}

.whatdoyoudo .desc {
    margin-bottom: 10px;
    color: #393939;
    font-size: 14px;
}

.whatdoyoudo .search_category {
    width: 100%;
    display: flex;
    position: relative;
}

.whatdoyoudo .search_category svg {
    position: absolute;
    top: 18px;
    width: 18px;
    left: 12px;
}

.whatdoyoudo .search_category input {
    width: 100%;
    border: 1px solid #d4d4d5;
    border-radius: 0;
    padding: 10px 20px 10px 15px;
    margin: 10px 0;
    outline: none;
}

.whatdoyoudo .search_category input::placeholder {
    color: #a1a1a1;
    font-weight: 400;
    font-size: 14px;
}

.servicebtn {
    margin-top: 20px;
}

.theme_btns {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    flex-wrap: wrap;
    gap: 10px;
}

.theme_btns button {
    min-width: 160px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: bold;
    border-radius: 20px !important;
    padding: 8px 45px;
    /* margin-top: 5px; */
    -webkit-border-radius: 60px !important;
    margin-top: 10px;
    border: none;
}

.theme_btnsQuestionTwo {
    /* display: flex; */
    padding: 20px 0;
    /* flex-wrap: wrap;
  align-items: center; */
    /* justify-content: space-between; */
}

.theme_btnsQuestionTwo button {
    min-width: 160px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: bold;
    border-radius: 20px !important;
    padding: 8px 45px;
    margin-right: 15px;
    -webkit-border-radius: 60px !important;
    margin-top: 10px;
    border: none;
}

.theme_btnsQuestionTwo .first_button {
    color: #6d2ef1;
    background-color: #fff;
    border: #6d2ef1 solid 1px;
}

.theme_btnsQuestionTwo .second_button {
    color: #fff;
    background-color: #6610f2;
    border: #6d2ef1 solid 1px;
}

.questionTwobtn {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

.skip_for_now_two {
    text-align: end;
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
    .servicebtn {
        margin-top: 0;
    }

    .trashRespBtn {
        height: 40px;
        margin-top: 0;
        padding-top: 0;
    }

    .langTrasRespBtn {
        align-items: flex-end;
        margin-top: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 425px) {
    .questionTwobtn {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .resp_skil {
        font-size: 16px !important;
        text-align: left !important;
    }
}

@media screen and (max-width: 425px) {
    .theme_btns button {
        width: 230px;
        margin: 0 auto;
    }

    .theme_btns .first_button {
        margin-bottom: 20px;
    }

    .theme_btnsQuestionTwo button {
        width: 230px;
        margin: 0 auto;
    }

    .theme_btnsQuestionTwo .first_button {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 425px) {
    .startIntroIn .langResp {
        text-align: center;
        font-size: 23px;
    }

    .takeTimeRespBtn {
        display: flex;
        padding: 40px 0;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    .startIntroFillOut {
        display: flex;
        margin: 27px auto 0 auto !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .startIntroBox .eduUniResp {
        font-size: 19px;
        text-align: start;
    }

    .startIntroIn .eduRespHead {
        /* line-height: 20px ; */
        font-size: 26px;
    }

    .eduSubHeaderResp {
        line-height: 24px;
        font-size: 14px;
        text-align: center;
    }
}

@media only screen and (max-width: 415px) and (min-width: 390px) {
    .startIntroIn .langResp {
        text-align: center;
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 375px) {
    .container_border .eduResp {
        padding: 12px;
    }

    .startIntroBox .eduUniResp {
        font-size: 16px;
        text-align: start;
    }

    .startIntroIn .eduRespHead {
        font-size: 20px;
    }

    .startIntroIn h5 {
        text-align: center !important;
        font-size: 19px !important;
    }

    .resp_skil {
        font-size: 16px !important;
        text-align: left !important;
    }
}

@media only screen and (max-width: 320px) {
    .container_border .eduResp {
        padding: 2px;
    }

    .startIntroIn .eduRespHead {
        line-height: 20px;
        font-size: 18px;
    }
}

.theme_btns .first_button {
    color: #6d2ef1;
    background-color: #fff;
    border: #6d2ef1 solid 1px;
}

.theme_btns .second_button {
    color: #fff;
    background-color: #6610f2;
    border: #6d2ef1 solid 1px;
}

.theme_btns .second_button:disabled {
    background-color: #b1b1b1;
    border: #b1b1b1;
}

.preview_space {
    padding-bottom: 0 !important;
}

.preview_space2 {
    padding-top: 0 !important;
}

.startIntroIn {
    padding: 20px;
}

.startIntroIn svg {
    cursor: pointer;
}

.startIntroIn h5 {
    color: #00065a;
    font-weight: 700;
}

.resp_skil {
    color: #00065a;
    font-weight: 700;
    margin: 0 0 0 10px;
}

.startIntroIn .experience_skip {
    color: rgb(109, 46, 241);
    font-size: 14px;
    font-weight: 600;
    text-align: end;
}

@media only screen and (max-width: 767px) {
    .startIntroIn {
        padding: 0;
    }
}

@media only screen and (max-width: 320px) {
    .container_border .eduResp {
        padding: 14px;
    }

    .startIntroIn {
        padding: 14px;
    }

    .startIntroIn h5 {
        text-align: center !important;
        font-size: 15px !important;
    }

    .startIntroTwoP {
        text-align: center;
        font-size: 14px;
    }

    .languageOption {
        display: flex;
        justify-content: space-between;
        background: #83b4ef3d;
        padding: 12px;
        color: #1f1f1f;
        font-size: 14px;
        line-height: 27px;
        font-weight: 700;
    }

    .startIntroFillOut {
        display: flex;
        margin: 27px auto 0 auto !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.startIntroP {
    font-size: 12px;
    margin-top: 10px;
}

.startIntroP2 {
    font-size: 13px;
    line-height: 22px;
}

.startIntroFillOut {
    border: 1px solid #d6d6d6;
    width: 200px;
    margin: 35px 0 0 0;
}

.startIntroRadiobtn {
    display: flex;
    justify-content: flex-end;
    padding-top: 4px;
    padding-right: 7px;
}

.startIntroSVG {
    display: flex;
    justify-content: center;
}

.startIntroIcon {
    height: 50px;
    width: 62px;
}

.startIntroText {
    font-size: 12px;
    text-align: center;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 15px;
    color: #00065a;
    margin-bottom: 5px;
}

.startIntroTwo {
    border: 1px solid #d6d6d6;
    margin-top: 10px;
}

.startIntroTwoH5 {
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
}

.startIntroTwoP2 {
    font-size: 14px;
    line-height: 26px;
    color: #393939;
    padding: 5px 0;
}

.startIntroBox {
    border: 1px solid #d6d6d6;
    padding: 10px;
    min-height: 170px;
    margin: 20px auto;
    color: #4d4d4d;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 80%;
}

.word_breaking {
    word-break: break-word;
}

.startIntroBoxAdd {
    border: 1px solid #d6d6d6;
    padding: 10px;
    height: 170px;
    margin: 20px auto;
    color: #4d4d4d;
    /* height: 80%; */
}

.startIntroBoxes {
    display: flex;
}

.startIntroExp2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;
}

.startIntroSkil {
    margin: 0 0 0 10px;
    line-height: 26px;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.startIntroTwoDate {
    font-size: 12px;
    margin: 0 0 0 10px;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.startIntroCompny {
    margin: 0 0 0 10px;
    font-size: 14px;
    line-height: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.startIntroTwoIcon {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.startIntroTwoIcon svg {
    width: 30px;
    cursor: pointer;
}

.profile_intro_add_col {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.profile_intro_add_col svg {
    cursor: pointer;
}

.afterSignUpSklls {
    font-size: 16px;
    margin: 0;
    color: #000;
    font-weight: 600;
}

.AboutYrSelfChar {
    font-size: 16px;
    margin: 0;
    color: #000;
    font-weight: 600;
}

.maxlabel_atcxt2 {
    color: #9c9a9f;
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    font-size: 12px;
}

.maxlabel_atcxt2_and_errors {
    display: flex;
    justify-content: space-between;
    color: #9c9a9f;
    font-weight: 400;
    margin-top: 5px;
    font-size: 12px;
}

.upload_certificate {
    background-color: #6d2ef1;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    border-radius: 60px;
    padding: 8px 15px;
    margin-top: 5px;
    min-width: 160px;
    margin-right: 15px;
    border: #6d2ef1 solid 1px;
}

.show_certificate {
    margin-bottom: 10px;
}

.show_more_description {
    cursor: pointer;
    color: #833df5;
    font-weight: 500;
    font-size: 14px;
}

.whatdoYoudo-head {
    color: #00065a !important;
    font-size: 14px !important;
    font-weight: 550 !important;
    line-height: 35px !important;
}

@media only screen and (max-width: 425px) {
    .whatdoYoudo-head {
        color: #00065a !important;
        font-size: 13px !important;
        font-weight: 550 !important;
        line-height: 35px !important;
    }

    .langResp {
    }
}

@media only screen and (max-width: 375px) {
    .whatdoYoudo-head {
        color: #00065a !important;
        font-size: 13px !important;
        font-weight: 550 !important;
        line-height: 18px !important;
    }
}

@media only screen and (max-width: 320px) {
    .whatdoyoudo {
        padding: 14px;
    }
}

.AfterSignUpYrselfTxt {
    font-size: 12px;
}

.services-choose {
    border: #d6d6d6;
}

.services-offer-option {
    font-size: 12px;
}

.hourly-rate {
    color: #393939;
    font-size: 16px;
    margin-bottom: 5px;
}

.hourly-rate2 {
    color: #878787;
    font-size: 14px;
}

.input_houry_price {
    border: #b9c9dc solid 1px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 30px !important;
    color: #8f8f8f;
    padding: 3px 20px;
    align-items: center;
}

.input_houry_price input {
    border: none;
    outline: none;
}

.pbx_pdd_sp2 {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: #d4d4d5 solid 1px;
}

.hourly-price_rsph {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    color: #828282;
    font-size: 14px;
}

.slsh_hr {
    font-size: 14px !important;
    line-height: 30px !important;
    display: flex;
    align-items: center;
    color: #828282;
    margin-left: 10px;
}

.addSkillBox {
    border: 1px solid #d4d4d5;
    padding: 5px 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 61px;
}

.addSkillBox > div {
    width: 100%;
}

@media screen and (max-width: 376px) {
    .addSkillBox .skill_bxr_gry {
        font-size: 11px;
    }
}

.publishPhoto {
    display: flex;
    align-items: center;
    gap: 23px;
}

.uploadPublish_button {
    min-width: 130px;
    max-height: 40px;
    justify-content: center;
    font-weight: bold;
    border-radius: 20px !important;
    padding: 8px 28px;
    -webkit-border-radius: 60px !important;
    border: 1px solid #6d2ef1;
    background: none;
    font-size: 12px;
    color: #6d2ef1;
    cursor: pointer;
}

/* .popup_uploadProfileImg {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
} */
.uploadPublishPhoto {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;
}

.uploadPublishPhoto .image_to_show {
    width: 100px;
    height: 100px;
}

.uploadPublishPhoto .image_to_show img {
    /* width: 320px;
  height: 224px; */
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
}

.uploadPublishPhoto .image_to_show .reactEasyCrop_CropArea {
    width: 250px !important;
    height: 250px !important;
    border-radius: 50%;
}

.uploadPublishPhoto .image_to_show svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.publishPhotoImg .image_to_show {
    width: 100px;
    height: 100px;
}

.publishPhotoImg .image_to_show img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.publishPhotoImg .image_to_show svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.uploadImgEror {
    color: red;
    font-size: 12px;
    text-align: center;
}

.uploadImageDetails {
    color: #393939;
    padding-left: 32px;
    font-size: 14px;
    line-height: 28px;
}

.uploadImageDetails li::marker {
    color: #6d2ef1;
    font-size: 1em;
}

.uploadImgDetailsTitle {
    color: #393939;
    font-weight: bold;
    font-size: 19px;
    line-height: 30px;
}

.priviewSubmitButton {
    min-width: 160px;
    background-color: #6d2ef1;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;
    border-radius: 20px !important;
    padding: 8px 47px;
    margin-right: 15px;
    -webkit-border-radius: 60px !important;
    margin-top: 10px;
    border: none;
}

.previewImageIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.preview-profile-button {
    min-width: 100px;
    color: #6d2ef1;
    font-size: 12px;
    display: flex;
    padding: 7px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background: white;
    border-radius: 20px !important;
    margin-top: 5px;
    -webkit-border-radius: 60px !important;
    border: 1px solid #6d2ef1;
    cursor: pointer;
}

.previewDet {
    padding-top: 10px;
}

.preview-profileThumbsIco {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.preview-profileThumbsIco img {
    width: 100px;
    height: 100px;
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .changePasswordRespPopup .popup_form_element {
        width: 100%;
    }

    .changePasswordRespPopup .showPassFreelancer {
        cursor: pointer;
        position: absolute;
        top: 51px;
        right: 10px;
        color: #aaaaaa;
    }
}

@media screen and (max-width: 768px) {
    .preview-profileThumbsIco {
        display: none;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .respUniversityName {
        max-width: 70%;
    }
}

@media screen and (max-width: 320px) {
    .respUniversityName {
        max-width: 70%;
    }

    .startIntroIn {
        padding-top: 0;
    }
}

@media screen and (max-width: 425px) {
    .publishPhoto {
        display: flex;
        flex-direction: column;
    }

    .popup_uploadProfileImg {
        display: flex;
        flex-direction: column;
    }

    .popup_uploadProfileImg .popup_form_element {
        width: 100%;
        padding: 0;
    }

    .theme_btns .first_button {
        margin-bottom: 20px;
    }
}

.preview-position {
    margin-right: 7px;
    word-break: break-word;
}

.edit_and_delete_icon {
    display: flex;
    align-items: center;
    gap: 8px;
}

.skillsDesign {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 8px;
    margin-bottom: 10px;
}

.skillsDesign_btn {
    border: none;
    border-radius: 20px;
    font-size: 11px;
    padding-left: 15px;
    padding-right: 14px;
    color: #393939;
}

.pbx_pdd_sp3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: #d4d4d5 solid 1px;
}

.previewWorkExp {
    display: flex;
    align-items: center;
    gap: 7px;
}

.preview-profileDate {
    font-size: 12px;
    color: #878787;
}

.previewFirstDiv {
    line-height: 25px;
}

.languageOption {
    display: flex;
    background: #83b4ef3d;
    padding: 10px;
    color: #1f1f1f;
    font-size: 14px;
    line-height: 27px;
    font-weight: 700;
}

.langAdd {
    margin: 0 10px;
}

.langLevel {
    margin: 0 10px;
}

.languageOptionName {
    display: flex;
    padding: 10px;
    color: #1f1f1f;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
}

.langSelect {
    color: #828282;
    border: 1px solid rgb(232, 231, 231);
    padding: 10px;
    width: 50%;
    margin-left: 50px;
}

.langPlus {
    display: flex;
    align-items: center;
    gap: 10px;
}

.langPlusIcon {
    font-size: 22px;
    color: #6d2ef1;
    margin-left: 5px;
    margin-right: 10px;
}

.langAdd2 {
    color: #00065a;
    flex-grow: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    cursor: pointer;
}

.langAdd2 {
    color: #00065a;
    flex-grow: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
}

@media screen and (max-width: 575px) {
    .resp_frel_horly {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .startIntroTwoH5 {
        line-height: 28px;
    }

    .langAdd2 {
        color: #00065a;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
    }

    .languageOption {
        font-size: 13px;
        justify-content: space-between;
    }

    .langLevel {
        display: flex;
        justify-content: flex-end;
    }

    .langChoseDrop {
        display: flex;
        justify-content: flex-end;
        margin-right: -10px;
        margin-left: 0;
        width: 100%;
    }

    .langSelect {
        margin-left: 0;
        width: 75%;
    }

    .startIntroTwoP {
        line-height: 24px;
        text-align: center;
        font-size: 14px;
    }
}

@media screen and (max-width: 325px) {
    .startIntroTwoH5 {
        line-height: 28px;
    }

    .languageOption {
        font-size: 12px;
    }

    .langLevel {
        display: flex;
        justify-content: flex-end;
    }

    .container_border {
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
    }

    .langChoseDrop {
        margin-right: 10px;
    }

    .langSelect {
        width: 100%;
        margin-left: 22px;
    }

    .langAdd2 {
        color: #00065a;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
    }
}

@media screen and (max-width: 768px) {
    .langSelect {
        width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .resp_preview_div .resp_preview1 {
        width: 100%;
    }

    .resp_preview_div .resp_preview2 {
        width: 100%;
    }
}

/* Freelancer intro end */

.textPage .title h1 {
    color: #00065a;
    margin-bottom: 20px;
}

/* how it works start */
/* header start */
.how_it_works {
    position: relative;
}

.how_it_works .header {
    position: relative;
    background-color: #6d2ef1;
    z-index: 0;
    padding: 20px 20px 0 20px;
}

.how_it_works .header_left {
    margin: 20px 0;
    color: #fff;
    width: 60%;
    padding: 60px 0;
}

.how_it_works .header_left .heading,
.how_it_works .header_left .desc,
.how_it_works .header_left .signup_btn {
    /* margin-left: auto; */
    width: 80%;
}

.how_it_works .header_left .heading {
    font-size: 60px;
    margin-top: 5px;
    line-height: 65px;
}

.how_it_works .header_left .desc {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 300;
    color: #d3d3d3;
}

.how_it_works .header_left .signup_btn button {
    color: #6d2ef1;
    padding: 10px 40px;
    border-radius: 40px;
    font-weight: 600;
    background: #fff;
    cursor: pointer;
    border: 1px solid #fff;
    margin: 10px 0 20px 0;
}

.how_it_works .header_left .signup_btn button:hover {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
}

.how_it_works .header_img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 45%;
    display: flex;
}

.how_it_works .header_img img {
    height: auto;
    width: 65%;
    margin: 0 auto;
}

@media screen and (max-width: 1024px) {
    .how_it_works .header_left .heading {
        font-size: 50px;
        margin-top: 0;
        line-height: 55px;
    }

    .how_it_works .header_left .desc {
        font-size: 14px;
    }

    .how_it_works .header_left {
        padding: 0 0 30px;
    }

    .how_it_works .header_img img {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .how_it_works .header_left .heading,
    .how_it_works .header_left .desc,
    .how_it_works .header_left .signup_btn {
        width: 100%;
        margin-left: 10px;
    }

    .how_it_works .header_left .heading {
        font-size: 40px;
        line-height: 45px;
    }

    .how_it_works .header_img {
        width: 40%;
    }

    .how_it_works .header_img img {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .how_it_works .header_img {
        display: none;
    }

    .how_it_works .header_left {
        width: 100% !important;
        padding: 10px 0;
    }

    .how_it_works.heading {
        font-size: 38px !important;
    }
}

@media screen and (max-width: 500px) {
    .how_it_works .header_img {
        display: none;
    }

    /* .how_it_works .diffrent_ways .tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
  .how_it_works .diffrent_ways .tabs {
    min-width: max-content;
  } */
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .how_it_works .header_img {
        display: none;
    }

    .how_it_works .header_left {
        width: 100% !important;
        padding: 10px 0;
    }

    .how_it_works .heading {
        font-size: 25px !important;
    }

    .how_it_works .award_winning_platform .right_icon {
        margin-left: 5px !important;
    }

    .how_it_works .award_winning_platform .heading {
        font-size: 20px !important;
    }

    /* .top_freelancers .top_freelancer {
    color: #3b3b3b;
    width: 100%;
    padding: 20px 30px;
  } */
}

@media screen and (max-width: 320px) {
    .how_it_works .award_winning_platform .heading {
        font-size: 20px !important;
    }

    .how_it_works .award_winning_platform .desc {
        padding-right: 16px !important;
    }
}

/* header end */
/* section 1st start */
.how_it_works .diffrent_ways .heading {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.how_it_works .diffrent_ways .tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 20px;
}

.how_it_works .diffrent_ways .tab {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    font-weight: 500;
    padding: 0 10px 15px 20px;
    color: #979797;
    cursor: pointer;
}

.how_it_works .diffrent_ways .active {
    border-bottom: 5px solid #6d2ef1;
    color: #170048 !important;
}

.how_it_works .diffrent_ways .tab sup {
    top: 0.5em;
    font-size: 10px;
    font-weight: 600;
    color: #170048;
}

.how_it_works .diffrent_ways .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    position: relative;
    margin-bottom: 40px;
}

.how_it_works .diffrent_ways .items .relation1 {
    position: absolute;
    top: 50px;
    right: -157px;
}

.how_it_works .diffrent_ways .items .relation2 {
    position: absolute;
    top: 5px;
    right: -157px;
}

.how_it_works .diffrent_ways .items .item_heading {
    text-align: center;
    font-weight: 600;
    color: #170b2e;
    font-size: 18px;
}

.how_it_works .diffrent_ways .items .item_desc {
    text-align: center;
    width: 80%;
    padding: 0 22px;
    font-size: 14px;
    margin-top: 5px;
}

.how_it_works .diffrent_ways .items .icon svg {
    width: 60px;
}

.how_it_works .diffrent_ways .items .icon {
    height: 100px;
    display: flex;
    /* justify-content: center; */
}

.talentScoutSecondRow {
    display: flex;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 1024px) {
    .how_it_works .diffrent_ways .items .relation1 {
        right: -124px;
    }

    .how_it_works .diffrent_ways .items .relation1 svg {
        width: 220px;
    }

    .how_it_works .diffrent_ways .items .relation2 svg {
        width: 220px;
    }

    .how_it_works .diffrent_ways .items .relation2 {
        right: -123px;
    }
}

@media screen and (max-width: 768px) {
    .how_it_works .diffrent_ways .items .item_heading {
        font-size: 16px;
    }

    .how_it_works .diffrent_ways .items .item_desc {
        padding: 0;
        font-size: 12px;
    }

    .how_it_works .diffrent_ways .items .relation2 svg {
        width: 160px;
    }

    .how_it_works .diffrent_ways .items .relation1 svg {
        width: 160px;
    }

    .how_it_works .diffrent_ways .items .relation2 {
        right: -90px;
    }

    .how_it_works .diffrent_ways .items .relation1 {
        right: -92px;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .talentScoutSecondRow {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

/* section 1st end */
/* section 2nd start */
.how_it_works .award_winning_platform {
    background-color: #f3eeff;
    padding: 40px 0;
}

.how_it_works .award_winning_platform .heading {
    font-size: 40px;
    font-weight: 600;
    color: #170048;
}

.how_it_works .award_winning_platform .desc {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.how_it_works .award_winning_platform .right_icon {
    margin-left: 20px;
}

.how_it_works .award_winning_platform .right_icon svg {
    width: 24px;
}

@media screen and (max-width: 375px) and (min-width: 320px) {
    .how_it_works .award_winning_platform .right_icon svg {
        width: 18px;
    }
}

.how_it_works .award_winning_platform .box_container {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    gap: 20px;
    justify-content: center;
    margin-left: auto;
}

.how_it_works .award_winning_platform .box_container .box {
    width: 33%;
    background-color: #e7defc;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3a344a;
}

.how_it_works .award_winning_platform .box_container .box h2 {
    font-weight: 600;
}

.how_it_works .award_winning_platform .box_container .box span {
    text-align: center;
    font-size: 14px;
}

.how_it_works .award_winning_platform .awp_btn button {
    background-color: #6d2ef1;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 500;
}

@media screen and (max-width: 1024px) {
    .how_it_works .award_winning_platform .heading {
        font-size: 30px;
    }

    .how_it_works .award_winning_platform .box_container .box {
        width: 43%;
    }

    .how_it_works .award_winning_platform .right_icon {
        margin-left: 7px;
    }

    .how_it_works .award_winning_platform .box_container {
        width: 100%;
        margin-bottom: 20px;
    }

    .how_it_works .award_winning_platform .awp_btn button {
        margin-left: 40px;
    }

    .CreateYourProfile {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .how_it_works .award_winning_platform .right_icon {
        margin-left: 5px;
    }

    .how_it_works .award_winning_platform .desc {
        padding-right: 30px;
    }
}

/* section 2nd end */
/* -------------------- section 3rd start ------------------------------------ - */
.top_freelancers {
    position: relative;
}

.top_freelancers h2 {
    text-align: center;
    margin: 50px 0;
    font-size: 55px;
    color: #170048;
    font-weight: 600;
}

.top_freelancers .top_freelancer {
    color: #3b3b3b;
    width: 80%;
    padding: 20px 30px;
}

@media (max-width: 767px) {
    .top_freelancers .top_freelancer {
        width: 100%;
    }
}

.top_freelancers .top_freelancer .image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #e7defc;
    margin: 10px 10px 10px 35px;
}

.top_freelancers .top_freelancer .rate {
    padding: 5px 5px;
    background-color: #ff88fe;
    color: #fff;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: relative;
    top: -55px;
    right: -85px;
}

.top_freelancers .top_freelancer .image img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: relative;
    left: -35px;
    top: -10px;
    object-fit: cover;
}

.top_freelancers .top_freelancer .name {
    color: #3b3b3b;
    font-size: 25px;
    font-weight: 700;
}

@media screen and (max-width: 425px) {
    .services_sec_heading h2 {
        font-size: 30px !important;
        margin: -35px auto;
    }
}

.top_freelancers .top_freelancer .address {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 600;
}

.top_freelancers .top_freelancer .designation {
    color: #3b3b3b;
    font-size: 15px;
    font-weight: 400;
}

.top_freelancers .top_freelancer .about {
    color: #3b3b3b;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.top_freelancers .top_freelancer .about div {
    margin-right: 10px;
    font-weight: 600;
    font-size: 13px;
}

.top_freelancers .top_freelancer .about div span {
    font-weight: 400;
    font-size: 14px;
}

.top_freelancers .top_freelancer .about svg {
    width: 20px;
}

@media screen and (max-width: 768px) {
    .top_freelancers h2 {
        font-size: 45px;
    }

    .top_freelancers .top_freelancer .image {
        width: 130px;
        height: 130px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
    .top_freelancers .top_freelancer {
        color: #3b3b3b;
        width: 100%;
        padding: 20px 30px;
    }
}

/* section 3rd end */
/* how it works end */

.border_bx_sec.right_job_detail_home .heading h2 {
    font-size: 23px;
    text-align: center;
    font-weight: 700;
    width: 80%;
    color: #3b3b3b;
    margin: 30px auto auto;
}

.right_job_detail_home .with_email {
    margin: 0 30px;
}

.right_job_detail_home .with_email .email_inp {
    position: relative;
    height: 55px;
    width: 100%;
}

.right_job_detail_home .with_email input {
    outline: none;
    border: none;
    background-color: #ebebf7;
    height: 45px;
    width: 100%;
    padding: 0 40px;
    z-index: -1;
    border-radius: 0;
}

.right_job_detail_home .with_email .forgot_pass_email_icon {
    position: absolute;
    top: 25px;
    left: 12px;
    fill: #170048;
    z-index: 1;
}

.right_job_detail_home .sign_up_btn {
    margin: 20px auto;
    text-align: center;
}

.right_job_detail_home .sign_up_btn button {
    padding: 10px 55px;
    color: #fff;
    border: none;
    background-color: #6610f2;
    border-radius: 40px;
}

.right_job_detail_home hr {
    width: 80%;
    margin: 20px auto;
}

.right_job_detail_home .job_post_heading {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #170048;
}

.right_job_detail_home .post_job_btn_home {
    text-align: center;
}

.right_job_detail_home .post_job_btn_home button {
    padding: 8px 35px;
    color: #6610f2;
    border: 1px solid #6610f2;
    background-color: #fff;
    border-radius: 40px;
    margin: 25px 0;
    font-weight: 500;
    font-size: 15px;
}

/* Category wise Skill start */
.cat_wise_skills .header {
    background-color: #6d2ef1;
    display: flex;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    margin: 20px 0;
}

.cat_wise_skills .header .header_left {
    width: 50%;
    color: #fff;
}

.cat_wise_skills .header .header_left .heading h1 {
    /* font-size: 55px; */
    font-size: 50px;
    width: 90%;
    /* line-height: 55px; */
    margin: 20px 0;
}

.cat_wise_skills .header .header_left .desc {
    font-size: 16px;
    margin: 20px 0;
    width: 90%;
}

.cat_wise_skills .header .header_left .get_start_btn button {
    padding: 10px 30px;
    border: none;
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    margin: 20px 0;
}

.cat_wise_skills .header .header_right {
    width: 50%;
}

.cat_wise_skills .header .header_right .header_img {
    width: 100%;
}

.cat_wise_skills .header .header_right img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

@media screen and (max-width: 1024px) {
    .cat_wise_skills .header .header_left .heading h1 {
        font-size: 38px;
        line-height: 38px;
        margin-top: 5px;
    }
}

@media screen and (max-width: 768px) {
    .cat_wise_skills .header .header_left .heading h1 {
        font-size: 25px;
        line-height: 25px;
    }

    .cat_wise_skills .header .header_left .desc {
        margin: 0;
    }

    .cat_wise_skills .header .header_left .get_start_btn button {
        margin-bottom: 0;
        padding: 8px 30px;
    }
}

@media screen and (max-width: 425px) {
    .cat_wise_skills .header {
        flex-direction: column;
    }

    .cat_wise_skills .header .header_left {
        width: 100%;
    }

    .cat_wise_skills .header .header_left .desc {
        width: 100%;
    }

    .cat_wise_skills .header .header_left .heading h1 {
        width: 100%;
    }

    .cat_wise_skills .header .header_right {
        width: 100%;
        display: none;
    }
}

/* skill list section strt */
.skills_lists {
    position: relative;
    margin: 20px 0;
}

.skills_lists .skills_list_heading h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.skills_lists .skill_list_star svg {
    fill: #fa61ff !important;
}

.skills_lists .heading {
    display: flex;
}

.skills_lists .heading span {
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 10px;
    line-height: 24px;
}

.skills_lists .desc {
    color: #5e6d55;
    font-weight: 500;
    margin: 10px 0;
}

.skills_lists .skill_box {
    background-color: #e7defc;
    padding: 20px;
    cursor: pointer;
    border-radius: 15px;
    margin: 10px 0;
}

.skills_lists .skill_box .skill_name {
    margin: 0 0 10px 0;
    color: #001e00;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    display: flex;
    align-items: center;
    height: 50px;
}

.skills_lists .about_skill {
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 500;
}

.skills_lists .about_skill .skill_list_star svg {
    fill: #fa61ff !important;
    width: 15px;
    margin-right: 10px;
}

.skills_lists .skill_user_imgs {
    display: flex;
    position: relative;
    margin: 10px 0;
}

.skills_lists .skill_user_imgs .image {
    width: 60px;
    height: 60px;
}

.skills_lists .skill_user_imgs .image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.skills_lists .skill_user_imgs .image2 {
    position: relative;
    right: 30px;
}

.skills_lists .skill_user_imgs .image3 {
    position: relative;
    right: 60px;
}

.skills_lists .skill_box:hover .skill_user_imgs .image2 {
    right: 10px;
    transition: 1s;
}

.skills_lists .skill_box:hover .skill_user_imgs .image3 {
    right: 20px;
    transition: 1s;
}

.skills_lists .more_cat_skills {
    margin: 10px 0;
}

.skills_lists .more_cat_skills span {
    color: #6d2ef1;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .skills_lists .skill_box .skill_name {
        margin: 0 0 10px 0;
    }

    .skills_lists .about_skill {
        font-size: 14px;
    }

    .skills_lists .skill_user_imgs {
        margin: 10px 0;
    }
}

@media screen and (max-width: 768px) {
    .skills_lists .skills_list_heading h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .skills_lists .heading span {
        font-size: 22px;
    }

    .skills_lists .desc {
        font-size: 14px;
    }
}

@media screen and (max-width: 425px) {
    .skills_lists .skills_list_heading h1 {
        font-size: 25px;
        margin-bottom: 20px;
    }

    .skills_lists .heading {
        padding: 0 20px;
    }

    .skills_lists .heading span {
        font-size: 20px;
    }

    .skills_lists .desc {
        font-size: 14px;
        margin: 5px 0 0 0;
        padding: 0 20px;
    }

    .skills_lists .more_cat_skills {
        margin: 0;
    }
}

@media screen and (max-width: 375px) {
    .skills_lists .skills_list_heading h1 {
        font-size: 20px;
    }

    .skills_lists .heading span {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

/* skill list section end */
/* browse_projects section start ------------------------- */
.browse_projects .heading h2 {
    font-size: 40px;
    font-weight: 600;
}

.browse_projects .desc {
    color: #5e6d55;
    font-size: 25px;
    line-height: 28px;
    font-weight: 500;
}

.browse_projects .banner {
    background-color: #f3eeff;
    position: relative;
    margin: 20px 0;
    border-radius: 15px;
    height: 350px;
}

.browse_projects .titles {
    padding: 20px;
}

.browse_projects .titles .title {
    font-weight: 500;
    margin: 10px 0;
    color: #8d8989;
    cursor: pointer;
}

.browse_projects .titles .title.active {
    border-left: 1px solid black;
    padding-left: 10px;
    font-size: 15px;
    color: #000;
    font-weight: 600;
}

.browse_projects .browse_project_btn button {
    padding: 8px 40px;
    border: none;
    background-color: #6d2ef1;
    color: #fff;
    border-radius: 40px;
    margin: 20px 20px;
}

.browse_projects .image {
    width: 100%;
    height: inherit;
}

.browse_projects .image img {
    height: 100%;
    width: 100%;
    border-radius: 0 15px 15px 0;
    object-fit: cover;
}

@media screen and (max-width: 1024px) {
    .browse_projects .heading h2 {
        font-size: 30px;
    }

    .browse_projects .desc {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .browse_projects .heading h2 {
        font-size: 30px;
    }

    .browse_projects .banner {
        height: 300px;
    }

    .browse_projects .desc {
        font-size: 18px;
    }

    .browse_projects .titles .title {
        font-size: 14px;
    }

    .browse_projects .titles .title.active {
        padding-left: 5px;
        font-size: 14px;
        font-weight: 600;
    }
}

@media screen and (max-width: 425px) {
    .browse_projects .banner {
        height: auto;
    }

    .browse_projects .heading h2 {
        font-size: 25px;
    }

    .browse_projects .desc {
        font-size: 15px;
    }

    .browse_projects .image {
        height: 220px;
        margin-bottom: 80px;
        padding: 0 20px;
    }

    .browse_projects .image img {
        border-radius: 0;
    }

    .browse_projects .browse_project_btn button {
        position: absolute;
        bottom: 0;
    }
}

@media screen and (max-width: 325px) {
    .browse_projects .image {
        height: 170px;
    }
}

/* browse_projects section end */

/* Category wise Skill end */

/* skill wise develpers start ----------------------- */
.dev_cat_wise .header {
    background-color: #6d2ef1;
    z-index: 0;
    padding: 20px 20px 0 20px;
    margin-bottom: 50px;
}

.dev_cat_wise .header .container {
    display: flex;
    position: relative;
}

.dev_cat_wise .header_left {
    margin: 20px 0;
    color: #fff;
    width: 50%;
    padding: 60px 0;
}

.dev_cat_wise .header_right {
    width: 50%;
    display: flex;
    margin-top: 15px;
    /* align-items: flex-end; */
}

.dev_cat_wise .header_left .heading {
    font-size: 44px;
    font-weight: 600;
}

.dev_cat_wise .header_left .desc {
    font-size: 14px;
    font-weight: 300;
}

.dev_cat_wise .header_left .header_btns {
    display: flex;
    align-items: center;
    gap: 20px;
}

.dev_cat_wise .header_left .header_btns .hire_btn button {
    background-color: #fff;
    color: #6d2ef1;
    padding: 10px 40px;
    border: none;
    border-radius: 40px;
    font-weight: 600;
}

.dev_cat_wise .header_left .header_btns .talk_to_client {
    /* text-decoration: underline; */
    font-weight: 500;
    cursor: pointer;
}

.dev_cat_wise .header_left .review {
    font-weight: 500;
    position: relative;
    top: 100px;
}

.dev_cat_wise .header_left .review span {
    margin-right: 10px;
}

.dev_cat_wise .header_left .review .count {
    margin-right: 10px;
    font-weight: 300;
    font-size: 15px;
}

.dev_cat_wise .header_img {
    text-align: right;
}

.dev_cat_wise .header_img img {
    height: auto;
    width: 75%;
    margin: 0 auto;
}

.dev_cat_wise .home_image_div {
    width: 70%;
    margin: auto;
}

.dev_cat_wise .home_image_div img {
    height: auto;
    max-width: 100%;
}

@media screen and (max-width: 1024px) {
    .dev_cat_wise .header_left {
        padding: 20px 0;
    }

    .dev_cat_wise .header_left .heading {
        font-size: 40px;
    }

    .dev_cat_wise .header_left .review {
        top: 27px;
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .dev_cat_wise .header_img img {
        width: 100%;
    }

    .dev_cat_wise .header_left .heading {
        font-size: 30px;
    }

    .dev_cat_wise .header_left {
        padding: 10px 0;
        margin: 10px 0;
    }

    .dev_cat_wise .header_left .header_btns .hire_btn button {
        padding: 10px 15px;
    }

    .dev_cat_wise .header_left .header_btns {
        font-size: 14px;
        gap: 10px;
    }
}

@media screen and (max-width: 425px) {
    .dev_cat_wise .header_right {
        display: none;
    }

    .dev_cat_wise .header_left {
        width: 100%;
    }

    .dev_cat_wise .header {
        padding: 10px 10px 0 10px;
    }

    .dev_cat_wise .header_left .header_btns {
        margin-bottom: 10px;
    }

    .dev_cat_wise .header_left .review {
        top: 9px;
    }
}

/* cards */

.dev_cat_wise .developers_list {
    position: relative;
}

.dev_cat_wise .developers_list .heading h2 {
    color: #00065a;
    font-size: 22px;
    font-weight: 600;
}

.dev_cat_wise .developers_list .desc {
    color: #393939;
}

.dev_cat_wise .developers_list .desc span {
    color: #6d2ef1;
    font-weight: 500;
    cursor: pointer;
}

.dev_cat_wise .developers_list .cards {
    margin: 30px 0;
}

.dev_cat_wise .developers_list .cards .dev_card {
    border: 1px solid #d6d6d6;
    padding: 20px;
    margin: 20px 0;
    height: 390px;
}

.dev_cat_wise .developers_list .cards .dev_card .image {
    width: 90px;
    margin: auto;
    height: 90px;
}

.dev_cat_wise .developers_list .cards .dev_card .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.dev_cat_wise .developers_list .cards .dev_card .name {
    text-align: center;
    color: #00065a;
    font-size: 18px;
    font-weight: 700;
    margin: 5px 0;
}

.dev_cat_wise .developers_list .cards .dev_card .designation {
    text-align: center;
    color: #393939;
    font-size: 14px;
    margin: 5px 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.dev_cat_wise .developers_list .cards .dev_card .ratings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #747474;
    font-size: 14px;
}

.dev_cat_wise .developers_list .cards .dev_card .ratings .top_rated svg {
    width: 30px;
}

.dev_cat_wise .developers_list .cards .dev_card .ratings .top_rated span {
    margin-right: 10px;
}

.dev_cat_wise .developers_list .cards .dev_card .ratings .rate span {
    margin-left: 10px;
}

.dev_cat_wise .developers_list .cards .dev_card .skills {
    display: flex;
    flex-wrap: wrap;
    height: 90px;
    align-items: flex-start;
}

.dev_cat_wise .developers_list .cards .dev_card .skills .skill {
    background-color: #eaeef2;
    color: #393939;
    padding: 8px 25px;
    border-radius: 30px;
    margin: 5px 5px 5px 0;
    font-size: 14px;
}

.dev_cat_wise .developers_list .cards .dev_card hr {
    margin: 20px auto;
    color: #b7b7b7;
}

.dev_cat_wise .developers_list .cards .dev_card .details_btn {
    text-align: center;
}

.dev_cat_wise .developers_list .cards .dev_card .details_btn button {
    padding: 10px 30px;
    border: none;
    background-color: #6d2ef1;
    color: #fff;
    border-radius: 40px;
    margin: 10px 0;
}

@media screen and (max-width: 425px) {
    .dev_cat_wise .developers_list .heading h2 {
        font-size: 22px;
    }

    .dev_cat_wise .developers_list .desc {
        font-size: 14px;
    }
}

/* instant booking popup start */

.instant_booking_popup_heading {
    color: #6d2ef1;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    position: relative;
    width: 80%;
    margin: 30px auto 0 auto;
}

.instant_booking_popup .desc {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    color: #979797;
    font-size: 14px;
}

.instant_booking_popup .with_email .email_inp {
    position: relative;
    height: 45px;
    width: 100%;
}

.instant_booking_popup .with_email input {
    outline: none;
    border: none;
    background-color: #ebebf7;
    height: 45px;
    width: 100%;
    padding: 0 40px;
    z-index: -1;
    border-radius: 0;
}

.instant_booking_popup .with_email .forgot_pass_email_icon {
    position: absolute;
    top: 25px;
    left: 12px;
    fill: #170048;
    z-index: 1;
}

.instant_booking_popup .google_btn {
    max-width: unset;
    width: 100%;
}

.instant_booking_popup .job_details_or {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0;
    color: #170048;
    position: relative;
    margin: 20px 40px;
}

.instant_booking_popup .job_details_or::before {
    position: absolute;
    left: 0;
    width: 46%;
    height: 1px;
    content: " ";
    margin-top: 16px;
    background-color: #9da0f2;
}

.instant_booking_popup .job_details_or::after {
    position: absolute;
    right: 0;
    width: 46%;
    height: 1px;
    content: " ";
    margin-top: 16px;
    background-color: #9da0f2;
}

.instant_booking_popup .with_email {
    margin: 0 15px 40px;
}

.instant_booking_popup .sign_up_btn_ibp button {
    min-width: 160px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    background-color: #6610f2;
    padding: 10px 45px;
    -webkit-border-radius: 60px !important;
    margin: 10px 15px 20px;
    border: none;
}

.instant_booking_popup .sign_up_btn_ibp button:disabled {
    background-color: #ababab;
}

/* instant booking popup end */

/* skill wise develpers end ----------------------- */

.addSkillBox .skill_bxr_gry {
    align-items: center;
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 5px;
    padding: 6px 16px;
    transition: 0.1s;
}

.addSkillBox .skill_bxr_gry button {
    background-color: initial;
    border: none;
    outline: none;
    position: relative;
    right: 0;
    width: 20px;
    height: 20px;
    margin-right: -10px;
    margin-left: 2px;
    transition: 0.1s;
    border-radius: 100%;

    &:hover {
        background: #fff2;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        width: 10px;
        height: 2px;
        background: #000;
        position: absolute;
        top: 9px;
        left: 5px;
    }

    &:before {
        transform: rotate(-45deg);
    }
    &:after {
        transform: rotate(45deg);
    }
}

.addSkillBox .skill_bxr_gry:hover button {
    color: #fff;

    &:before,
    &:after {
        background: #fff;
    }
}

.addSkillBox .search_inp_box {
    display: flex;
    position: relative;
    width: 100%;
}

.addSkillBox .search_inp_box .suggest_skills {
    top: 40px;
    max-width: 210px;
}

.addSkillBox .search_inp_box .suggest_skills span {
    font-size: 14px;
    height: unset;
}

/* Fixed bid start------------------ */
.fixed_bid > .heading {
    display: flex;
    justify-content: space-between;
}

.fixed_bid > .heading .head {
    color: #6d2ef1;
    font-weight: 700;
    font-size: 18px;
}

.fixed_bid .heading .budget {
    color: #170048;
    font-weight: 700;
    font-size: 15px;
}

.fixed_bid .how_to_be_paid > .heading {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin-top: 20px;
}

@media (max-width: 481px) {
    .mobileSizeFont {
        font-size: 14px !important;
    }
}

.fixed_bid .how_to_be_paid > .methods .method {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.fixed_bid .how_to_be_paid > .methods .method .heading {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
}

.fixed_bid .how_to_be_paid > .methods .method .desc {
    color: #170048;
    font-weight: 400;
    font-size: 13px;
}

.fixed_bid .milestone {
    border-bottom: 1px solid #dbdbdb;
    padding: 20px 0;
}

.fixed_bid .milestone .heading {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 5px 0;
}

.fixed_bid .milestone .milestone_count .headings {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    gap: 15px;
}

.fixed_bid .milestone .milestone_count .input_list {
    display: flex;
    /* align-items: center; */
    gap: 15px;
    margin: 15px 0;
}

.fixed_bid .milestone .milestone_count .headings .srno,
.fixed_bid .milestone .milestone_count .input_list .srno {
    flex: 1;
    padding-top: 8px;
}

.fixed_bid .milestone .milestone_count .headings .desc,
.fixed_bid .milestone .milestone_count .input_list .desc {
    flex: 16;
}

.fixed_bid .milestone .milestone_count .headings .date,
.fixed_bid .milestone .milestone_count .input_list .date {
    flex: 10;
}

.fixed_bid .milestone .milestone_count .headings .amount,
.fixed_bid .milestone .milestone_count .input_list .amount {
    flex: 10;
    position: relative;
}

.fixed_bid .milestone .milestone_count .headings .remove,
.fixed_bid .milestone .milestone_count .input_list .remove {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    padding: 0;
    margin: 0;
    color: #6610f2;
    font-weight: 500;
}

.fixed_bid .milestone .milestone_count .input_list .desc input,
.fixed_bid .milestone .milestone_count .input_list .srno input,
.fixed_bid .milestone .milestone_count .input_list .date input,
.fixed_bid .milestone .milestone_count .input_list .amount input {
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #8194ab;
    padding: 5px 10px;
    background-color: #f2f8ff;
}

.fixed_bid .milestone .milestone_count .input_list .amount input {
    text-align: right;
}

@media screen and (max-width: 767px) {
    .fixed_bid .milestone .milestone_count .headings {
        display: none;
    }

    .fixed_bid .milestone .milestone_count .input_list {
        flex-direction: column;
    }
}

.fixed_bid .dollar_icon {
    position: absolute;
    top: 8px;
    left: 10px;
    font-weight: 700;
}

.fixed_bid .milestone .add_milestone {
    text-align: right;
}

.fixed_bid .milestone .add_milestone span {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    color: #6610f2;
}

.fixed_bid .total_project_price {
    border-bottom: 1px solid #dbdbdb;
    padding: 20px 0;
}

.bi-question-circle-fill {
    font-size: 16px;
    color: #6d2ef1;
}

.fixed_bid .total_project_price .heading {
    display: flex;
    justify-content: space-between;
}

.fixed_bid .total_project_price .heading .head {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 15px 0;
}

.fixed_bid .total_project_price .heading .amt {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 5px 0;
}

.fixed_bid .total_project_price .desc {
    color: #170048;
    font-weight: 400;
    font-size: 13px;
}

.fixed_bid .unify_service_fee {
    border-bottom: 1px solid #dbdbdb;
    padding: 20px 0;
}

.fixed_bid .unify_service_fee div {
    display: flex;
    justify-content: space-between;
}

.fixed_bid .unify_service_fee .head {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 15px 0;
}

.fixed_bid .unify_service_fee .amt {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 5px 0;
}

.fixed_bid .unify_service_fee .desc {
    color: #170048;
    font-weight: 400;
    font-size: 13px;
}

.fixed_bid .unify_service_fee {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 5px 0;
}

.fixed_bid .unify_service_fee {
    border-bottom: 1px solid #dbdbdb;
    padding: 20px 0;
}

.fixed_bid .you_will_recive .heading {
    display: flex;
    justify-content: space-between;
}

.fixed_bid .you_will_recive .heading .head {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 15px 0;
}

.fixed_bid .you_will_recive .heading .amt {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 5px 0;
}

.fixed_bid .you_will_recive .desc {
    color: #170048;
    font-weight: 400;
    font-size: 13px;
}

.fixed_bid .project_duration label {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin: 20px 0 10px 0;
}

.fixed_bid .project_duration select {
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #8194ab;
    padding-inline: 10px;
    background-color: #f2f8ff;
}

.full_project_bid .heading {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
    margin-top: 20px;
}

.full_project_bid .head {
    color: #170048;
    font-weight: 600;
    font-size: 15px;
}

.full_project_bid .desc {
    color: #170048;
    font-weight: 400;
    font-size: 13px;
}

.full_project_bid input {
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #dbdbdb;
    text-align: right;
    padding: 5px 10px;
}

.full_project_bid input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.send_proposal_btn_job_detail:disabled {
    opacity: 1;
    color: #fff;
    background-color: #ababab;
    border: none;
    font-weight: 600;
}

/* Fixed bid end ------------------ */

/* ------------------------ all proposals start --------------------- */
.all_proposals_list .proposals_list {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border-bottom: 1px solid #e2e2e2;
    padding: 10px 0;
    align-items: center;
}

.all_proposals_list .proposals_list .date_time .init_date,
.all_proposals_list .proposals_list .titls {
    font-weight: 600;
    font-size: 15px;
}

.all_proposals_list .proposals_list .titls {
    max-width: 470px;
    color: #6610f2;
    /* -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden; */
    word-break: break-word;
}

.all_proposals_list .proposals_list .titls:hover {
    color: #467af3;
    text-decoration: underline;
}

.all_proposals_list .proposals_list .date_time .time {
    color: #324047;
    font-size: 14px;
}

/* ------------------------ all proposals end --------------------- */

/* ------------------------ single submitted proposal detail after click on submitted proposals start --------------------- */
.single_submitted_proposal {
    margin: 20px auto;
}

.single_submitted_proposal > .heading {
    color: #170048;
    font-weight: 700;
    font-size: 25px;
    margin: 20px 0;
}

.single_submitted_proposal .sep_bdr {
    border: 1px solid #bfbfbf;
    margin-bottom: 20px;
    border-radius: 10px;
}

.single_submitted_proposal .job_details,
.single_submitted_proposal .skills {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 10px;
    padding: 20px 20px 20px 20px;
}

.single_submitted_proposal .job_details .left {
    border-right: 1px solid #e9e9e9;
    padding: 0 5px;
}

.single_submitted_proposal .job_details .left .left_heading {
    font-weight: 600;
    font-size: 18px;
    color: #6d2ef1;
}

.single_submitted_proposal .job_details .left .left_title {
    font-weight: 600;
    font-size: 20px;
    color: #170048;
    word-break: break-word;
}

.single_submitted_proposal .job_details .left .categories {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 20px;
}

.single_submitted_proposal .job_details .left .categories span {
    border: 1px solid #6d2ef1;
    color: #6d2ef1;
    padding: 5px 20px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
}

.single_submitted_proposal .job_details .left .categories .date {
    font-size: 14px;
    color: #5d5d5d;
    font-weight: 600;
}

@media screen and (max-width: 767px) {
    .no_resp_border {
        border-right: unset !important;
    }

    .resp_cate span {
        padding: 5px 8px !important;
    }
}

@media screen and (max-width: 425px) {
    .resp_withrw_pr_btn {
        padding: 8px 20px !important;
    }
}

.single_submitted_proposal .job_details .left .description {
    color: #adadad;
    font-weight: 400;
    font-size: 14px;
    margin: 20px 0;
    white-space: pre-line;
    word-break: break-word;
}

.single_submitted_proposal .job_details .left .view_more_posting {
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin: 10px 0;
}

.single_submitted_proposal .job_details .right {
    padding: 30px 0;
}

.single_submitted_proposal .job_details .right svg {
    width: 20px;
    height: 20px;
}

.single_submitted_proposal .job_details .right svg {
    width: 20px;
    height: 20px;
}

.single_submitted_proposal .job_details .right .right_item {
    margin-bottom: 20px;
}

.single_submitted_proposal .job_details .right .right_item .head {
    display: flex;
    font-weight: 600;
    gap: 10px;
    align-items: center;
    color: #6d2ef1;
}

.single_submitted_proposal .job_details .right .right_item .desc {
    font-weight: 600;
    font-size: 15px;
    color: #462d7a;
    margin-left: 30px;
}

.single_submitted_proposal .skills .head {
    font-size: 16px;
    font-weight: 700;
    color: #170048;
}

.single_submitted_proposal .sep_bdr .skills .skill_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.single_submitted_proposal .sep_bdr .skills .skill_list span {
    border: 1px solid #6d2ef1;
    color: #6d2ef1;
    padding: 5px 20px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
}

.single_submitted_proposal .sep_bdr .terms {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_submitted_proposal .sep_bdr .terms .head {
    display: flex;
    justify-content: space-between;
}

.single_submitted_proposal .sep_bdr .terms .head .headi {
    font-size: 16px;
    font-weight: 700;
    color: #170048;
}

.single_submitted_proposal .sep_bdr .terms .head .budget {
    font-size: 14px;
    font-weight: 700;
    color: #170048;
}

.single_submitted_proposal .sep_bdr .terms .how_be_paid,
.single_submitted_proposal .sep_bdr .terms .price_project {
    margin: 20px 0;
}

.single_submitted_proposal .sep_bdr .terms .how_be_paid .head {
    font-size: 15px;
    font-weight: 700;
    color: #170048;
}

.single_submitted_proposal .sep_bdr .terms .how_be_paid .desc {
    font-size: 14px;
    color: #a2a2a2;
    font-weight: 400;
}

.single_submitted_proposal .sep_bdr .terms .price_project .head {
    font-size: 15px;
    font-weight: 700;
    color: #170048;
}

.single_submitted_proposal .sep_bdr .terms .price_project .desc {
    font-size: 13px;
    color: #626262;
    font-weight: 400;
}

.single_submitted_proposal .sep_bdr .terms .price_project .amt {
    font-size: 13px;
    color: #626262;
    font-weight: 600;
    margin-top: 5px;
}

.single_submitted_proposal .sep_bdr .terms .you_recive .head {
    font-size: 15px;
    font-weight: 700;
    color: #170048;
}

.single_submitted_proposal .sep_bdr .terms .you_recive .desc {
    font-size: 13px;
    color: #626262;
    font-weight: 400;
}

.single_submitted_proposal .sep_bdr .terms .you_recive .amt {
    font-size: 13px;
    color: #626262;
    font-weight: 600;
    margin-top: 5px;
}

.single_submitted_proposal .sep_bdr.cover_letter {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_submitted_proposal .cover_letter .heading {
    color: #170048;
    font-weight: 700;
    font-size: 25px;
    margin: 10px 0;
}

.single_submitted_proposal .cover_letter .desc {
    word-break: break-word;
    color: #6b6b6b;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    white-space: pre-line;
}

.single_submitted_proposal .about_client {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_submitted_proposal .about_client .heading {
    color: #170048;
    font-weight: 700;
    font-size: 20px;
    margin: 10px 0;
}

.single_submitted_proposal .about_client .isvarified {
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: #5d5d5d;
}

.single_submitted_proposal .about_client .reviews {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 10px 0;
}

.reviews .stars {
    display: flex !important;
    align-items: center !important;
    gap: 3px !important;
}

.single_submitted_proposal .about_client .stars svg {
    width: 15px;
    height: 15px;
}

.single_submitted_proposal .about_client .reviews .desc {
    color: #462d7a;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.single_submitted_proposal .about_client .location {
    margin-top: 30px;
}

.single_submitted_proposal .about_client .location .headingl {
    color: #170048;
    font-weight: 700;
    font-size: 16px;
}

.single_submitted_proposal .about_client .location .country {
    color: #6d2ef1;
    font-size: 16px;
    font-weight: 500;
}

.single_submitted_proposal .about_client .location .timezone {
    color: #462d7a;
    font-size: 14px;
    font-weight: 500;
}

.single_submitted_proposal .about_client .history .headingh {
    color: #170048;
    font-weight: 700;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.single_submitted_proposal .about_client .history .desc {
    color: #462d7a;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
}

.single_submitted_proposal .about_client .member_since {
    color: #462d7a;
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
}

.location_drop_icon {
    width: 60px;
}

.location_drop_icon img {
    height: 15px;
    width: 22px;
}

.associ_sg {
    color: #170048;
    font-weight: 600;
    font-size: 14px;
}

.associ_sg .ass_ag_na {
    text-transform: capitalize;
}

.proposal_image_view img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.proposal_profile {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile_align_det {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

/* ------------------------ single submitted proposal detail after click on submitted proposals end --------------------- */

/* ------------------------ single active proposal detail after click on submitted proposals end --------------------- */
.single_active_proposal {
    margin: 20px auto;
}

.single_active_proposal > .heading {
    color: #170048;
    font-weight: 700;
    font-size: 25px;
    margin: 20px 0;
}

.single_active_proposal .sep_bdr {
    border: 1px solid #bfbfbf;
    margin-bottom: 20px;
}

.single_active_proposal .sep_bdr .job_details,
.single_active_proposal .sep_bdr .skills {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 10px;
    padding: 20px 20px;
}

.single_active_proposal .sep_bdr .job_details .left {
    border-right: 1px solid #e9e9e9;
    padding: 0 5px;
}

.single_active_proposal .sep_bdr .job_details .left .left_heading {
    font-weight: 600;
    font-size: 18px;
    color: #6d2ef1;
}

.single_active_proposal .sep_bdr .job_details .left .left_title {
    font-weight: 600;
    font-size: 20px;
    color: #170048;
    word-break: break-word;
}

.single_active_proposal .sep_bdr .job_details .left .categories {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 20px;
}

.single_active_proposal .sep_bdr .job_details .left .categories span {
    border: 1px solid #6d2ef1;
    color: #6d2ef1;
    padding: 5px 20px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
}

.single_active_proposal .sep_bdr .job_details .left .categories .date {
    font-size: 14px;
    color: #5d5d5d;
    font-weight: 600;
}

.single_active_proposal .sep_bdr .job_details .left .description {
    color: #adadad;
    font-weight: 400;
    font-size: 14px;
    margin: 20px 0;
    word-break: break-word;
}

.single_active_proposal .sep_bdr .job_details .left .view_more_posting {
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin: 10px 0;
}

.single_active_proposal .sep_bdr .job_details .right {
    padding: 30px 0;
}

.single_active_proposal .sep_bdr .job_details .right svg {
    width: 20px;
    height: 20px;
}

.single_active_proposal .sep_bdr .job_details .right svg {
    width: 20px;
    height: 20px;
}

.single_active_proposal .sep_bdr .job_details .right .right_item {
    margin-top: 20px;
}

.single_active_proposal .sep_bdr .job_details .right .right_item .head {
    display: flex;
    font-weight: 600;
    gap: 10px;
    align-items: center;
    color: #6d2ef1;
}

.single_active_proposal .sep_bdr .job_details .right .right_item .desc {
    font-weight: 600;
    font-size: 15px;
    color: #462d7a;
    margin-left: 30px;
}

.single_active_proposal .sep_bdr .skills .head {
    font-size: 16px;
    font-weight: 700;
    color: #170048;
}

.single_active_proposal .sep_bdr .skills .skill_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.single_active_proposal .sep_bdr .skills .skill_list span {
    border: 1px solid #6d2ef1;
    color: #6d2ef1;
    padding: 5px 20px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
}

.single_active_proposal .sep_bdr .terms {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_active_proposal .sep_bdr .terms .head {
    display: flex;
    justify-content: space-between;
}

.single_active_proposal .sep_bdr .terms .head .headi {
    font-size: 16px;
    font-weight: 700;
    color: #170048;
}

.single_active_proposal .sep_bdr .terms .head .budget {
    font-size: 14px;
    font-weight: 700;
    color: #170048;
}

.single_active_proposal .sep_bdr .terms .how_be_paid,
.single_active_proposal .sep_bdr .terms .price_project {
    margin: 20px 0;
}

.single_active_proposal .sep_bdr .terms .how_be_paid .head {
    font-size: 15px;
    font-weight: 700;
    color: #170048;
}

.single_active_proposal .sep_bdr .terms .how_be_paid .desc {
    font-size: 14px;
    color: #a2a2a2;
    font-weight: 400;
}

.single_active_proposal .sep_bdr .terms .price_project .head {
    font-size: 15px;
    font-weight: 700;
    color: #170048;
}

.single_active_proposal .sep_bdr .terms .price_project .desc {
    font-size: 13px;
    color: #626262;
    font-weight: 400;
}

.single_active_proposal .sep_bdr .terms .price_project .amt {
    font-size: 13px;
    color: #626262;
    font-weight: 600;
    margin-top: 5px;
}

.single_active_proposal .sep_bdr .terms .you_recive .head {
    font-size: 15px;
    font-weight: 700;
    color: #170048;
}

.single_active_proposal .sep_bdr .terms .you_recive .desc {
    font-size: 13px;
    color: #626262;
    font-weight: 400;
}

.single_active_proposal .sep_bdr .terms .you_recive .amt {
    font-size: 13px;
    color: #626262;
    font-weight: 600;
    margin-top: 5px;
}

.single_active_proposal .cover_letter {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_active_proposal .cover_letter .heading {
    color: #170048;
    font-weight: 600;
    font-size: 22px;
    margin: 10px 0;
}

.single_active_proposal .cover_letter .desc {
    color: #6b6b6b;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    white-space: pre-line;
    word-break: break-word;
}

.single_active_proposal .about_client {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_active_proposal .about_client .heading {
    color: #170048;
    font-weight: 700;
    font-size: 20px;
    margin: 10px 0;
}

.single_active_proposal .about_client .isvarified {
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: #5d5d5d;
}

.single_active_proposal .about_client .reviews {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 10px 0;
}

.reviews .stars svg {
    width: 12px;
    height: 12px;
}

.single_active_proposal .about_client .reviews .desc {
    color: #462d7a;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.single_active_proposal .about_client .location {
    margin-top: 30px;
}

.single_active_proposal .about_client .location .headingl {
    color: #170048;
    font-weight: 700;
    font-size: 16px;
}

.single_active_proposal .about_client .location .country {
    color: #6d2ef1;
    font-size: 16px;
    font-weight: 500;
}

.single_active_proposal .about_client .location .timezone {
    color: #462d7a;
    font-size: 14px;
    font-weight: 500;
}

.single_active_proposal .about_client .history .headingh {
    color: #170048;
    font-weight: 700;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.single_active_proposal .about_client .history .desc {
    color: #462d7a;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
}

.single_active_proposal .about_client .member_since {
    color: #462d7a;
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
}

.single_active_proposal .sep_bdr.original_client_msg {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_active_proposal .original_client_msg .heading {
    color: #170048;
    font-weight: 600;
    font-size: 22px;
    margin: 10px 0;
}

.single_active_proposal .original_client_msg .desc {
    color: #6b6b6b;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
}

.single_active_proposal .sep_bdr .messages {
    margin-bottom: 10px;
    padding: 20px 30px;
}

.single_active_proposal .messages .heading {
    color: #170048;
    font-weight: 600;
    font-size: 22px;
    margin: 10px 0;
}

.single_active_proposal .messages .project_details {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
    margin-top: 20px;
}

.single_active_proposal .messages .project_details .left .title {
    font-weight: 600;
    color: #170048;
    display: flex;
    gap: 10px;
    font-size: 14px;
}

.single_active_proposal .messages .project_details .left .time {
    color: #929292;
}

.single_active_proposal .messages .project_details .left .desc {
    color: #6d6d6d;
}

.single_active_proposal .messages .actions {
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
    margin-top: 20px;
    gap: 20px;
}

.single_active_proposal .messages .actions .btns {
    color: #6610f2;
    cursor: pointer;
}

.single_active_proposal .messages .message {
    margin: 10px 0;
    display: flex;
    gap: 20px;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9;
}

.single_active_proposal .messages .message .img {
    width: 60px;
    height: 60px;
}

.single_active_proposal .messages .message .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.single_active_proposal .messages .message .right {
    padding: 10px 0;
}

.single_active_proposal .messages .message .right .name {
    font-weight: 600;
}

.single_active_proposal .messages .message .right .name {
    display: flex;
    gap: 10px;
    align-items: center;
}

.single_active_proposal .messages .message .right .name span {
    color: #c1c1c1;
    font-size: 14px;
}

.single_active_proposal .messages .message .right .msg {
    color: #a6a6a6;
    font-size: 16px;
}

.single_active_proposal .messages .write_msg .msg_inp {
    margin-bottom: 10px;
    width: 100%;
}

.single_active_proposal .messages .write_msg .msg_inp input {
    width: 100%;
    border: none;
    outline: none;
}

.single_active_proposal .messages .write_msg .icons {
    display: flex;
    justify-content: space-between;
}

.single_active_proposal .messages .write_msg .icons svg {
    width: 20px;
    height: 20px;
}

.single_active_proposal .messages .write_msg .icons .right,
.single_active_proposal .messages .write_msg .icons .left {
    display: flex;
    gap: 10px;
}

.proposed_milestone_box {
    border-top: 1px solid #e9e9e9;
    padding: 15px 30px 30px 30px;
}

.proposed_head {
    font-size: 18px;
    font-weight: 500;
}

.desc_del_am {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid #e9e9e9;
    padding-top: 20px;
    font-size: 16px;
    font-weight: 500;
}

.head_desc {
    display: flex;
    align-items: center;
    gap: 28px;
    /* padding-top: 8px; */
    font-size: 16px;
    font-weight: 500;
    padding-left: 50px;
}

.deliv_amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-top: 20px; */
    font-size: 16px;
    font-weight: 500;
}

.data_of_proposed_ml {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid #e9e9e9;
    padding-top: 15px;
}

.head_desc_data {
    display: flex;
    align-items: center;
    gap: 28px;
    padding-top: 8px;
    font-size: 16px;
    font-weight: 400;
    padding-left: 50px;
    word-break: break-word;
}

.deliv_amount_data {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    font-size: 16px;
    font-weight: 400;
}

.delavery_div {
    width: 35%;
    text-align: center;
}

.amount_div {
    width: 35%;
    text-align: center;
}

.view_proposed_attachement {
    width: 100%;
    padding-left: 30px;
}

.atachment_head_div {
    color: #170048;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
}

.cover_letter_bid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.ammout_and_bid {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.headings_cover {
    color: #170048;
    font-weight: 500;
    font-size: 18px;
    margin: 1px 0;
}

@media screen and (max-width: 991px) {
    .desc_del_am {
        width: 100%;
    }

    .head_desc {
        padding-left: 25px;
    }

    .data_of_proposed_ml {
        width: 100%;
    }

    .head_desc_data {
        padding-left: 25px;
    }

    .profile_align_det {
        justify-content: flex-start;
        padding-top: 10px;
    }

    .view_proposed_attachement {
        width: 100%;
    }

    .single_active_proposal .cover_letter {
        margin-bottom: 10px;
        padding: 5px 30px 10px 30px;
    }
}

@media screen and (max-width: 767px) {
    .desc_del_am {
        justify-content: space-between;
    }

    .deliv_amount {
        justify-content: space-between;
        gap: 10px;
    }

    .delavery_div {
        width: 100%;
    }

    .amount_div {
        width: 60%;
    }

    .data_of_proposed_ml {
        justify-content: space-between;
    }

    .head_desc_data {
        gap: 15px;
    }

    .deliv_amount_data {
        justify-content: space-between;
        gap: 10px;
        width: 50%;
    }
}

@media screen and (max-width: 575px) {
    .deliv_amount {
        gap: 20px;
    }

    .head_desc {
        gap: 15px;
        padding-left: 15px;
    }

    .head_desc_data {
        padding-left: 15px;
    }
}

@media screen and (max-width: 499px) {
    .proposal_profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .profile_align_det {
        padding-top: 10px;
        justify-content: center;
    }

    .resp_table_proposed {
        display: flex;
    }

    .resp_head_proposal,
    .address_with_drop {
        text-align: center;
    }

    .desc_del_am {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 10px;
        margin: 0;
        justify-content: center;
        line-height: 30px;
    }

    .data_of_proposed_ml {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 10px;
        margin: 0;
        line-height: 30px;
    }

    .deliv_amount {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-top: 6px;
        align-items: flex-start;
    }

    .head_desc {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-left: 0;
    }

    .head_desc_data {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .deliv_amount_data {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-top: 0;
    }

    .head_desc_data {
        padding-top: 0;
    }
}

@media screen and (max-width: 450px) {
    .message_hire_btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 424px) {
    .proposed_milestone_box {
        padding: 10px;
    }
}

@media screen and (max-width: 375px) {
    .view_proposed_attachement {
        padding-left: 24px;
    }

    .atachment_head_div {
        margin-bottom: 3px;
    }

    .card_with_attachment {
        width: 90%;
        margin: auto;
    }

    .cover_letter_bid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .ammout_and_bid {
        display: flex;
        flex-direction: row-reverse;
        gap: 30px;
    }
}

@media screen and (max-width: 374px) {
    .view_proposed_attachement {
        padding-left: 24px;
    }
}

@media screen and (max-width: 320px) {
    .head_desc,
    .deliv_amount,
    .head_desc_data,
    .deliv_amount_data {
        font-size: 14px;
    }
}

/* ------------------------ single active proposal detail after click on submitted proposals end --------------------- */

/* ------------------------ all contarct after settime sheet start --------------------- */

.single_active_proposal .messages .seeTimeSheetContent .left .seeTimesheetTime {
    color: #929292;
    font-size: 13px;
}

.single_active_proposal .messages .seeTimeSheetContent {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
    margin-top: 8px;
}

.seeTimeSheetNewBtnSpan {
    margin-left: 6px;
}

.seeTimeSheetNewBtn {
    background: #6d2ef1 0 0 no-repeat padding-box;
    border-radius: 60px;
    border: none;
    font-size: 10px;
    color: #fff;
    padding: 2px 6px;
}

.messageTime {
    font-size: 11px;
    color: #b7b7b7;
}

.messageText {
    margin: 10px 0;
    display: flex;
    gap: 20px;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9;
}

.single_active_proposal .messages .messageImg {
    width: 45px;
    height: 45px;
    margin-top: 12px;
}

.messageTopIcon {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* ------------------------ all contarct after settime sheet end --------------------- */

/* how it works start */
@media screen and (min-width: 768px) {
    .how_it_works.tab_scroll_cont {
        justify-content: space-evenly !important;
    }
}

/* how it works end */

.required_stars {
    position: relative;
    color: #f34343;
    font-size: 15px;
}

#basic-addon1 {
    font-size: 13px;
    padding: 9px 11px;
    border: 2px solid rgba(235, 235, 235);
    border-right: none;
}

.portfolio_popup label {
    display: flex !important;
    flex-direction: row;
}

.with_email .jobSignInError {
    color: red;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;
}

.jobSignInError {
    color: red;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;
}

.charCount {
    font-size: 12px;
    color: gray;
    text-align: end;
    font-weight: 300;
}

/* document card start */
.document_card {
    border: var(--input-border);
    padding: 12px 12px 12px 12px;
    position: relative;
    margin-top: 10px;
    display: inline-block;
    border-radius: 5px;
    color: rgb(102, 102, 102);

    .image-info {
        display: flex;
        align-items: center;

        svg {
            color: #666666;
            margin-right: 4px;
        }
    }

    .heading {
        color: #666666;
        font-size: 13px;
    }

    .name {
        color: #6d2ef1;
        font-size: 14px;
        word-break: break-word;
        font-weight: 500;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .close_icon {
        position: absolute;
        right: -14px;
        top: -16px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        border-radius: 190%;
        background: #dddddd;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;

        svg {
            width: 20px;
            height: 20px;
            color: #444444;
            transition: 0.2s;
        }

        &:hover {
            background: #ff0000;

            svg {
                color: #ffffff;
            }
        }
    }
}

.document_name_alignment {
    display: flex;
    align-items: center;
}

.img-downlaod-button {
    background-color: #6d2ef1 !important;
    color: #fff !important;
    font-weight: 600;
    font-size: 14px;
    /* margin-left: 20px; */
    line-height: 20px;
    -webkit-border-radius: 30px;
    border: none;
    padding: 8px 25px;
}

.document_resp_all {
    display: flex;
}

@media screen and (max-width: 376px) {
    .document_card {
        min-width: 300px;
    }
}

@media screen and (max-width: 374px) {
    .document_card {
        display: flex;
        align-items: center;
        min-width: 245px;
    }

    .resp_doc_div {
        min-width: 100% !important;
    }

    .img-downlaod-button {
        padding: 6px 11px;
    }
}

@media screen and (max-width: 315px) {
    .document_card {
        padding: 12px 5px 12px 5px;
    }

    .document_resp_all {
        width: 100%;
    }
}

/* document card end */

/* Page not found start */

.pageNotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-height: 425px;
}

.pageNotFoundResp {
    padding: 0 30px;

    svg,
    img {
        max-width: 150px;
        width: 100%;
        height: auto;
    }
}
.pageNotFoundHead {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: -1.25px;
    color: #666666;
    max-width: 550px;
}

@media screen and (max-width: 500px) and (min-width: 318px) {
    .pageNotFoundHead {
        font-size: 22px;
    }
}

/* Page not found end */

/* All Contract start */
.contract1 {
    border: 1px solid #e8e7e7;
    /* background-color: aliceblue; */
}

.allContractsEarning {
    line-height: 35px;
}

.allContractsEarning .blue {
    width: 18px;
    height: 18px;
    background: #6d2ef1 0 0 no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    margin-right: 5px;
}

.allContractsEarning .green {
    width: 18px;
    height: 18px;
    background: #6fd943 0 0 no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    margin-right: 5px;
}

.allContractsEarning .gray {
    width: 18px;
    height: 18px;
    background: #e4e4e4 0 0 no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    margin-right: 5px;
}

.allContractsEarning .allContractsRuppe {
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    color: #1f1f1f;
}

.mileTimeSubHead {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    color: #6d2ef1;
}

.mileSubHead {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #170048;
}

.milestoneStep2 {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.linking_work {
    display: flex;
    align-items: baseline;
    gap: 8px;
}
.linking_work a {
    white-space: nowrap;
}

.graph_image img {
    width: 20px;
}

.pendingBalance {
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.24px;
    color: #170048;
    padding-right: 10px;
}

.bugsProcess {
    font-weight: 600;
    font-size: 15px;
    line-height: 3px;
    letter-spacing: 0.2px;
    color: #170048;
}

/* All Contract end */

.milestone_changes .show_changes {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    padding: 15px 15px;
    margin: 20px 0 10px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    font-size: 14px;
}

.milestone_changes .about_milestone {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
}

.milestone_changes .about_milestone .edit {
    border: 1px solid #6610f2;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 6px;
}

.milestone_changes .about_milestone .edit svg {
    width: 100%;
    height: 100%;
}

.milestone_changes .about_milestone .milestone .amt {
    font-weight: 700;
}

.milestone_changes .new_milestone button {
    background-color: #6d2ef1;
    color: #fff;
    font: medium 18px/30px !important;
    font-weight: 500;
    border-radius: 60px !important;
    padding: 11px 35px;
    margin-top: 25px;
    min-width: 160px;
    border: #6d2ef1 solid 1px;
    -webkit-border-radius: 60px !important;
}

.milestone_changes .popup_form_element .about {
    font-size: 12px;
    color: #4d4d4d;
}

.add_milestone_popup .popup_form_element input,
.edit_active_milestone .popup_form_element input {
    height: 40px;
}

/* request milestone changes popup end */

/* Buy Unicoins screen start */
.buy_coins {
    padding: 30px;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    margin: 40px 0;
}

.buy_coins .heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.buy_coins .your_coins {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.buy_coins .your_coins .title {
    font-weight: 600;
}

.buy_coins .your_coins .numbers {
    font-weight: 500;
    color: #a5a5a5;
}

.buy_coins .row.buy {
    margin-top: 30px;
}

.buy_coins .row .inps {
    display: flex;
    flex-direction: column;
    position: relative;
}

.buy_coins .row .inps > button {
    position: absolute;
    bottom: 9px;
    right: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6610f2;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 15px;
}

.buy_coins .row .inps select,
.buy_coins .row .inps input {
    height: 40px;
    border: 1px solid #d5d5d5;
    outline: none;
    margin: 5px 0;
    border-radius: 4px;
    color: #534e4e;
    padding: 0 10px;
}

.buy_coins .about_coins {
    font-size: 14px;
    line-height: 30px;
    color: #a7a7a7;
    margin: 20px 0;
}

.theme_btns_big {
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
}

.theme_btns_big .first_button {
    color: #6d2ef1;
    background-color: #fff;
    border: #6d2ef1 solid 1px;
}

.theme_btns_big .second_button {
    color: #fff;
    background-color: #6610f2;
    border: #6d2ef1 solid 1px;
}

.theme_btns_big button {
    min-width: 200px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 600;
    border-radius: 20px !important;
    padding: 8px 45px;
    -webkit-border-radius: 60px !important;
    margin-top: 10px;
    border: none;
}

/* Buy Unicoins screen end */

.export_to_csv {
    cursor: pointer;
    color: #6610f2;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (min-width: 768px) {
    .skill_container_profile {
        padding: 5px 20px;
    }

    .portofolio_container_profile {
        padding: 0 10px;
    }
}

.p_relative {
    position: relative;
}

.choose_file_resubmit_revision {
    position: absolute;
    height: 36px;
    background: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    color: #3a3a3a;
    cursor: pointer;
    right: 0;
    font-weight: 500;
    font-size: 12px;
}

.choose_file_icon_resubmit_revision {
    position: absolute;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.choose_file_resubmit_revision_inp {
    padding: 0 0 0 0 !important;
    display: none;
}

.choose_file_resubmit_revision_inp_2 {
    background-color: #fff;
    cursor: pointer;
    height: 40px;
    width: 100%;
    border: 2px solid #ebebeb;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.choose_file_icon_resubmit_revision span {
    padding: 0 8px;
    font-size: 13px;
}

/* view original offer start */
.view_original_offer {
    margin: 40px auto;
}

.view_original_offer .top_heading {
    color: #170048;
    font-size: 26px;
    font-weight: 700;
}

.view_original_offer .offer_details {
    border: 1px solid #d4d4d4;
    padding: 30px;
    border-radius: 2px;
}

.view_original_offer .offer_details .heading {
    color: #170048;
    font-size: 20px;
    font-weight: 600;
}

.view_original_offer .offer_details .desc {
    color: #170048;
    font-size: 14px;
    margin: 10px 0;
    font-weight: 500;
}

.view_original_offer .offer_details .project_name {
    color: #170048;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
}

.view_original_offer .offer_details .description {
    color: #6b6b6b;
    font-size: 14px;
    margin-top: 10px;
    opacity: 0.8;
}

.view_original_offer .offer_details .more {
    font-size: 14px;
    font-weight: 500;
    color: #5403ff;
    cursor: pointer;
    margin-top: 10px;
}

.view_original_offer .offer_details .client {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #e0e0e0;
    align-items: center;
}

.view_original_offer .offer_details .client .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.view_original_offer .offer_details .client .image {
    display: flex;
    gap: 15px;
}

.view_original_offer .offer_details .client .image .name {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 600;
}

.view_original_offer .offer_details .client .image .about_client {
    color: #697a7e;
    font-size: 12px;
}

.view_original_offer .offer_details .client .image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.view_original_offer .offer_details .view_contract_btn {
    border: 1px solid #6610f2;
    color: #6610f2;
    cursor: pointer;
    padding: 8px 25px;
    font-weight: 500;
    border-radius: 40px;
}

/* view original offer end */

.submit_work_for_payment_popup .about_work {
    color: #4d4d4d;
    font-size: 14px;
    margin-top: 10px;
}

.submit_work_for_payment_popup .work_status {
    color: #6d2ef1;
    border: 1px solid #6d2ef1;
    padding: 5px 20px;
    border-radius: 40px;
    display: inline-block;
    /* cursor: pointer; */
    font-weight: 500;
    margin: 10px 0;
}

.submit_work_for_payment_popup .when_payment {
    color: #4d4d4d;
    font-size: 14px;
    margin: 10px 0 20px;
}

.submit_work_for_payment_popup .bb_divide {
    border-bottom: 1px solid #e4d4fe;
    margin: 10px 0;
}

.create_stripe_acc {
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    gap: 15px;
}

.create_stripe_acc button {
    min-width: 160px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 600;
    border-radius: 20px !important;
    padding: 8px 45px;
    -webkit-border-radius: 60px !important;
    background-color: #fff;
    border: #6d2ef1 solid 1px;
}

.create_stripe_acc .active {
    color: #fff;
    background-color: #6610f2;
    border: #6d2ef1 solid 1px;
}

@media screen and (max-width: 425px) {
    .create_stripe_acc button {
        width: 230px;
        margin: 0 auto;
    }
}

p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
a::selection,
h6::selection,
i::selection,
b::selection,
div::selection,
label::selection,
td::selection,
th::selection,
input::selection,
select::selection,
li::selection,
button::selection,
img::selection,
span::selection {
    background-color: #6610f2 !important;
    color: #fff !important;
}

.freelancer_accounts_list {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding: 10px 0;
}

.freelancer_accounts_list .bank_name {
    width: 75%;
    padding-top: 10px;
}

.freelancer_accounts_list .icon {
    width: 100px;
}

.freelancer_accounts_list .bank_menu_icon {
    position: relative;
}

.freelancer_accounts_list .bank_menu_icon .menu_items {
    position: absolute;
    top: 4px;
    right: 54px;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.freelancer_accounts_list .bank_menu_icon .menu_items span {
    padding: 2px 10px;
    width: 100%;
    cursor: pointer;
    text-align: start;
}

.freelancer_accounts_list .bank_menu_icon .menu_items span:hover {
    background: #f2f2f2;
}

@media screen and (max-width: 767px) {
    .freelancer_accounts_list .icon {
        display: none;
    }

    .freelancer_accounts_list .bank_name {
        padding-top: 0;
        width: auto;
    }

    .freelancer_accounts_list .bank_menu_icon button {
        margin: 0;
        width: 28px !important;
        height: 28px !important;
        padding: 0;
    }

    .s_nav_body.get_paid_banks {
        padding: 0;
    }
}

.freelancer_accounts_list .icon img {
    width: 100%;
}

.next_b_btn_c button:disabled {
    color: #fff;
    background-color: #bdbdbd !important;
    border: none;
}

/* .next_b_btn_c button:disabled:hover {
  color: #fff !important;
} */

.fb_btns_s_pro .can_not_send_proposal {
    color: #fff;
    background-color: #adadad;
    border: none;
    cursor: not-allowed;
}

.fb_btns_s_pro .can_not_send_proposal:hover {
    color: #fff;
    background-color: #adadad;
    border: none;
    cursor: not-allowed;
}

.select_controlinvite-input-wrapper {
    width: 510px;
    height: 40px;
    padding: 0 10px;
    outline: none;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.agree_termsColor {
    font-weight: 600;
    color: #f3a2cf;
    cursor: pointer;
}

.reset_link_conReset {
    color: #6d2ef1 !important;
    font-size: 14px;
    cursor: pointer;
}

.showWarning {
    position: absolute;
    top: -100px;
    left: -150px;
    width: 300px;
    font-size: 12px;
    border: 1px solid #cacaca;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
}

.menu_btn_arrow1showWarning {
    position: absolute;
    top: 82px;
    left: 149px;
    color: #cacaca;
    transform: rotateZ(90deg);
    font-weight: 200;
    font-size: 26px;
}

.portfolio_view {
    position: relative;
}

.portfolio_view .heading {
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0.2px;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    text-align: start;
}

.portfolio_view .desc {
    font-size: 14px;
    color: #ababab;
    word-break: break-word;
    white-space: pre-line;
}

.portfolio_view .image_p {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
    /* margin-top: 20px; */
    border-radius: 5px;
    overflow: hidden;
}

.portfolio_view .image_p img {
    max-width: 200px;
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .portfolio_view .image_p img {
        max-width: 100%;
    }
}

.SwitchAaccount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select_team_member {
    max-width: 460px;
}

.select_team_member .send_proposal_agency button {
    background-color: #0e1f3e;
    padding: 7px 35px;
    color: #fff;
    border: #0e1f3e solid 1px;
    font-size: 15px;
    margin-top: 20px;
    border-radius: 50px;
    text-transform: uppercase;
}

.select_team_member .input_field_d select {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding: 0 10px;
    border: 1px solid #d1d1d1;
    outline: none;
    color: #8a8a8a;
}

.notification_not_seen {
    background-color: #efe5ff;
}

.unseen_count {
    position: absolute;
    pointer-events: none;
    top: -4px;
    right: -8px;
    font-size: 8px;
    background: #6610f2 !important;
    color: #fff !important;
    border-radius: 8px;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notifica_nav:hover {
    color: #000 !important;
}

.home_number_one {
    font-size: 400px;
    fill: none;
    font-weight: 800;
    -webkit-text-stroke: 1px #ffffff61;
    position: absolute;
    color: transparent;
    z-index: -1;
    right: 38%;
    text-shadow: none;
    top: -7%;
    pointer-events: none;
}

/* @media screen and (max-width: 1400px) {
  .home_number_one {
    font-size: 357px;
  }
} */
@media screen and (max-width: 1024px) {
    .home_number_one {
        top: -6%;
    }
}

@media screen and (max-width: 768px) {
    .home_number_one {
        right: 30%;
        font-size: 250px;
    }
}

@media screen and (max-width: 500px) {
    .home_number_one {
        font-size: 180px;
    }
}

@media screen and (max-width: 320px) {
    .home_number_one {
        right: 22%;
    }
}

.count_home_two {
    font-size: 400px;
    fill: none;
    font-weight: 700;
    -webkit-text-stroke: 1px #c4c1c161;
    position: absolute;
    color: transparent;
    z-index: -1;
    right: 35%;
    text-shadow: none;
    top: -139px;
    left: 403px;
}

@media screen and (max-width: 1280px) {
    .count_home_two {
        font-size: 300px;
        top: -67px;
    }
}

@media screen and (max-width: 1024px) {
    .count_home_two {
        font-size: 350px;
        left: 240px;
        top: -197px;
    }
}

@media screen and (max-width: 500px) {
    .count_home_two {
        font-size: 180px !important;
        left: 105px;
        top: -103px;
    }
}

@media screen and (max-width: 375px) {
    .count_home_two {
        left: 58px;
        top: -102px;
    }
}

@media screen and (max-width: 325px) {
    .count_home_two {
        left: 40px;
        top: -101px;
    }
}

.bg_spot_image2,
.bg_spot_image1 {
    position: absolute;
}

.bg_spot_image1 {
    left: 0;
    bottom: 0;
    transform: translate(-50%, 0);
}

.bg_spot_image1 svg {
    width: 480px;
}

.bg_spot_image2 {
    right: 0;
    top: 0;
}

@media screen and (max-width: 900px) {
    .bg_spot_image1 {
        display: none;
    }

    .bg_spot_image2 {
        display: none;
    }
}

.bg_spot_image2 svg {
    width: 320px;
}

.home_numbering_3 {
    font-size: 450px;
    fill: none;
    font-weight: 800;
    -webkit-text-stroke: 1px #c4c1c161;
    position: absolute;
    color: transparent;
    z-index: -1;
    right: 25%;
    text-shadow: none;
    top: 0;
    transform: translate(-50%, -30%);
}

.home_numbering_4 {
    font-size: 350px;
    fill: none;
    font-weight: 800;
    -webkit-text-stroke: 1px #c4c1c161;
    position: absolute;
    color: transparent;
    z-index: -1;
    text-shadow: none;
    top: 50px;
    left: 0;
    right: 0;
}

@media screen and (max-width: 1280px) {
    .home_numbering_3 {
        font-size: 300px;
    }

    .home_numbering_4 {
        font-size: 300px;
    }
}

@media screen and (max-width: 1024px) {
    .home_numbering_3 {
        font-size: 350px;
        transform: translate(-50%, -30%);
    }

    .home_numbering_4 {
        font-size: 350px;
    }
}

@media screen and (max-width: 768px) {
    .home_numbering_3 {
        right: 50%;
        font-size: 200px;
        transform: translate(50%, -40%);
    }

    .home_numbering_4 {
        font-size: 300px;
    }
}

@media screen and (max-width: 500px) {
    .home_numbering_4 {
        font-size: 200px;
    }
}

@media screen and (max-width: 375px) {
    .home_numbering_4 {
        font-size: 200px;
    }
}

@media screen and (max-width: 350px) {
    .home_numbering_4 {
        font-size: 180px;
    }
}

/* unify about page start */
.about_unify_page {
    position: relative;
}

.about_unify_page .middle_div {
    padding-top: 180px;
    /* margin-top: 200px; */
}

.about_unify_page .text {
    color: #00065a;
    font-size: 60px;
    font-weight: 800;

    letter-spacing: -1px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 80%;
}

.about_unify_page .textspan {
    color: #00065a;
    font-size: 60px;
    font-weight: 800;

    letter-spacing: -1px;
    position: relative;
    text-align: center;
    width: 80%;
    margin: -21px auto 0;
}

.about_unify_page .back_number2 {
    font-weight: 700;
    font-size: 350px;
    position: absolute;
    top: -213px;
    left: 444px;
    opacity: 0.2;
    color: transparent;
    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
}

.about_unify_page .text-div {
    position: relative;
    display: flex;
    flex-direction: column;
}

.about_unify_page .heading_div {
    display: flex;
    flex-direction: column;
}

.about_unify_page .para_div {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.about_unify_page .para {
    text-align: center;
    font-size: 16px;
    width: 50%;
    line-height: 25px;
}

.about_unify_page .pink_dash {
    background-color: #ff88fe;
    content: "";
    height: 25px !important;
    left: 383px;
    position: absolute;
    top: 54px;
    width: 360px !important;
    z-index: -1;
}

.about_unify_page .text_h2 {
    display: flex;
    flex-direction: column;
    color: #00065a;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1px;
    margin: 0 auto;
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 4;
}

.about_unify_page .text_h3 {
    display: flex;
    flex-direction: column;
    color: #00065a;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1px;

    position: relative;

    width: 100%;
    z-index: 4;
    box-sizing: content-box;
}

.about_unify_page .pink_dash2 {
    background-color: #ff88fe;
    content: "";
    height: 25px !important;
    left: 0;
    position: absolute;
    top: 125px;
    width: 442px !important;
    z-index: -1;
}

.about_unify_page .second_middle {
    position: relative;
    margin-bottom: 180px;
    background: linear-gradient(0.25turn, #ffffff 59%, #fafafa00);
    display: flex;
    margin-top: 60px;
}

.about_unify_page .left_div {
    padding-top: 60px;
    width: 100%;
    min-height: 500px;
}

.about_unify_page .right_div {
    position: absolute;
    right: -104px;
    top: 0;
    z-index: -2;
    height: 108%;
}

.about_unify_page .right_div3 {
    position: absolute;
    right: -104px;
    top: 0;
    z-index: -2;
    height: 108%;
}

.about_unify_page .left_div_para {
    width: 48%;
    margin-top: 40px;
    line-height: 25px;
    font-size: 16px;
}

.about_unify_page .main_img {
    height: 92%;
    object-fit: contain;
}

.about_unify_page .pink_dash4 {
    background-color: #ff88fe;
    content: "";
    height: 25px !important;
    left: 0;
    position: absolute;
    top: 48px;
    width: 486px !important;
    z-index: -1;
}

.about_unify_page .second_middle2 {
    display: grid;
    width: 100%;
    position: relative;
    background: linear-gradient(0.25turn, #fafafa00, #ffffff 38%);
    margin-bottom: 130px;
}

.about_unify_page .left_div2 {
    z-index: -2;
    margin-left: -135px;
    width: 100%;
    max-height: 500px;
}

.about_unify_page .right_div2 {
    position: absolute;
    padding-top: 60px;
    right: 25px;
    width: 67%;
}

.about_unify_page .second_middle3 {
    position: relative;
    background: linear-gradient(0.25turn, #ffffff 59%, #fafafa00);
}

.about_unify_page .whole {
    flex-direction: column;
    text-align: center;
}

.about_unify_page .final_div {
    width: 100%;
    display: grid;
    justify-content: center;
    padding-top: 60px;
}

.about_unify_page .pink_dash5 {
    background-color: #ff88fe;
    content: "";
    height: 36px !important;
    left: 183px;
    position: absolute;
    top: 48px;
    width: 470px !important;
    z-index: -1;
}

.about_unify_page .last_btn {
    font-size: 20px;
    font-weight: 500;
    width: 50%;
    background-color: #170048;
    border: 1px solid #170048;
    color: white;
    padding: 14px 0 !important;
    border-radius: 300px;
    margin-top: 40px;
    margin-bottom: 30px;
}

.about_unify_page .btn_div {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.about_unify_page .left_div_para2 {
    width: 70%;
    margin-top: 40px;
    line-height: 25px;
}

.about_unify_page .pink_dash3 {
    background-color: #ff88fe;
    content: "";
    height: 25px !important;
    left: 113px;
    position: absolute;
    top: 125px;
    width: 386px !important;
    z-index: -1;
}

.about_unify_page .last_para {
    margin-top: 10px;
    line-height: 25px;
}

.about_unify_page .bg_color {
    background-color: #ebf4ff;
    /* display: flex; */
}

.about_unify_page .back_number3 {
    font-size: 350px;
    position: absolute;
    top: -171px;
    left: 49px;
    opacity: 0.2;
    color: transparent;
    font-weight: 700;
    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
}

.about_unify_page .back_number4 {
    font-size: 350px;
    position: absolute;
    top: -179px;
    /* left: -8px; */
    opacity: 0.2;
    color: transparent;

    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
    font-weight: 700;
}

.about_unify_page .back_number5 {
    font-size: 350px;
    position: absolute;
    top: -161px;
    left: 21px;
    opacity: 0.2;
    color: transparent;

    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
    font-weight: 700;
}

@media (max-width: 1399px) {
    .about_unify_page .back_number2 {
        left: 338px;
    }

    .about_unify_page .pink_dash5 {
        left: 164px;
        position: absolute;
        top: 45px;
        width: 436px !important;
    }

    .about_unify_page .pink_dash4 {
        width: 444px !important;
    }

    .about_unify_page .pink_dash3 {
        left: 109px;
        top: 115px;
        width: 342px !important;
    }

    .about_unify_page .pink_dash2 {
        top: 112px;
        width: 407px !important;
    }

    .about_unify_page .pink_dash {
        left: 316px;
        width: 331px !important;
    }
}

@media (max-width: 1199px) {
    .about_unify_page .pink_dash {
        left: 227px;
    }

    .about_unify_page .back_number2 {
        left: 248px;
    }
}

@media (max-width: 991px) {
    .about_unify_page .pink_dash5 {
        display: none;
    }

    .about_unify_page .pink_dash4 {
        display: none;
    }

    .about_unify_page .pink_dash3 {
        display: none;
    }

    .about_unify_page .pink_dash2 {
        display: none;
    }

    .about_unify_page .pink_dash {
        display: none;
    }

    .about_unify_page .back_number2 {
        left: 114px;
    }

    .about_unify_page .middle_div {
        /* margin-top: 0; */
    }

    .about_unify_page .left_div2 {
        padding-top: 450px;
        margin-left: -33px;
    }

    .about_unify_page .right_div2 {
        position: absolute;
        left: 0;
        top: 99px;
        width: 100%;
        padding-top: 0;
    }

    .about_unify_page .second_middle2 {
        background: none;
        margin-top: 560px;
        margin-bottom: 500px;
    }

    .about_unify_page .main_img {
        width: 100%;
        height: max-content;
    }

    .about_unify_page .second_middle {
        background: none;
        /* margin-top: 0; */
    }

    .about_unify_page .right_div {
        top: 439px;
        left: 0;
        width: 100%;
    }

    .about_unify_page .right_div3 {
        top: 475px;
        left: 0;
        width: 100%;
    }

    .about_unify_page .left_div {
        width: 100%;
    }

    .about_unify_page .left_div_para {
        width: 100%;
    }

    .about_unify_page .second_middle3 {
        background: none;
        margin-top: 635px;
    }

    .about_unify_page .final_div {
        margin-top: 523px;
    }

    .about_unify_page .text {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .about_unify_page .back_number2 {
        font-size: 250px;
        top: -118px;
        left: 167px;
    }

    .about_unify_page .back_number5 {
        font-size: 250px;
        top: -117px;
        left: 95px;
    }

    .about_unify_page .back_number4 {
        font-size: 250px;
        top: -179px;
        left: 96px;
    }

    .about_unify_page .back_number3 {
        font-size: 250px;
        top: -64px;
        left: 104px;
    }

    .about_unify_page .second_middle {
        margin-top: 0;
    }

    .about_unify_page .middle_div {
        padding-top: 100px;
    }

    .about_unify_page .last_para {
        font-size: 10px;
    }

    .about_unify_page .text {
        font-size: 50px;
    }

    .about_unify_page .textspan {
        font-size: 50px;
    }

    .about_unify_page .text_h2 {
        font-size: 50px;
    }

    .about_unify_page .text_h3 {
        font-size: 50px;
    }

    .about_unify_page .para {
        width: 80%;
    }
}

@media (max-width: 767px) {
    .about_unify_page .back_number2 {
        left: 108px;
    }

    .about_unify_page .second_middle2 {
        margin-top: 440px;
    }

    .about_unify_page .second_middle3 {
        margin-top: 471px;
    }

    .about_unify_page .final_div {
        margin-top: 383px;
    }
}

@media (max-width: 430px) {
    .about_unify_page .text {
        font-size: 40px;
    }

    .about_unify_page .textspan {
        font-size: 40px;
    }

    .about_unify_page .para {
        line-height: 20px;
        width: 85%;
    }

    .about_unify_page .text_h2 {
        font-size: 40px;
    }

    .about_unify_page .right_div {
        top: 400px;
    }

    .about_unify_page .text_h3 {
        font-size: 40px;
    }

    .about_unify_page .left_div_para2 {
        line-height: 20px;
        font-size: 16px;
    }

    .about_unify_page .second_middle {
        margin-top: -30px;
    }

    .about_unify_page .second_middle2 {
        margin-top: 230px;
        margin-bottom: 354px;
    }

    .about_unify_page .left_div_para {
        line-height: 20px;
    }

    .about_unify_page .middle_div {
        padding-top: 108px;
    }

    .about_unify_page .left_div2 {
        margin-left: -20px;
        padding-top: 471px;
    }

    .about_unify_page .final_div {
        margin-top: 189px;
    }

    .about_unify_page .second_middle3 {
        margin-top: 381px;
    }

    .about_unify_page .right_div3 {
        top: 370px;
    }

    .about_unify_page .back_number2 {
        font-size: 200px !important;
        top: -118px !important;
        left: 83px !important;
    }

    .about_unify_page .back_number5 {
        font-size: 200px !important;
        top: -117px !important;
        left: 95px !important;
    }

    .about_unify_page .back_number4 {
        font-size: 200px !important;
        top: -124px !important;
        left: 78px !important;
    }

    .about_unify_page .back_number3 {
        font-size: 200px !important;
        top: -56px !important;
        left: 104px !important;
    }
}

@media (max-width: 376px) {
    .about_unify_page .text {
        font-size: 40px;
    }

    .about_unify_page .textspan {
        font-size: 40px;
    }

    .about_unify_page .para {
        line-height: 20px;
        width: 85%;
    }

    .about_unify_page .text_h2 {
        font-size: 40px;
    }

    .about_unify_page .right_div {
        top: 400px;
    }

    .about_unify_page .text_h3 {
        font-size: 40px;
    }

    .about_unify_page .left_div_para2 {
        line-height: 20px;
        font-size: 16px;
    }

    .about_unify_page .second_middle {
        margin-top: -30px;
    }

    .about_unify_page .second_middle2 {
        margin-top: 198px;
        margin-bottom: 300px;
    }

    .about_unify_page .left_div_para {
        line-height: 20px;
    }

    .about_unify_page .middle_div {
        padding-top: 108px;
    }

    .about_unify_page .left_div2 {
        margin-left: -20px;
        padding-top: 471px;
    }

    .about_unify_page .final_div {
        margin-top: 250px;
    }

    .about_unify_page .second_middle3 {
        margin-top: 0;
    }

    .about_unify_page .last_btn {
        font-size: 16px;
    }

    .about_unify_page .right_div3 {
        top: 482px;
    }

    .about_unify_page .back_number2 {
        font-size: 200px !important;
        top: -118px !important;
        left: 63px !important;
    }

    .about_unify_page .back_number5 {
        font-size: 200px !important;
        top: -75px !important;
        left: 95px !important;
    }

    .about_unify_page .back_number4 {
        font-size: 200px !important;
        top: -124px !important;
        left: 78px !important;
    }

    .about_unify_page .back_number3 {
        font-size: 200px !important;
        top: -56px !important;
        left: 104px !important;
    }
}

@media (max-width: 320px) {
    .about_unify_page .back_number2 {
        left: 28px !important;
    }

    .about_unify_page .back_number5 {
        left: 32px !important;
    }

    .about_unify_page .back_number4 {
        left: 30px !important;
    }

    .about_unify_page .second_middle {
        margin-top: 0;
        margin-bottom: 130px !important;
    }

    .about_unify_page .left_div_para2 {
        width: 100%;
    }

    .about_unify_page .para {
        width: 100%;
    }

    .about_unify_page .back_number3 {
        top: -32px !important;
        left: 28px !important;
    }

    .about_unify_page .para_div {
        margin-bottom: 15px;
    }

    .about_unify_page .second_middle2 {
        margin-top: 133px;
        margin-bottom: 274px;
    }

    .about_unify_page .left_div2 {
        padding-top: 432px;
    }
}

/* @media (max-width: 309px) {
  .about_unify_page .right_div3 {
    top: 298px;
  }
  .about_unify_page .left_div2 {
    padding-top: 300px;
  }
}
@media (max-width: 350px) {
  .about_unify_page .left_div2 {
    padding-top: 300px;
  }
  .about_unify_page .back_number2 {
    font-size: 180px !important;
    top: -118px !important;
    left: 23px !important;
  }

  .about_unify_page .back_number5 {
    font-size: 180px !important;
    top: -118px !important;
    left: 23px !important;
  }
  .about_unify_page .back_number4 {
    font-size: 180px !important;
    top: -118px !important ;
    left: 23px !important;
  }
  .about_unify_page .back_number3 {
    font-size: 180px !important;
    top: -118px !important;
    left: 23px !important;
  }
} */

/* unify about page  end*/

/* Earn and learn page start */
.learn_and_earn_page {
    position: relative;
}

.learn_and_earn_page .home_pink_dash {
    background-color: #ff88fe;
    content: "";
    height: 23px !important;
    left: 447px;
    position: absolute;
    top: 47px;
    width: 416px !important;
    z-index: -1;
}

.learn_and_earn_page .home_pink_dash2 {
    background-color: #ff88fe;
    content: "";
    height: 36px !important;
    left: 164px;
    position: absolute;
    top: 115px;
    width: 278px !important;
    z-index: -1;
}

.learn_and_earn_page .home_pink_dash3 {
    background-color: #ff88fe;
    content: "";
    height: 33px !important;
    left: 263px;
    position: absolute;
    top: 38px;
    width: 346px !important;

    z-index: -1;
}

.learn_and_earn_page .home_pink_dash4 {
    background-color: #ff88fe;
    content: "";
    height: 33px !important;
    left: -3px;
    position: absolute;
    top: 112px;
    width: 430px !important;
    z-index: -1;
}

.learn_and_earn_page .home_pink_dash5 {
    background-color: #ff88fe;
    content: "";
    height: 36px !important;
    left: 0;
    position: absolute;
    top: 112px;
    width: 409px !important;
    z-index: -1;
}

.learn_and_earn_page .home_back_number2 {
    font-weight: 700;
    font-size: 350px;
    position: absolute;
    top: -213px;
    left: 463px;
    opacity: 0.2;
    color: transparent;
    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
}

.learn_and_earn_page .home_back_number3 {
    font-size: 350px;
    position: absolute;
    top: -184px;
    left: 42px;
    opacity: 0.2;
    color: transparent;
    font-weight: 700;
    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
}

.learn_and_earn_page .home_back_number4 {
    font-size: 350px;
    position: absolute;
    top: -179px;
    /* left: -8px; */
    opacity: 0.2;
    color: transparent;

    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
    font-weight: 700;
}

.learn_and_earn_page .home_back_number5 {
    font-size: 350px;
    position: absolute;
    top: -161px;
    left: 21px;
    opacity: 0.2;
    color: transparent;

    -webkit-text-stroke: 2px rgb(110, 106, 106);
    text-shadow: none;
    font-weight: 700;
}

.learn_and_earn_page header {
    display: flex;
    justify-content: space-between;

    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.learn_and_earn_page .home_middle_div {
    padding-top: 180px;
    /* margin-top: 300px; */
    /* it is for two page ntng else  */
}

.learn_and_earn_page .home_text {
    color: #00065a;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 80%;
}

.learn_and_earn_page .home_text-div {
    position: relative;
}

.learn_and_earn_page .home_para_div {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.learn_and_earn_page .home_para {
    text-align: center;
    font-size: 16px;
    width: 50%;
    line-height: 25px;
}

.learn_and_earn_page .home_text_h2 {
    display: flex;
    flex-direction: column;
    color: #00065a;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1px;
    margin: 0 auto;
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 4;
}

.learn_and_earn_page .home_text_h3 {
    display: flex;
    flex-direction: column;
    color: #00065a;
    font-size: 85px;
    font-weight: 800;
    letter-spacing: -1px;

    position: relative;

    width: 100%;
    z-index: 4;
    box-sizing: content-box;
}

.learn_and_earn_page .home_second_middle {
    position: relative;
    margin-bottom: 300px;
    background: linear-gradient(0.25turn, #ffffff 59%, #fafafa00);
    display: flex;
    margin-top: 145px;
}

.learn_and_earn_page .home_left_div {
    padding-top: 60px;
    width: 100%;
    min-height: 500px;
}

.learn_and_earn_page .home_right_div {
    position: absolute;
    right: -104px;
    top: 0;
    z-index: -2;
    height: 108%;
}

.learn_and_earn_page .home_right_div3 {
    position: absolute;
    right: -104px;
    top: 0;
    z-index: -2;
    height: 108%;
}

.learn_and_earn_page .home_left_div_para {
    width: 45%;
    font-size: 16px;
    margin-top: 40px;
    line-height: 25px;
}

.learn_and_earn_page .home_main_img {
    height: 92%;
    object-fit: contain;
}

.learn_and_earn_page .home_second_middle2 {
    display: grid;
    width: 100%;
    position: relative;
    background: linear-gradient(0.25turn, #fafafa00, #ffffff 36%);
    margin-bottom: 120px;
    margin-top: -155px;
}

.learn_and_earn_page .home_left_div2 {
    z-index: -2;
    margin-left: -135px;
    width: 100%;
    max-height: 500px;
}

.learn_and_earn_page .home_right_div2 {
    position: absolute;
    padding-top: 60px;
    right: 25px;
    width: 67%;
}

.learn_and_earn_page .home_second_middle3 {
    position: relative;
    background: linear-gradient(0.25turn, #ffffff 59%, #fafafa00);
}

.learn_and_earn_page .home_whole {
    flex-direction: column;
    text-align: center;
}

.learn_and_earn_page .home_final_div {
    width: 100%;
    display: grid;
    justify-content: center;
    padding-top: 60px;
}

.learn_and_earn_page .home_last_btn {
    font-size: 25px;
    font-weight: 500;
    width: 50%;
    background-color: #170048;
    border: 1px solid #170048;
    color: white;
    padding: 14px 0 !important;
    border-radius: 300px;
    margin-top: 60px;
    margin-bottom: 30px;
}

.learn_and_earn_page .home_btn_div {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.learn_and_earn_page .home_left_div_para2 {
    width: 60%;
    margin-top: 40px;
    font-size: 16px;
    line-height: 25px;
}

.learn_and_earn_page .home_last_para {
    margin-top: 10px;
    font-size: 20px;
    line-height: 25px;
}

.learn_and_earn_page .home_bg_color {
    background-color: #ebf4ff !important;
}

@media (max-width: 1024px) {
    .learn_and_earn_page .home_middle_div {
        /* margin-top: 200px; */
    }

    .learn_and_earn_page .home_back_number2 {
        font-size: 300px;
        top: -193px;
        left: 270px;
    }

    .learn_and_earn_page .home_back_number3 {
        font-size: 300px;
        top: -145px;
        left: 71px;
    }

    .learn_and_earn_page .home_back_number4 {
        font-size: 300px;
        top: -138px;
        left: 2px;
    }

    .learn_and_earn_page .home_back_number5 {
        font-size: 300px;
        top: -121px;
        left: 43px;
    }

    .learn_and_earn_page .home_pink_dash5 {
        display: none;
    }

    .learn_and_earn_page .home_pink_dash4 {
        display: none;
    }

    .learn_and_earn_page .home_pink_dash3 {
        display: none;
    }

    .learn_and_earn_page .home_pink_dash2 {
        display: none;
    }

    .learn_and_earn_page .home_pink_dash {
        display: none;
    }

    .learn_and_earn_page .home_text_h2 {
        font-size: 55px;
    }

    .learn_and_earn_page .home_text {
        font-size: 55px;
    }

    .learn_and_earn_page .home_para {
        font-size: 20px;
        width: 70%;
    }

    .learn_and_earn_page .home_left_div_para {
        font-size: 20px;
        width: 55%;
    }

    .learn_and_earn_page .home_second_middle3 {
        background: linear-gradient(0.25turn, #ffffff 42%, #fafafa00);
    }

    .learn_and_earn_page .home_second_middle {
        background: linear-gradient(0.25turn, #ffffff 43%, #fafafa00);
    }

    .learn_and_earn_page .home_second_middle2 {
        background: linear-gradient(0.25turn, #fafafa00, #ffffff 48%);
    }

    .learn_and_earn_page .home_right_div2 {
        right: -50px;
    }

    .learn_and_earn_page .home_left_div2 {
        margin-left: 0;
    }
}

@media (max-width: 991px) {
    .learn_and_earn_page .home_back_number2 {
        left: 44px;
    }

    .learn_and_earn_page .home_right_div2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding-top: 0;
    }

    .learn_and_earn_page .home_second_middle2 {
        background: none;
        margin-top: 700px;
        margin-bottom: 500px;
    }

    .learn_and_earn_page .home_main_img {
        width: 100%;
        height: max-content;
    }

    .learn_and_earn_page .home_second_middle {
        background: none;
    }

    .learn_and_earn_page .home_right_div {
        top: 400px;
        left: 0;
        width: 100%;
    }

    .learn_and_earn_page .home_right_div3 {
        top: 475px;
        left: 0;
        width: 100%;
    }

    .learn_and_earn_page .home_left_div {
        width: 100%;
    }

    .learn_and_earn_page .home_left_div_para {
        width: 100%;
    }

    .learn_and_earn_page .home_second_middle3 {
        background: none;
        margin-top: 700px;
    }

    .learn_and_earn_page .home_final_div {
        margin-top: 700px;
    }

    .learn_and_earn_page .home_text {
        width: 100%;
    }

    .learn_and_earn_page .home_left_div2 {
        margin-left: 0;
        padding-top: 355px;
    }
}

@media (max-width: 750px) {
    .learn_and_earn_page .home_back_number2 {
        font-size: 350px;
        top: -227px;
    }

    .learn_and_earn_page .home_back_number5 {
        font-size: 350px;
        top: -152px;
    }

    .learn_and_earn_page .home_back_number4 {
        font-size: 350px;
        top: -169px;
    }

    .learn_and_earn_page .home_back_number3 {
        font-size: 350px;
        top: -159px;
    }
}

@media (max-width: 450px) {
    .learn_and_earn_page .home_back_number2 {
        font-size: 200px;
        top: -118px;
        left: 97px;
    }

    .learn_and_earn_page .home_back_number5 {
        font-size: 200px;
        top: -117px;
        left: 95px;
    }

    .learn_and_earn_page .home_back_number4 {
        font-size: 200px;
        top: -124px;
        left: 96px;
    }

    .learn_and_earn_page .home_back_number3 {
        font-size: 200px;
        top: -121px;
        left: 104px;
    }

    .learn_and_earn_page .home_last_para {
        font-size: 8px;
    }

    .learn_and_earn_page .home_left_div2 {
        margin-left: 0;

        padding-top: 235px;
    }

    .learn_and_earn_page .home_right_div2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding-top: 0;
    }

    .learn_and_earn_page .home_second_middle2 {
        background: none;
        margin-top: 190px;
        margin-bottom: 50px;
    }

    .learn_and_earn_page .home_main_img {
        width: 100%;
        height: max-content;
    }

    .learn_and_earn_page .home_second_middle {
        background: none;
        margin-bottom: 0;
        margin-top: 170px;
    }

    .learn_and_earn_page .home_right_div {
        top: 200px;
        left: 0;
        width: 100%;
    }

    .learn_and_earn_page .home_right_div3 {
        top: 250px;
        left: 0;
        width: 100%;
    }

    .learn_and_earn_page .home_left_div {
        width: 100%;
        padding-top: 0;
    }

    .learn_and_earn_page .home_left_div_para {
        width: 100%;
    }

    .learn_and_earn_page .home_middle_div {
        margin-top: 30px;
    }

    .learn_and_earn_page .home_second_middle3 {
        background: none;
        margin-bottom: 0;
        margin-top: 250px;
    }

    .learn_and_earn_page .home_text {
        width: 100%;
        font-size: 35px;
    }

    .learn_and_earn_page .home_text_h2 {
        font-size: 35px;
    }

    .learn_and_earn_page .home_text_h3 {
        font-size: 35px;
    }

    .learn_and_earn_page .home_para {
        font-size: 12px;
    }

    .learn_and_earn_page .home_left_div_para {
        font-size: 12px;
        text-align: center;
    }

    .learn_and_earn_page .home_left_div_para2 {
        font-size: 12px;
        text-align: center;
        line-height: 25px;
    }

    .learn_and_earn_page .home_final_div {
        margin-top: 70px;
    }

    .learn_and_earn_page .home_text_h2 {
        text-align: center;
    }

    .learn_and_earn_page .home_text_h3 {
        text-align: center;
    }

    .learn_and_earn_page .home_last_btn {
        font-size: 12px;
    }
}

@media (max-width: 350px) {
    .learn_and_earn_page .home_left_div2 {
        padding-top: 308px;
    }

    .learn_and_earn_page .home_back_number2 {
        font-size: 180px;
        top: -118px;
        left: 23px;
    }

    .learn_and_earn_page .home_back_number5 {
        font-size: 180px;
        top: -118px;
        left: 23px;
    }

    .learn_and_earn_page .home_back_number4 {
        font-size: 180px;
        top: -118px;
        left: 23px;
    }

    .learn_and_earn_page .home_back_number3 {
        font-size: 180px;
        top: -118px;
        left: 23px;
    }
}

/* Earn and learn page end */


.responsiveToggler {
    top: 35px !important;
}

@media (max-width: 375px) {
    .clinetDayTimesheet {
        flex-direction: unset !important;
    }

    .clinetDayTimesheetDate,
    .clinetDayTimesheetTime {
        width: unset !important;
    }

    .clinetDayTimesheetTime {
        margin-left: 0 !important;
    }

    .timesheetTracked {
        padding: 0 !important;
    }
}

.no_result_found_chat {
    margin-top: 50px;
}

.no_result_found_chat .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.no_result_found_chat .mesage_fs {
    text-align: center;
    color: #ababab;
}

.out_of_office {
    color: #000;
    padding: 10px 20px 0 20px;
}

.chat_messages_body {
    position: relative;
}

.show_user_list {
    display: block;
}


@media (max-width: 481px) {
    .chat_messages_body {
        width: 100%;
    }
}

.css-13cymwt-control {
    border: none !important;
    background: #f5f5fe !important;
}

.hire_btnHover:hover {
    text-decoration: underline !important;
    color: #fff !important;
}

.date_separator {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 90%;
    color: #cfcfcf;
    margin: 10px auto;
    font-size: 14px;
}

.date_separator span {
    font-size: 12px;
    min-width: max-content;
}

.date_separator .before {
    background-color: #cfcfcf;
    height: 1px;
    width: 100%;
}

.date_separator .after {
    background-color: #cfcfcf;
    height: 1px;
    width: 100%;
}

.ForFreelancersNav {
    font-size: 16px !important;
}

.budgetvalidationpupop {
    position: absolute;
    top: 30px;
    left: 200px;
    padding: 5px 15px;
    background-color: #6d2ef1;
    color: #fff;
    border-radius: 5px;
}

@media (max-width: 481px) {
    .budgetvalidationpupop {
        top: 50px;
        left: 0;
    }
}

@media (max-width: 767px) {
    .psearch-pnpou {
        left: 0;
    }
}

.uppers {
    text-transform: uppercase;
}

.clie_row_box {
    width: 75%;
    margin: auto;
    padding-bottom: 20px;
}

.client_comap_sec {
    max-width: 780px;
    /* margin: 0 auto; */
    text-align: left;
    margin-top: 40px;
}

.client_w_b {
    width: 72%;
}

.reviewContractHeading {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.reviewContractSubHeading {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    word-wrap: break-word;
}

.reviewContractAmount {
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.reviewContractProfile {
    display: flex;
    gap: 20px;
}

.reviewContractProfileLeft {
    /* display: flex; */
}

.reviewContractName {
    font-weight: 500;
    font-size: 18px;
}

.reviewContractProfileText {
    font-weight: 400;
    color: #747473;
    font-size: 18px;
}

.reviewContractProfileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.reviewContractHighlightedContainer {
    background-color: #f8f8f8;
    padding: 10px;
    margin-top: 34px;
    border-radius: 10px;
}

.revwContHeading {
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.emailPreviewBtn {
    background-color: #6d2ef1;
    padding: 12px 25px;
    color: #fff;
    border: #6d2ef1 solid 1px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    -webkit-border-radius: 30px;
}

.emailPreviewBtn:disabled,
.emailPreviewBtn[disabled] {
    border: 1px solid #cccccc;
    background-color: #cccccc;
    color: #888888;
    cursor: not-allowed;
}

.reviewEmailSent {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0 !important;
}

.emailSentImg {
    display: flex;
    justify-content: center;
}

.emailContractSentHeading {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    text-align: center;
}

.emailContractSentTerms {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.popup_btns_emailCenter {
    justify-content: center;
    display: flex;
}

/* borderless input */
.borlderLessInputhr[type="number"] {
    border: none;
    background-color: unset;
    outline: 0;
    font-size: 20px;
    width: 100%;
    display: inline;
    color: #757575;
}

.borlderLessInputhr[type="number"]::placeholder {
    font-size: 20px;
    color: #000;
}

.borlderLessInput[type="number"]:focus {
    border: none;
    background-color: unset;
    outline: 0;
    width: 100%;
    height: 20px;
    display: inline;
}

.borderLessInputHourPlace {
    font-size: 20px;
    font-weight: 600;
    color: #757575;
}

.borlderLessInput[type="number"] {
    border: none;
    background-color: unset;
    outline: 0;
    font-size: 45px;
    width: 100%;
    height: 45px;
    display: inline;
    color: #757575;
}

.borlderLessInput[type="number"]::placeholder {
    font-size: 45px;
    color: #000;
}

.borlderLessInput[type="number"]:focus {
    border: none;
    background-color: unset;
    outline: 0;
    width: 100%;
    height: 45px;
    display: inline;
}

.borderLessInputFixedPlace {
    font-size: 45px;
    font-weight: 600;
    color: #757575;
}

.addMilestoneContainer {
    border: 1px solid #cdcccc;
    display: flex;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

.PopupmilestoneHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.directContractpopup_box_bpn {
    max-width: 700px;
    padding: 10px 30px;
    background: #ffffff 0 0 no-repeat padding-box;
    opacity: 1;
    min-height: 200px;
    max-height: 95vh;
    overflow-y: auto;
}

.direct_contreact_t_bt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dir_cont_listData {
    border-bottom: 1px solid #d4d4d5;
    margin-bottom: 5px;
    padding: 10px 10px 15px 10px;
}

.dir_cont_listData.last-child {
    border-bottom: none;
}

/* .dir_cont_listData:last-child {
  border-bottom: unset;
} */

.dir_act_cont_hed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    text-transform: capitalize;
}

.dir_act_cont_hed .btn_dir {
    height: 35px;
    width: 35px;
}

.dir_act_job_name {
    font-size: 16px;
    font-weight: 600;
}

.dir_cl_emil {
    color: #b2b2b2;
}

.dir_wait_days {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 600;
}

.dir_wait_days .big_circle_wait {
    color: #6d2ef1;
    font-size: 11px;
    padding-right: 3px;
}

.dir_wait_days .small_circle_wait {
    font-size: 6px;
}

.btn_direct_forDrop {
    /* position: relative; */
}

.show_drop_direc {
    position: absolute;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    width: 100%;
    top: 40px;
    right: 0;
    max-width: max-content;
    padding: 15px 55px 15px 25px;
    z-index: 99;
}

.show_drop_direc div {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 12px;
    cursor: pointer;
}

.show_drop_direc div:hover {
    color: #6d2ef1;
}

.show_drop_direc div:last-child {
    padding-bottom: 0;
}

.show_drop_direc_det {
    position: absolute;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    width: 100%;
    top: 67px;
    right: 18px;
    max-width: max-content;
    padding: 15px 55px 15px 25px;
    z-index: 99;
}

.show_drop_direc_det div {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 12px;
    cursor: pointer;
}

.show_drop_direc_det div:hover {
    color: #6d2ef1;
}

.show_drop_direc_det div:last-child {
    padding-bottom: 0;
}

/* direct contarct details */

.contart_wit_waiting {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contart_wit_waiting .waiting_box {
    font-size: 12px;
    font-weight: 400;
    background: #6610f2;
    padding: 5px 15px;
    color: #fff;
    border-radius: 30px;
}

.contart_wit_waiting .withdrwan_box {
    font-size: 12px;
    font-weight: 400;
    background: #b2b2b2;
    padding: 5px 15px;
    color: #fff;
    border-radius: 30px;
}

.dir_contract_details_box {
    width: 60%;
    margin: 80px auto !important;
    padding: 20px !important;
    position: relative;
    background-color: #fff;
}

.direct_contract_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.det_dir_contract {
    font-size: 24px;
    font-weight: 600;
}

.det_dir_proj_name {
    font-size: 20px;
    font-weight: 500;
    margin-top: 25px;
    padding-bottom: 10px;
}

.dir_job_name_det {
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    word-break: break-all;
}

.otpSent {
    color: green;
    font-size: 15px;
}

.quickSecurity_main {
    width: 40%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    background-color: #fff;
}

.direct_work_submit {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    background-color: #fff;
}

.direct_work_submit .direct_work_icons {
    width: 200px;
    margin: auto;
}

.direct_work_submit .direct_work_icons svg {
    max-width: 100%;
    height: auto;
}

.resendBtn {
    cursor: pointer;
    color: #6d2ef1;
    text-decoration: underline;
    font-weight: 500;
}

@media (max-width: 991px) {
    .dir_contract_details_box {
        width: 100%;
        /* margin: 25px auto !important;
    padding: 20px !important;
    position: relative; */
    }
}

/* direct contract share link popup */

.dir_cont_shr_link {
    width: 600px;
    border-radius: 10px;
}

@media (max-width: 650px) {
    .dir_cont_shr_link {
        width: unset;
    }
}

@media (max-width: 481px) {
    .contart_wit_waiting {
        flex-direction: column;
    }

    .direct_contreact_t_bt {
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
}

.share_cont_h {
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 20px;
}

.dir_cont_shr_link .only_client_tag {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 15px;
}

.shr_link_inp_box {
    width: 100%;
    padding-bottom: 40px;
}

.shr_link_inp_box .share_input {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    outline: none;
    padding: 3px 8px;
}

.share_popup_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding-top: 10px;
}

.decline_direct_thank {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share_popup_btn .close_Link_btn {
    border: 1px solid #6d2ef1;
    font-size: 16px;
    font-weight: 500;
    color: #6d2ef1;
    background: #fff;
    border-radius: 46px;
    padding: 5px 12px;
}

.share_popup_btn .copy_link_btn {
    background: #6d2ef1;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding: 6px 16px;
    border: none;
    border-radius: 30px;
}

.decline_direct_thank .copy_link_btn {
    background: #6d2ef1;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding: 6px 16px;
    border: none;
    border-radius: 30px;
}

/* direct contract get help popup */

.direct_get_help_popup {
    width: 600px;
    border-radius: 10px;
}

@media (max-width: 650px) {
    .direct_get_help_popup {
        width: unset;
    }
}

.direct_get_help_popup .help_get_link {
    color: #6d2ef1;
    font-size: 22px;
    text-decoration: underline;
    padding-bottom: 20px;
}

.decline_head_tag {
    text-align: center;
    font-size: 38px;
    font-weight: 500;
    padding-bottom: 20px;
}

.direct_get_help_popup .getHelp_tag {
    color: #b2b2b2;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.widthdraw_img {
    height: 200px;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 35px;
}

.widthdraw_img img {
    max-width: 100%;
    height: 100%;
}

.withdraw_h_tag {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 20px;
}

.withdraw_p_tag {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 20px;
}

@media (max-width: 374px) {
    .share_popup_btn {
        gap: 10px;
        justify-content: flex-start;
        flex-wrap: wrap-reverse;
    }
}

/* Archived Direct contract list */

.dir_withdrawal_days {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 600;
}

.dir_withdrawal_days .big_circle_wait {
    color: #b2b2b2;
    font-size: 11px;
    padding-right: 3px;
}

.dir_withdrawal_days .small_circle_wait {
    font-size: 6px;
}

/* direct contract pament method  */

.payment_direct_check_cont {
    padding-bottom: 30px;
    border-bottom: 1px solid #d4d4d5;
    margin-bottom: 30px;
}

.payment_message_box {
    background: #d4d4d5;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 3px;
}

.payment_message_box .pay_message {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
}

.payment_message_box .pay_message-note {
    word-wrap: break-word;
}

.payment_continue {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment_continue .payment_continue_btn {
    background-color: #6d2ef1;
    color: #fff;
    font: medium 18px/30px !important;
    font-weight: bold;
    border-radius: 60px !important;
    padding: 11px 45px;
    margin-top: 10px;
    min-width: 160px;
    margin-right: 15px;
    border: #6d2ef1 solid 1px;
    -webkit-border-radius: 60px !important;
}

.payment_continue .payment_continue_disabled_btn {
    background-color: #d4d4d5;
    color: #fff;
    font: medium 18px/30px;
    font-weight: bold;
    border-radius: 60px;
    padding: 11px 45px;
    margin-top: 10px;
    min-width: 160px;
    margin-right: 15px;
    border: #d4d4d5 solid 1px;
    -webkit-border-radius: 60px;
}

.payment_continue .payment_decline_btn {
    color: #6d2ef1;
    font-weight: bold;
    border-radius: 60px;
    background: #fff;
    padding: 11px 45px;
    margin-top: 5px;
    min-width: 160px;
    margin-right: 15px;
    border: #6d2ef1 solid 1px;
    -webkit-border-radius: 60px !important;
}

.byo_get_started_main {
    margin: 8% auto;
    width: 70%;
}

.getStartedBtnDisabled:disabled {
    border-color: #000;
}

.byo_offer_details_main {
    margin-top: 5%;
}

@media (max-width: 768px) {
    .byo_offer_details_hidden {
        display: none !important;
    }
}

.back_next_gapx {
    row-gap: 50px;
    /* decline contract popup */
}

.dir_cont_dicline {
    /* width: 800px; */
    border-radius: 10px;
}

@media (max-width: 650px) {
    .dir_cont_dicline {
        width: unset;
    }
}

.direct_decline_cont .decline_res {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
}

.direct_decline_cont .declin_textarea_box {
    width: 100%;
    margin-bottom: 10px;
}

.direct_decline_cont .declin_textarea_box textarea {
    width: 100%;
    outline: none;
    padding: 12px;
    border: 1px solid #b2b2b2;
    border-radius: 10px;
}

.contract_decline_box {
    font-size: 12px;
    font-weight: 400;
    background: #9f0000;
    padding: 5px 15px;
    color: #fff;
    border-radius: 30px;
}

.body-scroll-lock {
    overflow: hidden;
}

.verification_popup {
    max-width: 800px;
    padding: 30px;
    border-radius: $popup-border-radius;
    box-shadow: $popup-shadow;
    overflow: visible;
    display: flex;
    flex-direction: column;

    .popup_form_element {
        margin-top: 10px;

        * {
            font-size: 13px;
        }
    }

    .popup_body {
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        bottom: 0;
    }

    .popup_footer {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #c9c9c9;
        padding-top: 20px;
        margin-top: 20px;
    }
}

.carousel-indicators {
    display: none;
}

.loading-input {
    background: #ebebeb;
    height: 43px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        width: 40%;
        height: 300%;
        position: absolute;
        top: -100%;
        transform: rotate(20deg);
        background: linear-gradient(to right, #ebebeb 0%, #ffffff 50%, #ebebeb 100%);

        animation-name: loading-input-animation;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes loading-input-animation {
    from { left: -20% }
    30% { left: 120% }
    to { left: 120% }
}