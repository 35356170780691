$popup-border-radius: 6px;
$popup-shadow: 0 0 10px #0004;

$theme-colors: (
    "primary": #6d2ef1,
);

:root {
    --theme-color-unify: #6d2ef1;
    --bs-link-hover-color: var(--theme-color-unify) !important;
    --swiper-theme-color: var(--theme-color-unify) !important;
    --bs-link-color: var(--theme-color-unify) !important;
    --font: "Poppins", sans-serif;

    --color-accent: #6d2ef1;
    --text-color: #222222;
    --input-border: 2px solid #ebebeb;
    --border-color: #ebebeb;
    --border-color-dark: #cccccc;
}