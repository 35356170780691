
.carouser-home {
    margin-top: -90px;
    padding-top: 90px;
}

@media (max-width: 481px) {
    .home_top_header .home_left {
        margin-top: 10px !important;
    }

    .active_btn {
        font-size: 14px;
        padding: 9px 9px !important;
    }
}