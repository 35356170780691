
input {
    font-family: inherit;
    font-size: 13px;
}

.unf-input-group {
    position: relative;

    svg {
        fill: var( --theme-color-unify);
    }
}

.unf-input-text {
    border: var(--input-border);
    border-radius: 5px;
    display: block;
    width: 100%;
    min-height: 40px;
    padding: 6px 10px;
    outline: none;
    transition: 0.2s;

    &:focus {
        border: 2px solid var( --theme-color-unify);
    }
}

.unf-input-icon {
    position: absolute;
    right: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #303030;

    .fa {
        font-size: 20px;
    }
}

/*------- OLD BUTTONS ----------------------------------------*/

.btn:hover {
    border-color: inherit;
    color: inherit;
}